var currentEncounterId = 0;

function automCompletePop(inp)
{
    //show link for input
    var link = inp.parentNode.querySelector('.monster-link');

    if (link != null)
    {
		link.style.display = 'block';
	}

	var monster = null;

	for (var i=0;i<monsters.length;i++)
	{
		if (monsters[i].name == inp.value)
		{
		  var monster = monsters[i];
		  break;
		}
	}

	if (monster != null)
	{
		var row = inp.parentNode.parentNode;

		link.href = formatLink(monster.name);

		var monsterInput = row.querySelector('.monster-input');
		monsterInput.setAttribute('monster_id', monster.id);

		var hpInput = row.querySelector('.hp-input');
		hpInput.value = monster.HP;

		var dexMod = parseInt((monster.DEX - 10) / 2);
		var initInput = row.querySelector('.initiative');
		initInput.setAttribute('mod', dexMod);

		var hpd = monster.HPD;		
		var hpInput = row.querySelector('.hp-input');
		hpInput.setAttribute('hpd', hpd);

		var hpDice = hpInput.parentNode.querySelector('.dice');
		hpDice.style.display = 'none';

		var acInput = row.querySelector('.ac-input');
		acInput.value = monster.AC;
	}
}

var monsterNames = [];

for (var i=0;i<monsters.length;i++)
{
  monsterNames.push(monsters[i].name);
}

var gridBody = document.getElementById('initiative-body');

if (gridBody != null)
{
	var pckry = new Packery(gridBody, {
	  gutter: 10
	});
}

var addButton = document.getElementById('add-button');

if (addButton != null)
{
	addButton.addEventListener('click', addRow, false);
}

function addRow(data)
{
	pckry.shiftLayout();
	var row = document.createElement('div');
	row.className = 'grid-row';

	//dragable handle
	var handle = document.createElement('i');
	handle.className = 'row-handle material-icons';
	handle.innerHTML = 'drag_indicator';
	row.appendChild(handle);

	//cells
	for (var i=0;i<4;i++)
	{
		//cell
		var td = document.createElement('div');
		td.className = 'grid-td';
		row.appendChild(td);

		//input
		var input = document.createElement('input');
		input.type = 'text';

        var initiativeBonus = 0;
	    var monsterId = 0;

	    if (typeof(data) != 'undefined')
	    {
	      var dataChecks = [
	        typeof(data.monster_id) 	  != 'undefined',
	        typeof(data.initiative) 	  != 'undefined',
	        typeof(data.initiative_bonus) != 'undefined',
	        typeof(data.name) 			  != 'undefined',
	        typeof(data.hp) 			  != 'undefined',
	        typeof(data.ac) 			  != 'undefined',
	      ]
	      

	      if (!dataChecks.includes(false))
	      {
	        switch (i)
	        {
	          case 0:
	            input.value = data.initiative;
	            initiativeBonus = data.initiative_bonus;
	            break;
	          case 1:
	            input.value = data.name;
	            monsterId = data.monster_id;
	            break;
	          case 2:
	            input.value = data.hp;
	            monsterId = data.monster_id;
	            break;
	          case 3:
	            input.value = data.ac;
	            break;
	        }
	      }
	    }

		td.appendChild(input);

		if (i == 0)
		{
	  		input.setAttribute('mod', initiativeBonus);

			input.addEventListener('input', function() {
				var dexMod = this.parentNode.querySelector('.mod');

				if (dexMod != null)
				{
					dexMod.innerHTML = '';
				}
			}, false);

			var mod = document.createElement('div');
			mod.className = 'mod';
			td.appendChild(mod);

			//add dice to initiative input
			input.className = 'initiative';
			var dice = document.createElement('div');
			dice.className = 'dice';

			(function() {
				var cell = input;

				dice.addEventListener('click', function() {
					var mod = parseInt(cell.getAttribute('mod'));
					cell.value = Math.floor(Math.random() * 20) + 1 + parseInt(mod);
					var modSymbol = parseInt(mod) > -1 ? '+' : '';
					cell.parentNode.querySelector('.mod').innerHTML = '('+modSymbol+mod+')';
					saveSession();
				}, false);
			}());

			td.appendChild(dice);
		}

		if (i == 1)
		{
			input.className = 'monster-input';
			autocomplete(input, monsterNames);
			
			(()=> {
				const monsterInput = input;
				setTimeout(() => {
					monsterInput.focus();
				}, 10);
			})();

			//create button
			var monsterLinkButton = document.createElement('a');
			monsterLinkButton.className = 'monster-link';
			monsterLinkButton.target = '_blank';
			td.appendChild(monsterLinkButton);

			input.setAttribute('monster_id', monsterId);

			(function() {
				var mlButton = monsterLinkButton;
				input.addEventListener('change', function() {
					if (this.value.trim() == '')
					{
						this.setAttribute('monster_id', '0');
						mlButton.style.display = 'none';
					}
				});
			}());

			if (monsterId > 0)
			{
				for (var m=0;m<monsters.length;m++)
				{
					if (monsters[m].id == monsterId)
					{
						monsterLinkButton.href = formatLink(monsters[m].name);
						monsterLinkButton.style.display = 'block';
						break;
					}
				}
			}

			//button icon
			var monsterLinkIcon = document.createElement('i');
			monsterLinkIcon.className = 'material-icons';
			monsterLinkIcon.innerHTML = 'link';
			monsterLinkButton.appendChild(monsterLinkIcon);
		}

		if (i == 0 || i == 2)
		{
			//number modifier
			(function() {
				var gridRow = row;
				var cell = td;
				var cellInput = input;

				cellInput.addEventListener('focus', function() {
					var modContainer = document.getElementById('mod-container');

					if (modContainer != null && modContainer.parentNode != cell)
					{
						modContainer.parentNode.removeChild(modContainer);
					}

					modContainer = document.getElementById('mod-container');

					if (modContainer == null)
					{
						//container
						modContainer = document.createElement('div');
						modContainer.id = 'mod-container';
						cell.appendChild(modContainer);

						//top arrow
						var tri = document.createElement('div');
						tri.className = 'arrow-up';
						modContainer.appendChild(tri);

						//number input
						var modInput = document.createElement('input');
						modInput.type = 'number';
						modInput.value = '1';
						modContainer.appendChild(modInput);

						//plus button
						var plusButton = document.createElement('div');
						plusButton.innerHTML = '+';
						plusButton.className = 'mod-button noselect';
						plusButton.id = 'plus-mod-button';
						modContainer.appendChild(plusButton);

						plusButton.addEventListener('click', function() {
							var cellInputValue = isNaN(parseInt(cellInput.value)) ? 0 : parseInt(cellInput.value);
							var modInputValue = isNaN(parseInt(modInput.value)) ? 0 : parseInt(modInput.value);
							cellInput.value = cellInputValue + modInputValue;
							hpUpdate.call(cellInput, gridRow);
							saveSession();
						}, false);

						//subtract button
						var subtractButton = document.createElement('div');
						subtractButton.innerHTML = '-';
						subtractButton.className = 'mod-button noselect';
						subtractButton.id = 'subtract-mod-button';
						modContainer.appendChild(subtractButton);

						subtractButton.addEventListener('click', function() {
							var cellInputValue = isNaN(parseInt(cellInput.value)) ? 0 : parseInt(cellInput.value);
							var modInputValue = isNaN(parseInt(modInput.value)) ? 0 : parseInt(modInput.value);
							cellInput.value = cellInputValue - modInputValue;
							hpUpdate.call(cellInput, gridRow);
							saveSession();
						}, false);
					}
				}, false);
			}());
		}

		if (i == 2)
		{
	  		input.className = 'hp-input';
	  		var hpd = null;

	  		//add dice to hp input
			var dice = document.createElement('div');
			dice.className = 'dice';

			if (hpd == null && monsterId > 0)
			{				
				for (var m=0;m<monsters.length;m++)
				{					
					if (monsters[m].id == monsterId)
					{
						hpd = monsters[m].HPD;
						break;
					}
				}
			}

			dice.style.display = hpd == null ? 'none' : 'block';

			(function() {
				var cell = input;
				var hitDice = hpd;

				dice.addEventListener('click', function() {
					if (cell.hasAttribute('hpd'))
			  		{
			  			hitDice = cell.getAttribute('hpd');
			  			hitDice = hitDice.split(',');
			  		}			  		

					var value = 0;
					for (var d=0;d<parseInt(hitDice[0]);d++)
					{
						value += Math.floor(Math.random() * parseInt(hitDice[1])) + 1;
					}
					value = value + parseInt(hitDice[2]);
					value = value > 0 ? value : 1;

					cell.value = value;
					saveSession();
				}, false);
			}());

			td.appendChild(dice);
		}

		(function() {
			var hpRow = row;
			var index = i;

			input.addEventListener('change', function()
			{
				if (index == 2)
				{
					hpUpdate.call(this, hpRow);
				}

				saveSession();
			}, false);
		}());

	    if (i == 3)
	    {
	      input.className = 'ac-input';
	    }
    }

    //delete row button
	var deleteButton = document.createElement('span');
	deleteButton.className = 'row-delete material-icons';
	deleteButton.innerHTML = 'delete';
	deleteButton.addEventListener('click', function() {
		pckry.remove(row);
		pckry.shiftLayout();
		saveSession();
	}, false);
	row.appendChild(deleteButton);

	gridBody.appendChild(row);

	//binds drag
	var draggie = new Draggabilly(row, {
		axis: 'y',
		handle: '.row-handle'
	});
	pckry.bindDraggabillyEvents(draggie);
	pckry.appended(row);
}

//var set
var roundNumber = 1;
var turnNumber = 0;

//add monster links
var monsterInput = document.querySelectorAll('.monster-input');
for (var i=0;i<monsterInput.length;i++)
{
	if (monsterInput[i].value != "" && monsterNames.includes(monsterInput[i].value))
	{
		automCompletePop(monsterInput[i]);
	}
}

//add dex mods
var initiativeInput = document.querySelectorAll('.initiative');;
for (var i=0;i<initiativeInput.length;i++)
{
	if (initiativeInput[i].value != "")
	{
		var mod = initiativeInput[i].getAttribute('mod');
		var modSymbol = parseInt(mod) > -1 ? '+' : '';

		initiativeInput[i].parentNode.querySelector('.mod').innerHTML = '('+modSymbol+mod+')';
	}
}

var firstInit = document.querySelector('.initiative');
//firstInit.focus();

var sortButton = document.getElementById('sort-button');

if (sortButton != null)
{
	sortButton.addEventListener('click', sortRows, false);
}

//clears mod container
document.addEventListener('click', bodyClick, false);

function formatLink(monsterName)
{
	monsterName = monsterName.replace(/ /g, '-');
	monsterName = monsterName.replace(/\'/g, '');
	monsterName = monsterName.replace(/"/g,  '');
	monsterName = monsterName.replace(/\(/g, '');
	monsterName = monsterName.replace(/\)/g, '');
	monsterName = monsterName.toLowerCase();

	return 'https://www.dndbeyond.com/monsters/' + monsterName;
}

function bodyClick(e)
{
	var targetSiblings = $(e.target).siblings();

	for (var i=0;i<targetSiblings.length;i++)
	{
		var modSibling = targetSiblings[i].id == 'mod-container';

		if (modSibling)
		{
			break;
		}
	}

	var checks = [
		e.target.closest('#mod-container') == null,
		!modSibling
	];

	if (!checks.includes(false))
	{
		var modContainer = document.getElementById('mod-container');

		if (modContainer != null)
		{
			modContainer.parentNode.removeChild(modContainer);
		}
	}
}

function hpUpdate(row)
{
	if (parseInt(this.value) <= 0)
	{
		row.classList.add('dead-row');
	}
	else
	{
		row.classList.remove('dead-row');
	}
}

function sortRows()
{
    pckry.destroy();

	var wrapper = $('#initiative-body');
    var items = wrapper.children('.grid-row');

    var orderUnsorted = [];
    var orderSorted = [];
    var initiative = [];
    var initiatives = $('#initiative-body .grid-row');

    for (var i=0;i<initiatives.length;i++)
    {
    	var number = $('.initiative', initiatives[i])[0].value;

    	orderUnsorted.push(number);
    	orderSorted.push(number);
    }

    orderSorted.sort(function(a, b){return b - a});

    for (var i=0;i<orderSorted.length;i++)
    {
    	var index = orderUnsorted.indexOf(orderSorted[i]);
    	initiative.push(index);
    	orderUnsorted[index] = -1;
    }

	wrapper.append($.map(initiative, function(v){ return items[v] }) );

	pckry = new Packery(gridBody, {
	  gutter: 10
	});

	resetDrag();

	//reset turn
	var rows = pckry.getItemElements();

	for (var i=0;i<rows.length;i++)
	{
		if (i==0)
		{
			rows[i].classList.remove('active-row');
			rows[i].classList.add('active-row');
		}
		else
		{
			rows[i].classList.remove('active-row');
		}
	}

	saveSession();
}

function resetDrag()
{
	pckry.reloadItems();

	var rows = document.getElementsByClassName('grid-row');

	for (var i=0;i<rows.length;i++)
	{
		var draggie = new Draggabilly(rows[i], {
			axis: 'y',
			handle: '.row-handle'
		});
		pckry.bindDraggabillyEvents(draggie);
	}
}

var turnButton = document.getElementById('turn-button');

if (turnButton != null)
{
	turnButton.addEventListener('click', nextTurn, false);
}

var roundCounter = document.getElementById('round-counter');

function nextTurn()
{
	var rows = pckry.getItemElements();

	for (var i=0;i<rows.length;i++)
	{
		if (rows[i].classList.contains('active-row'))
		{
			var index = (i+1) >= rows.length ? 0 : (i+1);

			var index = i+1;

			if (index >= rows.length)
			{
				roundNumber++;
				roundCounter.innerHTML = 'Round ' + roundNumber;
				index = 0;
			}

      		turnNumber = index;

			rows[index].classList.add('active-row');
			rows[i].classList.remove('active-row');

			break;
		}
	}

	saveSession();
}

var roundResetButton = document.getElementById('round-reset-button');

if (roundResetButton != null)
{
	roundResetButton.addEventListener('click', resetRoundCounter, false);
}

function resetRoundCounter()
{
	this.classList.remove('reset-click');
	addSpin(this);

	roundNumber = 1;
	roundCounter.innerHTML = 'Round ' + roundNumber;

	saveSession();
}

function addSpin(elem)
{
	setTimeout(function() {
		elem.classList.add('reset-click');
	}, 10);
}

var clearButton = document.getElementById('clear-button');

if (clearButton != null)
{
	clearButton.addEventListener('click', clearGridCheck, false);
}

function clearGridCheck()
{
	swal({
		title: "Are you sure?",
		text: "Once cleared, you will not be able to recover this list.",
		icon: "warning",
		buttons: true,
		dangerMode: true,
	})
	.then((willDelete) => {
		if (willDelete)
		{
			clearGrid();
			saveSession();

			//adds empty rows
			for (var i=0;i<5;i++)
			{
				addRow();
			}

			//make first row active
			pckry.getItemElements()[0].classList.add('active-row');
		}
	});
}

function clearGrid()
{
	//clear grid here
	turnNumber = 0;
	pckry.destroy();
	gridBody.innerHTML = "";

	pckry = new Packery(gridBody, {
		gutter: 10
	});

	localStorage.removeItem("data");
}

var statusMessage = document.getElementById('status-message');

var saveTimeout;
var saveTimeoutDuration = 1000;

function saveSession()
{
	clearTimeout(saveTimeout);

	saveTimeout = setTimeout(function() {
		statusMessage.style.visibility = 'visible';
		statusMessage.style.opacity = '1';

		statusMessage.innerHTML = 'Saving...';

		var data = {
			id: currentEncounterId,
			name: document.querySelector('#encounter-name').innerText,
			round: roundNumber,
			turn: turnNumber,
			rows: []
		};

		var row = pckry.getItemElements();

		for (var i=0;i<row.length;i++)
		{
			var inputs = row[i].querySelectorAll('input');

			var dataRow = {
			  monster_id: row[i].querySelector('.monster-input').getAttribute('monster_id'),
			  initiative: row[i].querySelector('.initiative').value,
			  initiative_bonus: row[i].querySelector('.initiative').getAttribute('mod'),
			  name: row[i].querySelector('.monster-input').value,
			  hp: row[i].querySelector('.hp-input').value,
			  ac: row[i].querySelector('.ac-input').value
			}

			var validRow = false;

			for (var property in dataRow)
			{
				if (dataRow.hasOwnProperty(property))
				{
					var value = dataRow[property];

					if (value !== '' && parseInt(value) !== 0)
					{
						validRow = true;
						break;
					}
				}
			}

			if (validRow)
			{
				data.rows.push(dataRow);
			}
		}

		$.ajax({
			method: "POST",
			url: "/index.php?route=tracker/tracker/saveSession",
			data: data,
			dataType: 'json'
		})
		.done(function(data) {

			if ('limit' in data)
			{
				swal({
					title: data.limit.title,
					text: data.limit.description,				
  					buttons: ["Cancel", "Upgrade"],
				}).then((result) => {
				  if (result) {
				    window.location = '/subscribe';
				  }
				});

				statusMessage.innerHTML = 'Couldn\'t Save Changes. Limit Reached.';
			}
			else if ('encounter_id' in data)
			{
				currentEncounterId = data.encounter_id;

				statusMessage.innerHTML = 'All Changes Saved';
			}
			else if ('error' in data)
			{
				statusMessage.innerHTML = data.error;
			}
			else
			{
				statusMessage.innerHTML = 'Error: Couldn\'t Save Encounter';
			}
			
			hideStatus();
		})
		.fail(function(data) {
			statusMessage.innerHTML = 'Error: Couldn\'t Save Encounter';
			hideStatus();
		});

		//save locally
		localStorage.setItem("data", JSON.stringify(data));
	}, saveTimeoutDuration);
}

function hideStatus()
{
	setTimeout(function() {
		statusMessage.style.opacity = '0';

		setTimeout(function() {
			statusMessage.style.visibility = 'hidden';
			statusMessage.innerHTML = '';
		}, 1100);
	}, 5000);
}

function getSession(encounter_id)
{
	$.ajax({
		method: "POST",
		url: "/index.php?route=tracker/tracker/loadSession",
		data: {
			encounter_id: encounter_id
		}
	})
	.done(function(data) {
		var data = JSON.stringify(JSON.parse(data).data);

		currentEncounterId = encounter_id;
		loadSession(data);
	});
}

var encounterListContainer = document.querySelector('#encounter-list-container');
var encounterListCover = document.querySelector('#encounter-list-cover');

if (encounterListCover != null)
{
	encounterListCover.addEventListener('click', closeEncounterList, false);
}

function getEncounters()
{
	$.ajax({
		method: "POST",
		url: "/index.php?route=tracker/tracker/getEncounters",
	})
	.done(function(data) {
		encounterListCover.style.display = 'block';
		encounterListContainer.innerHTML = "";

		data = JSON.parse(data).data;

		if (data.length > 0)
		{
			for (var i=0;i<data.length;i++)
			{
				var encounterRow = document.createElement('div');
				encounterRow.className = 'encounter-row';

				var encounter = document.createElement('div');
				encounter.className = 'encounter';
				encounter.innerHTML = data[i].name;

				(function() {
					var encounterId = data[i].encounter_id;

					encounter.addEventListener('click', function() {
						getSession(encounterId);
						closeEncounterList();
					}, false);
				}());

				//encounter delete button
				var encounterDelete = document.createElement('div');
				encounterDelete.className = 'encoutner-delete-button';

				(function() {
					var encounterId = data[i].encounter_id;

					encounterDelete.addEventListener('click', function() {
						deleteEncounter(encounterId, true);
					}, false);
				}());

				var deleteIcon = document.createElement('i');
				deleteIcon.className = 'material-icons';
				deleteIcon.innerHTML = 'delete';
				encounterDelete.appendChild(deleteIcon);

				encounterRow.appendChild(encounter);
				encounterRow.appendChild(encounterDelete);

				encounterListContainer.appendChild(encounterRow);
			}
		}
		else
		{
			encounterListContainer.innerHTML = 'No Encounters Found';
		}

		encounterListContainer.style.display = 'block';
	});
}

function deleteEncounter(removeEncounter, openEncounters = false)
{
	swal({
		title: "Are you sure?",
		text: "Once deleted, you will not be able to recover this encounter.",
		icon: "warning",
		buttons: true,
		dangerMode: true,
	})
	.then((willDelete) => {
		if (willDelete)
		{
			$.ajax({
				method: "POST",
				url: "/index.php?route=tracker/tracker/deleteEncounter",
				data: {
					id: removeEncounter
				}
			})
			.done(function(data) {

				if (openEncounters)
				{
					getEncounters();
				}
			});
		}
	});
}

function closeEncounterList()
{
	encounterListCover.style.display = 'none';
	encounterListContainer.style.display = 'none';
}

function newEncounter()
{
	currentEncounterId = 0;
	document.querySelector('#encounter-name').innerText = 'Untitled Encounter';
	clearGrid();

	//adds empty rows
	for (var i=0;i<5;i++)
	{
		addRow();
	}

	//make first row active
	pckry.getItemElements()[0].classList.add('active-row');
}

function loadSession(data)
{
	if (data != null)
	{
		var data = JSON.parse(data);

		clearGrid();

		var encounterName = document.querySelector('#encounter-name');
		encounterName.innerText = data.encounter_name;

		encounterName.addEventListener('input', saveSession, false);

		for (var i=0;i<data.rows.length;i++)
		{
			addRow(data.rows[i]);
		}

		roundNumber = data.round;
		var roundCounter = document.getElementById('round-counter');
		roundCounter.innerHTML = 'Round ' + roundNumber;

		turnNumber = data.turn;
		var rows = pckry.getItemElements();

		for (var i=0;i<rows.length;i++)
		{
			if (i == turnNumber)
			{
				rows[i].classList.add('active-row');
			}
		}
	}
	else
	{
		for (var i=0;i<5;i++)
		{
			addRow();
		}

		//make first row active
		pckry.getItemElements()[0].classList.add('active-row');
	}
}

if (gridBody != null)
{
	//adds empty rows
	for (var i=0;i<5;i++)
	{
		addRow();
	}
}

if (typeof(pckry) != 'undefined')
{
	//make first row active
	pckry.getItemElements()[0].classList.add('active-row');

	//saves session if table is reordered
	pckry.on( 'dragItemPositioned', function(draggedItem) {
		saveSession();
	});
}

window.addEventListener('beforeunload ', function() {
	saveSession();
}, false);

window.addEventListener('unload', function() {
	saveSession();
}, false);
