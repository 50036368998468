var cookieConsentContainer = document.getElementById('cookie-consent');

function updateCookieConsent()
{
  localStorage.setItem('cookieConsent', 'true');

  if (cookieConsentContainer != null)
  {
    cookieConsentContainer.style.display = 'none';
  }
}

function checkCookieConsent()
{
  var cookieConsent = localStorage.getItem('cookieConsent');

  if (cookieConsent == null && cookieConsentContainer != null)
  {
    cookieConsentContainer.style.display = 'grid';
  }
}

checkCookieConsent();