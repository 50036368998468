
var monsters = [
  { id: 117, ruleset: 'dnd 5e', name: "Aarakocra", AC: 12, HP: 13, DEX: 14, HPD:[3, 8, 0] },
  { id: 118, ruleset: 'dnd 5e', name: "Aboleth", AC: 17, HP: 135, DEX: 9, HPD:[18, 10, 36] },
  { id: 119, ruleset: 'dnd 5e', name: "Abominable Yeti", AC: 15, HP: 137, DEX: 10, HPD:[11, 12, 66] },
  { id: 120, ruleset: 'dnd 5e', name: "Acolyte", AC: 10, HP: 9, DEX: 10, HPD:[2, 8, 0] },
  { id: 121, ruleset: 'dnd 5e', name: "Adult Black Dragon", AC: 19, HP: 195, DEX: 14, HPD: [17,12,85], HPD:[17, 12, 85] },
  { id: 122, ruleset: 'dnd 5e', name: "Adult Blue Dracolich", AC: 19, HP: 225, DEX: 10, HPD:[18, 12, 108] },
  { id: 123, ruleset: 'dnd 5e', name: "Adult Blue Dragon", AC: 19, HP: 225, DEX: 10, HPD:[26, 20, 208] },
  { id: 124, ruleset: 'dnd 5e', name: "Adult Brass Dragon", AC: 18, HP: 172, DEX: 10, HPD:[17, 20, 119] },
  { id: 125, ruleset: 'dnd 5e', name: "Adult Bronze Dragon", AC: 19, HP: 212, DEX: 10, HPD:[24, 20, 192] },
  { id: 126, ruleset: 'dnd 5e', name: "Adult Copper Dragon", AC: 18, HP: 184, DEX: 12, HPD:[20, 20, 140] },
  { id: 127, ruleset: 'dnd 5e', name: "Adult Gold Dragon", AC: 19, HP: 256, DEX: 14, HPD:[28, 20, 252] },
  { id: 128, ruleset: 'dnd 5e', name: "Adult Green Dragon", AC: 19, HP: 207, DEX: 12, HPD:[22, 20, 154] },
  { id: 129, ruleset: 'dnd 5e', name: "Adult Red Dragon", AC: 19, HP: 256, DEX: 10, HPD:[28, 20, 252] },
  { id: 130, ruleset: 'dnd 5e', name: "Adult Silver Dragon", AC: 19, HP: 243, DEX: 10, HPD:[25, 20, 225] },
  { id: 131, ruleset: 'dnd 5e', name: "Adult White Dragon", AC: 18, HP: 200, DEX: 10, HPD:[18, 20, 144] },
  { id: 132, ruleset: 'dnd 5e', name: "Aerisi Kalinoth", AC: 13, HP: 66, DEX: 16, HPD:[12, 8, 12] },
  { id: 133, ruleset: 'dnd 5e', name: "Ahmaergo", AC: 18, HP: 143, DEX: 15, HPD:[22, 8, 44] },
  { id: 134, ruleset: 'dnd 5e', name: "Air Elemental", AC: 15, HP: 90, DEX: 20, HPD:[12, 10, 24] },
  { id: 135, ruleset: 'dnd 5e', name: "Air Elemental Myrmidon", AC: 18, HP: 117, DEX: 14, HPD:[18, 8, 36] },
  { id: 136, ruleset: 'dnd 5e', name: "Allosaurus", AC: 13, HP: 51, DEX: 13, HPD:[6, 10, 18] },
  { id: 137, ruleset: 'dnd 5e', name: "Ambush Drake", AC: 13, HP: 22, DEX: 15, HPD:[4, 6, 8] },
  { id: 138, ruleset: 'dnd 5e', name: "Ammalia Cassalanter", AC: 12, HP: 45, DEX: 14, HPD:[10, 8, 0] },
  { id: 139, ruleset: 'dnd 5e', name: "Ancient Black Dragon", AC: 22, HP: 367, DEX: 14, HPD:[21, 20, 147] },
  { id: 140, ruleset: 'dnd 5e', name: "Ancient Blue Dragon", AC: 22, HP: 481, DEX: 10, HPD:[26, 20, 208] },
  { id: 141, ruleset: 'dnd 5e', name: "Ancient Brass Dragon", AC: 20, HP: 297, DEX: 10, HPD:[17, 20, 119] },
  { id: 142, ruleset: 'dnd 5e', name: "Ancient Bronze Dragon", AC: 22, HP: 444, DEX: 10, HPD:[24, 20, 192] },
  { id: 143, ruleset: 'dnd 5e', name: "Ancient Copper Dragon", AC: 21, HP: 350, DEX: 12, HPD:[20, 20, 140] },
  { id: 144, ruleset: 'dnd 5e', name: "Ancient Gold Dragon", AC: 22, HP: 546, DEX: 14, HPD:[28, 20, 252] },
  { id: 145, ruleset: 'dnd 5e', name: "Ancient Green Dragon", AC: 21, HP: 385, DEX: 12, HPD:[22, 20, 154] },
  { id: 146, ruleset: 'dnd 5e', name: "Ancient Red Dragon", AC: 22, HP: 546, DEX: 10, HPD:[28, 30, 252] },
  { id: 147, ruleset: 'dnd 5e', name: "Ancient Silver Dragon", AC: 22, HP: 487, DEX: 10, HPD:[25, 20, 225] },
  { id: 148, ruleset: 'dnd 5e', name: "Ancient White Dragon", AC: 20, HP: 333, DEX: 10, HPD:[18, 20, 144] },
  { id: 149, ruleset: 'dnd 5e', name: "Androsphinx", AC: 17, HP: 199, DEX: 10, HPD:[19, 10, 95] },
  { id: 150, ruleset: 'dnd 5e', name: "Animated Armor", AC: 18, HP: 33, DEX: 11, HPD:[6, 8, 6] },
  { id: 151, ruleset: 'dnd 5e', name: "Ankheg", AC: 14, HP: 39, DEX: 11, HPD:[6, 10, 6] },
  { id: 152, ruleset: 'dnd 5e', name: "Ankylosaurus", AC: 15, HP: 68, DEX: 11, HPD:[8, 12, 16] },
  { id: 153, ruleset: 'dnd 5e', name: "Ape", AC: 12, HP: 19, DEX: 14, HPD:[3, 8, 6] },
  { id: 154, ruleset: 'dnd 5e', name: "Apprentice Wizard", AC: 10, HP: 9, DEX: 10, HPD:[2, 8, 0] },
  { id: 155, ruleset: 'dnd 5e', name: "Aquatic Elf", AC: 13, HP: 16, DEX: 16, HPD:[2, 6, 5] },
  { id: 156, ruleset: 'dnd 5e', name: "Arcanaloth", AC: 17, HP: 104, DEX: 12, HPD:[16, 8, 32] },
  { id: 157, ruleset: 'dnd 5e', name: "Archdruid", AC: 16, HP: 132, DEX: 14, HPD:[24, 8, 24] },
  { id: 158, ruleset: 'dnd 5e', name: "Archmage", AC: 12, HP: 99, DEX: 14, HPD:[18, 8, 18] },
  { id: 159, ruleset: 'dnd 5e', name: "Assassin", AC: 15, HP: 78, DEX: 16, HPD:[12, 8, 24] },
  { id: 160, ruleset: 'dnd 5e', name: "Awakened Shrub", AC: 9, HP: 10, DEX: 8, HPD:[3, 6, 0] },
  { id: 161, ruleset: 'dnd 5e', name: "Awakened Tree", AC: 13, HP: 59, DEX: 6, HPD:[7, 12, 14] },
  { id: 162, ruleset: 'dnd 5e', name: "Axe Beak", AC: 11, HP: 19, DEX: 12, HPD:[3, 10, 3] },
  { id: 163, ruleset: 'dnd 5e', name: "Azbara Jos", AC: 13, HP: 39, DEX: 16, HPD:[6, 8, 12] },
  { id: 164, ruleset: 'dnd 5e', name: "Azer", AC: 17, HP: 39, DEX: 12, HPD:[6, 8, 12] },
  { id: 165, ruleset: 'dnd 5e', name: "Baboon", AC: 12, HP: 3, DEX: 14, HPD:[1, 6, 0] },
  { id: 166, ruleset: 'dnd 5e', name: "Badger", AC: 10, HP: 3, DEX: 11, HPD:[1, 4, 1] },
  { id: 167, ruleset: 'dnd 5e', name: "Balloon Grenadier", AC: 14, HP: 33, DEX: 16, HPD:[6, 6, 14] },
  { id: 168, ruleset: 'dnd 5e', name: "Balor", AC: 19, HP: 262, DEX: 15, HPD:[21, 12, 126] },
  { id: 169, ruleset: 'dnd 5e', name: "Bandit", AC: 12, HP: 11, DEX: 12, HPD:[2, 8, 2] },
  { id: 170, ruleset: 'dnd 5e', name: "Bandit Captain", AC: 15, HP: 65, DEX: 16, HPD:[10, 8, 20] },
  { id: 171, ruleset: 'dnd 5e', name: "Banshee", AC: 12, HP: 58, DEX: 14, HPD:[13, 8, 0] },
  { id: 172, ruleset: 'dnd 5e', name: "Barbed Devil", AC: 15, HP: 110, DEX: 17, HPD:[13, 8, 52] },
  { id: 173, ruleset: 'dnd 5e', name: "Bard", AC: 15, HP: 44, DEX: 14, HPD:[8, 8, 8] },
  { id: 174, ruleset: 'dnd 5e', name: "Barlgura", AC: 15, HP: 68, DEX: 15, HPD:[8, 10, 24] },
  { id: 175, ruleset: 'dnd 5e', name: "Barnibus Blastwind", AC: 10, HP: 24, DEX: 10, HPD:[7, 8, -7] },
  { id: 176, ruleset: 'dnd 5e', name: "Basilisk", AC: 15, HP: 52, DEX: 8, HPD:[8, 8, 16] },
  { id: 177, ruleset: 'dnd 5e', name: "Bastian Thermandar", AC: 12, HP: 78, DEX: 14, HPD:[12, 8, 24] },
  { id: 178, ruleset: 'dnd 5e', name: "Bat", AC: 12, HP: 1, DEX: 15, HPD:[1, 4, -1] },
  { id: 179, ruleset: 'dnd 5e', name: "Bearded Devil", AC: 13, HP: 52, DEX: 15, HPD:[8, 8, 16] },
  { id: 180, ruleset: 'dnd 5e', name: "Behir", AC: 17, HP: 168, DEX: 16, HPD:[16, 12, 64] },
  { id: 181, ruleset: 'dnd 5e', name: "Beholder", AC: 18, HP: 180, DEX: 14, HPD:[19, 10, 76] },
  { id: 182, ruleset: 'dnd 5e', name: "Beholder Zombie", AC: 15, HP: 93, DEX: 8, HPD:[11, 10, 33] },
  { id: 183, ruleset: 'dnd 5e', name: "Berserker", AC: 13, HP: 67, DEX: 12, HPD:[9, 8, 27] },
  { id: 184, ruleset: 'dnd 5e', name: "Black Bear", AC: 11, HP: 19, DEX: 10, HPD:[3, 8, 6] },
  { id: 185, ruleset: 'dnd 5e', name: "Black Dragon Wyrmling", AC: 17, HP: 33, DEX: 14, HPD:[6, 8, 6] },
  { id: 186, ruleset: 'dnd 5e', name: "Black Earth Guard", AC: 18, HP: 39, DEX: 11, HPD:[6, 8, 12] },
  { id: 187, ruleset: 'dnd 5e', name: "Black Earth Priest", AC: 17, HP: 45, DEX: 11, HPD:[7, 8, 14] },
  { id: 188, ruleset: 'dnd 5e', name: "Black Pudding", AC: 7, HP: 85, DEX: 5, HPD:[10, 10, 30] },
  { id: 189, ruleset: 'dnd 5e', name: "Black Viper", AC: 16, HP: 84, DEX: 18, HPD:[13, 8, 26] },
  { id: 190, ruleset: 'dnd 5e', name: "Blagothkus", AC: 17, HP: 138, DEX: 13, HPD:[12, 12, 60] },
  { id: 191, ruleset: 'dnd 5e', name: "Blink Dog", AC: 13, HP: 22, DEX: 17, HPD:[4, 8, 4] },
  { id: 192, ruleset: 'dnd 5e', name: "Blood Hawk", AC: 12, HP: 7, DEX: 14, HPD:[2, 6, 0] },
  { id: 193, ruleset: 'dnd 5e', name: "Blue Dragon Wyrmling", AC: 17, HP: 52, DEX: 10, HPD:[8, 8, 16] },
  { id: 194, ruleset: 'dnd 5e', name: "Blue Slaad", AC: 15, HP: 123, DEX: 15, HPD:[13, 10, 52] },
  { id: 195, ruleset: 'dnd 5e', name: "Boar", AC: 11, HP: 11, DEX: 11, HPD:[2, 8, 2] },
  { id: 196, ruleset: 'dnd 5e', name: "Boar Pit Fighter", AC: 15, HP: 58, DEX: 16, HPD:[6, 10, 25] },
  { id: 197, ruleset: 'dnd 5e', name: "Bodyguard", AC: 15, HP: 65, DEX: 16, HPD:[15, 8, 30] },
  { id: 198, ruleset: 'dnd 5e', name: "Bone Devil", AC: 19, HP: 142, DEX: 16, HPD:[15, 10, 60] },
  { id: 199, ruleset: 'dnd 5e', name: "Bone Naga", AC: 15, HP: 58, DEX: 16, HPD:[9, 10, 9] },
  { id: 200, ruleset: 'dnd 5e', name: "Bore Worm", AC: 18, HP: 247, DEX: 7, HPD:[15, 20, 90] },
  { id: 201, ruleset: 'dnd 5e', name: "Brass Dragon Wyrmling", AC: 16, HP: 16, DEX: 10, HPD:[3, 8, 3] },
  { id: 202, ruleset: 'dnd 5e', name: "Breex Vandermast", AC: 16, HP: 71, DEX: 14, HPD:[11, 8, 21] },
  { id: 203, ruleset: 'dnd 5e', name: "Bronze Dragon Wyrmling", AC: 17, HP: 32, DEX: 10, HPD:[5, 8, 10] },
  { id: 204, ruleset: 'dnd 5e', name: "Brown Bear", AC: 11, HP: 34, DEX: 10, HPD:[4, 10, 12] },
  { id: 205, ruleset: 'dnd 5e', name: "Bugbear", AC: 16, HP: 27, DEX: 14, HPD:[5, 8, 5] },
  { id: 206, ruleset: 'dnd 5e', name: "Bugbear Chief", AC: 17, HP: 65, DEX: 14, HPD:[10, 8, 20] },
  { id: 207, ruleset: 'dnd 5e', name: "Bulette", AC: 17, HP: 94, DEX: 11, HPD:[9, 10, 45] },
  { id: 208, ruleset: 'dnd 5e', name: "Bullywug", AC: 15, HP: 11, DEX: 12, HPD:[2, 8, 2] },
  { id: 209, ruleset: 'dnd 5e', name: "Burrowshark", AC: 18, HP: 82, DEX: 12, HPD:[11, 8, 33] },
  { id: 210, ruleset: 'dnd 5e', name: "Cambion", AC: 19, HP: 82, DEX: 18, HPD:[11, 8, 33] },
  { id: 211, ruleset: 'dnd 5e', name: "Camel", AC: 9, HP: 15, DEX: 8, HPD:[2, 10, 4] },
  { id: 212, ruleset: 'dnd 5e', name: "Captain Othelstan", AC: 19, HP: 93, DEX: 10, HPD:[11, 10, 33] },
  { id: 213, ruleset: 'dnd 5e', name: "Carrion Crawler", AC: 13, HP: 51, DEX: 13, HPD:[6, 10, 18] },
  { id: 214, ruleset: 'dnd 5e', name: "Cat", AC: 12, HP: 2, DEX: 15, HPD:[1, 4, 0] },
  { id: 215, ruleset: 'dnd 5e', name: "Centaur", AC: 12, HP: 45, DEX: 14, HPD:[6, 10, 12] },
  { id: 216, ruleset: 'dnd 5e', name: "Chain Devil", AC: 16, HP: 85, DEX: 15, HPD:[10, 8, 40] },
  { id: 217, ruleset: 'dnd 5e', name: "Champion", AC: 18, HP: 143, DEX: 15, HPD:[22, 8, 44] },
  { id: 218, ruleset: 'dnd 5e', name: "Chasme", AC: 15, HP: 84, DEX: 15, HPD:[13, 10, 13] },
  { id: 219, ruleset: 'dnd 5e', name: "Chimera", AC: 14, HP: 114, DEX: 11, HPD:[12, 10, 48] },
  { id: 220, ruleset: 'dnd 5e', name: "Chuul", AC: 16, HP: 93, DEX: 10, HPD:[11, 10, 33] },
  { id: 221, ruleset: 'dnd 5e', name: "Clay Golem", AC: 14, HP: 133, DEX: 9, HPD:[14, 10, 56] },
  { id: 222, ruleset: 'dnd 5e', name: "Cloak of Mulmaster", AC: 12, HP: 22, DEX: 14, HPD:[4, 6, 10] },
  { id: 223, ruleset: 'dnd 5e', name: "Cloaker", AC: 14, HP: 78, DEX: 15, HPD:[12, 10, 12] },
  { id: 224, ruleset: 'dnd 5e', name: "Cloud Giant", AC: 14, HP: 200, DEX: 10, HPD:[16, 12, 96] },
  { id: 225, ruleset: 'dnd 5e', name: "Cockatrice", AC: 11, HP: 27, DEX: 12, HPD:[6, 6, 6] },
  { id: 226, ruleset: 'dnd 5e', name: "Commoner", AC: 10, HP: 4, DEX: 10, HPD:[1, 8, 0] },
  { id: 227, ruleset: 'dnd 5e', name: "Constrictor Snake", AC: 12, HP: 13, DEX: 14, HPD:[2, 10, 2] },
  { id: 228, ruleset: 'dnd 5e', name: "Copper Dragon Wyrmling", AC: 16, HP: 22, DEX: 12, HPD:[4, 8, 4] },
  { id: 229, ruleset: 'dnd 5e', name: "Couatl", AC: 19, HP: 97, DEX: 20, HPD:[13, 8, 39] },
  { id: 230, ruleset: 'dnd 5e', name: "Crab", AC: 11, HP: 2, DEX: 11, HPD:[1, 4, 0] },
  { id: 231, ruleset: 'dnd 5e', name: "Crawling Claw", AC: 12, HP: 2, DEX: 14, HPD:[1, 4, 0] },
  { id: 232, ruleset: 'dnd 5e', name: "Crocodile", AC: 12, HP: 19, DEX: 10, HPD:[3, 10, 3] },
  { id: 233, ruleset: 'dnd 5e', name: "Crushing Wave Priest", AC: 13, HP: 52, DEX: 11, HPD:[8, 8, 16] },
  { id: 234, ruleset: 'dnd 5e', name: "Crushing Wave Reaver", AC: 14, HP: 22, DEX: 14, HPD:[4, 8, 4] },
  { id: 235, ruleset: 'dnd 5e', name: "Cult Assassin", AC: 15, HP: 32, DEX: 16, HPD:[4, 10, 12] },
  { id: 236, ruleset: 'dnd 5e', name: "Cult Fanatic", AC: 13, HP: 33, DEX: 14, HPD:[6, 8, 6] },
  { id: 237, ruleset: 'dnd 5e', name: "Cultist", AC: 12, HP: 9, DEX: 12, HPD:[2, 8, 0] },
  { id: 238, ruleset: 'dnd 5e', name: "Cyclops", AC: 14, HP: 138, DEX: 11, HPD:[12, 12, 60] },
  { id: 239, ruleset: 'dnd 5e', name: "Dao", AC: 18, HP: 187, DEX: 12, HPD:[15, 10, 105] },
  { id: 240, ruleset: 'dnd 5e', name: "Dark Tide Knight", AC: 13, HP: 58, DEX: 16, HPD:[9, 8, 18] },
  { id: 241, ruleset: 'dnd 5e', name: "Darkmantle", AC: 11, HP: 22, DEX: 12, HPD:[5, 6, 5] },
  { id: 242, ruleset: 'dnd 5e', name: "Davil Starsong", AC: 15, HP: 82, DEX: 14, HPD:[15, 8, 15] },
  { id: 243, ruleset: 'dnd 5e', name: "Death Dog", AC: 12, HP: 39, DEX: 14, HPD:[6, 8, 12] },
  { id: 244, ruleset: 'dnd 5e', name: "Death Knight", AC: 20, HP: 180, DEX: 11, HPD:[19, 8, 95] },
  { id: 245, ruleset: 'dnd 5e', name: "Death Slaad", AC: 18, HP: 170, DEX: 15, HPD:[20, 8, 80] },
  { id: 246, ruleset: 'dnd 5e', name: "Death Tyrant", AC: 19, HP: 187, DEX: 14, HPD:[25, 10, 50] },
  { id: 247, ruleset: 'dnd 5e', name: "Deep Gnome (Svirfneblin)", AC: 15, HP: 16, DEX: 14, HPD:[3, 6, 6] },
  { id: 248, ruleset: 'dnd 5e', name: "Deer", AC: 13, HP: 4, DEX: 16, HPD:[1, 8, 0] },
  { id: 249, ruleset: 'dnd 5e', name: "Demilich", AC: 20, HP: 80, DEX: 20, HPD:[32, 4, 0] },
  { id: 250, ruleset: 'dnd 5e', name: "Derro", AC: 13, HP: 13, DEX: 14, HPD:[3, 6, 3] },
  { id: 251, ruleset: 'dnd 5e', name: "Derro Savant", AC: 13, HP: 49, DEX: 14, HPD:[8, 6, 8] },
  { id: 252, ruleset: 'dnd 5e', name: "Deva", AC: 17, HP: 136, DEX: 18, HPD:[16, 8, 64] },
  { id: 253, ruleset: 'dnd 5e', name: "Dire Wolf", AC: 14, HP: 37, DEX: 15, HPD:[5, 10, 10] },
  { id: 254, ruleset: 'dnd 5e', name: "Displacer Beast", AC: 13, HP: 85, DEX: 15, HPD:[10, 10, 30] },
  { id: 255, ruleset: 'dnd 5e', name: "Djinni", AC: 17, HP: 161, DEX: 15, HPD:[14, 10, 84] },
  { id: 256, ruleset: 'dnd 5e', name: "Doppelganger", AC: 14, HP: 52, DEX: 18, HPD:[8, 8, 16] },
  { id: 257, ruleset: 'dnd 5e', name: "Doril Cire, Fire Cultist", AC: 13, HP: 11, DEX: 14, HPD:[2, 8, 0] },
  { id: 258, ruleset: 'dnd 5e', name: "Draft Horse", AC: 10, HP: 19, DEX: 10, HPD:[3, 10, 3] },
  { id: 259, ruleset: 'dnd 5e', name: "Dragon Turtle", AC: 20, HP: 341, DEX: 10, HPD:[22, 20, 110] },
  { id: 260, ruleset: 'dnd 5e', name: "Dragonclaw", AC: 14, HP: 16, DEX: 16, HPD:[3, 8, 3] },
  { id: 261, ruleset: 'dnd 5e', name: "Dragonfang", AC: 15, HP: 78, DEX: 16, HPD:[12, 8, 24] },
  { id: 262, ruleset: 'dnd 5e', name: "Dragonsoul", AC: 16, HP: 110, DEX: 18, HPD:[17, 8, 34] },
  { id: 263, ruleset: 'dnd 5e', name: "Dragonwing", AC: 14, HP: 33, DEX: 16, HPD:[6, 8, 6] },
  { id: 264, ruleset: 'dnd 5e', name: "Dralmorrer Borngray", AC: 16, HP: 52, DEX: 14, HPD:[7, 10, 14] },
  { id: 265, ruleset: 'dnd 5e', name: "Drannin Splithelm", AC: 18, HP: 93, DEX: 10, HPD:[11, 8, 44] },
  { id: 266, ruleset: 'dnd 5e', name: "Dretch", AC: 11, HP: 18, DEX: 11, HPD:[4, 6, 4] },
  { id: 267, ruleset: 'dnd 5e', name: "Drider", AC: 19, HP: 123, DEX: 16, HPD:[13, 10, 52] },
  { id: 268, ruleset: 'dnd 5e', name: "Drow", AC: 15, HP: 13, DEX: 14, HPD:[3, 8, 0] },
  { id: 269, ruleset: 'dnd 5e', name: "Drow Elite Warrior", AC: 18, HP: 71, DEX: 18, HPD:[11, 8, 22] },
  { id: 270, ruleset: 'dnd 5e', name: "Drow Gunslinger", AC: 18, HP: 84, DEX: 18, HPD:[13, 8, 26] },
  { id: 271, ruleset: 'dnd 5e', name: "Drow Mage", AC: 12, HP: 45, DEX: 14, HPD:[10, 8, 0] },
  { id: 272, ruleset: 'dnd 5e', name: "Drow Priestess of Lolth", AC: 16, HP: 71, DEX: 14, HPD:[13, 8, 13] },
  { id: 273, ruleset: 'dnd 5e', name: "Drow Spore Servant", AC: 15, HP: 13, DEX: 14, HPD:[3, 8, 0] },
  { id: 274, ruleset: 'dnd 5e', name: "Druid", AC: 11, HP: 27, DEX: 12, HPD:[5, 8, 5] },
  { id: 275, ruleset: 'dnd 5e', name: "Dryad", AC: 11, HP: 22, DEX: 12, HPD:[5, 8, 0] },
  { id: 276, ruleset: 'dnd 5e', name: "Duergar", AC: 16, HP: 26, DEX: 11, HPD:[4, 8, 8] },
  { id: 277, ruleset: 'dnd 5e', name: "Duergar Kavalrachni", AC: 16, HP: 26, DEX: 11, HPD:[4, 8, 8] },
  { id: 278, ruleset: 'dnd 5e', name: "Duergar Spore Servant", AC: 16, HP: 26, DEX: 11, HPD:[4, 8, 8] },
  { id: 279, ruleset: 'dnd 5e', name: "Duodrone", AC: 15, HP: 11, DEX: 13, HPD:[2, 8, 2] },
  { id: 280, ruleset: 'dnd 5e', name: "Durnan", AC: 16, HP: 144, DEX: 15, HPD:[17, 8, 68] },
  { id: 281, ruleset: 'dnd 5e', name: "Dust Mephit", AC: 12, HP: 17, DEX: 14, HPD:[5, 6, 0] },
  { id: 282, ruleset: 'dnd 5e', name: "Eagle", AC: 12, HP: 3, DEX: 15, HPD:[1, 6, 0] },
  { id: 283, ruleset: 'dnd 5e', name: "Earth Elemental", AC: 17, HP: 126, DEX: 8, HPD:[12, 10, 60] },
  { id: 284, ruleset: 'dnd 5e', name: "Earth Elemental Myrmidon", AC: 18, HP: 127, DEX: 10, HPD:[17, 8, 51] },
  { id: 285, ruleset: 'dnd 5e', name: "Efreeti", AC: 17, HP: 200, DEX: 12, HPD:[16, 10, 112] },
  { id: 286, ruleset: 'dnd 5e', name: "Elemental Anchor", AC: 14, HP: 33, DEX: 16, HPD:[6, 6, 14] },
  { id: 287, ruleset: 'dnd 5e', name: "Elephant", AC: 12, HP: 76, DEX: 9, HPD:[8, 12, 24] },
  { id: 288, ruleset: 'dnd 5e', name: "Elizar Dryflagon", AC: 14, HP: 71, DEX: 15, HPD:[11, 8, 22] },
  { id: 289, ruleset: 'dnd 5e', name: "Elk", AC: 10, HP: 13, DEX: 10, HPD:[2, 10, 2] },
  { id: 290, ruleset: 'dnd 5e', name: "Empyrean", AC: 22, HP: 313, DEX: 21, HPD:[19, 12, 190] },
  { id: 291, ruleset: 'dnd 5e', name: "Erinyes", AC: 18, HP: 153, DEX: 16, HPD:[18, 8, 72] },
  { id: 292, ruleset: 'dnd 5e', name: "Eternal Flame Guardian", AC: 17, HP: 45, DEX: 13, HPD:[7, 8, 14] },
  { id: 293, ruleset: 'dnd 5e', name: "Eternal Flame Priest", AC: 12, HP: 52, DEX: 15, HPD:[8, 8, 16] },
  { id: 294, ruleset: 'dnd 5e', name: "Ettercap", AC: 13, HP: 44, DEX: 15, HPD:[8, 8, 8] },
  { id: 295, ruleset: 'dnd 5e', name: "Ettin", AC: 12, HP: 85, DEX: 8, HPD:[10, 10, 30] },
  { id: 296, ruleset: 'dnd 5e', name: "Evil Mage", AC: 12, HP: 22, DEX: 14, HPD:[5, 8, 0] },
  { id: 297, ruleset: 'dnd 5e', name: "Evil Sorcerer", AC: 12, HP: 22, DEX: 14, HPD:[7, 8, 14] },
  { id: 298, ruleset: 'dnd 5e', name: "Faerie Dragon (older)", AC: 15, HP: 14, DEX: 20, HPD:[4, 4, 4] },
  { id: 299, ruleset: 'dnd 5e', name: "Faerie Dragon (younger)", AC: 15, HP: 14, DEX: 20, HPD:[4, 4, 4] },
  { id: 300, ruleset: 'dnd 5e', name: "Farvnik the Venomous", AC: 17, HP: 58, DEX: 12, HPD:[9, 8, 18] },
  { id: 301, ruleset: 'dnd 5e', name: "Fathomer", AC: 10, HP: 52, DEX: 11, HPD:[8, 8, 16] },
  { id: 302, ruleset: 'dnd 5e', name: "Feathergale Knight", AC: 16, HP: 33, DEX: 14, HPD:[6, 6, 6] },
  { id: 303, ruleset: 'dnd 5e', name: "Female Steeder", AC: 14, HP: 30, DEX: 16, HPD:[4, 10, 8] },
  { id: 304, ruleset: 'dnd 5e', name: "Fire Elemental", AC: 13, HP: 102, DEX: 17, HPD:[12, 10, 36] },
  { id: 305, ruleset: 'dnd 5e', name: "Fire Elemental Myrmidon", AC: 18, HP: 117, DEX: 18, HPD:[19, 8, 38] },
  { id: 306, ruleset: 'dnd 5e', name: "Fire Giant", AC: 18, HP: 162, DEX: 9, HPD:[13, 12, 78] },
  { id: 307, ruleset: 'dnd 5e', name: "Fire Giant Whelp", AC: 17, HP: 126, DEX: 9, HPD:[13, 12, 78] },
  { id: 308, ruleset: 'dnd 5e', name: "Fire Snake", AC: 14, HP: 22, DEX: 14, HPD:[5, 8, 0] },
  { id: 309, ruleset: 'dnd 5e', name: "Flameskull", AC: 13, HP: 40, DEX: 17, HPD:[9, 4, 18] },
  { id: 310, ruleset: 'dnd 5e', name: "Flamewrath", AC: 12, HP: 105, DEX: 14, HPD:[14, 8, 42] },
  { id: 311, ruleset: 'dnd 5e', name: "Flesh Golem", AC: 9, HP: 93, DEX: 9, HPD:[11, 8, 44] },
  { id: 312, ruleset: 'dnd 5e', name: "Flumph", AC: 12, HP: 7, DEX: 15, HPD:[2, 6, 0] },
  { id: 313, ruleset: 'dnd 5e', name: "Flying Snake", AC: 14, HP: 5, DEX: 18, HPD:[2, 4, 0] },
  { id: 314, ruleset: 'dnd 5e', name: "Flying Sword", AC: 17, HP: 17, DEX: 15, HPD:[5, 6, 0] },
  { id: 315, ruleset: 'dnd 5e', name: "Fomorian", AC: 14, HP: 149, DEX: 10, HPD:[13, 12, 65] },
  { id: 316, ruleset: 'dnd 5e', name: "Frog", AC: 11, HP: 1, DEX: 13, HPD:[1, 4, -1] },
  { id: 317, ruleset: 'dnd 5e', name: "Frost Giant", AC: 15, HP: 138, DEX: 9, HPD:[12, 12, 60] },
  { id: 318, ruleset: 'dnd 5e', name: "Frulam Mondath", AC: 16, HP: 44, DEX: 10, HPD:[8, 8, 8] },
  { id: 319, ruleset: 'dnd 5e', name: "Galeb Duhr", AC: 16, HP: 85, DEX: 14, HPD:[9, 8, 45] },
  { id: 320, ruleset: 'dnd 5e', name: "Gar Shatterkeel", AC: 16, HP: 112, DEX: 15, HPD:[15, 8, 45] },
  { id: 321, ruleset: 'dnd 5e', name: "Gargoyle", AC: 15, HP: 52, DEX: 11, HPD:[7, 8, 21] },
  { id: 322, ruleset: 'dnd 5e', name: "Gas Spore", AC: 5, HP: 1, DEX: 1, HPD:[1, 10, -4] },
  { id: 323, ruleset: 'dnd 5e', name: "Gazer", AC: 13, HP: 13, DEX: 17, HPD:[3, 4, 6] },
  { id: 324, ruleset: 'dnd 5e', name: "Gelatinous Cube", AC: 6, HP: 84, DEX: 3, HPD:[8, 10, 40] },
  { id: 325, ruleset: 'dnd 5e', name: "Ghald", AC: 15, HP: 102, DEX: 17, HPD:[12, 10, 36] },
  { id: 326, ruleset: 'dnd 5e', name: "Ghast", AC: 13, HP: 36, DEX: 17, HPD:[8, 8, 0] },
  { id: 327, ruleset: 'dnd 5e', name: "Ghost", AC: 11, HP: 45, DEX: 13, HPD:[10, 8, 0] },
  { id: 328, ruleset: 'dnd 5e', name: "Ghoul", AC: 12, HP: 22, DEX: 15, HPD:[5, 8, 0] },
  { id: 329, ruleset: 'dnd 5e', name: "Giant Ape", AC: 12, HP: 157, DEX: 14, HPD:[15, 12, 60] },
  { id: 330, ruleset: 'dnd 5e', name: "Giant Badger", AC: 10, HP: 13, DEX: 10, HPD:[2, 8, 4] },
  { id: 331, ruleset: 'dnd 5e', name: "Giant Bat", AC: 13, HP: 22, DEX: 16, HPD:[4, 10, 0] },
  { id: 332, ruleset: 'dnd 5e', name: "Giant Boar", AC: 12, HP: 42, DEX: 10, HPD:[5, 10, 15] },
  { id: 333, ruleset: 'dnd 5e', name: "Giant Centipede", AC: 13, HP: 4, DEX: 14, HPD:[1, 6, 1] },
  { id: 334, ruleset: 'dnd 5e', name: "Giant Constrictor Snake", AC: 12, HP: 60, DEX: 14, HPD:[2, 10, 2] },
  { id: 335, ruleset: 'dnd 5e', name: "Giant Crab", AC: 15, HP: 13, DEX: 15, HPD:[3, 8, 0] },
  { id: 336, ruleset: 'dnd 5e', name: "Giant Crocodile", AC: 14, HP: 85, DEX: 9, HPD:[9, 12, 27] },
  { id: 337, ruleset: 'dnd 5e', name: "Giant Eagle", AC: 13, HP: 26, DEX: 17, HPD:[4, 10, 4] },
  { id: 338, ruleset: 'dnd 5e', name: "Giant Elk", AC: 14, HP: 42, DEX: 16, HPD:[5, 12, 10] },
  { id: 339, ruleset: 'dnd 5e', name: "Giant Fire Beetle", AC: 13, HP: 4, DEX: 10, HPD:[1, 6, 1] },
  { id: 340, ruleset: 'dnd 5e', name: "Giant Fly", AC: 11, HP: 19, DEX: 13, HPD:[3, 10, 3] },
  { id: 341, ruleset: 'dnd 5e', name: "Giant Frog", AC: 11, HP: 18, DEX: 13, HPD:[4, 8, 0] },
  { id: 342, ruleset: 'dnd 5e', name: "Giant Goat", AC: 11, HP: 19, DEX: 11, HPD:[3, 10, 3] },
  { id: 343, ruleset: 'dnd 5e', name: "Giant Hyena", AC: 12, HP: 45, DEX: 14, HPD:[6, 10, 12] },
  { id: 344, ruleset: 'dnd 5e', name: "Giant Lizard", AC: 12, HP: 19, DEX: 12, HPD:[3, 10, 3] },
  { id: 345, ruleset: 'dnd 5e', name: "Giant Octopus", AC: 11, HP: 52, DEX: 13, HPD:[8, 10, 8] },
  { id: 346, ruleset: 'dnd 5e', name: "Giant Owl", AC: 12, HP: 19, DEX: 15, HPD:[3, 10, 3] },
  { id: 347, ruleset: 'dnd 5e', name: "Giant Poisonous Snake", AC: 14, HP: 11, DEX: 18, HPD:[2, 8, 2] },
  { id: 348, ruleset: 'dnd 5e', name: "Giant Rat", AC: 12, HP: 7, DEX: 15, HPD:[2, 6, 0] },
  { id: 349, ruleset: 'dnd 5e', name: "Giant Scorpion", AC: 15, HP: 52, DEX: 13, HPD:[7, 10, 14] },
  { id: 350, ruleset: 'dnd 5e', name: "Giant Sea Horse", AC: 13, HP: 16, DEX: 15, HPD:[3, 10, 0] },
  { id: 351, ruleset: 'dnd 5e', name: "Giant Shark", AC: 13, HP: 126, DEX: 11, HPD:[11, 12, 55] },
  { id: 352, ruleset: 'dnd 5e', name: "Giant Spider", AC: 14, HP: 26, DEX: 16, HPD:[4, 10, 4] },
  { id: 353, ruleset: 'dnd 5e', name: "Giant Toad", AC: 11, HP: 39, DEX: 13, HPD:[6, 10, 6] },
  { id: 354, ruleset: 'dnd 5e', name: "Giant Vulture", AC: 10, HP: 22, DEX: 10, HPD:[3, 10, 6] },
  { id: 355, ruleset: 'dnd 5e', name: "Giant Wasp", AC: 12, HP: 13, DEX: 14, HPD:[3, 8, 0] },
  { id: 356, ruleset: 'dnd 5e', name: "Giant Weasel", AC: 13, HP: 9, DEX: 16, HPD:[2, 8, 0] },
  { id: 357, ruleset: 'dnd 5e', name: "Giant Wolf Spider", AC: 13, HP: 11, DEX: 16, HPD:[2, 8, 2] },
  { id: 358, ruleset: 'dnd 5e', name: "Gibbering Mouther", AC: 9, HP: 67, DEX: 8, HPD:[9, 8, 27] },
  { id: 359, ruleset: 'dnd 5e', name: "Githyanki Gish", AC: 17, HP: 123, DEX: 15, HPD:[19, 8, 38] },
  { id: 360, ruleset: 'dnd 5e', name: "Githyanki Knight", AC: 18, HP: 91, DEX: 14, HPD:[14, 8, 28] },
  { id: 361, ruleset: 'dnd 5e', name: "Githyanki Warrior", AC: 17, HP: 49, DEX: 14, HPD:[9, 8, 9] },
  { id: 362, ruleset: 'dnd 5e', name: "Githzerai Monk", AC: 14, HP: 38, DEX: 15, HPD:[7, 8, 7] },
  { id: 363, ruleset: 'dnd 5e', name: "Githzerai Zerth", AC: 17, HP: 84, DEX: 18, HPD:[12, 8, 26] },
  { id: 364, ruleset: 'dnd 5e', name: "Glabrezu", AC: 17, HP: 157, DEX: 15, HPD:[15, 10, 75] },
  { id: 365, ruleset: 'dnd 5e', name: "Gladiator", AC: 16, HP: 112, DEX: 15, HPD:[15, 8, 45] },
  { id: 366, ruleset: 'dnd 5e', name: "Gnoll", AC: 15, HP: 22, DEX: 12, HPD:[5, 8, 0] },
  { id: 367, ruleset: 'dnd 5e', name: "Gnoll Fang of Teenoghu", AC: 14, HP: 65, DEX: 15, HPD:[10, 8, 20] },
  { id: 368, ruleset: 'dnd 5e', name: "Gnoll Pack Lord", AC: 15, HP: 49, DEX: 14, HPD:[9, 8, 9] },
  { id: 369, ruleset: 'dnd 5e', name: "Goat", AC: 10, HP: 4, DEX: 10, HPD:[1, 8, 0] },
  { id: 370, ruleset: 'dnd 5e', name: "Goblin", AC: 15, HP: 7, DEX: 14, HPD:[2, 6, 0] },
  { id: 371, ruleset: 'dnd 5e', name: "Goblin Boss", AC: 17, HP: 21, DEX: 14, HPD:[6, 6, 0] },
  { id: 372, ruleset: 'dnd 5e', name: "Gold Dragon Wyrmling", AC: 17, HP: 60, DEX: 14, HPD:[8, 8, 24] },
  { id: 373, ruleset: 'dnd 5e', name: "Gorgon", AC: 19, HP: 114, DEX: 11, HPD:[12, 10, 48] },
  { id: 374, ruleset: 'dnd 5e', name: "Goristro", AC: 19, HP: 310, DEX: 11, HPD:[23, 12, 161] },
  { id: 375, ruleset: 'dnd 5e', name: "Gray Ooze", AC: 8, HP: 22, DEX: 6, HPD:[3, 8, 9] },
  { id: 376, ruleset: 'dnd 5e', name: "Gray Slaad", AC: 18, HP: 127, DEX: 17, HPD:[17, 8, 51] },
  { id: 377, ruleset: 'dnd 5e', name: "Green Dragon Wyrmling", AC: 17, HP: 38, DEX: 12, HPD:[7, 8, 7] },
  { id: 378, ruleset: 'dnd 5e', name: "Green Hag", AC: 17, HP: 82, DEX: 12, HPD:[11, 8, 33] },
  { id: 379, ruleset: 'dnd 5e', name: "Green Slaad", AC: 16, HP: 127, DEX: 15, HPD:[15, 10, 45] },
  { id: 380, ruleset: 'dnd 5e', name: "Grell", AC: 12, HP: 55, DEX: 14, HPD:[10, 8, 10] },
  { id: 381, ruleset: 'dnd 5e', name: "Grick", AC: 14, HP: 27, DEX: 14, HPD:[6, 8, 0] },
  { id: 382, ruleset: 'dnd 5e', name: "Grick Alpha", AC: 18, HP: 75, DEX: 16, HPD:[10, 10, 20] },
  { id: 383, ruleset: 'dnd 5e', name: "Griffon", AC: 12, HP: 59, DEX: 15, HPD:[7, 10, 21] },
  { id: 384, ruleset: 'dnd 5e', name: "Griffon Cavalry Rider", AC: 17, HP: 58, DEX: 15, HPD:[9, 8, 18] },
  { id: 385, ruleset: 'dnd 5e', name: "Grimlock", AC: 11, HP: 11, DEX: 12, HPD:[2, 8, 2] },
  { id: 386, ruleset: 'dnd 5e', name: "Grimshackle Brothers, Halfling Bandits", AC: 12, HP: 9, DEX: 13, HPD:[2, 8, 0] },
  { id: 387, ruleset: 'dnd 5e', name: "Guard", AC: 16, HP: 11, DEX: 12, HPD:[2, 8, 2] },
  { id: 388, ruleset: 'dnd 5e', name: "Guard Drake", AC: 14, HP: 52, DEX: 11, HPD:[7, 8, 21] },
  { id: 389, ruleset: 'dnd 5e', name: "Guardian Naga", AC: 18, HP: 127, DEX: 18, HPD:[15, 10, 45] },
  { id: 390, ruleset: 'dnd 5e', name: "Guild Mage", AC: 11, HP: 22, DEX: 14, HPD:[9, 10, 9] },
  { id: 391, ruleset: 'dnd 5e', name: "Gynosphinx", AC: 17, HP: 136, DEX: 15, HPD:[16, 10, 48] },
  { id: 392, ruleset: 'dnd 5e', name: "Halaster Blackcloak", AC: 14, HP: 246, DEX: 18, HPD:[29, 8, 116] },
  { id: 393, ruleset: 'dnd 5e', name: "Half-Dragon Ettin", AC: 12, HP: 85, DEX: 8, HPD:[10, 10, 30] },
  { id: 394, ruleset: 'dnd 5e', name: "Half-Dragon Troll", AC: 15, HP: 84, DEX: 13, HPD:[6, 10, 36] },
  { id: 395, ruleset: 'dnd 5e', name: "Half-Ogre", AC: 12, HP: 30, DEX: 10, HPD:[4, 10, 8] },
  { id: 396, ruleset: 'dnd 5e', name: "Half-Red Dragon Veteran", AC: 18, HP: 65, DEX: 13, HPD:[10, 8, 20] },
  { id: 397, ruleset: 'dnd 5e', name: "Harpy", AC: 11, HP: 38, DEX: 13, HPD:[7, 8, 7] },
  { id: 398, ruleset: 'dnd 5e', name: "Hawk", AC: 13, HP: 1, DEX: 16, HPD:[1, 4, -1] },
  { id: 399, ruleset: 'dnd 5e', name: "Hell Hound", AC: 15, HP: 45, DEX: 12, HPD:[7, 8, 14] },
  { id: 400, ruleset: 'dnd 5e', name: "Hellenrae", AC: 16, HP: 78, DEX: 18, HPD:[12, 8, 24] },
  { id: 401, ruleset: 'dnd 5e', name: "Helmed Horror", AC: 20, HP: 60, DEX: 13, HPD:[8, 8, 24] },
  { id: 402, ruleset: 'dnd 5e', name: "Hezrou", AC: 16, HP: 136, DEX: 17, HPD:[13, 10, 65] },
  { id: 403, ruleset: 'dnd 5e', name: "Hill Giant", AC: 13, HP: 105, DEX: 8, HPD:[10, 12, 40] },
  { id: 404, ruleset: 'dnd 5e', name: "Hippogriff", AC: 11, HP: 19, DEX: 13, HPD:[3, 10, 3] },
  { id: 405, ruleset: 'dnd 5e', name: "Hlam", AC: 22, HP: 137, DEX: 24, HPD:[25, 8, 25] },
  { id: 406, ruleset: 'dnd 5e', name: "Hobgoblin", AC: 18, HP: 11, DEX: 12, HPD:[2, 8, 2] },
  { id: 407, ruleset: 'dnd 5e', name: "Hobgoblin Captain", AC: 17, HP: 39, DEX: 14, HPD:[6, 8, 12] },
  { id: 408, ruleset: 'dnd 5e', name: "Hobgoblin Warlord", AC: 20, HP: 97, DEX: 14, HPD:[13, 8, 39] },
  { id: 409, ruleset: 'dnd 5e', name: "Homunculus", AC: 13, HP: 5, DEX: 15, HPD:[2, 4, 0] },
  { id: 410, ruleset: 'dnd 5e', name: "Hook Horror", AC: 15, HP: 75, DEX: 10, HPD:[10, 10, 20] },
  { id: 411, ruleset: 'dnd 5e', name: "Hook Horror Spore Servant", AC: 15, HP: 75, DEX: 10, HPD:[10, 10, 10] },
  { id: 412, ruleset: 'dnd 5e', name: "Horned Devil", AC: 18, HP: 178, DEX: 17, HPD:[17, 10, 85] },
  { id: 413, ruleset: 'dnd 5e', name: "Howling Hatred Initiate", AC: 13, HP: 9, DEX: 15, HPD:[2, 8, 0] },
  { id: 414, ruleset: 'dnd 5e', name: "Howling Hatred Priest", AC: 15, HP: 45, DEX: 16, HPD:[10, 8, 0] },
  { id: 415, ruleset: 'dnd 5e', name: "Hrabbaz", AC: 12, HP: 112, DEX: 15, HPD:[15, 8, 45] },
  { id: 416, ruleset: 'dnd 5e', name: "Hunter Shark", AC: 12, HP: 45, DEX: 13, HPD:[6, 10, 12] },
  { id: 417, ruleset: 'dnd 5e', name: "Hurricane", AC: 14, HP: 33, DEX: 16, HPD:[6, 8, 6] },
  { id: 418, ruleset: 'dnd 5e', name: "Hydra", AC: 15, HP: 172, DEX: 12, HPD:[15, 12, 75] },
  { id: 419, ruleset: 'dnd 5e', name: "Hyena", AC: 11, HP: 5, DEX: 13, HPD:[1, 8, 1] },
  { id: 420, ruleset: 'dnd 5e', name: "Ice Devil", AC: 18, HP: 180, DEX: 14, HPD:[19, 10, 76] },
  { id: 421, ruleset: 'dnd 5e', name: "Ice Mephit", AC: 11, HP: 21, DEX: 13, HPD:[6, 6, 0] },
  { id: 422, ruleset: 'dnd 5e', name: "Ice Toad", AC: 12, HP: 32, DEX: 10, HPD:[7, 10, 14] },
  { id: 423, ruleset: 'dnd 5e', name: "Imix", AC: 17, HP: 325, DEX: 24, HPD:[26, 12, 156] },
  { id: 424, ruleset: 'dnd 5e', name: "Imp", AC: 13, HP: 10, DEX: 17, HPD:[3, 4, 3] },
  { id: 425, ruleset: 'dnd 5e', name: "Intellect Devourer", AC: 12, HP: 21, DEX: 14, HPD:[6, 4, 6] },
  { id: 426, ruleset: 'dnd 5e', name: "Invisible Stalker", AC: 14, HP: 104, DEX: 19, HPD:[16, 8, 32] },
  { id: 427, ruleset: 'dnd 5e', name: "Iron Golem", AC: 20, HP: 210, DEX: 9, HPD:[20, 10, 100] },
  { id: 428, ruleset: 'dnd 5e', name: "Istrid Horn", AC: 18, HP: 117, DEX: 10, HPD:[18, 8, 36] },
  { id: 429, ruleset: 'dnd 5e', name: "Ixitxachitl", AC: 15, HP: 18, DEX: 16, HPD:[4, 6, 4] },
  { id: 430, ruleset: 'dnd 5e', name: "Ixitxachitl Cleric", AC: 15, HP: 18, DEX: 16, HPD:[4, 6, 4] },
  { id: 431, ruleset: 'dnd 5e', name: "Jackal", AC: 12, HP: 3, DEX: 15, HPD:[1, 6, 0] },
  { id: 432, ruleset: 'dnd 5e', name: "Jackalwere", AC: 12, HP: 18, DEX: 15, HPD:[4, 8, 0] },
  { id: 433, ruleset: 'dnd 5e', name: "Jalester Silvermane", AC: 18, HP: 71, DEX: 14, HPD:[13, 8, 13] },
  { id: 434, ruleset: 'dnd 5e', name: "Jamna Gleamsilver", AC: 15, HP: 22, DEX: 17, HPD:[13, 8, 13] },
  { id: 435, ruleset: 'dnd 5e', name: "Jarlaxle Baenre", AC: 24, HP: 123, DEX: 22, HPD:[19, 8, 38] },
  { id: 436, ruleset: 'dnd 5e', name: "Jeralla, Sorcerer", AC: 10, HP: 21, DEX: 10, HPD:[5, 6, 5] },
  { id: 437, ruleset: 'dnd 5e', name: "Karsev the Drowner", AC: 18, HP: 33, DEX: 10, HPD:[6, 8, 6] },
  { id: 438, ruleset: 'dnd 5e', name: "Kenku", AC: 13, HP: 13, DEX: 16, HPD:[3, 8, 0] },
  { id: 439, ruleset: 'dnd 5e', name: "Keresta Delvingstone", AC: 16, HP: 144, DEX: 18, HPD:[17, 8, 68] },
  { id: 440, ruleset: 'dnd 5e', name: "Killer Whale", AC: 12, HP: 90, DEX: 10, HPD:[12, 12, 12] },
  { id: 441, ruleset: 'dnd 5e', name: "Knight", AC: 18, HP: 52, DEX: 11, HPD:[8, 8, 16] },
  { id: 442, ruleset: 'dnd 5e', name: "Kobold", AC: 12, HP: 5, DEX: 15, HPD:[2, 6, -2] },
  { id: 443, ruleset: 'dnd 5e', name: "Kraken", AC: 18, HP: 472, DEX: 11, HPD:[27, 20, 189] },
  { id: 444, ruleset: 'dnd 5e', name: "Kuo-toa", AC: 13, HP: 18, DEX: 10, HPD:[4, 8, 0] },
  { id: 445, ruleset: 'dnd 5e', name: "Kuo-toa Archpriest", AC: 13, HP: 97, DEX: 14, HPD:[13, 8, 39] },
  { id: 446, ruleset: 'dnd 5e', name: "Kuo-toa Monitor", AC: 13, HP: 65, DEX: 10, HPD:[10, 8, 20] },
  { id: 447, ruleset: 'dnd 5e', name: "Kuo-toa Whip", AC: 11, HP: 65, DEX: 10, HPD:[10, 8, 20] },
  { id: 448, ruleset: 'dnd 5e', name: "Lacedon", AC: 12, HP: 22, DEX: 15, HPD:[5, 8, 0] },
  { id: 449, ruleset: 'dnd 5e', name: "Laeral Silverhand", AC: 18, HP: 228, DEX: 17, HPD:[24, 8, 120] },
  { id: 450, ruleset: 'dnd 5e', name: "Lamia", AC: 13, HP: 97, DEX: 13, HPD:[13, 10, 26] },
  { id: 451, ruleset: 'dnd 5e', name: "Langdedrosa Cyanwrath", AC: 17, HP: 57, DEX: 13, HPD:[6, 12, 18] },
  { id: 452, ruleset: 'dnd 5e', name: "Larva", AC: 9, HP: 9, DEX: 9, HPD:[2, 8, 0] },
  { id: 453, ruleset: 'dnd 5e', name: "Lava Child", AC: 11, HP: 60, DEX: 13, HPD:[8, 8, 24] },
  { id: 454, ruleset: 'dnd 5e', name: "Lemure", AC: 7, HP: 13, DEX: 5, HPD:[3, 8, 0] },
  { id: 455, ruleset: 'dnd 5e', name: "Lich", AC: 17, HP: 135, DEX: 16, HPD:[18, 8, 54] },
  { id: 456, ruleset: 'dnd 5e', name: "Lion", AC: 12, HP: 26, DEX: 15, HPD:[4, 10, 4] },
  { id: 457, ruleset: 'dnd 5e', name: "Little Wing", AC: 15, HP: 52, DEX: 16, HPD:[8, 8, 16] },
  { id: 458, ruleset: 'dnd 5e', name: "Living Unseen Servant", AC: 10, HP: 4, DEX: 10, HPD:[1, 8, 0] },
  { id: 459, ruleset: 'dnd 5e', name: "Lizard", AC: 10, HP: 2, DEX: 11, HPD:[1, 4, 0] },
  { id: 460, ruleset: 'dnd 5e', name: "Lizardfolk", AC: 15, HP: 22, DEX: 10, HPD:[4, 8, 4] },
  { id: 461, ruleset: 'dnd 5e', name: "Lizardfolk King/Queen", AC: 15, HP: 78, DEX: 12, HPD:[12, 8, 24] },
  { id: 462, ruleset: 'dnd 5e', name: "Lizardfolk Shaman", AC: 13, HP: 27, DEX: 10, HPD:[5, 8, 5] },
  { id: 463, ruleset: 'dnd 5e', name: "Mage", AC: 12, HP: 40, DEX: 14, HPD:[9, 8, 0] },
  { id: 464, ruleset: 'dnd 5e', name: "Magma Mephit", AC: 11, HP: 22, DEX: 12, HPD:[5, 6, 5] },
  { id: 465, ruleset: 'dnd 5e', name: "Magmin", AC: 14, HP: 9, DEX: 15, HPD:[2, 6, 2] },
  { id: 466, ruleset: 'dnd 5e', name: "Male Steeder", AC: 12, HP: 13, DEX: 12, HPD:[2, 8, 4] },
  { id: 467, ruleset: 'dnd 5e', name: "Mammoth", AC: 13, HP: 126, DEX: 9, HPD:[11, 12, 55] },
  { id: 468, ruleset: 'dnd 5e', name: "Manes", AC: 9, HP: 9, DEX: 9, HPD:[2, 6, 2] },
  { id: 469, ruleset: 'dnd 5e', name: "Manshoon", AC: 19, HP: 126, DEX: 14, HPD:[23, 8, 23] },
  { id: 470, ruleset: 'dnd 5e', name: "Manticore", AC: 14, HP: 68, DEX: 16, HPD:[8, 10, 24] },
  { id: 471, ruleset: 'dnd 5e', name: "Marid", AC: 17, HP: 229, DEX: 12, HPD:[17, 10, 136] },
  { id: 472, ruleset: 'dnd 5e', name: "Marilith", AC: 18, HP: 189, DEX: 20, HPD:[18, 10, 90] },
  { id: 473, ruleset: 'dnd 5e', name: "Marlos Urnrayle", AC: 15, HP: 136, DEX: 11, HPD:[16, 8, 64] },
  { id: 474, ruleset: 'dnd 5e', name: "Martial Arts Adept", AC: 16, HP: 60, DEX: 17, HPD:[11, 8, 11] },
  { id: 475, ruleset: 'dnd 5e', name: "Mastiff", AC: 12, HP: 5, DEX: 14, HPD:[1, 8, 1] },
  { id: 476, ruleset: 'dnd 5e', name: "Medusa", AC: 15, HP: 127, DEX: 15, HPD:[17, 8, 51] },
  { id: 477, ruleset: 'dnd 5e', name: "Meira Faerenduil (Ghost)", AC: 16, HP: 91, DEX: 16, HPD:[14, 8, 28] },
  { id: 478, ruleset: 'dnd 5e', name: "Meloon Wardragon", AC: 18, HP: 143, DEX: 15, HPD:[22, 8, 44] },
  { id: 479, ruleset: 'dnd 5e', name: "Merfolk", AC: 11, HP: 11, DEX: 13, HPD:[2, 8, 2] },
  { id: 480, ruleset: 'dnd 5e', name: "Merrow", AC: 13, HP: 45, DEX: 10, HPD:[6, 10, 12] },
  { id: 481, ruleset: 'dnd 5e', name: "Mezzoloth", AC: 18, HP: 75, DEX: 11, HPD:[10, 8, 30] },
  { id: 482, ruleset: 'dnd 5e', name: "Mimic", AC: 12, HP: 58, DEX: 12, HPD:[9, 8, 18] },
  { id: 483, ruleset: 'dnd 5e', name: "Mind Flayer", AC: 15, HP: 71, DEX: 12, HPD:[13, 8, 13] },
  { id: 484, ruleset: 'dnd 5e', name: "Mind Flayer Arcanist", AC: 15, HP: 71, DEX: 12, HPD:[13, 8, 13] },
  { id: 485, ruleset: 'dnd 5e', name: "Miner", AC: 12, HP: 11, DEX: 12, HPD:[3, 6, 6] },
  { id: 486, ruleset: 'dnd 5e', name: "Minotaur", AC: 14, HP: 76, DEX: 11, HPD:[9, 10, 27] },
  { id: 487, ruleset: 'dnd 5e', name: "Minotaur Skeleton", AC: 12, HP: 67, DEX: 11, HPD:[9, 10, 18] },
  { id: 488, ruleset: 'dnd 5e', name: "Miraj Vizann", AC: 10, HP: 82, DEX: 10, HPD:[11, 8, 33] },
  { id: 489, ruleset: 'dnd 5e', name: "Mirt", AC: 16, HP: 153, DEX: 18, HPD:[18, 8, 72] },
  { id: 490, ruleset: 'dnd 5e', name: "Monodrone", AC: 15, HP: 5, DEX: 13, HPD:[1, 8, 1] },
  { id: 491, ruleset: 'dnd 5e', name: "Mormesk the Wraith", AC: 13, HP: 45, DEX: 16, HPD:[6, 8, 18] },
  { id: 492, ruleset: 'dnd 5e', name: "Mud Mephit", AC: 11, HP: 27, DEX: 12, HPD:[6, 6, 6] },
  { id: 493, ruleset: 'dnd 5e', name: "Muiral", AC: 16, HP: 195, DEX: 11, HPD:[23, 10, 69] },
  { id: 494, ruleset: 'dnd 5e', name: "Mule", AC: 10, HP: 11, DEX: 10, HPD:[2, 8, 2] },
  { id: 495, ruleset: 'dnd 5e', name: "Mummy", AC: 11, HP: 58, DEX: 8, HPD:[9, 8, 18] },
  { id: 496, ruleset: 'dnd 5e', name: "Mummy Lord", AC: 17, HP: 97, DEX: 10, HPD:[13, 8, 39] },
  { id: 497, ruleset: 'dnd 5e', name: "Musanna", AC: 13, HP: 27, DEX: 12, HPD:[4, 8, 10] },
  { id: 498, ruleset: 'dnd 5e', name: "Myconid Adult", AC: 12, HP: 22, DEX: 10, HPD:[4, 8, 4] },
  { id: 499, ruleset: 'dnd 5e', name: "Myconid Sovereign", AC: 13, HP: 60, DEX: 10, HPD:[8, 10, 16] },
  { id: 500, ruleset: 'dnd 5e', name: "Myconid Sprout", AC: 10, HP: 7, DEX: 10, HPD:[2, 6, 0] },
  { id: 501, ruleset: 'dnd 5e', name: "Naergoth Bladelord", AC: 18, HP: 135, DEX: 12, HPD:[18, 8, 54] },
  { id: 502, ruleset: 'dnd 5e', name: "Nalfeshnee", AC: 18, HP: 184, DEX: 10, HPD:[16, 10, 96] },
  { id: 503, ruleset: 'dnd 5e', name: "Needle Blight", AC: 12, HP: 11, DEX: 12, HPD:[2, 8, 2] },
  { id: 504, ruleset: 'dnd 5e', name: "Neothelid", AC: 16, HP: 325, DEX: 7, HPD:[21, 20, 105] },
  { id: 505, ruleset: 'dnd 5e', name: "Neronvain", AC: 17, HP: 117, DEX: 17, HPD:[18, 8, 36] },
  { id: 506, ruleset: 'dnd 5e', name: "Nezznar the Black Spider", AC: 11, HP: 27, DEX: 13, HPD:[6, 8, 0] },
  { id: 507, ruleset: 'dnd 5e', name: "Night Hag", AC: 17, HP: 112, DEX: 15, HPD:[15, 8, 45] },
  { id: 508, ruleset: 'dnd 5e', name: "Nightmare", AC: 13, HP: 68, DEX: 15, HPD:[8, 10, 24] },
  { id: 509, ruleset: 'dnd 5e', name: "Nimblewright", AC: 18, HP: 45, DEX: 18, HPD:[6, 8, 18] },
  { id: 510, ruleset: 'dnd 5e', name: "Noble", AC: 15, HP: 9, DEX: 12, HPD:[2, 8, 0] },
  { id: 511, ruleset: 'dnd 5e', name: "Nothic", AC: 15, HP: 45, DEX: 16, HPD:[6, 8, 18] },
  { id: 512, ruleset: 'dnd 5e', name: "Nycaloth", AC: 18, HP: 123, DEX: 11, HPD:[13, 10, 52] },
  { id: 513, ruleset: 'dnd 5e', name: "Ochre Jelly", AC: 8, HP: 45, DEX: 6, HPD:[6, 10, 12] },
  { id: 514, ruleset: 'dnd 5e', name: "Octopus", AC: 12, HP: 3, DEX: 15, HPD:[1, 6, 0] },
  { id: 515, ruleset: 'dnd 5e', name: "Ogre", AC: 11, HP: 59, DEX: 8, HPD:[7, 10, 21] },
  { id: 516, ruleset: 'dnd 5e', name: "Ogre Zombie", AC: 8, HP: 85, DEX: 6, HPD:[9, 10, 36] },
  { id: 517, ruleset: 'dnd 5e', name: "Ogrémoch", AC: 20, HP: 526, DEX: 11, HPD:[27, 20, 243] },
  { id: 518, ruleset: 'dnd 5e', name: "Olhydra", AC: 18, HP: 324, DEX: 22, HPD:[24, 12, 168] },
  { id: 519, ruleset: 'dnd 5e', name: "One-Eyed Shiver", AC: 12, HP: 49, DEX: 14, HPD:[9, 8, 9] },
  { id: 520, ruleset: 'dnd 5e', name: "Oni", AC: 16, HP: 110, DEX: 11, HPD:[13, 10, 39] },
  { id: 521, ruleset: 'dnd 5e', name: "Orc", AC: 13, HP: 15, DEX: 12, HPD:[2, 8, 6] },
  { id: 522, ruleset: 'dnd 5e', name: "Orc Eye of Gruumsh", AC: 16, HP: 45, DEX: 12, HPD:[6, 8, 18] },
  { id: 523, ruleset: 'dnd 5e', name: "Orc War Chief", AC: 16, HP: 93, DEX: 12, HPD:[11, 8, 44] },
  { id: 524, ruleset: 'dnd 5e', name: "Oreioth", AC: 11, HP: 39, DEX: 13, HPD:[6, 8, 12] },
  { id: 525, ruleset: 'dnd 5e', name: "Orog", AC: 18, HP: 42, DEX: 12, HPD:[5, 8, 20] },
  { id: 526, ruleset: 'dnd 5e', name: "Otyugh", AC: 14, HP: 114, DEX: 11, HPD:[12, 10, 48] },
  { id: 527, ruleset: 'dnd 5e', name: "Owl", AC: 11, HP: 1, DEX: 13, HPD:[1, 4, -1] },
  { id: 528, ruleset: 'dnd 5e', name: "Owlbear", AC: 13, HP: 59, DEX: 12, HPD:[7, 10, 21] },
  { id: 529, ruleset: 'dnd 5e', name: "Panther", AC: 12, HP: 13, DEX: 15, HPD:[3, 8, 0] },
  { id: 530, ruleset: 'dnd 5e', name: "Pegasus", AC: 12, HP: 59, DEX: 15, HPD:[7, 10, 21] },
  { id: 531, ruleset: 'dnd 5e', name: "Pentadrone", AC: 16, HP: 32, DEX: 14, HPD:[5, 10, 5] },
  { id: 532, ruleset: 'dnd 5e', name: "Peryton", AC: 13, HP: 33, DEX: 12, HPD:[6, 8, 6] },
  { id: 533, ruleset: 'dnd 5e', name: "Pharblex Spattergoo", AC: 15, HP: 59, DEX: 12, HPD:[7, 8, 28] },
  { id: 534, ruleset: 'dnd 5e', name: "Phase Spider", AC: 13, HP: 32, DEX: 15, HPD:[5, 10, 5] },
  { id: 535, ruleset: 'dnd 5e', name: "Piercer", AC: 15, HP: 22, DEX: 13, HPD:[3, 8, 9] },
  { id: 536, ruleset: 'dnd 5e', name: "Pit Fiend", AC: 19, HP: 300, DEX: 14, HPD:[24, 10, 168] },
  { id: 537, ruleset: 'dnd 5e', name: "Pixie", AC: 15, HP: 1, DEX: 20, HPD:[1, 4, -1] },
  { id: 538, ruleset: 'dnd 5e', name: "Planetar", AC: 19, HP: 200, DEX: 20, HPD:[16, 10, 112] },
  { id: 539, ruleset: 'dnd 5e', name: "Plesiosaurus", AC: 13, HP: 68, DEX: 15, HPD:[8, 10, 24] },
  { id: 540, ruleset: 'dnd 5e', name: "Poisonous Snake", AC: 13, HP: 2, DEX: 16, HPD:[1, 4, 0] },
  { id: 541, ruleset: 'dnd 5e', name: "Polar Bear", AC: 12, HP: 42, DEX: 10, HPD:[5, 10, 15] },
  { id: 542, ruleset: 'dnd 5e', name: "Poltergeist", AC: 12, HP: 22, DEX: 14, HPD:[5, 8, 0] },
  { id: 543, ruleset: 'dnd 5e', name: "Pony", AC: 10, HP: 11, DEX: 10, HPD:[2, 8, 2] },
  { id: 544, ruleset: 'dnd 5e', name: "Priest", AC: 13, HP: 27, DEX: 10, HPD:[5, 8, 5] },
  { id: 545, ruleset: 'dnd 5e', name: "Pseudodragon", AC: 13, HP: 7, DEX: 15, HPD:[2, 4, 2] },
  { id: 546, ruleset: 'dnd 5e', name: "Psychic Gray Ooze", AC: 8, HP: 22, DEX: 6, HPD:[3, 8, 9] },
  { id: 547, ruleset: 'dnd 5e', name: "Pteranodon", AC: 13, HP: 13, DEX: 15, HPD:[3, 8, 0] },
  { id: 548, ruleset: 'dnd 5e', name: "Purple Worm", AC: 18, HP: 247, DEX: 7, HPD:[15, 20, 90] },
  { id: 549, ruleset: 'dnd 5e', name: "Quadrone", AC: 16, HP: 22, DEX: 14, HPD:[4, 8, 4] },
  { id: 550, ruleset: 'dnd 5e', name: "Quaggoth", AC: 13, HP: 45, DEX: 12, HPD:[6, 8, 18] },
  { id: 551, ruleset: 'dnd 5e', name: "Quaggoth Spore Servant", AC: 13, HP: 45, DEX: 12, HPD:[6, 8, 18] },
  { id: 552, ruleset: 'dnd 5e', name: "Quaggoth Thonot", AC: 13, HP: 45, DEX: 12, HPD:[6, 8, 18] },
  { id: 553, ruleset: 'dnd 5e', name: "Quasit", AC: 13, HP: 7, DEX: 17, HPD:[3, 4, 0] },
  { id: 554, ruleset: 'dnd 5e', name: "Quipper", AC: 13, HP: 1, DEX: 16, HPD:[1, 4, -1] },
  { id: 555, ruleset: 'dnd 5e', name: "Rakshasa", AC: 16, HP: 110, DEX: 17, HPD:[13, 8, 52] },
  { id: 556, ruleset: 'dnd 5e', name: "Rat", AC: 10, HP: 1, DEX: 11, HPD:[1, 4, -1] },
  { id: 557, ruleset: 'dnd 5e', name: "Rath Modar", AC: 13, HP: 71, DEX: 16, HPD:[11, 8, 22] },
  { id: 558, ruleset: 'dnd 5e', name: "Raven", AC: 12, HP: 1, DEX: 14, HPD:[1, 4, -1] },
  { id: 559, ruleset: 'dnd 5e', name: "Razerblast", AC: 17, HP: 112, DEX: 11, HPD:[15, 8, 75] },
  { id: 560, ruleset: 'dnd 5e', name: "Red Dragon Wyrmling", AC: 17, HP: 75, DEX: 10, HPD:[10, 8, 30] },
  { id: 561, ruleset: 'dnd 5e', name: "Red Plume Cavalry Officer", AC: 17, HP: 32, DEX: 11, HPD:[4, 10, 12] },
  { id: 562, ruleset: 'dnd 5e', name: "Red Plume Patrol", AC: 16, HP: 27, DEX: 10, HPD:[5, 8, 5] },
  { id: 563, ruleset: 'dnd 5e', name: "Red Slaad", AC: 14, HP: 93, DEX: 12, HPD:[11, 10, 33] },
  { id: 564, ruleset: 'dnd 5e', name: "Redbrand Ruffian", AC: 14, HP: 16, DEX: 14, HPD:[3, 8, 3] },
  { id: 565, ruleset: 'dnd 5e', name: "Reef Shark", AC: 12, HP: 22, DEX: 13, HPD:[4, 8, 4] },
  { id: 566, ruleset: 'dnd 5e', name: "Remallia Haventree", AC: 12, HP: 66, DEX: 14, HPD:[12, 8, 12] },
  { id: 567, ruleset: 'dnd 5e', name: "Remorhaz", AC: 17, HP: 195, DEX: 13, HPD:[17, 12, 85] },
  { id: 568, ruleset: 'dnd 5e', name: "Remorhaz Hatchling", AC: 12, HP: 45, DEX: 13, HPD:[11, 10, 33] },
  { id: 569, ruleset: 'dnd 5e', name: "Revenant", AC: 13, HP: 136, DEX: 14, HPD:[16, 8, 64] },
  { id: 570, ruleset: 'dnd 5e', name: "Rezmir", AC: 13, HP: 90, DEX: 16, HPD:[12, 8, 36] },
  { id: 571, ruleset: 'dnd 5e', name: "Rhinoceros", AC: 11, HP: 45, DEX: 8, HPD:[6, 10, 12] },
  { id: 572, ruleset: 'dnd 5e', name: "Riding Horse", AC: 10, HP: 13, DEX: 10, HPD:[2, 10, 2] },
  { id: 573, ruleset: 'dnd 5e', name: "Roc", AC: 15, HP: 248, DEX: 10, HPD:[16, 20, 80] },
  { id: 574, ruleset: 'dnd 5e', name: "Roper", AC: 20, HP: 93, DEX: 8, HPD:[11, 10, 33] },
  { id: 575, ruleset: 'dnd 5e', name: "Rothé Zombie", AC: 8, HP: 22, DEX: 6, HPD:[4, 8, 4] },
  { id: 576, ruleset: 'dnd 5e', name: "Rug of Smothering", AC: 12, HP: 33, DEX: 14, HPD:[6, 10, 0] },
  { id: 577, ruleset: 'dnd 5e', name: "Rust Monster", AC: 14, HP: 27, DEX: 12, HPD:[5, 8, 5] },
  { id: 578, ruleset: 'dnd 5e', name: "Saber-Toothed Tiger", AC: 12, HP: 52, DEX: 14, HPD:[7, 10, 14] },
  { id: 579, ruleset: 'dnd 5e', name: "Sacred Stone Monk", AC: 14, HP: 22, DEX: 15, HPD:[4, 8, 4] },
  { id: 580, ruleset: 'dnd 5e', name: "Sahuagin", AC: 12, HP: 22, DEX: 11, HPD:[4, 8, 4] },
  { id: 581, ruleset: 'dnd 5e', name: "Sahuagin Baron", AC: 16, HP: 76, DEX: 15, HPD:[9, 10, 27] },
  { id: 582, ruleset: 'dnd 5e', name: "Sahuagin Priestess", AC: 12, HP: 33, DEX: 11, HPD:[6, 8, 6] },
  { id: 583, ruleset: 'dnd 5e', name: "Salamander", AC: 15, HP: 90, DEX: 14, HPD:[12, 10, 24] },
  { id: 584, ruleset: 'dnd 5e', name: "Satyr", AC: 14, HP: 31, DEX: 16, HPD:[7, 8, 0] },
  { id: 585, ruleset: 'dnd 5e', name: "Scaladar", AC: 19, HP: 94, DEX: 10, HPD:[7, 12, 49] },
  { id: 586, ruleset: 'dnd 5e', name: "Scarecrow", AC: 11, HP: 36, DEX: 13, HPD:[8, 8, 0] },
  { id: 587, ruleset: 'dnd 5e', name: "Scorpion", AC: 11, HP: 1, DEX: 11, HPD:[1, 4, -1] },
  { id: 588, ruleset: 'dnd 5e', name: "Scout", AC: 13, HP: 16, DEX: 14, HPD:[3, 8, 3] },
  { id: 589, ruleset: 'dnd 5e', name: "Sea Hag", AC: 14, HP: 52, DEX: 13, HPD:[7, 8, 21] },
  { id: 590, ruleset: 'dnd 5e', name: "Sea Horse", AC: 11, HP: 1, DEX: 12, HPD:[1, 4, -1] },
  { id: 591, ruleset: 'dnd 5e', name: "Sergeant Kalugin", AC: 15, HP: 52, DEX: 12, HPD:[10, 8, 12] },
  { id: 592, ruleset: 'dnd 5e', name: "Severin", AC: 16, HP: 150, DEX: 13, HPD:[20, 8, 60] },
  { id: 593, ruleset: 'dnd 5e', name: "Shadow", AC: 12, HP: 16, DEX: 14, HPD:[3, 8, 3] },
  { id: 594, ruleset: 'dnd 5e', name: "Shadow Assassin", AC: 14, HP: 78, DEX: 19, HPD:[12, 8, 24] },
  { id: 595, ruleset: 'dnd 5e', name: "Shadow Demon", AC: 13, HP: 66, DEX: 17, HPD:[12, 8, 12] },
  { id: 596, ruleset: 'dnd 5e', name: "Shambling Mound", AC: 15, HP: 136, DEX: 8, HPD:[16, 10, 48] },
  { id: 597, ruleset: 'dnd 5e', name: "Shard of Ogrémoch", AC: 11, HP: 22, DEX: 8, HPD:[3, 8, 9] },
  { id: 598, ruleset: 'dnd 5e', name: "Shield Guardian", AC: 17, HP: 142, DEX: 8, HPD:[15, 10, 60] },
  { id: 599, ruleset: 'dnd 5e', name: "Shoalar Quanderil", AC: 10, HP: 60, DEX: 12, HPD:[8, 8, 24] },
  { id: 600, ruleset: 'dnd 5e', name: "Shrieker", AC: 5, HP: 13, DEX: 1, HPD:[3, 8, 0] },
  { id: 601, ruleset: 'dnd 5e', name: "Sildar Hallwinter", AC: 16, HP: 27, DEX: 10, HPD:[5, 8, 5] },
  { id: 602, ruleset: 'dnd 5e', name: "Silver Dragon Wyrmling", AC: 17, HP: 45, DEX: 10, HPD:[6, 8, 18] },
  { id: 603, ruleset: 'dnd 5e', name: "Skeemo Weirdbottle", AC: 12, HP: 72, DEX: 14, HPD:[16, 6, 16] },
  { id: 604, ruleset: 'dnd 5e', name: "Skeleton", AC: 13, HP: 13, DEX: 14, HPD:[2, 8, 4] },
  { id: 605, ruleset: 'dnd 5e', name: "Skyweaver", AC: 12, HP: 44, DEX: 14, HPD:[8, 8, 8] },
  { id: 606, ruleset: 'dnd 5e', name: "Slaad Tadpole", AC: 12, HP: 10, DEX: 15, HPD:[4, 4, 0] },
  { id: 607, ruleset: 'dnd 5e', name: "Smoke Mephit", AC: 12, HP: 22, DEX: 14, HPD:[5, 6, 5] },
  { id: 608, ruleset: 'dnd 5e', name: "Solar", AC: 21, HP: 243, DEX: 22, HPD:[18, 10, 144] },
  { id: 609, ruleset: 'dnd 5e', name: "Spectator", AC: 14, HP: 39, DEX: 14, HPD:[6, 8, 12] },
  { id: 610, ruleset: 'dnd 5e', name: "Specter", AC: 12, HP: 22, DEX: 14, HPD:[5, 8, 0] },
  { id: 611, ruleset: 'dnd 5e', name: "Spellcaster Vampire", AC: 16, HP: 144, DEX: 18, HPD:[17, 8, 68] },
  { id: 612, ruleset: 'dnd 5e', name: "Spider", AC: 12, HP: 1, DEX: 14, HPD:[1, 4, -1] },
  { id: 613, ruleset: 'dnd 5e', name: "Spined Devil", AC: 13, HP: 22, DEX: 15, HPD:[5, 6, 5] },
  { id: 614, ruleset: 'dnd 5e', name: "Spirit Naga", AC: 15, HP: 75, DEX: 17, HPD:[10, 10, 20] },
  { id: 615, ruleset: 'dnd 5e', name: "Sprite", AC: 15, HP: 2, DEX: 18, HPD:[1, 4, 0] },
  { id: 616, ruleset: 'dnd 5e', name: "Spy", AC: 12, HP: 27, DEX: 15, HPD:[6, 8, 0] },
  { id: 617, ruleset: 'dnd 5e', name: "Steam Mephit", AC: 10, HP: 21, DEX: 11, HPD:[6, 6, 0] },
  { id: 618, ruleset: 'dnd 5e', name: "Stirge", AC: 14, HP: 2, DEX: 16, HPD:[1, 4, 0] },
  { id: 619, ruleset: 'dnd 5e', name: "Stone Dwarf", AC: 15, HP: 67, DEX: 16, HPD:[6, 8, 12] },
  { id: 620, ruleset: 'dnd 5e', name: "Stone Giant", AC: 17, HP: 126, DEX: 15, HPD:[11, 12, 55] },
  { id: 621, ruleset: 'dnd 5e', name: "Stone Giant Shaman", AC: 17, HP: 126, DEX: 15, HPD:[11, 12, 54] },
  { id: 622, ruleset: 'dnd 5e', name: "Stone Golem", AC: 17, HP: 178, DEX: 9, HPD:[17, 10, 85] },
  { id: 623, ruleset: 'dnd 5e', name: "Stonemelder", AC: 17, HP: 75, DEX: 10, HPD:[10, 8, 30] },
  { id: 624, ruleset: 'dnd 5e', name: "Storm Giant", AC: 16, HP: 230, DEX: 14, HPD:[20, 12, 100] },
  { id: 625, ruleset: 'dnd 5e', name: "Stormgale, Cult Leader", AC: 12, HP: 66, DEX: 14, HPD:[10, 8, 28] },
  { id: 626, ruleset: 'dnd 5e', name: "Succubus/Incubus", AC: 15, HP: 66, DEX: 17, HPD:[12, 8, 12] },
  { id: 627, ruleset: 'dnd 5e', name: "Swarm of Bats", AC: 12, HP: 22, DEX: 15, HPD:[5, 8, 0] },
  { id: 628, ruleset: 'dnd 5e', name: "Swarm of Beetles", AC: 12, HP: 22, DEX: 13, HPD:[5, 8, 0] },
  { id: 629, ruleset: 'dnd 5e', name: "Swarm of Insects", AC: 12, HP: 22, DEX: 13, HPD:[5, 8, 0] },
  { id: 630, ruleset: 'dnd 5e', name: "Swarm of Poisonous Snakes", AC: 14, HP: 36, DEX: 18, HPD:[8, 8, 0] },
  { id: 631, ruleset: 'dnd 5e', name: "Swarm of Quippers", AC: 13, HP: 28, DEX: 16, HPD:[8, 8, -8] },
  { id: 632, ruleset: 'dnd 5e', name: "Swarm of Rats", AC: 10, HP: 24, DEX: 11, HPD:[7, 8, -7] },
  { id: 633, ruleset: 'dnd 5e', name: "Swarm of Ravens", AC: 12, HP: 24, DEX: 14, HPD:[7, 8, -7] },
  { id: 634, ruleset: 'dnd 5e', name: "Swarm of Wasps", AC: 12, HP: 22, DEX: 13, HPD:[5, 8, 0] },
  { id: 635, ruleset: 'dnd 5e', name: "Swashbuckler", AC: 17, HP: 66, DEX: 18, HPD:[12, 8, 12] },
  { id: 636, ruleset: 'dnd 5e', name: "Talis the White", AC: 18, HP: 58, DEX: 12, HPD:[9, 8, 18] },
  { id: 637, ruleset: 'dnd 5e', name: "Tarrasque", AC: 25, HP: 676, DEX: 11, HPD:[33, 20, 330] },
  { id: 638, ruleset: 'dnd 5e', name: "Tashlyn Yafeera", AC: 18, HP: 149, DEX: 15, HPD:[23, 8, 46] },
  { id: 639, ruleset: 'dnd 5e', name: "Three Crones", AC: 12, HP: 9, DEX: 10, HPD:[2, 8, 0] },
  { id: 640, ruleset: 'dnd 5e', name: "Thri-kreen", AC: 15, HP: 33, DEX: 15, HPD:[6, 8, 6] },
  { id: 641, ruleset: 'dnd 5e', name: "Thug", AC: 11, HP: 32, DEX: 11, HPD:[5, 8, 10] },
  { id: 642, ruleset: 'dnd 5e', name: "Thurl Merosska", AC: 16, HP: 71, DEX: 14, HPD:[11, 8, 21] },
  { id: 643, ruleset: 'dnd 5e', name: "Tiamat", AC: 25, HP: 615, DEX: 10, HPD:[30, 20, 200] },
  { id: 644, ruleset: 'dnd 5e', name: "Tiger", AC: 12, HP: 37, DEX: 15, HPD:[5, 10, 10] },
  { id: 645, ruleset: 'dnd 5e', name: "Treant", AC: 16, HP: 138, DEX: 8, HPD:[12, 12, 60] },
  { id: 646, ruleset: 'dnd 5e', name: "Tribal Warrior", AC: 12, HP: 11, DEX: 11, HPD:[2, 8, 2] },
  { id: 647, ruleset: 'dnd 5e', name: "Triceratops", AC: 13, HP: 95, DEX: 9, HPD:[10, 12, 30] },
  { id: 648, ruleset: 'dnd 5e', name: "Tridrone", AC: 15, HP: 16, DEX: 13, HPD:[3, 8, 3] },
  { id: 649, ruleset: 'dnd 5e', name: "Troglodyte", AC: 11, HP: 13, DEX: 10, HPD:[2, 8, 4] },
  { id: 650, ruleset: 'dnd 5e', name: "Troll", AC: 15, HP: 84, DEX: 13, HPD:[8, 10, 40] },
  { id: 651, ruleset: 'dnd 5e', name: "Twig Blight", AC: 13, HP: 4, DEX: 13, HPD:[1, 6, 1] },
  { id: 652, ruleset: 'dnd 5e', name: "Tyrannosaurus Rex", AC: 13, HP: 136, DEX: 10, HPD:[13, 12, 52] },
  { id: 653, ruleset: 'dnd 5e', name: "Ulitharid", AC: 15, HP: 127, DEX: 12, HPD:[17, 10, 34] },
  { id: 654, ruleset: 'dnd 5e', name: "Ultroloth", AC: 19, HP: 153, DEX: 16, HPD:[18, 8, 72] },
  { id: 655, ruleset: 'dnd 5e', name: "Umber Hulk", AC: 18, HP: 93, DEX: 13, HPD:[11, 10, 33] },
  { id: 656, ruleset: 'dnd 5e', name: "Unicorn", AC: 12, HP: 67, DEX: 14, HPD:[9, 10, 18] },
  { id: 657, ruleset: 'dnd 5e', name: "Vajra Safahr", AC: 14, HP: 126, DEX: 14, HPD:[23, 8, 23] },
  { id: 658, ruleset: 'dnd 5e', name: "Vampire", AC: 16, HP: 144, DEX: 18, HPD:[17, 8, 68] },
  { id: 659, ruleset: 'dnd 5e', name: "Vampire Spawn", AC: 15, HP: 82, DEX: 16, HPD:[11, 8, 33] },
  { id: 660, ruleset: 'dnd 5e', name: "Vampiric Ixitxachitl", AC: 16, HP: 44, DEX: 18, HPD:[8, 8, 8] },
  { id: 661, ruleset: 'dnd 5e', name: "Vampiric Ixitxachitl Cleric", AC: 16, HP: 44, DEX: 18, HPD:[8, 8, 8] },
  { id: 662, ruleset: 'dnd 5e', name: "Vanifer", AC: 15, HP: 112, DEX: 16, HPD:[15, 8, 45] },
  { id: 663, ruleset: 'dnd 5e', name: "Veteran", AC: 17, HP: 58, DEX: 13, HPD:[9, 8, 18] },
  { id: 664, ruleset: 'dnd 5e', name: "Victoro Cassalanter", AC: 15, HP: 97, DEX: 13, HPD:[15, 8, 30] },
  { id: 665, ruleset: 'dnd 5e', name: "Vine Blight", AC: 12, HP: 26, DEX: 8, HPD:[4, 8, 8] },
  { id: 666, ruleset: 'dnd 5e', name: "Violet Fungus", AC: 5, HP: 18, DEX: 1, HPD:[4, 8, 0] },
  { id: 667, ruleset: 'dnd 5e', name: "Volothamp “Volo” Geddarm", AC: 11, HP: 31, DEX: 12, HPD:[7, 8, 0] },
  { id: 668, ruleset: 'dnd 5e', name: "Vrock", AC: 15, HP: 104, DEX: 15, HPD:[11, 10, 44] },
  { id: 669, ruleset: 'dnd 5e', name: "Vulture", AC: 10, HP: 5, DEX: 10, HPD:[1, 8, 1] },
  { id: 670, ruleset: 'dnd 5e', name: "Walking Statue of Waterdeep", AC: 17, HP: 314, DEX: 8, HPD:[17, 20, 136] },
  { id: 671, ruleset: 'dnd 5e', name: "Warhorse", AC: 11, HP: 19, DEX: 12, HPD:[3, 10, 3] },
  { id: 672, ruleset: 'dnd 5e', name: "Warhorse Skeleton", AC: 13, HP: 22, DEX: 12, HPD:[3, 10, 6] },
  { id: 673, ruleset: 'dnd 5e', name: "Warrior Vampire", AC: 18, HP: 144, DEX: 18, HPD:[17, 8, 68] },
  { id: 674, ruleset: 'dnd 5e', name: "Water Elemental", AC: 14, HP: 114, DEX: 14, HPD:[12, 10, 48] },
  { id: 675, ruleset: 'dnd 5e', name: "Water Elemental Myrmidon", AC: 18, HP: 127, DEX: 14, HPD:[17, 8, 51] },
  { id: 676, ruleset: 'dnd 5e', name: "Water Weird", AC: 13, HP: 58, DEX: 16, HPD:[9, 10, 9] },
  { id: 677, ruleset: 'dnd 5e', name: "Weasel", AC: 13, HP: 1, DEX: 16, HPD:[1, 4, -1] },
  { id: 678, ruleset: 'dnd 5e', name: "Werebat", AC: 13, HP: 24, DEX: 17, HPD:[7, 6, 0] },
  { id: 679, ruleset: 'dnd 5e', name: "Werebear", AC: 11, HP: 135, DEX: 10, HPD:[18, 8, 54] },
  { id: 680, ruleset: 'dnd 5e', name: "Wereboar", AC: 11, HP: 78, DEX: 10, HPD:[12, 8, 24] },
  { id: 681, ruleset: 'dnd 5e', name: "Wererat", AC: 12, HP: 33, DEX: 15, HPD:[6, 8, 6] },
  { id: 682, ruleset: 'dnd 5e', name: "Weretiger", AC: 12, HP: 120, DEX: 15, HPD:[16, 8, 48] },
  { id: 683, ruleset: 'dnd 5e', name: "Werewolf", AC: 12, HP: 58, DEX: 13, HPD:[9, 8, 18] },
  { id: 684, ruleset: 'dnd 5e', name: "White Dragon Wyrmling", AC: 16, HP: 32, DEX: 10, HPD:[5, 8, 10] },
  { id: 685, ruleset: 'dnd 5e', name: "Wiggan Nettlebee", AC: 11, HP: 36, DEX: 12, HPD:[8, 6, 8] },
  { id: 686, ruleset: 'dnd 5e', name: "Wight", AC: 14, HP: 45, DEX: 14, HPD:[6, 8, 18] },
  { id: 687, ruleset: 'dnd 5e', name: "Will-o'-Wisp", AC: 19, HP: 22, DEX: 28, HPD:[9, 4, 0] },
  { id: 688, ruleset: 'dnd 5e', name: "Windharrow", AC: 15, HP: 55, DEX: 16, HPD:[10, 8, 10] },
  { id: 689, ruleset: 'dnd 5e', name: "Winged Kobold", AC: 13, HP: 7, DEX: 16, HPD:[3, 6, -3] },
  { id: 690, ruleset: 'dnd 5e', name: "Winter Wolf", AC: 13, HP: 75, DEX: 13, HPD:[10, 10, 20] },
  { id: 691, ruleset: 'dnd 5e', name: "Wolf", AC: 13, HP: 11, DEX: 15, HPD:[2, 8, 2] },
  { id: 692, ruleset: 'dnd 5e', name: "Worg", AC: 13, HP: 26, DEX: 13, HPD:[4, 10, 4] },
  { id: 693, ruleset: 'dnd 5e', name: "Wraith", AC: 13, HP: 67, DEX: 16, HPD:[9, 8, 27] },
  { id: 694, ruleset: 'dnd 5e', name: "Wyvern", AC: 13, HP: 110, DEX: 10, HPD:[13, 10, 39] },
  { id: 695, ruleset: 'dnd 5e', name: "Xaxan Thavish", AC: 12, HP: 22, DEX: 14, HPD:[9, 4, 0] },
  { id: 696, ruleset: 'dnd 5e', name: "Xorn", AC: 19, HP: 73, DEX: 10, HPD:[7, 8, 42] },
  { id: 697, ruleset: 'dnd 5e', name: "Yan-C-Bin", AC: 22, HP: 283, DEX: 24, HPD:[21, 12, 147] },
  { id: 698, ruleset: 'dnd 5e', name: "Yeti", AC: 12, HP: 51, DEX: 13, HPD:[6, 10, 18] },
  { id: 699, ruleset: 'dnd 5e', name: "Yochlol", AC: 15, HP: 136, DEX: 14, HPD:[16, 8, 64] },
  { id: 700, ruleset: 'dnd 5e', name: "Young Black Dragon", AC: 18, HP: 127, DEX: 14, HPD:[15, 10, 45] },
  { id: 701, ruleset: 'dnd 5e', name: "Young Blue Dragon", AC: 18, HP: 152, DEX: 10, HPD:[16, 10, 64] },
  { id: 702, ruleset: 'dnd 5e', name: "Young Brass Dragon", AC: 17, HP: 110, DEX: 10, HPD:[13, 10, 39] },
  { id: 703, ruleset: 'dnd 5e', name: "Young Bronze Dragon", AC: 18, HP: 142, DEX: 10, HPD:[15, 10, 60] },
  { id: 704, ruleset: 'dnd 5e', name: "Young Copper Dragon", AC: 17, HP: 119, DEX: 12, HPD:[14, 10, 42] },
  { id: 705, ruleset: 'dnd 5e', name: "Young Gold Dragon", AC: 18, HP: 178, DEX: 14, HPD:[17, 10, 85] },
  { id: 706, ruleset: 'dnd 5e', name: "Young Green Dragon", AC: 18, HP: 136, DEX: 12, HPD:[16, 10, 48] },
  { id: 707, ruleset: 'dnd 5e', name: "Young Purple Worm", AC: 14, HP: 93, DEX: 13, HPD:[11, 12, 32] },
  { id: 708, ruleset: 'dnd 5e', name: "Young Red Dragon", AC: 18, HP: 178, DEX: 10, HPD:[17, 10, 85] },
  { id: 709, ruleset: 'dnd 5e', name: "Young Red Shadow Dragon", AC: 18, HP: 178, DEX: 10, HPD:[17, 10, 85] },
  { id: 710, ruleset: 'dnd 5e', name: "Young Remorhaz", AC: 14, HP: 93, DEX: 13, HPD:[11, 10, 33] },
  { id: 711, ruleset: 'dnd 5e', name: "Young Silver Dragon", AC: 18, HP: 168, DEX: 10, HPD:[16, 10, 80] },
  { id: 712, ruleset: 'dnd 5e', name: "Young White Dragon", AC: 17, HP: 133, DEX: 10, HPD:[14, 10, 56] },
  { id: 713, ruleset: 'dnd 5e', name: "Yuan-Ti Abomination", AC: 15, HP: 127, DEX: 16, HPD:[15, 10, 45] },
  { id: 714, ruleset: 'dnd 5e', name: "Yuan-Ti Malison", AC: 12, HP: 66, DEX: 14, HPD:[18, 8, 12] },
  { id: 715, ruleset: 'dnd 5e', name: "Yuan-Ti Pureblood", AC: 11, HP: 40, DEX: 12, HPD:[9, 8, 0] },
  { id: 716, ruleset: 'dnd 5e', name: "Ziraj the Hunter", AC: 17, HP: 153, DEX: 18, HPD:[18, 8, 72] },
  { id: 717, ruleset: 'dnd 5e', name: "Zombie", AC: 8, HP: 22, DEX: 6, HPD:[3, 8, 9] },
  { id: 718, ruleset: 'dnd 5e', name: "Zorak Lightdrinker", AC: 18, HP: 144, DEX: 18, HPD:[17, 8, 68] },
  { id: 719, ruleset: 'pathfinder 2e', name: "Aapoph Serpentfolk", AC: 18, HP: 60, DEX: 8, HPD: [1, 8, 0] },
  { id: 720, ruleset: 'pathfinder 2e', name: "Aasimar Redeemer", AC: 23, HP: 73, DEX: 11, HPD: [1, 11, 0] },
  { id: 721, ruleset: 'pathfinder 2e', name: "Abandoned Zealot", AC: 22, HP: 75, DEX: 14, HPD: [1, 14, 0] },
  { id: 722, ruleset: 'pathfinder 2e', name: "Abrikandilu", AC: 19, HP: 70, DEX: 10, HPD: [1, 10, 0] },
  { id: 723, ruleset: 'pathfinder 2e', name: "Accursed Forge-Spurned", AC: 37, HP: 285, DEX: 26, HPD: [1, 26, 0] },
  { id: 724, ruleset: 'pathfinder 2e', name: "Adachros", AC: 34, HP: 225, DEX: 22, HPD: [1, 22, 0] },
  { id: 725, ruleset: 'pathfinder 2e', name: "Adamantine Golem", AC: 42, HP: 255, DEX: 26, HPD: [1, 26, 0] },
  { id: 726, ruleset: 'pathfinder 2e', name: "Adhukait", AC: 25, HP: 130, DEX: 15, HPD: [1, 15, 0] },
  { id: 727, ruleset: 'pathfinder 2e', name: "Adlet", AC: 30, HP: 180, DEX: 18, HPD: [1, 18, 0] },
  { id: 728, ruleset: 'pathfinder 2e', name: "Adrivallo", AC: 28, HP: 210, DEX: 16, HPD: [1, 16, 0] },
  { id: 729, ruleset: 'pathfinder 2e', name: "Adult Black Dragon", AC: 31, HP: 215, DEX: 22, HPD: [1, 22, 0] },
  { id: 730, ruleset: 'pathfinder 2e', name: "Adult Blue Dragon", AC: 34, HP: 260, DEX: 24, HPD: [1, 24, 0] },
  { id: 731, ruleset: 'pathfinder 2e', name: "Adult Brass Dragon", AC: 31, HP: 215, DEX: 21, HPD: [1, 21, 0] },
  { id: 732, ruleset: 'pathfinder 2e', name: "Adult Brine Dragon", AC: 33, HP: 222, DEX: 23, HPD: [1, 23, 0] },
  { id: 733, ruleset: 'pathfinder 2e', name: "Adult Bronze Dragon", AC: 34, HP: 260, DEX: 23, HPD: [1, 23, 0] },
  { id: 734, ruleset: 'pathfinder 2e', name: "Adult Cloud Dragon", AC: 36, HP: 254, DEX: 28, HPD: [1, 28, 0] },
  { id: 735, ruleset: 'pathfinder 2e', name: "Adult Copper Dragon", AC: 33, HP: 235, DEX: 23, HPD: [1, 23, 0] },
  { id: 736, ruleset: 'pathfinder 2e', name: "Adult Crystal Dragon", AC: 33, HP: 185, DEX: 20, HPD: [1, 20, 0] },
  { id: 737, ruleset: 'pathfinder 2e', name: "Adult Forest Dragon", AC: 36, HP: 290, DEX: 25, HPD: [1, 25, 0] },
  { id: 738, ruleset: 'pathfinder 2e', name: "Adult Gold Dragon", AC: 38, HP: 330, DEX: 29, HPD: [1, 29, 0] },
  { id: 739, ruleset: 'pathfinder 2e', name: "Adult Green Dragon", AC: 34, HP: 215, DEX: 22, HPD: [1, 22, 0] },
  { id: 740, ruleset: 'pathfinder 2e', name: "Adult Magma Dragon", AC: 34, HP: 270, DEX: 23, HPD: [1, 23, 0] },
  { id: 741, ruleset: 'pathfinder 2e', name: "Adult Red Dragon", AC: 37, HP: 305, DEX: 26, HPD: [1, 26, 0] },
  { id: 742, ruleset: 'pathfinder 2e', name: "Adult Sea Dragon", AC: 33, HP: 225, DEX: 21, HPD: [1, 21, 0] },
  { id: 743, ruleset: 'pathfinder 2e', name: "Adult Silver Dragon", AC: 37, HP: 295, DEX: 26, HPD: [1, 26, 0] },
  { id: 744, ruleset: 'pathfinder 2e', name: "Adult Sky Dragon", AC: 34, HP: 235, DEX: 24, HPD: [1, 24, 0] },
  { id: 745, ruleset: 'pathfinder 2e', name: "Adult Sovereign Dragon", AC: 37, HP: 275, DEX: 29, HPD: [1, 29, 0] },
  { id: 746, ruleset: 'pathfinder 2e', name: "Adult Umbral Dragon", AC: 37, HP: 275, DEX: 29, HPD: [1, 29, 0] },
  { id: 747, ruleset: 'pathfinder 2e', name: "Adult Underworld Dragon", AC: 31, HP: 195, DEX: 20, HPD: [1, 20, 0] },
  { id: 748, ruleset: 'pathfinder 2e', name: "Adult White Dragon", AC: 29, HP: 215, DEX: 20, HPD: [1, 20, 0] },
  { id: 749, ruleset: 'pathfinder 2e', name: "Aeolaeka (Stone Azata)", AC: 33, HP: 250, DEX: 23, HPD: [1, 23, 0] },
  { id: 750, ruleset: 'pathfinder 2e', name: "Afziaka Brute", AC: 23, HP: 120, DEX: 12, HPD: [1, 12, 0] },
  { id: 751, ruleset: 'pathfinder 2e', name: "Afziaka Stalker", AC: 32, HP: 250, DEX: 22, HPD: [1, 22, 0] },
  { id: 752, ruleset: 'pathfinder 2e', name: "Agai", AC: 28, HP: 155, DEX: 19, HPD: [1, 19, 0] },
  { id: 753, ruleset: 'pathfinder 2e', name: "Aghash", AC: 19, HP: 75, DEX: 12, HPD: [1, 12, 0] },
  { id: 754, ruleset: 'pathfinder 2e', name: "Agradaemon", AC: 45, HP: 400, DEX: 31, HPD: [1, 31, 0] },
  { id: 755, ruleset: 'pathfinder 2e', name: "Ahuizotl", AC: 23, HP: 105, DEX: 13, HPD: [1, 13, 0] },
  { id: 756, ruleset: 'pathfinder 2e', name: "Ahvothian", AC: 25, HP: 160, DEX: 18, HPD: [1, 18, 0] },
  { id: 757, ruleset: 'pathfinder 2e', name: "Aigamuxa", AC: 27, HP: 140, DEX: 19, HPD: [1, 19, 0] },
  { id: 758, ruleset: 'pathfinder 2e', name: "Ainamuuren", AC: 33, HP: 259, DEX: 25, HPD: [1, 25, 0] },
  { id: 759, ruleset: 'pathfinder 2e', name: "Air Mephit", AC: 16, HP: 12, DEX: 3, HPD: [1, 3, 0] },
  { id: 760, ruleset: 'pathfinder 2e', name: "Air Wisp", AC: 18, HP: 10, DEX: 6, HPD: [1, 6, 0] },
  { id: 761, ruleset: 'pathfinder 2e', name: "Aiudara Wraith", AC: 45, HP: 300, DEX: 33, HPD: [1, 33, 0] },
  { id: 762, ruleset: 'pathfinder 2e', name: "Akata", AC: 16, HP: 15, DEX: 6, HPD: [1, 6, 0] },
  { id: 763, ruleset: 'pathfinder 2e', name: "Akizendri", AC: 19, HP: 42, DEX: 8, HPD: [1, 8, 0] },
  { id: 764, ruleset: 'pathfinder 2e', name: "Akrida", AC: 21, HP: 60, DEX: 10, HPD: [1, 10, 0] },
  { id: 765, ruleset: 'pathfinder 2e', name: "Akuzhail", AC: 29, HP: 195, DEX: 21, HPD: [1, 21, 0] },
  { id: 766, ruleset: 'pathfinder 2e', name: "Alapolo", AC: 41, HP: 310, DEX: 28, HPD: [1, 28, 0] },
  { id: 767, ruleset: 'pathfinder 2e', name: "Alasen", AC: 36, HP: 245, DEX: 26, HPD: [1, 26, 0] },
  { id: 768, ruleset: 'pathfinder 2e', name: "Alate Ant", AC: 39, HP: 295, DEX: 28, HPD: [1, 28, 0] },
  { id: 769, ruleset: 'pathfinder 2e', name: "Albino Giant Bat", AC: 22, HP: 72, DEX: 15, HPD: [1, 15, 0] },
  { id: 770, ruleset: 'pathfinder 2e', name: "Alchemical Golem", AC: 27, HP: 150, DEX: 15, HPD: [1, 15, 0] },
  { id: 771, ruleset: 'pathfinder 2e', name: "Algea", AC: 30, HP: 144, DEX: 22, HPD: [1, 22, 0] },
  { id: 772, ruleset: 'pathfinder 2e', name: "Alghollthu Master", AC: 23, HP: 135, DEX: 17, HPD: [1, 17, 0] },
  { id: 773, ruleset: 'pathfinder 2e', name: "Aliriel", AC: 37, HP: 210, DEX: 27, HPD: [1, 27, 0] },
  { id: 774, ruleset: 'pathfinder 2e', name: "Aluum Enforcer", AC: 30, HP: 145, DEX: 17, HPD: [1, 17, 0] },
  { id: 775, ruleset: 'pathfinder 2e', name: "Amalgamite", AC: 33, HP: 220, DEX: 23, HPD: [1, 23, 0] },
  { id: 776, ruleset: 'pathfinder 2e', name: "Ammut", AC: 41, HP: 350, DEX: 33, HPD: [1, 33, 0] },
  { id: 777, ruleset: 'pathfinder 2e', name: "Amoeba Swarm", AC: 9, HP: 35, DEX: 3, HPD: [1, 3, 0] },
  { id: 778, ruleset: 'pathfinder 2e', name: "Amphisbaena", AC: 21, HP: 70, DEX: 10, HPD: [1, 10, 0] },
  { id: 779, ruleset: 'pathfinder 2e', name: "Anadi Elder", AC: 23, HP: 95, DEX: 15, HPD: [1, 15, 0] },
  { id: 780, ruleset: 'pathfinder 2e', name: "Anadi Fateweaver", AC: 21, HP: 75, DEX: 14, HPD: [1, 14, 0] },
  { id: 781, ruleset: 'pathfinder 2e', name: "Anadi Hunter", AC: 17, HP: 27, DEX: 9, HPD: [1, 9, 0] },
  { id: 782, ruleset: 'pathfinder 2e', name: "Anadi Lurker", AC: 19, HP: 45, DEX: 11, HPD: [1, 11, 0] },
  { id: 783, ruleset: 'pathfinder 2e', name: "Anadi Sage", AC: 20, HP: 58, DEX: 12, HPD: [1, 12, 0] },
  { id: 784, ruleset: 'pathfinder 2e', name: "Anadi Seeker", AC: 15, HP: 20, DEX: 7, HPD: [1, 7, 0] },
  { id: 785, ruleset: 'pathfinder 2e', name: "Anancus", AC: 25, HP: 170, DEX: 16, HPD: [1, 16, 0] },
  { id: 786, ruleset: 'pathfinder 2e', name: "Ancient Black Dragon", AC: 39, HP: 325, DEX: 30, HPD: [1, 30, 0] },
  { id: 787, ruleset: 'pathfinder 2e', name: "Ancient Blue Dragon", AC: 42, HP: 370, DEX: 31, HPD: [1, 31, 0] },
  { id: 788, ruleset: 'pathfinder 2e', name: "Ancient Brass Dragon", AC: 39, HP: 325, DEX: 30, HPD: [1, 30, 0] },
  { id: 789, ruleset: 'pathfinder 2e', name: "Ancient Brine Dragon", AC: 40, HP: 330, DEX: 32, HPD: [1, 32, 0] },
  { id: 790, ruleset: 'pathfinder 2e', name: "Ancient Bronze Dragon", AC: 43, HP: 360, DEX: 32, HPD: [1, 32, 0] },
  { id: 791, ruleset: 'pathfinder 2e', name: "Ancient Cloud Dragon", AC: 44, HP: 355, DEX: 34, HPD: [1, 34, 0] },
  { id: 792, ruleset: 'pathfinder 2e', name: "Ancient Copper Dragon", AC: 41, HP: 345, DEX: 30, HPD: [1, 30, 0] },
  { id: 793, ruleset: 'pathfinder 2e', name: "Ancient Crystal Dragon", AC: 42, HP: 275, DEX: 28, HPD: [1, 28, 0] },
  { id: 794, ruleset: 'pathfinder 2e', name: "Ancient Forest Dragon", AC: 44, HP: 410, DEX: 33, HPD: [1, 33, 0] },
  { id: 795, ruleset: 'pathfinder 2e', name: "Ancient Gold Dragon", AC: 46, HP: 450, DEX: 36, HPD: [1, 36, 0] },
  { id: 796, ruleset: 'pathfinder 2e', name: "Ancient Green Dragon", AC: 41, HP: 315, DEX: 30, HPD: [1, 30, 0] },
  { id: 797, ruleset: 'pathfinder 2e', name: "Ancient Magma Dragon", AC: 42, HP: 390, DEX: 33, HPD: [1, 33, 0] },
  { id: 798, ruleset: 'pathfinder 2e', name: "Ancient Red Dragon", AC: 45, HP: 425, DEX: 35, HPD: [1, 35, 0] },
  { id: 799, ruleset: 'pathfinder 2e', name: "Ancient Rivener", AC: 35, HP: 280, DEX: 25, HPD: [1, 25, 0] },
  { id: 800, ruleset: 'pathfinder 2e', name: "Ancient Sea Dragon", AC: 41, HP: 350, DEX: 30, HPD: [1, 30, 0] },
  { id: 801, ruleset: 'pathfinder 2e', name: "Ancient Silver Dragon", AC: 45, HP: 410, DEX: 32, HPD: [1, 32, 0] },
  { id: 802, ruleset: 'pathfinder 2e', name: "Ancient Skaveling", AC: 37, HP: 270, DEX: 29, HPD: [1, 29, 0] },
  { id: 803, ruleset: 'pathfinder 2e', name: "Ancient Sky Dragon", AC: 42, HP: 335, DEX: 32, HPD: [1, 32, 0] },
  { id: 804, ruleset: 'pathfinder 2e', name: "Ancient Sovereign Dragon", AC: 46, HP: 410, DEX: 36, HPD: [1, 36, 0] },
  { id: 805, ruleset: 'pathfinder 2e', name: "Ancient Umbral Dragon", AC: 45, HP: 375, DEX: 36, HPD: [1, 36, 0] },
  { id: 806, ruleset: 'pathfinder 2e', name: "Ancient Underworld Dragon", AC: 39, HP: 295, DEX: 28, HPD: [1, 28, 0] },
  { id: 807, ruleset: 'pathfinder 2e', name: "Ancient White Dragon", AC: 36, HP: 330, DEX: 30, HPD: [1, 30, 0] },
  { id: 808, ruleset: 'pathfinder 2e', name: "Ancient Wisp", AC: 33, HP: 130, DEX: 22, HPD: [1, 22, 0] },
  { id: 809, ruleset: 'pathfinder 2e', name: "Android Infiltrator", AC: 18, HP: 28, DEX: 6, HPD: [1, 6, 0] },
  { id: 810, ruleset: 'pathfinder 2e', name: "Angazhani", AC: 26, HP: 180, DEX: 18, HPD: [1, 18, 0] },
  { id: 811, ruleset: 'pathfinder 2e', name: "Angheuvore Flesh-Gnawer", AC: 18, HP: 30, DEX: 7, HPD: [1, 7, 0] },
  { id: 812, ruleset: 'pathfinder 2e', name: "Angoyang", AC: 35, HP: 250, DEX: 25, HPD: [1, 25, 0] },
  { id: 813, ruleset: 'pathfinder 2e', name: "Animate Dream", AC: 24, HP: 110, DEX: 14, HPD: [1, 14, 0] },
  { id: 814, ruleset: 'pathfinder 2e', name: "Animated Armor", AC: 17, HP: 20, DEX: 6, HPD: [1, 6, 0] },
  { id: 815, ruleset: 'pathfinder 2e', name: "Animated Blades", AC: 16, HP: 4, DEX: 3, HPD: [1, 3, 0] },
  { id: 816, ruleset: 'pathfinder 2e', name: "Animated Broom", AC: 16, HP: 6, DEX: 3, HPD: [1, 3, 0] },
  { id: 817, ruleset: 'pathfinder 2e', name: "Animated Colossus", AC: 39, HP: 245, DEX: 23, HPD: [1, 23, 0] },
  { id: 818, ruleset: 'pathfinder 2e', name: "Animated Dragonstorms", AC: 42, HP: 255, DEX: 30, HPD: [1, 30, 0] },
  { id: 819, ruleset: 'pathfinder 2e', name: "Animated Fireplace", AC: 24, HP: 65, DEX: 9, HPD: [1, 9, 0] },
  { id: 820, ruleset: 'pathfinder 2e', name: "Animated Furnace", AC: 30, HP: 135, DEX: 15, HPD: [1, 15, 0] },
  { id: 821, ruleset: 'pathfinder 2e', name: "Animated Silverware Swarm", AC: 16, HP: 14, DEX: 5, HPD: [1, 5, 0] },
  { id: 822, ruleset: 'pathfinder 2e', name: "Animated Statue", AC: 19, HP: 35, DEX: 9, HPD: [1, 9, 0] },
  { id: 823, ruleset: 'pathfinder 2e', name: "Animated Tea Cart", AC: 28, HP: 120, DEX: 13, HPD: [1, 13, 0] },
  { id: 824, ruleset: 'pathfinder 2e', name: "Animated Trebuchet", AC: 36, HP: 200, DEX: 21, HPD: [1, 21, 0] },
  { id: 825, ruleset: 'pathfinder 2e', name: "Ankhrav", AC: 20, HP: 40, DEX: 7, HPD: [1, 7, 0] },
  { id: 826, ruleset: 'pathfinder 2e', name: "Ankou", AC: 36, HP: 280, DEX: 25, HPD: [1, 25, 0] },
  { id: 827, ruleset: 'pathfinder 2e', name: "Ankou Assassin", AC: 40, HP: 333, DEX: 30, HPD: [1, 30, 0] },
  { id: 828, ruleset: 'pathfinder 2e', name: "Ankylosaurus", AC: 26, HP: 90, DEX: 12, HPD: [1, 12, 0] },
  { id: 829, ruleset: 'pathfinder 2e', name: "Annis Hag", AC: 24, HP: 85, DEX: 15, HPD: [1, 15, 0] },
  { id: 830, ruleset: 'pathfinder 2e', name: "Anugobu Apprentice", AC: 17, HP: 35, DEX: 12, HPD: [1, 12, 0] },
  { id: 831, ruleset: 'pathfinder 2e', name: "Anugobu Wondercrafter", AC: 23, HP: 90, DEX: 18, HPD: [1, 18, 0] },
  { id: 832, ruleset: 'pathfinder 2e', name: "Aolaz", AC: 42, HP: 255, DEX: 33, HPD: [1, 33, 0] },
  { id: 833, ruleset: 'pathfinder 2e', name: "Aphorite Sharpshooter", AC: 21, HP: 60, DEX: 8, HPD: [1, 8, 0] },
  { id: 834, ruleset: 'pathfinder 2e', name: "Ararda", AC: 43, HP: 280, DEX: 31, HPD: [1, 31, 0] },
  { id: 835, ruleset: 'pathfinder 2e', name: "Arbiter", AC: 16, HP: 22, DEX: 7, HPD: [1, 7, 0] },
  { id: 836, ruleset: 'pathfinder 2e', name: "Arboreal Archive", AC: 33, HP: 230, DEX: 25, HPD: [1, 25, 0] },
  { id: 837, ruleset: 'pathfinder 2e', name: "Arboreal Reaper", AC: 25, HP: 130, DEX: 15, HPD: [1, 15, 0] },
  { id: 838, ruleset: 'pathfinder 2e', name: "Arboreal Regent", AC: 26, HP: 150, DEX: 18, HPD: [1, 18, 0] },
  { id: 839, ruleset: 'pathfinder 2e', name: "Arboreal Snag", AC: 19, HP: 45, DEX: 8, HPD: [1, 8, 0] },
  { id: 840, ruleset: 'pathfinder 2e', name: "Arboreal Tar Tree", AC: 28, HP: 175, DEX: 18, HPD: [1, 18, 0] },
  { id: 841, ruleset: 'pathfinder 2e', name: "Arboreal Warden", AC: 20, HP: 75, DEX: 11, HPD: [1, 11, 0] },
  { id: 842, ruleset: 'pathfinder 2e', name: "Arcane Living Rune", AC: 34, HP: 245, DEX: 25, HPD: [1, 25, 0] },
  { id: 843, ruleset: 'pathfinder 2e', name: "Army Ant Swarm", AC: 21, HP: 55, DEX: 11, HPD: [1, 11, 0] },
  { id: 844, ruleset: 'pathfinder 2e', name: "Arskuva The Gnasher", AC: 31, HP: 270, DEX: 22, HPD: [1, 22, 0] },
  { id: 845, ruleset: 'pathfinder 2e', name: "Asanbosam", AC: 24, HP: 95, DEX: 17, HPD: [1, 17, 0] },
  { id: 846, ruleset: 'pathfinder 2e', name: "Assassin Vine", AC: 18, HP: 68, DEX: 10, HPD: [1, 10, 0] },
  { id: 847, ruleset: 'pathfinder 2e', name: "Astradaemon", AC: 39, HP: 240, DEX: 28, HPD: [1, 28, 0] },
  { id: 848, ruleset: 'pathfinder 2e', name: "Astral Deva", AC: 36, HP: 285, DEX: 26, HPD: [1, 26, 0] },
  { id: 849, ruleset: 'pathfinder 2e', name: "Athach", AC: 33, HP: 250, DEX: 22, HPD: [1, 22, 0] },
  { id: 850, ruleset: 'pathfinder 2e', name: "Attic Whisperer", AC: 21, HP: 60, DEX: 10, HPD: [1, 10, 0] },
  { id: 851, ruleset: 'pathfinder 2e', name: "Augnagar", AC: 36, HP: 225, DEX: 27, HPD: [1, 27, 0] },
  { id: 852, ruleset: 'pathfinder 2e', name: "Augur", AC: 17, HP: 14, DEX: 8, HPD: [1, 8, 0] },
  { id: 853, ruleset: 'pathfinder 2e', name: "Aukashungi Swarm", AC: 28, HP: 210, DEX: 18, HPD: [1, 18, 0] },
  { id: 854, ruleset: 'pathfinder 2e', name: "Aurumvorax", AC: 28, HP: 170, DEX: 18, HPD: [1, 18, 0] },
  { id: 855, ruleset: 'pathfinder 2e', name: "Avarek", AC: 26, HP: 150, DEX: 16, HPD: [1, 16, 0] },
  { id: 856, ruleset: 'pathfinder 2e', name: "Avatar Of The Lantern King", AC: 54, HP: 500, DEX: 39, HPD: [1, 39, 0] },
  { id: 857, ruleset: 'pathfinder 2e', name: "Avatar Of Walkena", AC: 40, HP: 380, DEX: 29, HPD: [1, 29, 0] },
  { id: 858, ruleset: 'pathfinder 2e', name: "Awakened Tree", AC: 22, HP: 100, DEX: 13, HPD: [1, 13, 0] },
  { id: 859, ruleset: 'pathfinder 2e', name: "Axiomite", AC: 26, HP: 155, DEX: 19, HPD: [1, 19, 0] },
  { id: 860, ruleset: 'pathfinder 2e', name: "Azarketi Explorer", AC: 18, HP: 30, DEX: 8, HPD: [1, 8, 0] },
  { id: 861, ruleset: 'pathfinder 2e', name: "Azer", AC: 17, HP: 45, DEX: 8, HPD: [1, 8, 0] },
  { id: 862, ruleset: 'pathfinder 2e', name: "Azmakian Effigy", AC: 25, HP: 110, DEX: 15, HPD: [1, 15, 0] },
  { id: 863, ruleset: 'pathfinder 2e', name: "Azure Worm", AC: 35, HP: 320, DEX: 22, HPD: [1, 22, 0] },
  { id: 864, ruleset: 'pathfinder 2e', name: "Azuretzi", AC: 22, HP: 75, DEX: 11, HPD: [1, 11, 0] },
  { id: 865, ruleset: 'pathfinder 2e', name: "Ba'aupa Mdoudu", AC: 33, HP: 245, DEX: 23, HPD: [1, 23, 0] },
  { id: 866, ruleset: 'pathfinder 2e', name: "Baatamidar", AC: 42, HP: 350, DEX: 39, HPD: [1, 39, 0] },
  { id: 867, ruleset: 'pathfinder 2e', name: "Babau", AC: 24, HP: 130, DEX: 13, HPD: [1, 13, 0] },
  { id: 868, ruleset: 'pathfinder 2e', name: "Baccali Alpaca", AC: 14, HP: 16, DEX: 4, HPD: [1, 4, 0] },
  { id: 869, ruleset: 'pathfinder 2e', name: "Badger", AC: 16, HP: 15, DEX: 6, HPD: [1, 6, 0] },
  { id: 870, ruleset: 'pathfinder 2e', name: "Balisse", AC: 26, HP: 145, DEX: 18, HPD: [1, 18, 0] },
  { id: 871, ruleset: 'pathfinder 2e', name: "Ball Python", AC: 16, HP: 20, DEX: 6, HPD: [1, 6, 0] },
  { id: 872, ruleset: 'pathfinder 2e', name: "Balor", AC: 45, HP: 480, DEX: 36, HPD: [1, 36, 0] },
  { id: 873, ruleset: 'pathfinder 2e', name: "Banshee", AC: 39, HP: 250, DEX: 32, HPD: [1, 32, 0] },
  { id: 874, ruleset: 'pathfinder 2e', name: "Baobhan Sith", AC: 24, HP: 105, DEX: 12, HPD: [1, 12, 0] },
  { id: 875, ruleset: 'pathfinder 2e', name: "Baomal", AC: 48, HP: 315, DEX: 34, HPD: [1, 34, 0] },
  { id: 876, ruleset: 'pathfinder 2e', name: "Barbazu", AC: 22, HP: 60, DEX: 13, HPD: [1, 13, 0] },
  { id: 877, ruleset: 'pathfinder 2e', name: "Barbtongued Wyvern", AC: 42, HP: 330, DEX: 29, HPD: [1, 29, 0] },
  { id: 878, ruleset: 'pathfinder 2e', name: "Barghest", AC: 21, HP: 50, DEX: 12, HPD: [1, 12, 0] },
  { id: 879, ruleset: 'pathfinder 2e', name: "Bargott", AC: 18, HP: 70, DEX: 9, HPD: [1, 9, 0] },
  { id: 880, ruleset: 'pathfinder 2e', name: "Barking Stag", AC: 34, HP: 245, DEX: 28, HPD: [1, 28, 0] },
  { id: 881, ruleset: 'pathfinder 2e', name: "Barnacle Ghoul", AC: 28, HP: 155, DEX: 17, HPD: [1, 17, 0] },
  { id: 882, ruleset: 'pathfinder 2e', name: "Basidirond", AC: 22, HP: 80, DEX: 8, HPD: [1, 8, 0] },
  { id: 883, ruleset: 'pathfinder 2e', name: "Basilisk", AC: 22, HP: 75, DEX: 11, HPD: [1, 11, 0] },
  { id: 884, ruleset: 'pathfinder 2e', name: "Bastion Archon", AC: 47, HP: 280, DEX: 37, HPD: [1, 37, 0] },
  { id: 885, ruleset: 'pathfinder 2e', name: "Bauble Beast", AC: 23, HP: 100, DEX: 13, HPD: [1, 13, 0] },
  { id: 886, ruleset: 'pathfinder 2e', name: "Baykok", AC: 27, HP: 200, DEX: 19, HPD: [1, 19, 0] },
  { id: 887, ruleset: 'pathfinder 2e', name: "Beaver", AC: 15, HP: 8, DEX: 6, HPD: [1, 6, 0] },
  { id: 888, ruleset: 'pathfinder 2e', name: "Bebilith", AC: 30, HP: 200, DEX: 21, HPD: [1, 21, 0] },
  { id: 889, ruleset: 'pathfinder 2e', name: "Beetle Carapace", AC: 25, HP: 90, DEX: 12, HPD: [1, 12, 0] },
  { id: 890, ruleset: 'pathfinder 2e', name: "Behemoth Hippopotamus", AC: 29, HP: 190, DEX: 19, HPD: [1, 19, 0] },
  { id: 891, ruleset: 'pathfinder 2e', name: "Behir", AC: 27, HP: 140, DEX: 17, HPD: [1, 17, 0] },
  { id: 892, ruleset: 'pathfinder 2e', name: "Belker", AC: 25, HP: 78, DEX: 14, HPD: [1, 14, 0] },
  { id: 893, ruleset: 'pathfinder 2e', name: "Belmazog", AC: 28, HP: 145, DEX: 18, HPD: [1, 18, 0] },
  { id: 894, ruleset: 'pathfinder 2e', name: "Benthic Reaver", AC: 46, HP: 500, DEX: 41, HPD: [1, 41, 0] },
  { id: 895, ruleset: 'pathfinder 2e', name: "Berberoka", AC: 36, HP: 310, DEX: 26, HPD: [1, 26, 0] },
  { id: 896, ruleset: 'pathfinder 2e', name: "Betobeto-San", AC: 33, HP: 170, DEX: 22, HPD: [1, 22, 0] },
  { id: 897, ruleset: 'pathfinder 2e', name: "Bharlen Sajor", AC: 32, HP: 190, DEX: 21, HPD: [1, 21, 0] },
  { id: 898, ruleset: 'pathfinder 2e', name: "Bhuta", AC: 30, HP: 175, DEX: 22, HPD: [1, 22, 0] },
  { id: 899, ruleset: 'pathfinder 2e', name: "Bida", AC: 27, HP: 135, DEX: 15, HPD: [1, 15, 0] },
  { id: 900, ruleset: 'pathfinder 2e', name: "Biloko Reaver", AC: 29, HP: 175, DEX: 19, HPD: [1, 19, 0] },
  { id: 901, ruleset: 'pathfinder 2e', name: "Biloko Veteran", AC: 21, HP: 58, DEX: 11, HPD: [1, 11, 0] },
  { id: 902, ruleset: 'pathfinder 2e', name: "Biloko Warrior", AC: 16, HP: 19, DEX: 7, HPD: [1, 7, 0] },
  { id: 903, ruleset: 'pathfinder 2e', name: "Binumir", AC: 19, HP: 25, DEX: 10, HPD: [1, 10, 0] },
  { id: 904, ruleset: 'pathfinder 2e', name: "Bishop Keppira D'bear", AC: 31, HP: 150, DEX: 20, HPD: [1, 20, 0] },
  { id: 905, ruleset: 'pathfinder 2e', name: "Bison", AC: 20, HP: 70, DEX: 8, HPD: [1, 8, 0] },
  { id: 906, ruleset: 'pathfinder 2e', name: "Black Bear", AC: 18, HP: 32, DEX: 8, HPD: [1, 8, 0] },
  { id: 907, ruleset: 'pathfinder 2e', name: "Black Pudding", AC: 14, HP: 165, DEX: 9, HPD: [1, 9, 0] },
  { id: 908, ruleset: 'pathfinder 2e', name: "Black Scorpion", AC: 38, HP: 275, DEX: 27, HPD: [1, 27, 0] },
  { id: 909, ruleset: 'pathfinder 2e', name: "Blindheim", AC: 18, HP: 27, DEX: 9, HPD: [1, 9, 0] },
  { id: 910, ruleset: 'pathfinder 2e', name: "Blink Dog", AC: 18, HP: 30, DEX: 8, HPD: [1, 8, 0] },
  { id: 911, ruleset: 'pathfinder 2e', name: "Blizzardborn", AC: 24, HP: 105, DEX: 14, HPD: [1, 14, 0] },
  { id: 912, ruleset: 'pathfinder 2e', name: "Blodeuwedd", AC: 24, HP: 105, DEX: 14, HPD: [1, 14, 0] },
  { id: 913, ruleset: 'pathfinder 2e', name: "Blood Boar", AC: 23, HP: 98, DEX: 15, HPD: [1, 15, 0] },
  { id: 914, ruleset: 'pathfinder 2e', name: "Blood Hag", AC: 26, HP: 170, DEX: 17, HPD: [1, 17, 0] },
  { id: 915, ruleset: 'pathfinder 2e', name: "Blood Ooze", AC: 12, HP: 90, DEX: 8, HPD: [1, 8, 0] },
  { id: 916, ruleset: 'pathfinder 2e', name: "Blood Painter", AC: 27, HP: 155, DEX: 19, HPD: [1, 19, 0] },
  { id: 917, ruleset: 'pathfinder 2e', name: "Blood Wolf", AC: 18, HP: 50, DEX: 9, HPD: [1, 9, 0] },
  { id: 918, ruleset: 'pathfinder 2e', name: "Bloodlash Bush", AC: 16, HP: 35, DEX: 6, HPD: [1, 6, 0] },
  { id: 919, ruleset: 'pathfinder 2e', name: "Bloodseeker", AC: 16, HP: 6, DEX: 6, HPD: [1, 6, 0] },
  { id: 920, ruleset: 'pathfinder 2e', name: "Bloodshroud", AC: 33, HP: 210, DEX: 23, HPD: [1, 23, 0] },
  { id: 921, ruleset: 'pathfinder 2e', name: "Bloom Cultist", AC: 22, HP: 75, DEX: 13, HPD: [1, 13, 0] },
  { id: 922, ruleset: 'pathfinder 2e', name: "Bloom Of Lamashtu", AC: 27, HP: 210, DEX: 21, HPD: [1, 21, 0] },
  { id: 923, ruleset: 'pathfinder 2e', name: "Bloom Wyvern", AC: 27, HP: 135, DEX: 16, HPD: [1, 16, 0] },
  { id: 924, ruleset: 'pathfinder 2e', name: "Bloomborn Athach", AC: 40, HP: 375, DEX: 31, HPD: [1, 31, 0] },
  { id: 925, ruleset: 'pathfinder 2e', name: "Blue-Ringed Octopus", AC: 16, HP: 15, DEX: 6, HPD: [1, 6, 0] },
  { id: 926, ruleset: 'pathfinder 2e', name: "Boar", AC: 18, HP: 30, DEX: 8, HPD: [1, 8, 0] },
  { id: 927, ruleset: 'pathfinder 2e', name: "Bodak", AC: 27, HP: 160, DEX: 17, HPD: [1, 17, 0] },
  { id: 928, ruleset: 'pathfinder 2e', name: "Bog Mummy", AC: 21, HP: 85, DEX: 12, HPD: [1, 12, 0] },
  { id: 929, ruleset: 'pathfinder 2e', name: "Bog Mummy Amalgamation", AC: 27, HP: 160, DEX: 18, HPD: [1, 18, 0] },
  { id: 930, ruleset: 'pathfinder 2e', name: "Bog Mummy Cultist", AC: 28, HP: 135, DEX: 19, HPD: [1, 19, 0] },
  { id: 931, ruleset: 'pathfinder 2e', name: "Bog Strider", AC: 18, HP: 30, DEX: 8, HPD: [1, 8, 0] },
  { id: 932, ruleset: 'pathfinder 2e', name: "Bogey", AC: 20, HP: 35, DEX: 9, HPD: [1, 9, 0] },
  { id: 933, ruleset: 'pathfinder 2e', name: "Bogeyman", AC: 30, HP: 175, DEX: 19, HPD: [1, 19, 0] },
  { id: 934, ruleset: 'pathfinder 2e', name: "Boggard Cultist", AC: 27, HP: 135, DEX: 16, HPD: [1, 16, 0] },
  { id: 935, ruleset: 'pathfinder 2e', name: "Boggard Hunter", AC: 24, HP: 140, DEX: 14, HPD: [1, 14, 0] },
  { id: 936, ruleset: 'pathfinder 2e', name: "Boggard Scout", AC: 16, HP: 24, DEX: 7, HPD: [1, 7, 0] },
  { id: 937, ruleset: 'pathfinder 2e', name: "Boggard Swampseer", AC: 18, HP: 40, DEX: 11, HPD: [1, 11, 0] },
  { id: 938, ruleset: 'pathfinder 2e', name: "Boggard Warden", AC: 28, HP: 160, DEX: 19, HPD: [1, 19, 0] },
  { id: 939, ruleset: 'pathfinder 2e', name: "Boggard Warrior", AC: 17, HP: 38, DEX: 8, HPD: [1, 8, 0] },
  { id: 940, ruleset: 'pathfinder 2e', name: "Bone Croupier", AC: 22, HP: 50, DEX: 11, HPD: [1, 11, 0] },
  { id: 941, ruleset: 'pathfinder 2e', name: "Bone Prophet", AC: 27, HP: 115, DEX: 15, HPD: [1, 15, 0] },
  { id: 942, ruleset: 'pathfinder 2e', name: "Bone Ship", AC: 42, HP: 415, DEX: 32, HPD: [1, 32, 0] },
  { id: 943, ruleset: 'pathfinder 2e', name: "Bone Skipper Swarm", AC: 24, HP: 120, DEX: 17, HPD: [1, 17, 0] },
  { id: 944, ruleset: 'pathfinder 2e', name: "Bone Warrior", AC: 21, HP: 65, DEX: 11, HPD: [1, 11, 0] },
  { id: 945, ruleset: 'pathfinder 2e', name: "Bore Worm Swarm", AC: 20, HP: 60, DEX: 12, HPD: [1, 12, 0] },
  { id: 946, ruleset: 'pathfinder 2e', name: "Bottlenose Dolphin", AC: 15, HP: 16, DEX: 7, HPD: [1, 7, 0] },
  { id: 947, ruleset: 'pathfinder 2e', name: "Brain Collector", AC: 26, HP: 140, DEX: 18, HPD: [1, 18, 0] },
  { id: 948, ruleset: 'pathfinder 2e', name: "Brainchild", AC: 30, HP: 200, DEX: 18, HPD: [1, 18, 0] },
  { id: 949, ruleset: 'pathfinder 2e', name: "Bralani", AC: 24, HP: 120, DEX: 14, HPD: [1, 14, 0] },
  { id: 950, ruleset: 'pathfinder 2e', name: "Bramble Champion Construct", AC: 20, HP: 45, DEX: 10, HPD: [1, 10, 0] },
  { id: 951, ruleset: 'pathfinder 2e', name: "Bregdi", AC: 27, HP: 180, DEX: 18, HPD: [1, 18, 0] },
  { id: 952, ruleset: 'pathfinder 2e', name: "Bright Walker", AC: 26, HP: 115, DEX: 19, HPD: [1, 19, 0] },
  { id: 953, ruleset: 'pathfinder 2e', name: "Brimorak", AC: 22, HP: 80, DEX: 12, HPD: [1, 12, 0] },
  { id: 954, ruleset: 'pathfinder 2e', name: "Brine Shark", AC: 19, HP: 45, DEX: 8, HPD: [1, 8, 0] },
  { id: 955, ruleset: 'pathfinder 2e', name: "Brontosaurus", AC: 28, HP: 220, DEX: 16, HPD: [1, 16, 0] },
  { id: 956, ruleset: 'pathfinder 2e', name: "Brood Leech Swarm", AC: 19, HP: 40, DEX: 9, HPD: [1, 9, 0] },
  { id: 957, ruleset: 'pathfinder 2e', name: "Brownie", AC: 16, HP: 25, DEX: 7, HPD: [1, 7, 0] },
  { id: 958, ruleset: 'pathfinder 2e', name: "Brughadatch", AC: 30, HP: 210, DEX: 19, HPD: [1, 19, 0] },
  { id: 959, ruleset: 'pathfinder 2e', name: "Brush Thylacine", AC: 18, HP: 30, DEX: 8, HPD: [1, 8, 0] },
  { id: 960, ruleset: 'pathfinder 2e', name: "Bugaboo", AC: 24, HP: 95, DEX: 14, HPD: [1, 14, 0] },
  { id: 961, ruleset: 'pathfinder 2e', name: "Bugbear Thug", AC: 17, HP: 34, DEX: 7, HPD: [1, 7, 0] },
  { id: 962, ruleset: 'pathfinder 2e', name: "Bugbear Tormentor", AC: 20, HP: 44, DEX: 8, HPD: [1, 8, 0] },
  { id: 963, ruleset: 'pathfinder 2e', name: "Bugul Noz", AC: 31, HP: 200, DEX: 23, HPD: [1, 23, 0] },
  { id: 964, ruleset: 'pathfinder 2e', name: "Bul-Gae", AC: 33, HP: 255, DEX: 26, HPD: [1, 26, 0] },
  { id: 965, ruleset: 'pathfinder 2e', name: "Bulette", AC: 30, HP: 120, DEX: 16, HPD: [1, 16, 0] },
  { id: 966, ruleset: 'pathfinder 2e', name: "Bunyip", AC: 19, HP: 45, DEX: 10, HPD: [1, 10, 0] },
  { id: 967, ruleset: 'pathfinder 2e', name: "Buso", AC: 18, HP: 48, DEX: 8, HPD: [1, 8, 0] },
  { id: 968, ruleset: 'pathfinder 2e', name: "Bythos", AC: 39, HP: 245, DEX: 30, HPD: [1, 30, 0] },
  { id: 969, ruleset: 'pathfinder 2e', name: "Cacodaemon", AC: 16, HP: 22, DEX: 6, HPD: [1, 6, 0] },
  { id: 970, ruleset: 'pathfinder 2e', name: "Cactus Leshy", AC: 18, HP: 30, DEX: 9, HPD: [1, 9, 0] },
  { id: 971, ruleset: 'pathfinder 2e', name: "Cadaverous Rake", AC: 27, HP: 125, DEX: 16, HPD: [1, 16, 0] },
  { id: 972, ruleset: 'pathfinder 2e', name: "Cairn Linnorm", AC: 43, HP: 360, DEX: 30, HPD: [1, 30, 0] },
  { id: 973, ruleset: 'pathfinder 2e', name: "Cairn Wight", AC: 20, HP: 67, DEX: 11, HPD: [1, 11, 0] },
  { id: 974, ruleset: 'pathfinder 2e', name: "Calathgar", AC: 21, HP: 75, DEX: 10, HPD: [1, 10, 0] },
  { id: 975, ruleset: 'pathfinder 2e', name: "Calcifda", AC: 31, HP: 140, DEX: 21, HPD: [1, 21, 0] },
  { id: 976, ruleset: 'pathfinder 2e', name: "Caligni Caller", AC: 24, HP: 70, DEX: 11, HPD: [1, 11, 0] },
  { id: 977, ruleset: 'pathfinder 2e', name: "Caligni Creeper", AC: 19, HP: 30, DEX: 8, HPD: [1, 8, 0] },
  { id: 978, ruleset: 'pathfinder 2e', name: "Caligni Dancer", AC: 17, HP: 18, DEX: 6, HPD: [1, 6, 0] },
  { id: 979, ruleset: 'pathfinder 2e', name: "Caligni Slayer", AC: 19, HP: 45, DEX: 8, HPD: [1, 8, 0] },
  { id: 980, ruleset: 'pathfinder 2e', name: "Caligni Stalker", AC: 21, HP: 60, DEX: 10, HPD: [1, 10, 0] },
  { id: 981, ruleset: 'pathfinder 2e', name: "Caligni Vanguard", AC: 24, HP: 50, DEX: 13, HPD: [1, 13, 0] },
  { id: 982, ruleset: 'pathfinder 2e', name: "Calikang", AC: 31, HP: 235, DEX: 22, HPD: [1, 22, 0] },
  { id: 983, ruleset: 'pathfinder 2e', name: "Camarach", AC: 40, HP: 320, DEX: 27, HPD: [1, 27, 0] },
  { id: 984, ruleset: 'pathfinder 2e', name: "Camel", AC: 15, HP: 20, DEX: 4, HPD: [1, 4, 0] },
  { id: 985, ruleset: 'pathfinder 2e', name: "Candlaron's Echo", AC: 46, HP: 315, DEX: 35, HPD: [1, 35, 0] },
  { id: 986, ruleset: 'pathfinder 2e', name: "Canopy Elder", AC: 42, HP: 445, DEX: 32, HPD: [1, 32, 0] },
  { id: 987, ruleset: 'pathfinder 2e', name: "Carbuncle", AC: 16, HP: 20, DEX: 7, HPD: [1, 7, 0] },
  { id: 988, ruleset: 'pathfinder 2e', name: "Carnivorous Blob", AC: 20, HP: 300, DEX: 23, HPD: [1, 23, 0] },
  { id: 989, ruleset: 'pathfinder 2e', name: "Carnivorous Crystal", AC: 20, HP: 300, DEX: 15, HPD: [1, 15, 0] },
  { id: 990, ruleset: 'pathfinder 2e', name: "Carrion Golem", AC: 19, HP: 60, DEX: 6, HPD: [1, 6, 0] },
  { id: 991, ruleset: 'pathfinder 2e', name: "Cassisian", AC: 18, HP: 20, DEX: 6, HPD: [1, 6, 0] },
  { id: 992, ruleset: 'pathfinder 2e', name: "Castoroides", AC: 19, HP: 45, DEX: 10, HPD: [1, 10, 0] },
  { id: 993, ruleset: 'pathfinder 2e', name: "Cat Sith", AC: 23, HP: 110, DEX: 14, HPD: [1, 14, 0] },
  { id: 994, ruleset: 'pathfinder 2e', name: "Cataclysm Beetle", AC: 44, HP: 340, DEX: 30, HPD: [1, 30, 0] },
  { id: 995, ruleset: 'pathfinder 2e', name: "Catfolk Pouncer", AC: 17, HP: 19, DEX: 6, HPD: [1, 6, 0] },
  { id: 996, ruleset: 'pathfinder 2e', name: "Catoblepas", AC: 33, HP: 215, DEX: 22, HPD: [1, 22, 0] },
  { id: 997, ruleset: 'pathfinder 2e', name: "Catrina", AC: 22, HP: 75, DEX: 13, HPD: [1, 13, 0] },
  { id: 998, ruleset: 'pathfinder 2e', name: "Caulborn", AC: 24, HP: 105, DEX: 18, HPD: [1, 18, 0] },
  { id: 999, ruleset: 'pathfinder 2e', name: "Caustic Monitor", AC: 34, HP: 235, DEX: 22, HPD: [1, 22, 0] },
  { id: 1000, ruleset: 'pathfinder 2e', name: "Cauthooj", AC: 33, HP: 215, DEX: 22, HPD: [1, 22, 0] },
  { id: 1001, ruleset: 'pathfinder 2e', name: "Cave Bear", AC: 24, HP: 95, DEX: 13, HPD: [1, 13, 0] },
  { id: 1002, ruleset: 'pathfinder 2e', name: "Cave Fisher", AC: 18, HP: 30, DEX: 7, HPD: [1, 7, 0] },
  { id: 1003, ruleset: 'pathfinder 2e', name: "Cave Giant", AC: 23, HP: 110, DEX: 15, HPD: [1, 15, 0] },
  { id: 1004, ruleset: 'pathfinder 2e', name: "Cave Scorpion", AC: 16, HP: 20, DEX: 7, HPD: [1, 7, 0] },
  { id: 1005, ruleset: 'pathfinder 2e', name: "Cavern Troll", AC: 22, HP: 135, DEX: 14, HPD: [1, 14, 0] },
  { id: 1006, ruleset: 'pathfinder 2e', name: "Cavnakash", AC: 22, HP: 83, DEX: 12, HPD: [1, 12, 0] },
  { id: 1007, ruleset: 'pathfinder 2e', name: "Cecaelia Trapper", AC: 24, HP: 53, DEX: 11, HPD: [1, 11, 0] },
  { id: 1008, ruleset: 'pathfinder 2e', name: "Centaur", AC: 20, HP: 40, DEX: 9, HPD: [1, 9, 0] },
  { id: 1009, ruleset: 'pathfinder 2e', name: "Centaur Scout", AC: 22, HP: 75, DEX: 13, HPD: [1, 13, 0] },
  { id: 1010, ruleset: 'pathfinder 2e', name: "Centipede Swarm", AC: 18, HP: 30, DEX: 9, HPD: [1, 9, 0] },
  { id: 1011, ruleset: 'pathfinder 2e', name: "Cephal Lorentus", AC: 28, HP: 140, DEX: 18, HPD: [1, 18, 0] },
  { id: 1012, ruleset: 'pathfinder 2e', name: "Ceustodaemon", AC: 23, HP: 130, DEX: 14, HPD: [1, 14, 0] },
  { id: 1013, ruleset: 'pathfinder 2e', name: "Chakanaj", AC: 36, HP: 300, DEX: 25, HPD: [1, 25, 0] },
  { id: 1014, ruleset: 'pathfinder 2e', name: "Changeling Exile", AC: 19, HP: 45, DEX: 11, HPD: [1, 11, 0] },
  { id: 1015, ruleset: 'pathfinder 2e', name: "Charau-ka", AC: 18, HP: 18, DEX: 6, HPD: [1, 6, 0] },
  { id: 1016, ruleset: 'pathfinder 2e', name: "Charau-ka Acolyte of Angazhan", AC: 19, HP: 45, DEX: 8, HPD: [1, 8, 0] },
  { id: 1017, ruleset: 'pathfinder 2e', name: "Charau-ka Butcher", AC: 21, HP: 95, DEX: 13, HPD: [1, 13, 0] },
  { id: 1018, ruleset: 'pathfinder 2e', name: "Charau-ka Dragon Priest", AC: 23, HP: 90, DEX: 15, HPD: [1, 15, 0] },
  { id: 1019, ruleset: 'pathfinder 2e', name: "Charau-ka Warrior", AC: 16, HP: 20, DEX: 6, HPD: [1, 6, 0] },
  { id: 1020, ruleset: 'pathfinder 2e', name: "Charghar", AC: 20, HP: 35, DEX: 12, HPD: [1, 12, 0] },
  { id: 1021, ruleset: 'pathfinder 2e', name: "Chattering Jaws", AC: 13, HP: 8, DEX: 3, HPD: [1, 3, 0] },
  { id: 1022, ruleset: 'pathfinder 2e', name: "Chernobue", AC: 33, HP: 220, DEX: 25, HPD: [1, 25, 0] },
  { id: 1023, ruleset: 'pathfinder 2e', name: "Chew Spider", AC: 18, HP: 22, DEX: 6, HPD: [1, 6, 0] },
  { id: 1024, ruleset: 'pathfinder 2e', name: "Child of Urgathoa", AC: 27, HP: 165, DEX: 18, HPD: [1, 18, 0] },
  { id: 1025, ruleset: 'pathfinder 2e', name: "Children Of Mhar", AC: 42, HP: 420, DEX: 30, HPD: [1, 30, 0] },
  { id: 1026, ruleset: 'pathfinder 2e', name: "Chimera", AC: 27, HP: 135, DEX: 16, HPD: [1, 16, 0] },
  { id: 1027, ruleset: 'pathfinder 2e', name: "Chimeric Manticore", AC: 25, HP: 120, DEX: 15, HPD: [1, 15, 0] },
  { id: 1028, ruleset: 'pathfinder 2e', name: "Chimpanzee Visitant", AC: 18, HP: 55, DEX: 9, HPD: [1, 9, 0] },
  { id: 1029, ruleset: 'pathfinder 2e', name: "Choker", AC: 18, HP: 28, DEX: 7, HPD: [1, 7, 0] },
  { id: 1030, ruleset: 'pathfinder 2e', name: "Choral", AC: 24, HP: 100, DEX: 14, HPD: [1, 14, 0] },
  { id: 1031, ruleset: 'pathfinder 2e', name: "Chouchin-Obake", AC: 24, HP: 75, DEX: 15, HPD: [1, 15, 0] },
  { id: 1032, ruleset: 'pathfinder 2e', name: "Chromatic Ooze", AC: 28, HP: 550, DEX: 30, HPD: [1, 30, 0] },
  { id: 1033, ruleset: 'pathfinder 2e', name: "Chupacabra", AC: 20, HP: 45, DEX: 9, HPD: [1, 9, 0] },
  { id: 1034, ruleset: 'pathfinder 2e', name: "Chuul", AC: 28, HP: 100, DEX: 15, HPD: [1, 15, 0] },
  { id: 1035, ruleset: 'pathfinder 2e', name: "Chyzaedu", AC: 30, HP: 135, DEX: 21, HPD: [1, 21, 0] },
  { id: 1036, ruleset: 'pathfinder 2e', name: "Cinder Rat", AC: 18, HP: 45, DEX: 9, HPD: [1, 9, 0] },
  { id: 1037, ruleset: 'pathfinder 2e', name: "City Guard Squadron", AC: 22, HP: 75, DEX: 12, HPD: [1, 12, 0] },
  { id: 1038, ruleset: 'pathfinder 2e', name: "Clacking Skull Swarm", AC: 29, HP: 120, DEX: 18, HPD: [1, 18, 0] },
  { id: 1039, ruleset: 'pathfinder 2e', name: "Claws Of Time", AC: 30, HP: 150, DEX: 21, HPD: [1, 21, 0] },
  { id: 1040, ruleset: 'pathfinder 2e', name: "Clay Golem", AC: 29, HP: 175, DEX: 16, HPD: [1, 16, 0] },
  { id: 1041, ruleset: 'pathfinder 2e', name: "Cleansed Cultist", AC: 24, HP: 100, DEX: 12, HPD: [1, 12, 0] },
  { id: 1042, ruleset: 'pathfinder 2e', name: "Cloaker", AC: 22, HP: 80, DEX: 12, HPD: [1, 12, 0] },
  { id: 1043, ruleset: 'pathfinder 2e', name: "Clockwork Amalgam", AC: 43, HP: 455, DEX: 36, HPD: [1, 36, 0] },
  { id: 1044, ruleset: 'pathfinder 2e', name: "Clockwork Assassin", AC: 34, HP: 230, DEX: 23, HPD: [1, 23, 0] },
  { id: 1045, ruleset: 'pathfinder 2e', name: "Clockwork Brewer", AC: 18, HP: 40, DEX: 8, HPD: [1, 8, 0] },
  { id: 1046, ruleset: 'pathfinder 2e', name: "Clockwork Buccaneer", AC: 28, HP: 140, DEX: 18, HPD: [1, 18, 0] },
  { id: 1047, ruleset: 'pathfinder 2e', name: "Clockwork Cannoneer", AC: 38, HP: 250, DEX: 25, HPD: [1, 25, 0] },
  { id: 1048, ruleset: 'pathfinder 2e', name: "Clockwork Clock Tower", AC: 48, HP: 325, DEX: 34, HPD: [1, 34, 0] },
  { id: 1049, ruleset: 'pathfinder 2e', name: "Clockwork Disposer", AC: 21, HP: 70, DEX: 13, HPD: [1, 13, 0] },
  { id: 1050, ruleset: 'pathfinder 2e', name: "Clockwork Door Warden", AC: 20, HP: 50, DEX: 12, HPD: [1, 12, 0] },
  { id: 1051, ruleset: 'pathfinder 2e', name: "Clockwork Dragon", AC: 39, HP: 265, DEX: 28, HPD: [1, 28, 0] },
  { id: 1052, ruleset: 'pathfinder 2e', name: "Clockwork Fabricator", AC: 19, HP: 50, DEX: 8, HPD: [1, 8, 0] },
  { id: 1053, ruleset: 'pathfinder 2e', name: "Clockwork Gunner", AC: 26, HP: 100, DEX: 16, HPD: [1, 16, 0] },
  { id: 1054, ruleset: 'pathfinder 2e', name: "Clockwork Handler", AC: 16, HP: 16, DEX: 8, HPD: [1, 8, 0] },
  { id: 1055, ruleset: 'pathfinder 2e', name: "Clockwork Hunter", AC: 18, HP: 12, DEX: 7, HPD: [1, 7, 0] },
  { id: 1056, ruleset: 'pathfinder 2e', name: "Clockwork Mage", AC: 27, HP: 115, DEX: 17, HPD: [1, 17, 0] },
  { id: 1057, ruleset: 'pathfinder 2e', name: "Clockwork Puppeteer", AC: 33, HP: 205, DEX: 20, HPD: [1, 20, 0] },
  { id: 1058, ruleset: 'pathfinder 2e', name: "Clockwork Rifler", AC: 24, HP: 80, DEX: 16, HPD: [1, 16, 0] },
  { id: 1059, ruleset: 'pathfinder 2e', name: "Clockwork Shambler Horde", AC: 25, HP: 240, DEX: 14, HPD: [1, 14, 0] },
  { id: 1060, ruleset: 'pathfinder 2e', name: "Clockwork Soldier", AC: 24, HP: 80, DEX: 16, HPD: [1, 16, 0] },
  { id: 1061, ruleset: 'pathfinder 2e', name: "Clockwork Sphinx", AC: 26, HP: 130, DEX: 19, HPD: [1, 19, 0] },
  { id: 1062, ruleset: 'pathfinder 2e', name: "Clockwork Spy", AC: 17, HP: 8, DEX: 8, HPD: [1, 8, 0] },
  { id: 1063, ruleset: 'pathfinder 2e', name: "Cloud Giant", AC: 30, HP: 220, DEX: 22, HPD: [1, 22, 0] },
  { id: 1064, ruleset: 'pathfinder 2e', name: "Cloudsplitter", AC: 42, HP: 335, DEX: 32, HPD: [1, 32, 0] },
  { id: 1065, ruleset: 'pathfinder 2e', name: "Cobblebone Swarm", AC: 19, HP: 30, DEX: 9, HPD: [1, 9, 0] },
  { id: 1066, ruleset: 'pathfinder 2e', name: "Cobbleswarm", AC: 16, HP: 20, DEX: 9, HPD: [1, 9, 0] },
  { id: 1067, ruleset: 'pathfinder 2e', name: "Cockatrice", AC: 19, HP: 45, DEX: 8, HPD: [1, 8, 0] },
  { id: 1068, ruleset: 'pathfinder 2e', name: "Cockroach Swarm", AC: 18, HP: 20, DEX: 6, HPD: [1, 6, 0] },
  { id: 1069, ruleset: 'pathfinder 2e', name: "Cocoon Of Lucid Potential", AC: 33, HP: 290, DEX: 26, HPD: [1, 26, 0] },
  { id: 1070, ruleset: 'pathfinder 2e', name: "Coil Spy", AC: 22, HP: 48, DEX: 10, HPD: [1, 10, 0] },
  { id: 1071, ruleset: 'pathfinder 2e', name: "Combusted", AC: 19, HP: 65, DEX: 6, HPD: [1, 6, 0] },
  { id: 1072, ruleset: 'pathfinder 2e', name: "Common Eurypterid", AC: 15, HP: 9, DEX: 2, HPD: [1, 2, 0] },
  { id: 1073, ruleset: 'pathfinder 2e', name: "Compsognathus", AC: 15, HP: 8, DEX: 5, HPD: [1, 5, 0] },
  { id: 1074, ruleset: 'pathfinder 2e', name: "Conqueror Worm", AC: 46, HP: 460, DEX: 37, HPD: [1, 37, 0] },
  { id: 1075, ruleset: 'pathfinder 2e', name: "Consonite Choir", AC: 32, HP: 200, DEX: 23, HPD: [1, 23, 0] },
  { id: 1076, ruleset: 'pathfinder 2e', name: "Contemplative", AC: 19, HP: 32, DEX: 8, HPD: [1, 8, 0] },
  { id: 1077, ruleset: 'pathfinder 2e', name: "Contemplative Meditant", AC: 38, HP: 275, DEX: 29, HPD: [1, 29, 0] },
  { id: 1078, ruleset: 'pathfinder 2e', name: "Contemplative Mentor", AC: 44, HP: 335, DEX: 31, HPD: [1, 31, 0] },
  { id: 1079, ruleset: 'pathfinder 2e', name: "Convergent Giant Eagle", AC: 37, HP: 290, DEX: 29, HPD: [1, 29, 0] },
  { id: 1080, ruleset: 'pathfinder 2e', name: "Coral Capuchin", AC: 16, HP: 20, DEX: 8, HPD: [1, 8, 0] },
  { id: 1081, ruleset: 'pathfinder 2e', name: "Cornugon", AC: 38, HP: 300, DEX: 28, HPD: [1, 28, 0] },
  { id: 1082, ruleset: 'pathfinder 2e', name: "Corpselight", AC: 17, HP: 40, DEX: 7, HPD: [1, 7, 0] },
  { id: 1083, ruleset: 'pathfinder 2e', name: "Corpseroot", AC: 30, HP: 225, DEX: 18, HPD: [1, 18, 0] },
  { id: 1084, ruleset: 'pathfinder 2e', name: "Corrosive Lizard", AC: 18, HP: 30, DEX: 7, HPD: [1, 7, 0] },
  { id: 1085, ruleset: 'pathfinder 2e', name: "Corrupted Relic", AC: 19, HP: 50, DEX: 9, HPD: [1, 9, 0] },
  { id: 1086, ruleset: 'pathfinder 2e', name: "Counteflora", AC: 29, HP: 220, DEX: 18, HPD: [1, 18, 0] },
  { id: 1087, ruleset: 'pathfinder 2e', name: "Crag Linnorm", AC: 37, HP: 270, DEX: 26, HPD: [1, 26, 0] },
  { id: 1088, ruleset: 'pathfinder 2e', name: "Cranium Preserver", AC: 28, HP: 220, DEX: 19, HPD: [1, 19, 0] },
  { id: 1089, ruleset: 'pathfinder 2e', name: "Crawling Hand", AC: 12, HP: 8, DEX: 5, HPD: [1, 5, 0] },
  { id: 1090, ruleset: 'pathfinder 2e', name: "Crawling Hand Swarm", AC: 21, HP: 60, DEX: 11, HPD: [1, 11, 0] },
  { id: 1091, ruleset: 'pathfinder 2e', name: "Crawling Slurry", AC: 30, HP: 300, DEX: 26, HPD: [1, 26, 0] },
  { id: 1092, ruleset: 'pathfinder 2e', name: "Creeping Crone", AC: 30, HP: 210, DEX: 21, HPD: [1, 21, 0] },
  { id: 1093, ruleset: 'pathfinder 2e', name: "Crimson Worm", AC: 40, HP: 410, DEX: 25, HPD: [1, 25, 0] },
  { id: 1094, ruleset: 'pathfinder 2e', name: "Crocodile", AC: 18, HP: 30, DEX: 7, HPD: [1, 7, 0] },
  { id: 1095, ruleset: 'pathfinder 2e', name: "Crossroads Guardian", AC: 25, HP: 115, DEX: 19, HPD: [1, 19, 0] },
  { id: 1096, ruleset: 'pathfinder 2e', name: "Crucidaemon", AC: 38, HP: 225, DEX: 26, HPD: [1, 26, 0] },
  { id: 1097, ruleset: 'pathfinder 2e', name: "Cu Sith", AC: 24, HP: 140, DEX: 16, HPD: [1, 16, 0] },
  { id: 1098, ruleset: 'pathfinder 2e', name: "Cuetzmonquali", AC: 40, HP: 360, DEX: 32, HPD: [1, 32, 0] },
  { id: 1099, ruleset: 'pathfinder 2e', name: "Culdewen", AC: 25, HP: 105, DEX: 15, HPD: [1, 15, 0] },
  { id: 1100, ruleset: 'pathfinder 2e', name: "Cunning Guide", AC: 16, HP: 14, DEX: 9, HPD: [1, 9, 0] },
  { id: 1101, ruleset: 'pathfinder 2e', name: "Cursed Guardian", AC: 21, HP: 58, DEX: 8, HPD: [1, 8, 0] },
  { id: 1102, ruleset: 'pathfinder 2e', name: "Cursed King", AC: 29, HP: 210, DEX: 20, HPD: [1, 20, 0] },
  { id: 1103, ruleset: 'pathfinder 2e', name: "Cyclops", AC: 21, HP: 80, DEX: 12, HPD: [1, 12, 0] },
  { id: 1104, ruleset: 'pathfinder 2e', name: "Cyclops Bully", AC: 28, HP: 155, DEX: 17, HPD: [1, 17, 0] },
  { id: 1105, ruleset: 'pathfinder 2e', name: "Cyclops Zombie", AC: 21, HP: 160, DEX: 8, HPD: [1, 8, 0] },
  { id: 1106, ruleset: 'pathfinder 2e', name: "Cythnigot", AC: 16, HP: 14, DEX: 5, HPD: [1, 5, 0] },
  { id: 1107, ruleset: 'pathfinder 2e', name: "D'ziriak", AC: 19, HP: 45, DEX: 10, HPD: [1, 10, 0] },
  { id: 1108, ruleset: 'pathfinder 2e', name: "Daelum", AC: 21, HP: 76, DEX: 11, HPD: [1, 11, 0] },
  { id: 1109, ruleset: 'pathfinder 2e', name: "Daeodon", AC: 21, HP: 60, DEX: 12, HPD: [1, 12, 0] },
  { id: 1110, ruleset: 'pathfinder 2e', name: "Dalos", AC: 34, HP: 240, DEX: 23, HPD: [1, 23, 0] },
  { id: 1111, ruleset: 'pathfinder 2e', name: "Damibwa", AC: 21, HP: 62, DEX: 12, HPD: [1, 12, 0] },
  { id: 1112, ruleset: 'pathfinder 2e', name: "Danava Titan", AC: 49, HP: 470, DEX: 41, HPD: [1, 41, 0] },
  { id: 1113, ruleset: 'pathfinder 2e', name: "Dancing Night Parade", AC: 40, HP: 450, DEX: 28, HPD: [1, 28, 0] },
  { id: 1114, ruleset: 'pathfinder 2e', name: "Dandasuka", AC: 23, HP: 60, DEX: 12, HPD: [1, 12, 0] },
  { id: 1115, ruleset: 'pathfinder 2e', name: "Daqqanoenyent", AC: 28, HP: 170, DEX: 17, HPD: [1, 17, 0] },
  { id: 1116, ruleset: 'pathfinder 2e', name: "Darg", AC: 33, HP: 250, DEX: 20, HPD: [1, 20, 0] },
  { id: 1117, ruleset: 'pathfinder 2e', name: "Dark Naga", AC: 27, HP: 115, DEX: 15, HPD: [1, 15, 0] },
  { id: 1118, ruleset: 'pathfinder 2e', name: "Darkmantle", AC: 15, HP: 20, DEX: 6, HPD: [1, 6, 0] },
  { id: 1119, ruleset: 'pathfinder 2e', name: "Davik Nettles", AC: 19, HP: 70, DEX: 10, HPD: [1, 10, 0] },
  { id: 1120, ruleset: 'pathfinder 2e', name: "Deadly Mantis", AC: 31, HP: 220, DEX: 20, HPD: [1, 20, 0] },
  { id: 1121, ruleset: 'pathfinder 2e', name: "Death Coach", AC: 35, HP: 228, DEX: 26, HPD: [1, 26, 0] },
  { id: 1122, ruleset: 'pathfinder 2e', name: "Death Drider", AC: 34, HP: 235, DEX: 24, HPD: [1, 24, 0] },
  { id: 1123, ruleset: 'pathfinder 2e', name: "Deathless Acolyte of Urgathoa", AC: 17, HP: 36, DEX: 9, HPD: [1, 9, 0] },
  { id: 1124, ruleset: 'pathfinder 2e', name: "Deathless Hierophant of Urgathoa", AC: 23, HP: 87, DEX: 15, HPD: [1, 15, 0] },
  { id: 1125, ruleset: 'pathfinder 2e', name: "Decrepit Mummy", AC: 17, HP: 40, DEX: 10, HPD: [1, 10, 0] },
  { id: 1126, ruleset: 'pathfinder 2e', name: "Deculi", AC: 33, HP: 215, DEX: 23, HPD: [1, 23, 0] },
  { id: 1127, ruleset: 'pathfinder 2e', name: "Deep Gnome Rockwarden", AC: 22, HP: 63, DEX: 14, HPD: [1, 14, 0] },
  { id: 1128, ruleset: 'pathfinder 2e', name: "Deep Gnome Scout", AC: 17, HP: 18, DEX: 7, HPD: [1, 7, 0] },
  { id: 1129, ruleset: 'pathfinder 2e', name: "Deep Gnome Warrior", AC: 18, HP: 34, DEX: 7, HPD: [1, 7, 0] },
  { id: 1130, ruleset: 'pathfinder 2e', name: "Deepwater Dhuthorex", AC: 28, HP: 155, DEX: 18, HPD: [1, 18, 0] },
  { id: 1131, ruleset: 'pathfinder 2e', name: "Defaced Naiad Queen", AC: 38, HP: 230, DEX: 28, HPD: [1, 28, 0] },
  { id: 1132, ruleset: 'pathfinder 2e', name: "Deimavigga", AC: 40, HP: 285, DEX: 32, HPD: [1, 32, 0] },
  { id: 1133, ruleset: 'pathfinder 2e', name: "Deinonychus", AC: 19, HP: 30, DEX: 7, HPD: [1, 7, 0] },
  { id: 1134, ruleset: 'pathfinder 2e', name: "Deinosuchus", AC: 26, HP: 175, DEX: 17, HPD: [1, 17, 0] },
  { id: 1135, ruleset: 'pathfinder 2e', name: "Demilich", AC: 38, HP: 220, DEX: 19, HPD: [1, 19, 0] },
  { id: 1136, ruleset: 'pathfinder 2e', name: "Denizen of Leng", AC: 27, HP: 100, DEX: 17, HPD: [1, 17, 0] },
  { id: 1137, ruleset: 'pathfinder 2e', name: "Derghodaemon", AC: 33, HP: 240, DEX: 24, HPD: [1, 24, 0] },
  { id: 1138, ruleset: 'pathfinder 2e', name: "Dero Magister", AC: 22, HP: 65, DEX: 8, HPD: [1, 8, 0] },
  { id: 1139, ruleset: 'pathfinder 2e', name: "Dero Stalker", AC: 19, HP: 30, DEX: 5, HPD: [1, 5, 0] },
  { id: 1140, ruleset: 'pathfinder 2e', name: "Dero Strangler", AC: 19, HP: 45, DEX: 6, HPD: [1, 6, 0] },
  { id: 1141, ruleset: 'pathfinder 2e', name: "Desecrated Guardian", AC: 42, HP: 360, DEX: 30, HPD: [1, 30, 0] },
  { id: 1142, ruleset: 'pathfinder 2e', name: "Desert Drake", AC: 27, HP: 135, DEX: 15, HPD: [1, 15, 0] },
  { id: 1143, ruleset: 'pathfinder 2e', name: "Desert Giant", AC: 27, HP: 185, DEX: 19, HPD: [1, 19, 0] },
  { id: 1144, ruleset: 'pathfinder 2e', name: "Desert's Howl", AC: 43, HP: 330, DEX: 33, HPD: [1, 33, 0] },
  { id: 1145, ruleset: 'pathfinder 2e', name: "Destrachan", AC: 27, HP: 135, DEX: 20, HPD: [1, 20, 0] },
  { id: 1146, ruleset: 'pathfinder 2e', name: "Devourer", AC: 31, HP: 175, DEX: 22, HPD: [1, 22, 0] },
  { id: 1147, ruleset: 'pathfinder 2e', name: "Dezullon", AC: 30, HP: 130, DEX: 18, HPD: [1, 18, 0] },
  { id: 1148, ruleset: 'pathfinder 2e', name: "Dhampir Wizard", AC: 17, HP: 22, DEX: 4, HPD: [1, 4, 0] },
  { id: 1149, ruleset: 'pathfinder 2e', name: "Dig-Widget", AC: 23, HP: 65, DEX: 9, HPD: [1, 9, 0] },
  { id: 1150, ruleset: 'pathfinder 2e', name: "Dimari-Diji", AC: 52, HP: 550, DEX: 43, HPD: [1, 43, 0] },
  { id: 1151, ruleset: 'pathfinder 2e', name: "Dingetooth", AC: 16, HP: 25, DEX: 5, HPD: [1, 5, 0] },
  { id: 1152, ruleset: 'pathfinder 2e', name: "Dire Wolf", AC: 18, HP: 50, DEX: 10, HPD: [1, 10, 0] },
  { id: 1153, ruleset: 'pathfinder 2e', name: "Dirge Piper", AC: 16, HP: 95, DEX: 8, HPD: [1, 8, 0] },
  { id: 1154, ruleset: 'pathfinder 2e', name: "Dismemberment Table", AC: 22, HP: 56, DEX: 11, HPD: [1, 11, 0] },
  { id: 1155, ruleset: 'pathfinder 2e', name: "Divine Warden Of Brigh", AC: 28, HP: 135, DEX: 18, HPD: [1, 18, 0] },
  { id: 1156, ruleset: 'pathfinder 2e', name: "Divine Warden Of Nethys", AC: 22, HP: 60, DEX: 11, HPD: [1, 11, 0] },
  { id: 1157, ruleset: 'pathfinder 2e', name: "Djinni", AC: 22, HP: 71, DEX: 13, HPD: [1, 13, 0] },
  { id: 1158, ruleset: 'pathfinder 2e', name: "Doblagub", AC: 35, HP: 250, DEX: 26, HPD: [1, 26, 0] },
  { id: 1159, ruleset: 'pathfinder 2e', name: "Domovoi", AC: 17, HP: 35, DEX: 11, HPD: [1, 11, 0] },
  { id: 1160, ruleset: 'pathfinder 2e', name: "Doorwarden", AC: 22, HP: 60, DEX: 13, HPD: [1, 13, 0] },
  { id: 1161, ruleset: 'pathfinder 2e', name: "Doppelganger", AC: 18, HP: 50, DEX: 7, HPD: [1, 7, 0] },
  { id: 1162, ruleset: 'pathfinder 2e', name: "Doprillu", AC: 36, HP: 260, DEX: 22, HPD: [1, 22, 0] },
  { id: 1163, ruleset: 'pathfinder 2e', name: "Doru", AC: 16, HP: 20, DEX: 7, HPD: [1, 7, 0] },
  { id: 1164, ruleset: 'pathfinder 2e', name: "Dracolisk", AC: 28, HP: 155, DEX: 18, HPD: [1, 18, 0] },
  { id: 1165, ruleset: 'pathfinder 2e', name: "Draconal", AC: 45, HP: 370, DEX: 36, HPD: [1, 36, 0] },
  { id: 1166, ruleset: 'pathfinder 2e', name: "Dragon Turtle", AC: 29, HP: 140, DEX: 18, HPD: [1, 18, 0] },
  { id: 1167, ruleset: 'pathfinder 2e', name: "Dragon's Blood Puffball", AC: 24, HP: 170, DEX: 12, HPD: [1, 12, 0] },
  { id: 1168, ruleset: 'pathfinder 2e', name: "Dragonscarred Dead", AC: 33, HP: 210, DEX: 24, HPD: [1, 24, 0] },
  { id: 1169, ruleset: 'pathfinder 2e', name: "Dragonshard Guardian", AC: 48, HP: 430, DEX: 39, HPD: [1, 39, 0] },
  { id: 1170, ruleset: 'pathfinder 2e', name: "Dragonstorm Fire Giant", AC: 42, HP: 400, DEX: 30, HPD: [1, 30, 0] },
  { id: 1171, ruleset: 'pathfinder 2e', name: "Drainberry Bush", AC: 23, HP: 135, DEX: 16, HPD: [1, 16, 0] },
  { id: 1172, ruleset: 'pathfinder 2e', name: "Drakauthix", AC: 25, HP: 190, DEX: 17, HPD: [1, 17, 0] },
  { id: 1173, ruleset: 'pathfinder 2e', name: "Drake Courser", AC: 32, HP: 230, DEX: 22, HPD: [1, 22, 0] },
  { id: 1174, ruleset: 'pathfinder 2e', name: "Drake Skeleton", AC: 26, HP: 130, DEX: 14, HPD: [1, 14, 0] },
  { id: 1175, ruleset: 'pathfinder 2e', name: "Dramofir", AC: 35, HP: 290, DEX: 28, HPD: [1, 28, 0] },
  { id: 1176, ruleset: 'pathfinder 2e', name: "Draugr", AC: 17, HP: 35, DEX: 7, HPD: [1, 7, 0] },
  { id: 1177, ruleset: 'pathfinder 2e', name: "Draxie", AC: 19, HP: 45, DEX: 8, HPD: [1, 8, 0] },
  { id: 1178, ruleset: 'pathfinder 2e', name: "Drazmorg the Damned", AC: 23, HP: 115, DEX: 16, HPD: [1, 16, 0] },
  { id: 1179, ruleset: 'pathfinder 2e', name: "Dread Dhuthorex", AC: 31, HP: 195, DEX: 22, HPD: [1, 22, 0] },
  { id: 1180, ruleset: 'pathfinder 2e', name: "Dread Roc", AC: 36, HP: 290, DEX: 26, HPD: [1, 26, 0] },
  { id: 1181, ruleset: 'pathfinder 2e', name: "Dread Wisp", AC: 31, HP: 90, DEX: 20, HPD: [1, 20, 0] },
  { id: 1182, ruleset: 'pathfinder 2e', name: "Dread Wraith", AC: 28, HP: 130, DEX: 19, HPD: [1, 19, 0] },
  { id: 1183, ruleset: 'pathfinder 2e', name: "Dreadsong Dancer", AC: 27, HP: 160, DEX: 13, HPD: [1, 13, 0] },
  { id: 1184, ruleset: 'pathfinder 2e', name: "Dream Spider", AC: 16, HP: 15, DEX: 6, HPD: [1, 6, 0] },
  { id: 1185, ruleset: 'pathfinder 2e', name: "Drelev Guard", AC: 27, HP: 135, DEX: 16, HPD: [1, 16, 0] },
  { id: 1186, ruleset: 'pathfinder 2e', name: "Drenchdead", AC: 32, HP: 230, DEX: 21, HPD: [1, 21, 0] },
  { id: 1187, ruleset: 'pathfinder 2e', name: "Dreshkan", AC: 20, HP: 72, DEX: 11, HPD: [1, 11, 0] },
  { id: 1188, ruleset: 'pathfinder 2e', name: "Dretch", AC: 17, HP: 45, DEX: 6, HPD: [1, 6, 0] },
  { id: 1189, ruleset: 'pathfinder 2e', name: "Drider", AC: 24, HP: 95, DEX: 13, HPD: [1, 13, 0] },
  { id: 1190, ruleset: 'pathfinder 2e', name: "Dromornis", AC: 28, HP: 150, DEX: 19, HPD: [1, 19, 0] },
  { id: 1191, ruleset: 'pathfinder 2e', name: "Drow Fighter", AC: 18, HP: 18, DEX: 6, HPD: [1, 6, 0] },
  { id: 1192, ruleset: 'pathfinder 2e', name: "Drow Hunter", AC: 25, HP: 115, DEX: 16, HPD: [1, 16, 0] },
  { id: 1193, ruleset: 'pathfinder 2e', name: "Drow Priestess", AC: 20, HP: 39, DEX: 9, HPD: [1, 9, 0] },
  { id: 1194, ruleset: 'pathfinder 2e', name: "Drow Rogue", AC: 19, HP: 26, DEX: 6, HPD: [1, 6, 0] },
  { id: 1195, ruleset: 'pathfinder 2e', name: "Drow Shootist", AC: 27, HP: 120, DEX: 16, HPD: [1, 16, 0] },
  { id: 1196, ruleset: 'pathfinder 2e', name: "Drow Warden", AC: 21, HP: 60, DEX: 11, HPD: [1, 11, 0] },
  { id: 1197, ruleset: 'pathfinder 2e', name: "Drowned Mummy", AC: 38, HP: 330, DEX: 30, HPD: [1, 30, 0] },
  { id: 1198, ruleset: 'pathfinder 2e', name: "Drusilla", AC: 25, HP: 115, DEX: 17, HPD: [1, 17, 0] },
  { id: 1199, ruleset: 'pathfinder 2e', name: "Dryad", AC: 19, HP: 55, DEX: 10, HPD: [1, 10, 0] },
  { id: 1200, ruleset: 'pathfinder 2e', name: "Dryad Queen", AC: 35, HP: 220, DEX: 25, HPD: [1, 25, 0] },
  { id: 1201, ruleset: 'pathfinder 2e', name: "Duende", AC: 17, HP: 35, DEX: 7, HPD: [1, 7, 0] },
  { id: 1202, ruleset: 'pathfinder 2e', name: "Duergar Bombardier", AC: 18, HP: 20, DEX: 4, HPD: [1, 4, 0] },
  { id: 1203, ruleset: 'pathfinder 2e', name: "Duergar Sharpshooter", AC: 17, HP: 16, DEX: 4, HPD: [1, 4, 0] },
  { id: 1204, ruleset: 'pathfinder 2e', name: "Duergar Taskmaster", AC: 18, HP: 30, DEX: 8, HPD: [1, 8, 0] },
  { id: 1205, ruleset: 'pathfinder 2e', name: "Dullahan", AC: 28, HP: 95, DEX: 14, HPD: [1, 14, 0] },
  { id: 1206, ruleset: 'pathfinder 2e', name: "Duneshaker Solifugid", AC: 42, HP: 340, DEX: 30, HPD: [1, 30, 0] },
  { id: 1207, ruleset: 'pathfinder 2e', name: "Duskwalker Ghost Hunter", AC: 21, HP: 56, DEX: 10, HPD: [1, 10, 0] },
  { id: 1208, ruleset: 'pathfinder 2e', name: "Dust Mephit", AC: 17, HP: 16, DEX: 3, HPD: [1, 3, 0] },
  { id: 1209, ruleset: 'pathfinder 2e', name: "Dvorovoi", AC: 18, HP: 44, DEX: 12, HPD: [1, 12, 0] },
  { id: 1210, ruleset: 'pathfinder 2e', name: "Dwandek", AC: 40, HP: 270, DEX: 31, HPD: [1, 31, 0] },
  { id: 1211, ruleset: 'pathfinder 2e', name: "Dweomercat", AC: 25, HP: 100, DEX: 15, HPD: [1, 15, 0] },
  { id: 1212, ruleset: 'pathfinder 2e', name: "Dybbuk", AC: 35, HP: 175, DEX: 27, HPD: [1, 27, 0] },
  { id: 1213, ruleset: 'pathfinder 2e', name: "Dyzallin Shraen", AC: 42, HP: 380, DEX: 32, HPD: [1, 32, 0] },
  { id: 1214, ruleset: 'pathfinder 2e', name: "Eagle", AC: 16, HP: 6, DEX: 6, HPD: [1, 6, 0] },
  { id: 1215, ruleset: 'pathfinder 2e', name: "Earth Mephit", AC: 15, HP: 20, DEX: 3, HPD: [1, 3, 0] },
  { id: 1216, ruleset: 'pathfinder 2e', name: "Earth Wisp", AC: 16, HP: 15, DEX: 6, HPD: [1, 6, 0] },
  { id: 1217, ruleset: 'pathfinder 2e', name: "Earthen Destrier", AC: 20, HP: 72, DEX: 10, HPD: [1, 10, 0] },
  { id: 1218, ruleset: 'pathfinder 2e', name: "Eberark", AC: 30, HP: 275, DEX: 19, HPD: [1, 19, 0] },
  { id: 1219, ruleset: 'pathfinder 2e', name: "Ecorche", AC: 38, HP: 275, DEX: 27, HPD: [1, 27, 0] },
  { id: 1220, ruleset: 'pathfinder 2e', name: "Efreeti", AC: 28, HP: 175, DEX: 17, HPD: [1, 17, 0] },
  { id: 1221, ruleset: 'pathfinder 2e', name: "Einherji", AC: 30, HP: 175, DEX: 17, HPD: [1, 17, 0] },
  { id: 1222, ruleset: 'pathfinder 2e', name: "Ekundayo's Dog", AC: 22, HP: 60, DEX: 12, HPD: [1, 12, 0] },
  { id: 1223, ruleset: 'pathfinder 2e', name: "Elananx", AC: 24, HP: 95, DEX: 14, HPD: [1, 14, 0] },
  { id: 1224, ruleset: 'pathfinder 2e', name: "Elasmosaurus", AC: 25, HP: 125, DEX: 16, HPD: [1, 16, 0] },
  { id: 1225, ruleset: 'pathfinder 2e', name: "Elder Cauthooj", AC: 36, HP: 255, DEX: 25, HPD: [1, 25, 0] },
  { id: 1226, ruleset: 'pathfinder 2e', name: "Elder Elemental Tsunami", AC: 36, HP: 260, DEX: 25, HPD: [1, 25, 0] },
  { id: 1227, ruleset: 'pathfinder 2e', name: "Elder Sphinx", AC: 38, HP: 300, DEX: 31, HPD: [1, 31, 0] },
  { id: 1228, ruleset: 'pathfinder 2e', name: "Elder Wyrmwraith", AC: 49, HP: 450, DEX: 40, HPD: [1, 40, 0] },
  { id: 1229, ruleset: 'pathfinder 2e', name: "Electric Eel", AC: 16, HP: 18, DEX: 4, HPD: [1, 4, 0] },
  { id: 1230, ruleset: 'pathfinder 2e', name: "Elemental Avalanche", AC: 32, HP: 215, DEX: 20, HPD: [1, 20, 0] },
  { id: 1231, ruleset: 'pathfinder 2e', name: "Elemental Hurricane", AC: 32, HP: 140, DEX: 20, HPD: [1, 20, 0] },
  { id: 1232, ruleset: 'pathfinder 2e', name: "Elemental Inferno", AC: 31, HP: 210, DEX: 20, HPD: [1, 20, 0] },
  { id: 1233, ruleset: 'pathfinder 2e', name: "Elemental Tsunami", AC: 31, HP: 195, DEX: 22, HPD: [1, 22, 0] },
  { id: 1234, ruleset: 'pathfinder 2e', name: "Elemental Vessel, Water", AC: 40, HP: 225, DEX: 28, HPD: [1, 28, 0] },
  { id: 1235, ruleset: 'pathfinder 2e', name: "Elephant", AC: 23, HP: 130, DEX: 13, HPD: [1, 13, 0] },
  { id: 1236, ruleset: 'pathfinder 2e', name: "Elk", AC: 16, HP: 20, DEX: 7, HPD: [1, 7, 0] },
  { id: 1237, ruleset: 'pathfinder 2e', name: "Eloko", AC: 25, HP: 115, DEX: 15, HPD: [1, 15, 0] },
  { id: 1238, ruleset: 'pathfinder 2e', name: "Elysian Sheep", AC: 25, HP: 140, DEX: 18, HPD: [1, 18, 0] },
  { id: 1239, ruleset: 'pathfinder 2e', name: "Elysian Titan", AC: 46, HP: 400, DEX: 36, HPD: [1, 36, 0] },
  { id: 1240, ruleset: 'pathfinder 2e', name: "Ember Fox", AC: 18, HP: 35, DEX: 8, HPD: [1, 8, 0] },
  { id: 1241, ruleset: 'pathfinder 2e', name: "Emperor Bird", AC: 18, HP: 27, DEX: 8, HPD: [1, 8, 0] },
  { id: 1242, ruleset: 'pathfinder 2e', name: "Emperor Cobra", AC: 22, HP: 80, DEX: 13, HPD: [1, 13, 0] },
  { id: 1243, ruleset: 'pathfinder 2e', name: "Empress Bore Worm", AC: 23, HP: 140, DEX: 13, HPD: [1, 13, 0] },
  { id: 1244, ruleset: 'pathfinder 2e', name: "Engelidis", AC: 40, HP: 290, DEX: 28, HPD: [1, 28, 0] },
  { id: 1245, ruleset: 'pathfinder 2e', name: "Enormous Dragonfly", AC: 27, HP: 165, DEX: 16, HPD: [1, 16, 0] },
  { id: 1246, ruleset: 'pathfinder 2e', name: "Enormous Flame Drake", AC: 27, HP: 140, DEX: 16, HPD: [1, 16, 0] },
  { id: 1247, ruleset: 'pathfinder 2e', name: "Eremite", AC: 45, HP: 375, DEX: 34, HPD: [1, 34, 0] },
  { id: 1248, ruleset: 'pathfinder 2e', name: "Erinys", AC: 27, HP: 120, DEX: 18, HPD: [1, 18, 0] },
  { id: 1249, ruleset: 'pathfinder 2e', name: "Eseneth", AC: 39, HP: 290, DEX: 29, HPD: [1, 29, 0] },
  { id: 1250, ruleset: 'pathfinder 2e', name: "Esipil", AC: 17, HP: 16, DEX: 7, HPD: [1, 7, 0] },
  { id: 1251, ruleset: 'pathfinder 2e', name: "Esobok", AC: 18, HP: 55, DEX: 12, HPD: [1, 12, 0] },
  { id: 1252, ruleset: 'pathfinder 2e', name: "Ether Spider", AC: 21, HP: 75, DEX: 12, HPD: [1, 12, 0] },
  { id: 1253, ruleset: 'pathfinder 2e', name: "Ethereal Sailor", AC: 25, HP: 60, DEX: 14, HPD: [1, 14, 0] },
  { id: 1254, ruleset: 'pathfinder 2e', name: "Etioling Blightmage", AC: 29, HP: 150, DEX: 19, HPD: [1, 19, 0] },
  { id: 1255, ruleset: 'pathfinder 2e', name: "Ettin", AC: 21, HP: 110, DEX: 16, HPD: [1, 16, 0] },
  { id: 1256, ruleset: 'pathfinder 2e', name: "Eunemvro", AC: 22, HP: 78, DEX: 11, HPD: [1, 11, 0] },
  { id: 1257, ruleset: 'pathfinder 2e', name: "Evangelist", AC: 24, HP: 90, DEX: 13, HPD: [1, 13, 0] },
  { id: 1258, ruleset: 'pathfinder 2e', name: "Everburning Mammoths", AC: 26, HP: 135, DEX: 16, HPD: [1, 16, 0] },
  { id: 1259, ruleset: 'pathfinder 2e', name: "Evindra", AC: 35, HP: 210, DEX: 24, HPD: [1, 24, 0] },
  { id: 1260, ruleset: 'pathfinder 2e', name: "Excorion", AC: 24, HP: 160, DEX: 18, HPD: [1, 18, 0] },
  { id: 1261, ruleset: 'pathfinder 2e', name: "Faceless Butcher", AC: 31, HP: 175, DEX: 21, HPD: [1, 21, 0] },
  { id: 1262, ruleset: 'pathfinder 2e', name: "Faceless Stalker", AC: 21, HP: 60, DEX: 10, HPD: [1, 10, 0] },
  { id: 1263, ruleset: 'pathfinder 2e', name: "Facetbound Cascader", AC: 38, HP: 280, DEX: 28, HPD: [1, 28, 0] },
  { id: 1264, ruleset: 'pathfinder 2e', name: "Factbound Nullifier", AC: 38, HP: 305, DEX: 25, HPD: [1, 25, 0] },
  { id: 1265, ruleset: 'pathfinder 2e', name: "Fading Fox", AC: 21, HP: 25, DEX: 11, HPD: [1, 11, 0] },
  { id: 1266, ruleset: 'pathfinder 2e', name: "Faerie Dragon", AC: 18, HP: 30, DEX: 16, HPD: [1, 16, 0] },
  { id: 1267, ruleset: 'pathfinder 2e', name: "Fafnheir", AC: 51, HP: 500, DEX: 38, HPD: [1, 38, 0] },
  { id: 1268, ruleset: 'pathfinder 2e', name: "Faithless Ecclesiarach", AC: 21, HP: 93, DEX: 16, HPD: [1, 16, 0] },
  { id: 1269, ruleset: 'pathfinder 2e', name: "Fallen Champion", AC: 28, HP: 130, DEX: 17, HPD: [1, 17, 0] },
  { id: 1270, ruleset: 'pathfinder 2e', name: "Falrok", AC: 37, HP: 250, DEX: 28, HPD: [1, 28, 0] },
  { id: 1271, ruleset: 'pathfinder 2e', name: "False Priestess", AC: 22, HP: 120, DEX: 15, HPD: [1, 15, 0] },
  { id: 1272, ruleset: 'pathfinder 2e', name: "Feathered Bear", AC: 29, HP: 160, DEX: 18, HPD: [1, 18, 0] },
  { id: 1273, ruleset: 'pathfinder 2e', name: "Fen Mosquito Swarm", AC: 19, HP: 25, DEX: 8, HPD: [1, 8, 0] },
  { id: 1274, ruleset: 'pathfinder 2e', name: "Fen Pudding", AC: 20, HP: 300, DEX: 15, HPD: [1, 15, 0] },
  { id: 1275, ruleset: 'pathfinder 2e', name: "Feral Skull Swarm", AC: 32, HP: 160, DEX: 21, HPD: [1, 21, 0] },
  { id: 1276, ruleset: 'pathfinder 2e', name: "Festering Gnasher", AC: 16, HP: 18, DEX: 8, HPD: [1, 8, 0] },
  { id: 1277, ruleset: 'pathfinder 2e', name: "Festrog", AC: 15, HP: 24, DEX: 6, HPD: [1, 6, 0] },
  { id: 1278, ruleset: 'pathfinder 2e', name: "Fetch Behemoth", AC: 44, HP: 460, DEX: 36, HPD: [1, 36, 0] },
  { id: 1279, ruleset: 'pathfinder 2e', name: "Fetch Stalker", AC: 42, HP: 350, DEX: 30, HPD: [1, 30, 0] },
  { id: 1280, ruleset: 'pathfinder 2e', name: "Fetchling Scout", AC: 18, HP: 18, DEX: 5, HPD: [1, 5, 0] },
  { id: 1281, ruleset: 'pathfinder 2e', name: "Fiddling Bones", AC: 18, HP: 30, DEX: 9, HPD: [1, 9, 0] },
  { id: 1282, ruleset: 'pathfinder 2e', name: "Filth Fire", AC: 21, HP: 70, DEX: 11, HPD: [1, 11, 0] },
  { id: 1283, ruleset: 'pathfinder 2e', name: "Fionn", AC: 32, HP: 175, DEX: 24, HPD: [1, 24, 0] },
  { id: 1284, ruleset: 'pathfinder 2e', name: "Fire Giant", AC: 31, HP: 175, DEX: 18, HPD: [1, 18, 0] },
  { id: 1285, ruleset: 'pathfinder 2e', name: "Fire Jellyfish Swarm", AC: 13, HP: 155, DEX: 10, HPD: [1, 10, 0] },
  { id: 1286, ruleset: 'pathfinder 2e', name: "Fire Mephit", AC: 17, HP: 16, DEX: 3, HPD: [1, 3, 0] },
  { id: 1287, ruleset: 'pathfinder 2e', name: "Fire Wisp", AC: 16, HP: 18, DEX: 6, HPD: [1, 6, 0] },
  { id: 1288, ruleset: 'pathfinder 2e', name: "Fire Yai", AC: 36, HP: 250, DEX: 26, HPD: [1, 26, 0] },
  { id: 1289, ruleset: 'pathfinder 2e', name: "Firewyrm", AC: 28, HP: 165, DEX: 16, HPD: [1, 16, 0] },
  { id: 1290, ruleset: 'pathfinder 2e', name: "Fjord Linnorm", AC: 40, HP: 315, DEX: 28, HPD: [1, 28, 0] },
  { id: 1291, ruleset: 'pathfinder 2e', name: "Flame Drake", AC: 22, HP: 75, DEX: 12, HPD: [1, 12, 0] },
  { id: 1292, ruleset: 'pathfinder 2e', name: "Flaming Skull", AC: 18, HP: 30, DEX: 9, HPD: [1, 9, 0] },
  { id: 1293, ruleset: 'pathfinder 2e', name: "Flash Beetle", AC: 16, HP: 6, DEX: 6, HPD: [1, 6, 0] },
  { id: 1294, ruleset: 'pathfinder 2e', name: "Flea Swarm", AC: 18, HP: 55, DEX: 13, HPD: [1, 13, 0] },
  { id: 1295, ruleset: 'pathfinder 2e', name: "Flesh Golem", AC: 26, HP: 140, DEX: 12, HPD: [1, 12, 0] },
  { id: 1296, ruleset: 'pathfinder 2e', name: "Fleshforged Conformer", AC: 26, HP: 145, DEX: 19, HPD: [1, 19, 0] },
  { id: 1297, ruleset: 'pathfinder 2e', name: "Fleshforged Dreadnought", AC: 42, HP: 300, DEX: 29, HPD: [1, 29, 0] },
  { id: 1298, ruleset: 'pathfinder 2e', name: "Flickerwisp", AC: 20, HP: 18, DEX: 9, HPD: [1, 9, 0] },
  { id: 1299, ruleset: 'pathfinder 2e', name: "Floating Femur", AC: 14, HP: 7, DEX: 3, HPD: [1, 3, 0] },
  { id: 1300, ruleset: 'pathfinder 2e', name: "Flumph", AC: 17, HP: 17, DEX: 8, HPD: [1, 8, 0] },
  { id: 1301, ruleset: 'pathfinder 2e', name: "Fluxwraith", AC: 39, HP: 250, DEX: 35, HPD: [1, 35, 0] },
  { id: 1302, ruleset: 'pathfinder 2e', name: "Flying Mountain Kaminari", AC: 42, HP: 320, DEX: 33, HPD: [1, 33, 0] },
  { id: 1303, ruleset: 'pathfinder 2e', name: "Flytrap Leshy", AC: 20, HP: 72, DEX: 11, HPD: [1, 11, 0] },
  { id: 1304, ruleset: 'pathfinder 2e', name: "Follower Of Shumfallow", AC: 15, HP: 8, DEX: 6, HPD: [1, 6, 0] },
  { id: 1305, ruleset: 'pathfinder 2e', name: "Forge-Spurned", AC: 22, HP: 75, DEX: 11, HPD: [1, 11, 0] },
  { id: 1306, ruleset: 'pathfinder 2e', name: "Formian Mageslayer", AC: 38, HP: 240, DEX: 31, HPD: [1, 31, 0] },
  { id: 1307, ruleset: 'pathfinder 2e', name: "Formian Queen", AC: 40, HP: 255, DEX: 31, HPD: [1, 31, 0] },
  { id: 1308, ruleset: 'pathfinder 2e', name: "Formian Worker", AC: 16, HP: 20, DEX: 6, HPD: [1, 6, 0] },
  { id: 1309, ruleset: 'pathfinder 2e', name: "Fortune Eater", AC: 25, HP: 100, DEX: 13, HPD: [1, 13, 0] },
  { id: 1310, ruleset: 'pathfinder 2e', name: "Fossil Golem", AC: 33, HP: 195, DEX: 20, HPD: [1, 20, 0] },
  { id: 1311, ruleset: 'pathfinder 2e', name: "Freshly Bloomed Basilisk", AC: 26, HP: 188, DEX: 18, HPD: [1, 18, 0] },
  { id: 1312, ruleset: 'pathfinder 2e', name: "Froghemoth", AC: 32, HP: 285, DEX: 25, HPD: [1, 25, 0] },
  { id: 1313, ruleset: 'pathfinder 2e', name: "Frost Drake", AC: 25, HP: 115, DEX: 14, HPD: [1, 14, 0] },
  { id: 1314, ruleset: 'pathfinder 2e', name: "Frost Giant", AC: 29, HP: 150, DEX: 17, HPD: [1, 17, 0] },
  { id: 1315, ruleset: 'pathfinder 2e', name: "Frost Troll", AC: 19, HP: 90, DEX: 12, HPD: [1, 12, 0] },
  { id: 1316, ruleset: 'pathfinder 2e', name: "Frost Worm", AC: 33, HP: 225, DEX: 22, HPD: [1, 22, 0] },
  { id: 1317, ruleset: 'pathfinder 2e', name: "Fuath", AC: 17, HP: 18, DEX: 8, HPD: [1, 8, 0] },
  { id: 1318, ruleset: 'pathfinder 2e', name: "Fuming Sludge", AC: 16, HP: 160, DEX: 11, HPD: [1, 11, 0] },
  { id: 1319, ruleset: 'pathfinder 2e', name: "Fungus Leshy", AC: 19, HP: 30, DEX: 6, HPD: [1, 6, 0] },
  { id: 1320, ruleset: 'pathfinder 2e', name: "Gaetane", AC: 36, HP: 315, DEX: 27, HPD: [1, 27, 0] },
  { id: 1321, ruleset: 'pathfinder 2e', name: "Gahlepod", AC: 24, HP: 140, DEX: 13, HPD: [1, 13, 0] },
  { id: 1322, ruleset: 'pathfinder 2e', name: "Gallowdead", AC: 37, HP: 280, DEX: 27, HPD: [1, 27, 0] },
  { id: 1323, ruleset: 'pathfinder 2e', name: "Galvo", AC: 27, HP: 158, DEX: 17, HPD: [1, 17, 0] },
  { id: 1324, ruleset: 'pathfinder 2e', name: "Gambulami", AC: 31, HP: 200, DEX: 21, HPD: [1, 21, 0] },
  { id: 1325, ruleset: 'pathfinder 2e', name: "Gancanagh", AC: 21, HP: 75, DEX: 11, HPD: [1, 11, 0] },
  { id: 1326, ruleset: 'pathfinder 2e', name: "Ganzi Martial Artist", AC: 21, HP: 36, DEX: 9, HPD: [1, 9, 0] },
  { id: 1327, ruleset: 'pathfinder 2e', name: "Gargoyle", AC: 21, HP: 40, DEX: 10, HPD: [1, 10, 0] },
  { id: 1328, ruleset: 'pathfinder 2e', name: "Garuda", AC: 28, HP: 135, DEX: 20, HPD: [1, 20, 0] },
  { id: 1329, ruleset: 'pathfinder 2e', name: "Gashadokuro", AC: 33, HP: 230, DEX: 24, HPD: [1, 24, 0] },
  { id: 1330, ruleset: 'pathfinder 2e', name: "Gathlain Wanderer", AC: 17, HP: 14, DEX: 6, HPD: [1, 6, 0] },
  { id: 1331, ruleset: 'pathfinder 2e', name: "Gbahali", AC: 28, HP: 170, DEX: 17, HPD: [1, 17, 0] },
  { id: 1332, ruleset: 'pathfinder 2e', name: "Gedovius", AC: 39, HP: 222, DEX: 28, HPD: [1, 28, 0] },
  { id: 1333, ruleset: 'pathfinder 2e', name: "Geist", AC: 26, HP: 120, DEX: 17, HPD: [1, 17, 0] },
  { id: 1334, ruleset: 'pathfinder 2e', name: "Gelatinous Cube", AC: 10, HP: 90, DEX: 5, HPD: [1, 5, 0] },
  { id: 1335, ruleset: 'pathfinder 2e', name: "Gelugon", AC: 34, HP: 215, DEX: 26, HPD: [1, 26, 0] },
  { id: 1336, ruleset: 'pathfinder 2e', name: "General Avinash Jurrg", AC: 36, HP: 225, DEX: 26, HPD: [1, 26, 0] },
  { id: 1337, ruleset: 'pathfinder 2e', name: "Ghaele", AC: 34, HP: 235, DEX: 25, HPD: [1, 25, 0] },
  { id: 1338, ruleset: 'pathfinder 2e', name: "Ghast", AC: 18, HP: 30, DEX: 8, HPD: [1, 8, 0] },
  { id: 1339, ruleset: 'pathfinder 2e', name: "Ghast Outlaw", AC: 28, HP: 155, DEX: 15, HPD: [1, 15, 0] },
  { id: 1340, ruleset: 'pathfinder 2e', name: "Ghastly Bear", AC: 27, HP: 135, DEX: 16, HPD: [1, 16, 0] },
  { id: 1341, ruleset: 'pathfinder 2e', name: "Ghiasi The Unraveler", AC: 40, HP: 285, DEX: 34, HPD: [1, 34, 0] },
  { id: 1342, ruleset: 'pathfinder 2e', name: "Gholdako", AC: 27, HP: 215, DEX: 16, HPD: [1, 16, 0] },
  { id: 1343, ruleset: 'pathfinder 2e', name: "Ghonhatine", AC: 30, HP: 175, DEX: 19, HPD: [1, 19, 0] },
  { id: 1344, ruleset: 'pathfinder 2e', name: "Ghoran Manipulator", AC: 18, HP: 45, DEX: 9, HPD: [1, 9, 0] },
  { id: 1345, ruleset: 'pathfinder 2e', name: "Ghost Commoner", AC: 20, HP: 30, DEX: 10, HPD: [1, 10, 0] },
  { id: 1346, ruleset: 'pathfinder 2e', name: "Ghost Mage", AC: 27, HP: 135, DEX: 17, HPD: [1, 17, 0] },
  { id: 1347, ruleset: 'pathfinder 2e', name: "Ghost Monk", AC: 25, HP: 115, DEX: 18, HPD: [1, 18, 0] },
  { id: 1348, ruleset: 'pathfinder 2e', name: "Ghost Pirate Captain", AC: 26, HP: 100, DEX: 17, HPD: [1, 17, 0] },
  { id: 1349, ruleset: 'pathfinder 2e', name: "Ghostly Guard", AC: 35, HP: 210, DEX: 29, HPD: [1, 29, 0] },
  { id: 1350, ruleset: 'pathfinder 2e', name: "Ghostly Mob", AC: 25, HP: 105, DEX: 16, HPD: [1, 16, 0] },
  { id: 1351, ruleset: 'pathfinder 2e', name: "Ghoul", AC: 16, HP: 20, DEX: 7, HPD: [1, 7, 0] },
  { id: 1352, ruleset: 'pathfinder 2e', name: "Ghoul Antipaladin", AC: 29, HP: 155, DEX: 15, HPD: [1, 15, 0] },
  { id: 1353, ruleset: 'pathfinder 2e', name: "Ghoul Crocodile", AC: 20, HP: 60, DEX: 11, HPD: [1, 11, 0] },
  { id: 1354, ruleset: 'pathfinder 2e', name: "Ghoul Gnawer", AC: 28, HP: 190, DEX: 18, HPD: [1, 18, 0] },
  { id: 1355, ruleset: 'pathfinder 2e', name: "Ghoul Razorclaw", AC: 34, HP: 230, DEX: 25, HPD: [1, 25, 0] },
  { id: 1356, ruleset: 'pathfinder 2e', name: "Ghul", AC: 21, HP: 85, DEX: 13, HPD: [1, 13, 0] },
  { id: 1357, ruleset: 'pathfinder 2e', name: "Giant Amoeba", AC: 8, HP: 45, DEX: 4, HPD: [1, 4, 0] },
  { id: 1358, ruleset: 'pathfinder 2e', name: "Giant Anaconda", AC: 25, HP: 175, DEX: 17, HPD: [1, 17, 0] },
  { id: 1359, ruleset: 'pathfinder 2e', name: "Giant Animated Statue", AC: 26, HP: 100, DEX: 13, HPD: [1, 13, 0] },
  { id: 1360, ruleset: 'pathfinder 2e', name: "Giant Ant", AC: 18, HP: 30, DEX: 7, HPD: [1, 7, 0] },
  { id: 1361, ruleset: 'pathfinder 2e', name: "Giant Aukashungi", AC: 36, HP: 300, DEX: 24, HPD: [1, 24, 0] },
  { id: 1362, ruleset: 'pathfinder 2e', name: "Giant Badger", AC: 18, HP: 30, DEX: 8, HPD: [1, 8, 0] },
  { id: 1363, ruleset: 'pathfinder 2e', name: "Giant Bat", AC: 18, HP: 30, DEX: 11, HPD: [1, 11, 0] },
  { id: 1364, ruleset: 'pathfinder 2e', name: "Giant Bone Skipper", AC: 25, HP: 115, DEX: 17, HPD: [1, 17, 0] },
  { id: 1365, ruleset: 'pathfinder 2e', name: "Giant Centipede", AC: 15, HP: 8, DEX: 6, HPD: [1, 6, 0] },
  { id: 1366, ruleset: 'pathfinder 2e', name: "Giant Chameleon", AC: 18, HP: 60, DEX: 10, HPD: [1, 10, 0] },
  { id: 1367, ruleset: 'pathfinder 2e', name: "Giant Cockroach", AC: 16, HP: 20, DEX: 6, HPD: [1, 6, 0] },
  { id: 1368, ruleset: 'pathfinder 2e', name: "Giant Crab", AC: 19, HP: 24, DEX: 8, HPD: [1, 8, 0] },
  { id: 1369, ruleset: 'pathfinder 2e', name: "Giant Crawling Hand", AC: 22, HP: 75, DEX: 12, HPD: [1, 12, 0] },
  { id: 1370, ruleset: 'pathfinder 2e', name: "Giant Dragonfly", AC: 21, HP: 60, DEX: 11, HPD: [1, 11, 0] },
  { id: 1371, ruleset: 'pathfinder 2e', name: "Giant Dragonfly Nymph", AC: 19, HP: 46, DEX: 8, HPD: [1, 8, 0] },
  { id: 1372, ruleset: 'pathfinder 2e', name: "Giant Eagle", AC: 19, HP: 45, DEX: 11, HPD: [1, 11, 0] },
  { id: 1373, ruleset: 'pathfinder 2e', name: "Giant Flea", AC: 19, HP: 50, DEX: 10, HPD: [1, 10, 0] },
  { id: 1374, ruleset: 'pathfinder 2e', name: "Giant Fly", AC: 17, HP: 20, DEX: 8, HPD: [1, 8, 0] },
  { id: 1375, ruleset: 'pathfinder 2e', name: "Giant Flying Squirrel", AC: 18, HP: 30, DEX: 8, HPD: [1, 8, 0] },
  { id: 1376, ruleset: 'pathfinder 2e', name: "Giant Flytrap", AC: 29, HP: 185, DEX: 17, HPD: [1, 17, 0] },
  { id: 1377, ruleset: 'pathfinder 2e', name: "Giant Frilled Lizard", AC: 22, HP: 75, DEX: 11, HPD: [1, 11, 0] },
  { id: 1378, ruleset: 'pathfinder 2e', name: "Giant Frog", AC: 15, HP: 30, DEX: 7, HPD: [1, 7, 0] },
  { id: 1379, ruleset: 'pathfinder 2e', name: "Giant Gecko", AC: 16, HP: 20, DEX: 7, HPD: [1, 7, 0] },
  { id: 1380, ruleset: 'pathfinder 2e', name: "Giant Hermit Crab", AC: 21, HP: 114, DEX: 13, HPD: [1, 13, 0] },
  { id: 1381, ruleset: 'pathfinder 2e', name: "Giant Hippocampus", AC: 27, HP: 170, DEX: 16, HPD: [1, 16, 0] },
  { id: 1382, ruleset: 'pathfinder 2e', name: "Giant Jellyfish", AC: 15, HP: 165, DEX: 12, HPD: [1, 12, 0] },
  { id: 1383, ruleset: 'pathfinder 2e', name: "Giant Leech", AC: 17, HP: 32, DEX: 5, HPD: [1, 5, 0] },
  { id: 1384, ruleset: 'pathfinder 2e', name: "Giant Maggot", AC: 13, HP: 18, DEX: 3, HPD: [1, 3, 0] },
  { id: 1385, ruleset: 'pathfinder 2e', name: "Giant Mantis", AC: 20, HP: 40, DEX: 9, HPD: [1, 9, 0] },
  { id: 1386, ruleset: 'pathfinder 2e', name: "Giant Mining Bee", AC: 18, HP: 30, DEX: 9, HPD: [1, 9, 0] },
  { id: 1387, ruleset: 'pathfinder 2e', name: "Giant Monitor Lizard", AC: 18, HP: 30, DEX: 7, HPD: [1, 7, 0] },
  { id: 1388, ruleset: 'pathfinder 2e', name: "Giant Moray Eel", AC: 21, HP: 65, DEX: 11, HPD: [1, 11, 0] },
  { id: 1389, ruleset: 'pathfinder 2e', name: "Giant Mosquito", AC: 24, HP: 80, DEX: 17, HPD: [1, 17, 0] },
  { id: 1390, ruleset: 'pathfinder 2e', name: "Giant Octopus", AC: 27, HP: 135, DEX: 15, HPD: [1, 15, 0] },
  { id: 1391, ruleset: 'pathfinder 2e', name: "Giant Opossum", AC: 17, HP: 35, DEX: 8, HPD: [1, 8, 0] },
  { id: 1392, ruleset: 'pathfinder 2e', name: "Giant Pangolin", AC: 21, HP: 63, DEX: 12, HPD: [1, 12, 0] },
  { id: 1393, ruleset: 'pathfinder 2e', name: "Giant Porcupine", AC: 18, HP: 32, DEX: 8, HPD: [1, 8, 0] },
  { id: 1394, ruleset: 'pathfinder 2e', name: "Giant Rat", AC: 15, HP: 8, DEX: 5, HPD: [1, 5, 0] },
  { id: 1395, ruleset: 'pathfinder 2e', name: "Giant Scorpion", AC: 19, HP: 45, DEX: 9, HPD: [1, 9, 0] },
  { id: 1396, ruleset: 'pathfinder 2e', name: "Giant Seahorse", AC: 19, HP: 58, DEX: 10, HPD: [1, 10, 0] },
  { id: 1397, ruleset: 'pathfinder 2e', name: "Giant Silverfish", AC: 15, HP: 17, DEX: 8, HPD: [1, 8, 0] },
  { id: 1398, ruleset: 'pathfinder 2e', name: "Giant Skunk", AC: 16, HP: 21, DEX: 6, HPD: [1, 6, 0] },
  { id: 1399, ruleset: 'pathfinder 2e', name: "Giant Snapping Turtle", AC: 28, HP: 170, DEX: 17, HPD: [1, 17, 0] },
  { id: 1400, ruleset: 'pathfinder 2e', name: "Giant Solifugid", AC: 16, HP: 20, DEX: 7, HPD: [1, 7, 0] },
  { id: 1401, ruleset: 'pathfinder 2e', name: "Giant Squid", AC: 28, HP: 155, DEX: 21, HPD: [1, 21, 0] },
  { id: 1402, ruleset: 'pathfinder 2e', name: "Giant Stag Beetle", AC: 22, HP: 55, DEX: 10, HPD: [1, 10, 0] },
  { id: 1403, ruleset: 'pathfinder 2e', name: "Giant Tapir", AC: 18, HP: 30, DEX: 8, HPD: [1, 8, 0] },
  { id: 1404, ruleset: 'pathfinder 2e', name: "Giant Tarantula", AC: 21, HP: 135, DEX: 14, HPD: [1, 14, 0] },
  { id: 1405, ruleset: 'pathfinder 2e', name: "Giant Tick", AC: 16, HP: 20, DEX: 6, HPD: [1, 6, 0] },
  { id: 1406, ruleset: 'pathfinder 2e', name: "Giant Toad", AC: 17, HP: 36, DEX: 8, HPD: [1, 8, 0] },
  { id: 1407, ruleset: 'pathfinder 2e', name: "Giant Trapdoor Spider", AC: 18, HP: 28, DEX: 7, HPD: [1, 7, 0] },
  { id: 1408, ruleset: 'pathfinder 2e', name: "Giant Tsetse Fly", AC: 18, HP: 30, DEX: 9, HPD: [1, 9, 0] },
  { id: 1409, ruleset: 'pathfinder 2e', name: "Giant Viper", AC: 19, HP: 26, DEX: 7, HPD: [1, 7, 0] },
  { id: 1410, ruleset: 'pathfinder 2e', name: "Giant Vulture", AC: 18, HP: 50, DEX: 12, HPD: [1, 12, 0] },
  { id: 1411, ruleset: 'pathfinder 2e', name: "Giant Wasp", AC: 19, HP: 45, DEX: 8, HPD: [1, 8, 0] },
  { id: 1412, ruleset: 'pathfinder 2e', name: "Giant Whiptail Centipede", AC: 19, HP: 45, DEX: 9, HPD: [1, 9, 0] },
  { id: 1413, ruleset: 'pathfinder 2e', name: "Giant Wolverine", AC: 21, HP: 65, DEX: 9, HPD: [1, 9, 0] },
  { id: 1414, ruleset: 'pathfinder 2e', name: "Giant Worker Bee", AC: 16, HP: 16, DEX: 5, HPD: [1, 5, 0] },
  { id: 1415, ruleset: 'pathfinder 2e', name: "Gibbering Mouther", AC: 21, HP: 120, DEX: 15, HPD: [1, 15, 0] },
  { id: 1416, ruleset: 'pathfinder 2e', name: "Gibtanius", AC: 28, HP: 140, DEX: 16, HPD: [1, 16, 0] },
  { id: 1417, ruleset: 'pathfinder 2e', name: "Gibtas Bounder", AC: 22, HP: 76, DEX: 13, HPD: [1, 13, 0] },
  { id: 1418, ruleset: 'pathfinder 2e', name: "Gibtas Spawn Swarm", AC: 23, HP: 70, DEX: 14, HPD: [1, 14, 0] },
  { id: 1419, ruleset: 'pathfinder 2e', name: "Gimmerling", AC: 34, HP: 235, DEX: 21, HPD: [1, 21, 0] },
  { id: 1420, ruleset: 'pathfinder 2e', name: "Ginjana Mindkeeper", AC: 31, HP: 195, DEX: 22, HPD: [1, 22, 0] },
  { id: 1421, ruleset: 'pathfinder 2e', name: "Girtablilu Seer", AC: 33, HP: 210, DEX: 25, HPD: [1, 25, 0] },
  { id: 1422, ruleset: 'pathfinder 2e', name: "Girtablilu Sentry", AC: 27, HP: 160, DEX: 18, HPD: [1, 18, 0] },
  { id: 1423, ruleset: 'pathfinder 2e', name: "Glabrezu", AC: 34, HP: 280, DEX: 24, HPD: [1, 24, 0] },
  { id: 1424, ruleset: 'pathfinder 2e', name: "Glass Elephant", AC: 32, HP: 245, DEX: 22, HPD: [1, 22, 0] },
  { id: 1425, ruleset: 'pathfinder 2e', name: "Glass Golem", AC: 26, HP: 135, DEX: 14, HPD: [1, 14, 0] },
  { id: 1426, ruleset: 'pathfinder 2e', name: "Gliminal", AC: 27, HP: 160, DEX: 18, HPD: [1, 18, 0] },
  { id: 1427, ruleset: 'pathfinder 2e', name: "Globster", AC: 12, HP: 170, DEX: 9, HPD: [1, 9, 0] },
  { id: 1428, ruleset: 'pathfinder 2e', name: "Gluttondark Babau", AC: 26, HP: 135, DEX: 15, HPD: [1, 15, 0] },
  { id: 1429, ruleset: 'pathfinder 2e', name: "Gluttonworm", AC: 41, HP: 445, DEX: 32, HPD: [1, 32, 0] },
  { id: 1430, ruleset: 'pathfinder 2e', name: "Glyptodon", AC: 23, HP: 65, DEX: 9, HPD: [1, 9, 0] },
  { id: 1431, ruleset: 'pathfinder 2e', name: "Gnagrif", AC: 18, HP: 35, DEX: 7, HPD: [1, 7, 0] },
  { id: 1432, ruleset: 'pathfinder 2e', name: "Gnoll Cultist", AC: 19, HP: 45, DEX: 8, HPD: [1, 8, 0] },
  { id: 1433, ruleset: 'pathfinder 2e', name: "Gnoll Hunter", AC: 18, HP: 29, DEX: 7, HPD: [1, 7, 0] },
  { id: 1434, ruleset: 'pathfinder 2e', name: "Gnoll Sergeant", AC: 21, HP: 60, DEX: 10, HPD: [1, 10, 0] },
  { id: 1435, ruleset: 'pathfinder 2e', name: "Goblin Bat-Dog", AC: 18, HP: 53, DEX: 9, HPD: [1, 9, 0] },
  { id: 1436, ruleset: 'pathfinder 2e', name: "Goblin Commando", AC: 17, HP: 18, DEX: 5, HPD: [1, 5, 0] },
  { id: 1437, ruleset: 'pathfinder 2e', name: "Goblin Dog", AC: 17, HP: 17, DEX: 6, HPD: [1, 6, 0] },
  { id: 1438, ruleset: 'pathfinder 2e', name: "Goblin Pyro", AC: 17, HP: 15, DEX: 4, HPD: [1, 4, 0] },
  { id: 1439, ruleset: 'pathfinder 2e', name: "Goblin War Chanter", AC: 17, HP: 16, DEX: 5, HPD: [1, 5, 0] },
  { id: 1440, ruleset: 'pathfinder 2e', name: "Goblin Warrior", AC: 16, HP: 6, DEX: 2, HPD: [1, 2, 0] },
  { id: 1441, ruleset: 'pathfinder 2e', name: "Gogiteth", AC: 31, HP: 250, DEX: 21, HPD: [1, 21, 0] },
  { id: 1442, ruleset: 'pathfinder 2e', name: "Gold Defender", AC: 34, HP: 190, DEX: 21, HPD: [1, 21, 0] },
  { id: 1443, ruleset: 'pathfinder 2e', name: "Gold Defender Garrison", AC: 29, HP: 240, DEX: 22, HPD: [1, 22, 0] },
  { id: 1444, ruleset: 'pathfinder 2e', name: "Golgopo", AC: 26, HP: 130, DEX: 16, HPD: [1, 16, 0] },
  { id: 1445, ruleset: 'pathfinder 2e', name: "Goliath Spider", AC: 30, HP: 220, DEX: 22, HPD: [1, 22, 0] },
  { id: 1446, ruleset: 'pathfinder 2e', name: "Gorgon", AC: 28, HP: 135, DEX: 19, HPD: [1, 19, 0] },
  { id: 1447, ruleset: 'pathfinder 2e', name: "Gorilla", AC: 19, HP: 45, DEX: 8, HPD: [1, 8, 0] },
  { id: 1448, ruleset: 'pathfinder 2e', name: "Gosreg", AC: 31, HP: 195, DEX: 21, HPD: [1, 21, 0] },
  { id: 1449, ruleset: 'pathfinder 2e', name: "Gourd Leshy", AC: 17, HP: 20, DEX: 5, HPD: [1, 5, 0] },
  { id: 1450, ruleset: 'pathfinder 2e', name: "Grabbles", AC: 18, HP: 35, DEX: 7, HPD: [1, 7, 0] },
  { id: 1451, ruleset: 'pathfinder 2e', name: "Grandfather Mantis", AC: 36, HP: 300, DEX: 27, HPD: [1, 27, 0] },
  { id: 1452, ruleset: 'pathfinder 2e', name: "Granite Glyptodont", AC: 28, HP: 145, DEX: 17, HPD: [1, 17, 0] },
  { id: 1453, ruleset: 'pathfinder 2e', name: "Granite Vulture", AC: 18, HP: 28, DEX: 10, HPD: [1, 10, 0] },
  { id: 1454, ruleset: 'pathfinder 2e', name: "Grappling Spirit", AC: 27, HP: 125, DEX: 17, HPD: [1, 17, 0] },
  { id: 1455, ruleset: 'pathfinder 2e', name: "Grauladon", AC: 17, HP: 35, DEX: 7, HPD: [1, 7, 0] },
  { id: 1456, ruleset: 'pathfinder 2e', name: "Grave Hag", AC: 28, HP: 155, DEX: 18, HPD: [1, 18, 0] },
  { id: 1457, ruleset: 'pathfinder 2e', name: "Grave Karina", AC: 30, HP: 240, DEX: 21, HPD: [1, 21, 0] },
  { id: 1458, ruleset: 'pathfinder 2e', name: "Grave Spinosaurus", AC: 30, HP: 280, DEX: 26, HPD: [1, 26, 0] },
  { id: 1459, ruleset: 'pathfinder 2e', name: "Graveknight", AC: 31, HP: 175, DEX: 19, HPD: [1, 19, 0] },
  { id: 1460, ruleset: 'pathfinder 2e', name: "Graveknight Captain", AC: 25, HP: 90, DEX: 14, HPD: [1, 14, 0] },
  { id: 1461, ruleset: 'pathfinder 2e', name: "Graveknight Champion", AC: 38, HP: 275, DEX: 27, HPD: [1, 27, 0] },
  { id: 1462, ruleset: 'pathfinder 2e', name: "Graveknight Warmaster", AC: 38, HP: 255, DEX: 26, HPD: [1, 26, 0] },
  { id: 1463, ruleset: 'pathfinder 2e', name: "Graveshell", AC: 17, HP: 20, DEX: 4, HPD: [1, 4, 0] },
  { id: 1464, ruleset: 'pathfinder 2e', name: "Gray Death", AC: 44, HP: 330, DEX: 33, HPD: [1, 33, 0] },
  { id: 1465, ruleset: 'pathfinder 2e', name: "Gray Ooze", AC: 14, HP: 60, DEX: 8, HPD: [1, 8, 0] },
  { id: 1466, ruleset: 'pathfinder 2e', name: "Graylok Ambusher", AC: 26, HP: 130, DEX: 19, HPD: [1, 19, 0] },
  { id: 1467, ruleset: 'pathfinder 2e', name: "Graylok Artillerist", AC: 26, HP: 100, DEX: 16, HPD: [1, 16, 0] },
  { id: 1468, ruleset: 'pathfinder 2e', name: "Graylok Gatebreaker", AC: 28, HP: 130, DEX: 16, HPD: [1, 16, 0] },
  { id: 1469, ruleset: 'pathfinder 2e', name: "Great Cyclops", AC: 32, HP: 235, DEX: 22, HPD: [1, 22, 0] },
  { id: 1470, ruleset: 'pathfinder 2e', name: "Great Grodair", AC: 23, HP: 130, DEX: 16, HPD: [1, 16, 0] },
  { id: 1471, ruleset: 'pathfinder 2e', name: "Great White Shark", AC: 21, HP: 60, DEX: 11, HPD: [1, 11, 0] },
  { id: 1472, ruleset: 'pathfinder 2e', name: "Greater Barghest", AC: 25, HP: 105, DEX: 16, HPD: [1, 16, 0] },
  { id: 1473, ruleset: 'pathfinder 2e', name: "Greater Nightmare", AC: 31, HP: 200, DEX: 22, HPD: [1, 22, 0] },
  { id: 1474, ruleset: 'pathfinder 2e', name: "Greater Shadow", AC: 24, HP: 75, DEX: 14, HPD: [1, 14, 0] },
  { id: 1475, ruleset: 'pathfinder 2e', name: "Green Hag", AC: 21, HP: 70, DEX: 10, HPD: [1, 10, 0] },
  { id: 1476, ruleset: 'pathfinder 2e', name: "Green Man", AC: 51, HP: 525, DEX: 42, HPD: [1, 42, 0] },
  { id: 1477, ruleset: 'pathfinder 2e', name: "Grendel", AC: 44, HP: 360, DEX: 35, HPD: [1, 35, 0] },
  { id: 1478, ruleset: 'pathfinder 2e', name: "Grick", AC: 20, HP: 35, DEX: 8, HPD: [1, 8, 0] },
  { id: 1479, ruleset: 'pathfinder 2e', name: "Griffon", AC: 21, HP: 60, DEX: 11, HPD: [1, 11, 0] },
  { id: 1480, ruleset: 'pathfinder 2e', name: "Grig", AC: 17, HP: 20, DEX: 7, HPD: [1, 7, 0] },
  { id: 1481, ruleset: 'pathfinder 2e', name: "Grikkitog", AC: 36, HP: 200, DEX: 29, HPD: [1, 29, 0] },
  { id: 1482, ruleset: 'pathfinder 2e', name: "Grim Reaper", AC: 47, HP: 320, DEX: 41, HPD: [1, 41, 0] },
  { id: 1483, ruleset: 'pathfinder 2e', name: "Grimbal", AC: 22, HP: 75, DEX: 13, HPD: [1, 13, 0] },
  { id: 1484, ruleset: 'pathfinder 2e', name: "Grimple", AC: 15, HP: 9, DEX: 6, HPD: [1, 6, 0] },
  { id: 1485, ruleset: 'pathfinder 2e', name: "Grimstalker", AC: 22, HP: 60, DEX: 12, HPD: [1, 12, 0] },
  { id: 1486, ruleset: 'pathfinder 2e', name: "Grindylow", AC: 15, HP: 14, DEX: 5, HPD: [1, 5, 0] },
  { id: 1487, ruleset: 'pathfinder 2e', name: "Grioth Cultist", AC: 18, HP: 40, DEX: 10, HPD: [1, 10, 0] },
  { id: 1488, ruleset: 'pathfinder 2e', name: "Grioth Scout", AC: 17, HP: 16, DEX: 7, HPD: [1, 7, 0] },
  { id: 1489, ruleset: 'pathfinder 2e', name: "Grippli Archer", AC: 20, HP: 44, DEX: 10, HPD: [1, 10, 0] },
  { id: 1490, ruleset: 'pathfinder 2e', name: "Grippli Greenspeaker", AC: 21, HP: 71, DEX: 13, HPD: [1, 13, 0] },
  { id: 1491, ruleset: 'pathfinder 2e', name: "Grippli Jinxer", AC: 23, HP: 95, DEX: 16, HPD: [1, 16, 0] },
  { id: 1492, ruleset: 'pathfinder 2e', name: "Grippli Scout", AC: 18, HP: 20, DEX: 8, HPD: [1, 8, 0] },
  { id: 1493, ruleset: 'pathfinder 2e', name: "Grippli Skirmisher", AC: 22, HP: 60, DEX: 12, HPD: [1, 12, 0] },
  { id: 1494, ruleset: 'pathfinder 2e', name: "Grisantian Lion", AC: 32, HP: 215, DEX: 25, HPD: [1, 25, 0] },
  { id: 1495, ruleset: 'pathfinder 2e', name: "Grizzly Bear", AC: 19, HP: 45, DEX: 10, HPD: [1, 10, 0] },
  { id: 1496, ruleset: 'pathfinder 2e', name: "Grodair", AC: 20, HP: 88, DEX: 13, HPD: [1, 13, 0] },
  { id: 1497, ruleset: 'pathfinder 2e', name: "Grogrisant", AC: 38, HP: 295, DEX: 30, HPD: [1, 30, 0] },
  { id: 1498, ruleset: 'pathfinder 2e', name: "Gromog", AC: 16, HP: 28, DEX: 1, HPD: [1, 1, 0] },
  { id: 1499, ruleset: 'pathfinder 2e', name: "Grootslang", AC: 38, HP: 370, DEX: 28, HPD: [1, 28, 0] },
  { id: 1500, ruleset: 'pathfinder 2e', name: "Groplit", AC: 16, HP: 16, DEX: 6, HPD: [1, 6, 0] },
  { id: 1501, ruleset: 'pathfinder 2e', name: "Grothlut", AC: 19, HP: 50, DEX: 5, HPD: [1, 5, 0] },
  { id: 1502, ruleset: 'pathfinder 2e', name: "Grouloop", AC: 27, HP: 185, DEX: 18, HPD: [1, 18, 0] },
  { id: 1503, ruleset: 'pathfinder 2e', name: "Guard Dog", AC: 15, HP: 8, DEX: 6, HPD: [1, 6, 0] },
  { id: 1504, ruleset: 'pathfinder 2e', name: "Guardian Naga", AC: 31, HP: 175, DEX: 22, HPD: [1, 22, 0] },
  { id: 1505, ruleset: 'pathfinder 2e', name: "Guecubu", AC: 27, HP: 110, DEX: 15, HPD: [1, 15, 0] },
  { id: 1506, ruleset: 'pathfinder 2e', name: "Gug", AC: 30, HP: 175, DEX: 19, HPD: [1, 19, 0] },
  { id: 1507, ruleset: 'pathfinder 2e', name: "Guillotine Golem", AC: 42, HP: 270, DEX: 28, HPD: [1, 28, 0] },
  { id: 1508, ruleset: 'pathfinder 2e', name: "Guloval", AC: 32, HP: 250, DEX: 23, HPD: [1, 23, 0] },
  { id: 1509, ruleset: 'pathfinder 2e', name: "Gumiho", AC: 39, HP: 310, DEX: 29, HPD: [1, 29, 0] },
  { id: 1510, ruleset: 'pathfinder 2e', name: "Gunpowder Ooze", AC: 29, HP: 400, DEX: 22, HPD: [1, 22, 0] },
  { id: 1511, ruleset: 'pathfinder 2e', name: "Gurgist Mauler", AC: 23, HP: 120, DEX: 14, HPD: [1, 14, 0] },
  { id: 1512, ruleset: 'pathfinder 2e', name: "Gurija", AC: 21, HP: 95, DEX: 11, HPD: [1, 11, 0] },
  { id: 1513, ruleset: 'pathfinder 2e', name: "Guthallath", AC: 43, HP: 325, DEX: 30, HPD: [1, 30, 0] },
  { id: 1514, ruleset: 'pathfinder 2e', name: "Gylou", AC: 36, HP: 240, DEX: 28, HPD: [1, 28, 0] },
  { id: 1515, ruleset: 'pathfinder 2e', name: "Hadrinnex", AC: 27, HP: 118, DEX: 17, HPD: [1, 17, 0] },
  { id: 1516, ruleset: 'pathfinder 2e', name: "Hadrosaurid", AC: 21, HP: 60, DEX: 13, HPD: [1, 13, 0] },
  { id: 1517, ruleset: 'pathfinder 2e', name: "Halbrux Far-Sight", AC: 30, HP: 195, DEX: 21, HPD: [1, 21, 0] },
  { id: 1518, ruleset: 'pathfinder 2e', name: "Hamatula", AC: 31, HP: 165, DEX: 24, HPD: [1, 24, 0] },
  { id: 1519, ruleset: 'pathfinder 2e', name: "Haniver", AC: 15, HP: 9, DEX: 5, HPD: [1, 5, 0] },
  { id: 1520, ruleset: 'pathfinder 2e', name: "Hantu Belian", AC: 27, HP: 150, DEX: 24, HPD: [1, 24, 0] },
  { id: 1521, ruleset: 'pathfinder 2e', name: "Hantu Denai", AC: 22, HP: 125, DEX: 18, HPD: [1, 18, 0] },
  { id: 1522, ruleset: 'pathfinder 2e', name: "Hargulka", AC: 26, HP: 190, DEX: 16, HPD: [1, 16, 0] },
  { id: 1523, ruleset: 'pathfinder 2e', name: "Harlo Krant", AC: 20, HP: 80, DEX: 13, HPD: [1, 13, 0] },
  { id: 1524, ruleset: 'pathfinder 2e', name: "Harmona", AC: 30, HP: 190, DEX: 24, HPD: [1, 24, 0] },
  { id: 1525, ruleset: 'pathfinder 2e', name: "Harmony In Agony", AC: 34, HP: 200, DEX: 23, HPD: [1, 23, 0] },
  { id: 1526, ruleset: 'pathfinder 2e', name: "Harpy", AC: 22, HP: 68, DEX: 12, HPD: [1, 12, 0] },
  { id: 1527, ruleset: 'pathfinder 2e', name: "Harpy Skeleton", AC: 22, HP: 60, DEX: 9, HPD: [1, 9, 0] },
  { id: 1528, ruleset: 'pathfinder 2e', name: "Harrow Doll", AC: 26, HP: 120, DEX: 14, HPD: [1, 14, 0] },
  { id: 1529, ruleset: 'pathfinder 2e', name: "Hatred Siktempora", AC: 42, HP: 240, DEX: 33, HPD: [1, 33, 0] },
  { id: 1530, ruleset: 'pathfinder 2e', name: "Headless Xulgath", AC: 29, HP: 195, DEX: 20, HPD: [1, 20, 0] },
  { id: 1531, ruleset: 'pathfinder 2e', name: "Hegessik", AC: 37, HP: 250, DEX: 29, HPD: [1, 29, 0] },
  { id: 1532, ruleset: 'pathfinder 2e', name: "Hegremon", AC: 34, HP: 225, DEX: 27, HPD: [1, 27, 0] },
  { id: 1533, ruleset: 'pathfinder 2e', name: "Hekatonkheires Titan", AC: 52, HP: 500, DEX: 43, HPD: [1, 43, 0] },
  { id: 1534, ruleset: 'pathfinder 2e', name: "Helg Eats-The-Eaters", AC: 37, HP: 275, DEX: 26, HPD: [1, 26, 0] },
  { id: 1535, ruleset: 'pathfinder 2e', name: "Hell Hound", AC: 19, HP: 40, DEX: 9, HPD: [1, 9, 0] },
  { id: 1536, ruleset: 'pathfinder 2e', name: "Hellbound Attorney", AC: 20, HP: 60, DEX: 11, HPD: [1, 11, 0] },
  { id: 1537, ruleset: 'pathfinder 2e', name: "Hellcat", AC: 25, HP: 110, DEX: 16, HPD: [1, 16, 0] },
  { id: 1538, ruleset: 'pathfinder 2e', name: "Hellcrown", AC: 16, HP: 20, DEX: 10, HPD: [1, 10, 0] },
  { id: 1539, ruleset: 'pathfinder 2e', name: "Hellknight Cavalry Brigade", AC: 27, HP: 135, DEX: 16, HPD: [1, 16, 0] },
  { id: 1540, ruleset: 'pathfinder 2e', name: "Hellwasp Swarm", AC: 24, HP: 95, DEX: 16, HPD: [1, 16, 0] },
  { id: 1541, ruleset: 'pathfinder 2e', name: "Herecite of Zevgavizeb", AC: 30, HP: 200, DEX: 20, HPD: [1, 20, 0] },
  { id: 1542, ruleset: 'pathfinder 2e', name: "Herexen", AC: 17, HP: 30, DEX: 8, HPD: [1, 8, 0] },
  { id: 1543, ruleset: 'pathfinder 2e', name: "Hermean Mutant", AC: 43, HP: 380, DEX: 35, HPD: [1, 35, 0] },
  { id: 1544, ruleset: 'pathfinder 2e', name: "Hermit Crab Swarm", AC: 19, HP: 42, DEX: 11, HPD: [1, 11, 0] },
  { id: 1545, ruleset: 'pathfinder 2e', name: "Hesperid", AC: 28, HP: 175, DEX: 19, HPD: [1, 19, 0] },
  { id: 1546, ruleset: 'pathfinder 2e', name: "Hesperid Queen", AC: 45, HP: 306, DEX: 34, HPD: [1, 34, 0] },
  { id: 1547, ruleset: 'pathfinder 2e', name: "Hezrou", AC: 31, HP: 245, DEX: 21, HPD: [1, 21, 0] },
  { id: 1548, ruleset: 'pathfinder 2e', name: "Hieracosphinx", AC: 22, HP: 70, DEX: 14, HPD: [1, 14, 0] },
  { id: 1549, ruleset: 'pathfinder 2e', name: "Hill Giant", AC: 24, HP: 140, DEX: 13, HPD: [1, 13, 0] },
  { id: 1550, ruleset: 'pathfinder 2e', name: "Hill Giant Butcher", AC: 36, HP: 260, DEX: 25, HPD: [1, 25, 0] },
  { id: 1551, ruleset: 'pathfinder 2e', name: "Hillstomper", AC: 31, HP: 200, DEX: 21, HPD: [1, 21, 0] },
  { id: 1552, ruleset: 'pathfinder 2e', name: "Hippocampus", AC: 16, HP: 24, DEX: 6, HPD: [1, 6, 0] },
  { id: 1553, ruleset: 'pathfinder 2e', name: "Hippogriff", AC: 18, HP: 32, DEX: 8, HPD: [1, 8, 0] },
  { id: 1554, ruleset: 'pathfinder 2e', name: "Hippopotamus", AC: 21, HP: 85, DEX: 11, HPD: [1, 11, 0] },
  { id: 1555, ruleset: 'pathfinder 2e', name: "Hive Mother", AC: 29, HP: 120, DEX: 16, HPD: [1, 16, 0] },
  { id: 1556, ruleset: 'pathfinder 2e', name: "Hobgoblin Archer", AC: 23, HP: 50, DEX: 10, HPD: [1, 10, 0] },
  { id: 1557, ruleset: 'pathfinder 2e', name: "Hobgoblin General", AC: 25, HP: 90, DEX: 13, HPD: [1, 13, 0] },
  { id: 1558, ruleset: 'pathfinder 2e', name: "Hobgoblin Soldier", AC: 18, HP: 20, DEX: 7, HPD: [1, 7, 0] },
  { id: 1559, ruleset: 'pathfinder 2e', name: "Hobji", AC: 19, HP: 45, DEX: 8, HPD: [1, 8, 0] },
  { id: 1560, ruleset: 'pathfinder 2e', name: "Hodag", AC: 24, HP: 90, DEX: 14, HPD: [1, 14, 0] },
  { id: 1561, ruleset: 'pathfinder 2e', name: "Hollow Hush", AC: 41, HP: 355, DEX: 33, HPD: [1, 33, 0] },
  { id: 1562, ruleset: 'pathfinder 2e', name: "Hollow Husks", AC: 20, HP: 130, DEX: 9, HPD: [1, 9, 0] },
  { id: 1563, ruleset: 'pathfinder 2e', name: "Hollow Serpent", AC: 37, HP: 280, DEX: 27, HPD: [1, 27, 0] },
  { id: 1564, ruleset: 'pathfinder 2e', name: "Homunculus", AC: 17, HP: 17, DEX: 3, HPD: [1, 3, 0] },
  { id: 1565, ruleset: 'pathfinder 2e', name: "Hooklimb Xulgath", AC: 29, HP: 190, DEX: 18, HPD: [1, 18, 0] },
  { id: 1566, ruleset: 'pathfinder 2e', name: "Hooktongue", AC: 35, HP: 275, DEX: 25, HPD: [1, 25, 0] },
  { id: 1567, ruleset: 'pathfinder 2e', name: "Hooktongue Hydra", AC: 34, HP: 240, DEX: 26, HPD: [1, 26, 0] },
  { id: 1568, ruleset: 'pathfinder 2e', name: "Horagnamon", AC: 28, HP: 100, DEX: 18, HPD: [1, 18, 0] },
  { id: 1569, ruleset: 'pathfinder 2e', name: "Horde Lich", AC: 35, HP: 250, DEX: 25, HPD: [1, 25, 0] },
  { id: 1570, ruleset: 'pathfinder 2e', name: "Horned Archon", AC: 22, HP: 65, DEX: 11, HPD: [1, 11, 0] },
  { id: 1571, ruleset: 'pathfinder 2e', name: "Hound Archon", AC: 22, HP: 70, DEX: 13, HPD: [1, 13, 0] },
  { id: 1572, ruleset: 'pathfinder 2e', name: "Hound of Tindalos", AC: 25, HP: 90, DEX: 17, HPD: [1, 17, 0] },
  { id: 1573, ruleset: 'pathfinder 2e', name: "House Drake", AC: 17, HP: 16, DEX: 8, HPD: [1, 8, 0] },
  { id: 1574, ruleset: 'pathfinder 2e', name: "Howling Spawn", AC: 31, HP: 175, DEX: 21, HPD: [1, 21, 0] },
  { id: 1575, ruleset: 'pathfinder 2e', name: "Huldra", AC: 21, HP: 70, DEX: 13, HPD: [1, 13, 0] },
  { id: 1576, ruleset: 'pathfinder 2e', name: "Hungering Growths", AC: 24, HP: 145, DEX: 15, HPD: [1, 15, 0] },
  { id: 1577, ruleset: 'pathfinder 2e', name: "Hungry Ghost", AC: 23, HP: 60, DEX: 13, HPD: [1, 13, 0] },
  { id: 1578, ruleset: 'pathfinder 2e', name: "Hunter Wight", AC: 24, HP: 112, DEX: 16, HPD: [1, 16, 0] },
  { id: 1579, ruleset: 'pathfinder 2e', name: "Hunting Spider", AC: 17, HP: 16, DEX: 7, HPD: [1, 7, 0] },
  { id: 1580, ruleset: 'pathfinder 2e', name: "Husk Zombie", AC: 17, HP: 55, DEX: 5, HPD: [1, 5, 0] },
  { id: 1581, ruleset: 'pathfinder 2e', name: "Hyaenodon", AC: 19, HP: 45, DEX: 9, HPD: [1, 9, 0] },
  { id: 1582, ruleset: 'pathfinder 2e', name: "Hyakume", AC: 36, HP: 275, DEX: 29, HPD: [1, 29, 0] },
  { id: 1583, ruleset: 'pathfinder 2e', name: "Hydra", AC: 23, HP: 90, DEX: 17, HPD: [1, 17, 0] },
  { id: 1584, ruleset: 'pathfinder 2e', name: "Hyena", AC: 16, HP: 20, DEX: 6, HPD: [1, 6, 0] },
  { id: 1585, ruleset: 'pathfinder 2e', name: "Hymmir Urath", AC: 21, HP: 80, DEX: 13, HPD: [1, 13, 0] },
  { id: 1586, ruleset: 'pathfinder 2e', name: "Hyrune Loxenna", AC: 37, HP: 240, DEX: 27, HPD: [1, 27, 0] },
  { id: 1587, ruleset: 'pathfinder 2e', name: "I", AC: 25, HP: 110, DEX: 19, HPD: [1, 19, 0] },
  { id: 1588, ruleset: 'pathfinder 2e', name: "I'iko Dragon", AC: 23, HP: 110, DEX: 12, HPD: [1, 12, 0] },
  { id: 1589, ruleset: 'pathfinder 2e', name: "Ibrique", AC: 35, HP: 200, DEX: 18, HPD: [1, 18, 0] },
  { id: 1590, ruleset: 'pathfinder 2e', name: "Ice Golem", AC: 21, HP: 80, DEX: 9, HPD: [1, 9, 0] },
  { id: 1591, ruleset: 'pathfinder 2e', name: "Ice Linnorm", AC: 41, HP: 330, DEX: 29, HPD: [1, 29, 0] },
  { id: 1592, ruleset: 'pathfinder 2e', name: "Ice Mephit", AC: 17, HP: 18, DEX: 3, HPD: [1, 3, 0] },
  { id: 1593, ruleset: 'pathfinder 2e', name: "Ice Mummy", AC: 26, HP: 130, DEX: 16, HPD: [1, 16, 0] },
  { id: 1594, ruleset: 'pathfinder 2e', name: "Ice Yai", AC: 34, HP: 230, DEX: 26, HPD: [1, 26, 0] },
  { id: 1595, ruleset: 'pathfinder 2e', name: "Icewyrm", AC: 30, HP: 185, DEX: 19, HPD: [1, 19, 0] },
  { id: 1596, ruleset: 'pathfinder 2e', name: "Ichor Slinger", AC: 20, HP: 65, DEX: 10, HPD: [1, 10, 0] },
  { id: 1597, ruleset: 'pathfinder 2e', name: "Icicle Snake", AC: 18, HP: 35, DEX: 7, HPD: [1, 7, 0] },
  { id: 1598, ruleset: 'pathfinder 2e', name: "Id Ooze", AC: 21, HP: 105, DEX: 15, HPD: [1, 15, 0] },
  { id: 1599, ruleset: 'pathfinder 2e', name: "Iffdahsil", AC: 43, HP: 380, DEX: 38, HPD: [1, 38, 0] },
  { id: 1600, ruleset: 'pathfinder 2e', name: "Ifrit Pyrochemist", AC: 18, HP: 18, DEX: 3, HPD: [1, 3, 0] },
  { id: 1601, ruleset: 'pathfinder 2e', name: "Iguanodon", AC: 24, HP: 95, DEX: 14, HPD: [1, 14, 0] },
  { id: 1602, ruleset: 'pathfinder 2e', name: "Ikeshti Brood-Minder", AC: 18, HP: 30, DEX: 7, HPD: [1, 7, 0] },
  { id: 1603, ruleset: 'pathfinder 2e', name: "Ileosa's Shell", AC: 34, HP: 235, DEX: 24, HPD: [1, 24, 0] },
  { id: 1604, ruleset: 'pathfinder 2e', name: "Ilthuliak", AC: 47, HP: 450, DEX: 37, HPD: [1, 37, 0] },
  { id: 1605, ruleset: 'pathfinder 2e', name: "Imentesh", AC: 30, HP: 175, DEX: 19, HPD: [1, 19, 0] },
  { id: 1606, ruleset: 'pathfinder 2e', name: "Immense Mandragora", AC: 43, HP: 400, DEX: 33, HPD: [1, 33, 0] },
  { id: 1607, ruleset: 'pathfinder 2e', name: "Immortal Ichor", AC: 26, HP: 350, DEX: 20, HPD: [1, 20, 0] },
  { id: 1608, ruleset: 'pathfinder 2e', name: "Imp", AC: 17, HP: 15, DEX: 7, HPD: [1, 7, 0] },
  { id: 1609, ruleset: 'pathfinder 2e', name: "Imperfect Automaton", AC: 23, HP: 85, DEX: 12, HPD: [1, 12, 0] },
  { id: 1610, ruleset: 'pathfinder 2e', name: "Incutilis", AC: 17, HP: 21, DEX: 7, HPD: [1, 7, 0] },
  { id: 1611, ruleset: 'pathfinder 2e', name: "Inmyeonjo", AC: 38, HP: 300, DEX: 30, HPD: [1, 30, 0] },
  { id: 1612, ruleset: 'pathfinder 2e', name: "Intellect Assemblage", AC: 43, HP: 355, DEX: 29, HPD: [1, 29, 0] },
  { id: 1613, ruleset: 'pathfinder 2e', name: "Intellect Devourer", AC: 26, HP: 130, DEX: 16, HPD: [1, 16, 0] },
  { id: 1614, ruleset: 'pathfinder 2e', name: "Interlocutor", AC: 33, HP: 215, DEX: 24, HPD: [1, 24, 0] },
  { id: 1615, ruleset: 'pathfinder 2e', name: "Invidiak", AC: 22, HP: 90, DEX: 15, HPD: [1, 15, 0] },
  { id: 1616, ruleset: 'pathfinder 2e', name: "Invisible Stalker", AC: 26, HP: 70, DEX: 16, HPD: [1, 16, 0] },
  { id: 1617, ruleset: 'pathfinder 2e', name: "Ioton", AC: 14, HP: 14, DEX: 3, HPD: [1, 3, 0] },
  { id: 1618, ruleset: 'pathfinder 2e', name: "Irahkatu", AC: 34, HP: 235, DEX: 22, HPD: [1, 22, 0] },
  { id: 1619, ruleset: 'pathfinder 2e', name: "Iridescent Elephant", AC: 21, HP: 110, DEX: 11, HPD: [1, 11, 0] },
  { id: 1620, ruleset: 'pathfinder 2e', name: "Irlgaunt", AC: 34, HP: 265, DEX: 24, HPD: [1, 24, 0] },
  { id: 1621, ruleset: 'pathfinder 2e', name: "Irnakurse", AC: 28, HP: 152, DEX: 18, HPD: [1, 18, 0] },
  { id: 1622, ruleset: 'pathfinder 2e', name: "Iron Golem", AC: 34, HP: 190, DEX: 21, HPD: [1, 21, 0] },
  { id: 1623, ruleset: 'pathfinder 2e', name: "Iron Taviah", AC: 24, HP: 85, DEX: 14, HPD: [1, 14, 0] },
  { id: 1624, ruleset: 'pathfinder 2e', name: "Ironclad Annihilator Beetle", AC: 49, HP: 400, DEX: 36, HPD: [1, 36, 0] },
  { id: 1625, ruleset: 'pathfinder 2e', name: "Iroran Mummy", AC: 31, HP: 190, DEX: 21, HPD: [1, 21, 0] },
  { id: 1626, ruleset: 'pathfinder 2e', name: "Irovetti's Fetch", AC: 44, HP: 440, DEX: 36, HPD: [1, 36, 0] },
  { id: 1627, ruleset: 'pathfinder 2e', name: "Irriseni Owlbear", AC: 23, HP: 85, DEX: 15, HPD: [1, 15, 0] },
  { id: 1628, ruleset: 'pathfinder 2e', name: "Iruxi Ossature", AC: 22, HP: 76, DEX: 13, HPD: [1, 13, 0] },
  { id: 1629, ruleset: 'pathfinder 2e', name: "Ishti", AC: 41, HP: 340, DEX: 29, HPD: [1, 29, 0] },
  { id: 1630, ruleset: 'pathfinder 2e', name: "Isqulug", AC: 31, HP: 230, DEX: 24, HPD: [1, 24, 0] },
  { id: 1631, ruleset: 'pathfinder 2e', name: "Ittan-Momen", AC: 20, HP: 20, DEX: 7, HPD: [1, 7, 0] },
  { id: 1632, ruleset: 'pathfinder 2e', name: "Ixamè", AC: 30, HP: 175, DEX: 22, HPD: [1, 22, 0] },
  { id: 1633, ruleset: 'pathfinder 2e', name: "Izfiitar", AC: 44, HP: 360, DEX: 36, HPD: [1, 36, 0] },
  { id: 1634, ruleset: 'pathfinder 2e', name: "Jabberwock", AC: 49, HP: 500, DEX: 40, HPD: [1, 40, 0] },
  { id: 1635, ruleset: 'pathfinder 2e', name: "Jaggaki", AC: 34, HP: 200, DEX: 22, HPD: [1, 22, 0] },
  { id: 1636, ruleset: 'pathfinder 2e', name: "Jaggedbriar Hag", AC: 25, HP: 92, DEX: 15, HPD: [1, 15, 0] },
  { id: 1637, ruleset: 'pathfinder 2e', name: "Jaiban", AC: 36, HP: 170, DEX: 23, HPD: [1, 23, 0] },
  { id: 1638, ruleset: 'pathfinder 2e', name: "Janni", AC: 20, HP: 60, DEX: 11, HPD: [1, 11, 0] },
  { id: 1639, ruleset: 'pathfinder 2e', name: "Japalisura", AC: 33, HP: 235, DEX: 22, HPD: [1, 22, 0] },
  { id: 1640, ruleset: 'pathfinder 2e', name: "Jewel", AC: 29, HP: 60, DEX: 21, HPD: [1, 21, 0] },
  { id: 1641, ruleset: 'pathfinder 2e', name: "Jin Durwhimmer", AC: 25, HP: 120, DEX: 19, HPD: [1, 19, 0] },
  { id: 1642, ruleset: 'pathfinder 2e', name: "Jinkin", AC: 17, HP: 19, DEX: 7, HPD: [1, 7, 0] },
  { id: 1643, ruleset: 'pathfinder 2e', name: "Jitterbone Contortionist", AC: 20, HP: 56, DEX: 10, HPD: [1, 10, 0] },
  { id: 1644, ruleset: 'pathfinder 2e', name: "Jorogumo", AC: 33, HP: 270, DEX: 26, HPD: [1, 26, 0] },
  { id: 1645, ruleset: 'pathfinder 2e', name: "Jotund Troll", AC: 35, HP: 360, DEX: 29, HPD: [1, 29, 0] },
  { id: 1646, ruleset: 'pathfinder 2e', name: "Jungle Drake", AC: 23, HP: 90, DEX: 13, HPD: [1, 13, 0] },
  { id: 1647, ruleset: 'pathfinder 2e', name: "Jurgrindor", AC: 17, HP: 50, DEX: 9, HPD: [1, 9, 0] },
  { id: 1648, ruleset: 'pathfinder 2e', name: "Juvenile Boar", AC: 15, HP: 16, DEX: 5, HPD: [1, 5, 0] },
  { id: 1649, ruleset: 'pathfinder 2e', name: "Jyoti", AC: 28, HP: 155, DEX: 21, HPD: [1, 21, 0] },
  { id: 1650, ruleset: 'pathfinder 2e', name: "K'nonna", AC: 26, HP: 140, DEX: 16, HPD: [1, 16, 0] },
  { id: 1651, ruleset: 'pathfinder 2e', name: "K.H.W.'s Echo", AC: 36, HP: 280, DEX: 30, HPD: [1, 30, 0] },
  { id: 1652, ruleset: 'pathfinder 2e', name: "Kaava Stalker", AC: 16, HP: 20, DEX: 7, HPD: [1, 7, 0] },
  { id: 1653, ruleset: 'pathfinder 2e', name: "Kadlaka", AC: 21, HP: 60, DEX: 14, HPD: [1, 14, 0] },
  { id: 1654, ruleset: 'pathfinder 2e', name: "Kalavakus", AC: 30, HP: 200, DEX: 19, HPD: [1, 19, 0] },
  { id: 1655, ruleset: 'pathfinder 2e', name: "Kallas Devil", AC: 27, HP: 180, DEX: 21, HPD: [1, 21, 0] },
  { id: 1656, ruleset: 'pathfinder 2e', name: "Kangaroo", AC: 15, HP: 18, DEX: 7, HPD: [1, 7, 0] },
  { id: 1657, ruleset: 'pathfinder 2e', name: "Kannitri", AC: 33, HP: 175, DEX: 26, HPD: [1, 26, 0] },
  { id: 1658, ruleset: 'pathfinder 2e', name: "Kapoacinth", AC: 20, HP: 40, DEX: 10, HPD: [1, 10, 0] },
  { id: 1659, ruleset: 'pathfinder 2e', name: "Kappa", AC: 18, HP: 35, DEX: 9, HPD: [1, 9, 0] },
  { id: 1660, ruleset: 'pathfinder 2e', name: "Kargstaad", AC: 43, HP: 360, DEX: 33, HPD: [1, 33, 0] },
  { id: 1661, ruleset: 'pathfinder 2e', name: "Kargstaad's Giant", AC: 37, HP: 270, DEX: 27, HPD: [1, 27, 0] },
  { id: 1662, ruleset: 'pathfinder 2e', name: "Karina", AC: 21, HP: 95, DEX: 12, HPD: [1, 12, 0] },
  { id: 1663, ruleset: 'pathfinder 2e', name: "Karumzek", AC: 21, HP: 60, DEX: 11, HPD: [1, 11, 0] },
  { id: 1664, ruleset: 'pathfinder 2e', name: "Karumzek Swarm", AC: 30, HP: 175, DEX: 21, HPD: [1, 21, 0] },
  { id: 1665, ruleset: 'pathfinder 2e', name: "Kasa-Obake", AC: 20, HP: 65, DEX: 13, HPD: [1, 13, 0] },
  { id: 1666, ruleset: 'pathfinder 2e', name: "Kasesh", AC: 19, HP: 35, DEX: 10, HPD: [1, 10, 0] },
  { id: 1667, ruleset: 'pathfinder 2e', name: "Kashrishi Evaluator", AC: 20, HP: 50, DEX: 13, HPD: [1, 13, 0] },
  { id: 1668, ruleset: 'pathfinder 2e', name: "Keketar", AC: 40, HP: 290, DEX: 30, HPD: [1, 30, 0] },
  { id: 1669, ruleset: 'pathfinder 2e', name: "Kellid Graveknight", AC: 37, HP: 255, DEX: 27, HPD: [1, 27, 0] },
  { id: 1670, ruleset: 'pathfinder 2e', name: "Kelpie", AC: 21, HP: 60, DEX: 11, HPD: [1, 11, 0] },
  { id: 1671, ruleset: 'pathfinder 2e', name: "Kerinza", AC: 32, HP: 235, DEX: 23, HPD: [1, 23, 0] },
  { id: 1672, ruleset: 'pathfinder 2e', name: "Khefak Scuttler", AC: 15, HP: 7, DEX: 4, HPD: [1, 4, 0] },
  { id: 1673, ruleset: 'pathfinder 2e', name: "Khravgodon", AC: 27, HP: 160, DEX: 18, HPD: [1, 18, 0] },
  { id: 1674, ruleset: 'pathfinder 2e', name: "Kieragan Skross", AC: 26, HP: 135, DEX: 16, HPD: [1, 16, 0] },
  { id: 1675, ruleset: 'pathfinder 2e', name: "Kilia Mwibo", AC: 36, HP: 345, DEX: 26, HPD: [1, 26, 0] },
  { id: 1676, ruleset: 'pathfinder 2e', name: "Kimenhul", AC: 45, HP: 425, DEX: 35, HPD: [1, 35, 0] },
  { id: 1677, ruleset: 'pathfinder 2e', name: "Kimilekki", AC: 40, HP: 380, DEX: 30, HPD: [1, 30, 0] },
  { id: 1678, ruleset: 'pathfinder 2e', name: "King Harral", AC: 35, HP: 195, DEX: 25, HPD: [1, 25, 0] },
  { id: 1679, ruleset: 'pathfinder 2e', name: "King Merlokrep", AC: 26, HP: 140, DEX: 16, HPD: [1, 16, 0] },
  { id: 1680, ruleset: 'pathfinder 2e', name: "Kirin", AC: 25, HP: 115, DEX: 17, HPD: [1, 17, 0] },
  { id: 1681, ruleset: 'pathfinder 2e', name: "Kirosthrek", AC: 45, HP: 375, DEX: 36, HPD: [1, 36, 0] },
  { id: 1682, ruleset: 'pathfinder 2e', name: "Kishi", AC: 25, HP: 138, DEX: 14, HPD: [1, 14, 0] },
  { id: 1683, ruleset: 'pathfinder 2e', name: "Kitsune Trickster", AC: 18, HP: 24, DEX: 10, HPD: [1, 10, 0] },
  { id: 1684, ruleset: 'pathfinder 2e', name: "Kob Moleg", AC: 39, HP: 295, DEX: 27, HPD: [1, 27, 0] },
  { id: 1685, ruleset: 'pathfinder 2e', name: "Kobold Dragon Mage", AC: 17, HP: 25, DEX: 5, HPD: [1, 5, 0] },
  { id: 1686, ruleset: 'pathfinder 2e', name: "Kobold Scout", AC: 18, HP: 16, DEX: 8, HPD: [1, 8, 0] },
  { id: 1687, ruleset: 'pathfinder 2e', name: "Kobold Tunnelrunner", AC: 16, HP: 16, DEX: 6, HPD: [1, 6, 0] },
  { id: 1688, ruleset: 'pathfinder 2e', name: "Kobold Warrior", AC: 16, HP: 8, DEX: 3, HPD: [1, 3, 0] },
  { id: 1689, ruleset: 'pathfinder 2e', name: "Kodama", AC: 21, HP: 95, DEX: 16, HPD: [1, 16, 0] },
  { id: 1690, ruleset: 'pathfinder 2e', name: "Kokogiak", AC: 33, HP: 215, DEX: 25, HPD: [1, 25, 0] },
  { id: 1691, ruleset: 'pathfinder 2e', name: "Kolyarut", AC: 34, HP: 215, DEX: 23, HPD: [1, 23, 0] },
  { id: 1692, ruleset: 'pathfinder 2e', name: "Kongamato", AC: 30, HP: 190, DEX: 19, HPD: [1, 19, 0] },
  { id: 1693, ruleset: 'pathfinder 2e', name: "Korog", AC: 39, HP: 295, DEX: 29, HPD: [1, 29, 0] },
  { id: 1694, ruleset: 'pathfinder 2e', name: "Korred", AC: 21, HP: 65, DEX: 12, HPD: [1, 12, 0] },
  { id: 1695, ruleset: 'pathfinder 2e', name: "Kothogaz, Dance Of Disharmony", AC: 49, HP: 400, DEX: 41, HPD: [1, 41, 0] },
  { id: 1696, ruleset: 'pathfinder 2e', name: "Kovintus Geomancer", AC: 18, HP: 40, DEX: 11, HPD: [1, 11, 0] },
  { id: 1697, ruleset: 'pathfinder 2e', name: "Kraken", AC: 42, HP: 360, DEX: 34, HPD: [1, 34, 0] },
  { id: 1698, ruleset: 'pathfinder 2e', name: "Krampus", AC: 46, HP: 380, DEX: 38, HPD: [1, 38, 0] },
  { id: 1699, ruleset: 'pathfinder 2e', name: "Krampus Celebrant", AC: 27, HP: 125, DEX: 19, HPD: [1, 19, 0] },
  { id: 1700, ruleset: 'pathfinder 2e', name: "Kreekoss", AC: 24, HP: 95, DEX: 15, HPD: [1, 15, 0] },
  { id: 1701, ruleset: 'pathfinder 2e', name: "Kreeth-Ni", AC: 19, HP: 40, DEX: 11, HPD: [1, 11, 0] },
  { id: 1702, ruleset: 'pathfinder 2e', name: "Krooth", AC: 26, HP: 150, DEX: 16, HPD: [1, 16, 0] },
  { id: 1703, ruleset: 'pathfinder 2e', name: "Kuchisake-Onna", AC: 36, HP: 252, DEX: 28, HPD: [1, 28, 0] },
  { id: 1704, ruleset: 'pathfinder 2e', name: "Kun", AC: 36, HP: 230, DEX: 27, HPD: [1, 27, 0] },
  { id: 1705, ruleset: 'pathfinder 2e', name: "Kundal", AC: 25, HP: 140, DEX: 15, HPD: [1, 15, 0] },
  { id: 1706, ruleset: 'pathfinder 2e', name: "Kurnugian Jackal", AC: 23, HP: 98, DEX: 17, HPD: [1, 17, 0] },
  { id: 1707, ruleset: 'pathfinder 2e', name: "Kurobozu", AC: 24, HP: 90, DEX: 14, HPD: [1, 14, 0] },
  { id: 1708, ruleset: 'pathfinder 2e', name: "Kurshkin", AC: 19, HP: 50, DEX: 10, HPD: [1, 10, 0] },
  { id: 1709, ruleset: 'pathfinder 2e', name: "Kushtaka", AC: 21, HP: 40, DEX: 12, HPD: [1, 12, 0] },
  { id: 1710, ruleset: 'pathfinder 2e', name: "Kuworsys", AC: 34, HP: 213, DEX: 22, HPD: [1, 22, 0] },
  { id: 1711, ruleset: 'pathfinder 2e', name: "Lacedon", AC: 18, HP: 32, DEX: 7, HPD: [1, 7, 0] },
  { id: 1712, ruleset: 'pathfinder 2e', name: "Lagofir", AC: 18, HP: 55, DEX: 11, HPD: [1, 11, 0] },
  { id: 1713, ruleset: 'pathfinder 2e', name: "Lamia", AC: 24, HP: 95, DEX: 13, HPD: [1, 13, 0] },
  { id: 1714, ruleset: 'pathfinder 2e', name: "Lamia Matriarch", AC: 27, HP: 135, DEX: 15, HPD: [1, 15, 0] },
  { id: 1715, ruleset: 'pathfinder 2e', name: "Lampad", AC: 22, HP: 85, DEX: 12, HPD: [1, 12, 0] },
  { id: 1716, ruleset: 'pathfinder 2e', name: "Lampad Queen", AC: 39, HP: 234, DEX: 27, HPD: [1, 27, 0] },
  { id: 1717, ruleset: 'pathfinder 2e', name: "Lantern Archon", AC: 16, HP: 20, DEX: 6, HPD: [1, 6, 0] },
  { id: 1718, ruleset: 'pathfinder 2e', name: "Laruhao", AC: 43, HP: 360, DEX: 35, HPD: [1, 35, 0] },
  { id: 1719, ruleset: 'pathfinder 2e', name: "Last Guard", AC: 45, HP: 330, DEX: 33, HPD: [1, 33, 0] },
  { id: 1720, ruleset: 'pathfinder 2e', name: "Lazurite-Infused Stone Golem", AC: 33, HP: 195, DEX: 22, HPD: [1, 22, 0] },
  { id: 1721, ruleset: 'pathfinder 2e', name: "Leaf Leshy", AC: 18, HP: 15, DEX: 4, HPD: [1, 4, 0] },
  { id: 1722, ruleset: 'pathfinder 2e', name: "Leandrus", AC: 18, HP: 30, DEX: 8, HPD: [1, 8, 0] },
  { id: 1723, ruleset: 'pathfinder 2e', name: "Ledalusca", AC: 17, HP: 40, DEX: 8, HPD: [1, 8, 0] },
  { id: 1724, ruleset: 'pathfinder 2e', name: "Legion Archon", AC: 27, HP: 100, DEX: 15, HPD: [1, 15, 0] },
  { id: 1725, ruleset: 'pathfinder 2e', name: "Lemure", AC: 13, HP: 20, DEX: 0, HPD: [1, 0, 0] },
  { id: 1726, ruleset: 'pathfinder 2e', name: "Leng Envoy", AC: 42, HP: 240, DEX: 31, HPD: [1, 31, 0] },
  { id: 1727, ruleset: 'pathfinder 2e', name: "Leng Ghoul", AC: 29, HP: 180, DEX: 19, HPD: [1, 19, 0] },
  { id: 1728, ruleset: 'pathfinder 2e', name: "Leng Spider", AC: 34, HP: 235, DEX: 24, HPD: [1, 24, 0] },
  { id: 1729, ruleset: 'pathfinder 2e', name: "Leopard", AC: 18, HP: 30, DEX: 7, HPD: [1, 7, 0] },
  { id: 1730, ruleset: 'pathfinder 2e', name: "Leprechaun", AC: 18, HP: 25, DEX: 11, HPD: [1, 11, 0] },
  { id: 1731, ruleset: 'pathfinder 2e', name: "Lerritan", AC: 46, HP: 490, DEX: 35, HPD: [1, 35, 0] },
  { id: 1732, ruleset: 'pathfinder 2e', name: "Lesser Death", AC: 39, HP: 255, DEX: 32, HPD: [1, 32, 0] },
  { id: 1733, ruleset: 'pathfinder 2e', name: "Lesser Jabberwock", AC: 47, HP: 430, DEX: 36, HPD: [1, 36, 0] },
  { id: 1734, ruleset: 'pathfinder 2e', name: "Lesser Manifestation Of Dahak", AC: 50, HP: 500, DEX: 39, HPD: [1, 39, 0] },
  { id: 1735, ruleset: 'pathfinder 2e', name: "Leucrotta", AC: 21, HP: 85, DEX: 11, HPD: [1, 11, 0] },
  { id: 1736, ruleset: 'pathfinder 2e', name: "Leukodaemon", AC: 28, HP: 155, DEX: 20, HPD: [1, 20, 0] },
  { id: 1737, ruleset: 'pathfinder 2e', name: "Levaloch", AC: 25, HP: 105, DEX: 16, HPD: [1, 16, 0] },
  { id: 1738, ruleset: 'pathfinder 2e', name: "Leydroth", AC: 40, HP: 315, DEX: 30, HPD: [1, 30, 0] },
  { id: 1739, ruleset: 'pathfinder 2e', name: "Lich", AC: 31, HP: 190, DEX: 20, HPD: [1, 20, 0] },
  { id: 1740, ruleset: 'pathfinder 2e', name: "Lickweed", AC: 20, HP: 65, DEX: 12, HPD: [1, 12, 0] },
  { id: 1741, ruleset: 'pathfinder 2e', name: "Lifeleecher Brawler", AC: 25, HP: 165, DEX: 15, HPD: [1, 15, 0] },
  { id: 1742, ruleset: 'pathfinder 2e', name: "Lignified Adamantine Golem", AC: 40, HP: 255, DEX: 26, HPD: [1, 26, 0] },
  { id: 1743, ruleset: 'pathfinder 2e', name: "Lillend", AC: 25, HP: 145, DEX: 16, HPD: [1, 16, 0] },
  { id: 1744, ruleset: 'pathfinder 2e', name: "Lintwerth", AC: 32, HP: 180, DEX: 22, HPD: [1, 22, 0] },
  { id: 1745, ruleset: 'pathfinder 2e', name: "Lion", AC: 19, HP: 45, DEX: 9, HPD: [1, 9, 0] },
  { id: 1746, ruleset: 'pathfinder 2e', name: "Lion Visitant", AC: 22, HP: 95, DEX: 13, HPD: [1, 13, 0] },
  { id: 1747, ruleset: 'pathfinder 2e', name: "Little Man in the Woods", AC: 24, HP: 95, DEX: 12, HPD: [1, 12, 0] },
  { id: 1748, ruleset: 'pathfinder 2e', name: "Living Boulder", AC: 17, HP: 36, DEX: 6, HPD: [1, 6, 0] },
  { id: 1749, ruleset: 'pathfinder 2e', name: "Living Landslide", AC: 21, HP: 90, DEX: 12, HPD: [1, 12, 0] },
  { id: 1750, ruleset: 'pathfinder 2e', name: "Living Sap", AC: 13, HP: 175, DEX: 7, HPD: [1, 7, 0] },
  { id: 1751, ruleset: 'pathfinder 2e', name: "Living Thunderclap", AC: 22, HP: 50, DEX: 9, HPD: [1, 9, 0] },
  { id: 1752, ruleset: 'pathfinder 2e', name: "Living Waterfall", AC: 20, HP: 90, DEX: 10, HPD: [1, 10, 0] },
  { id: 1753, ruleset: 'pathfinder 2e', name: "Living Whirlwind", AC: 24, HP: 50, DEX: 10, HPD: [1, 10, 0] },
  { id: 1754, ruleset: 'pathfinder 2e', name: "Living Wildfire", AC: 22, HP: 80, DEX: 10, HPD: [1, 10, 0] },
  { id: 1755, ruleset: 'pathfinder 2e', name: "Living-Sun Spire", AC: 36, HP: 200, DEX: 21, HPD: [1, 21, 0] },
  { id: 1756, ruleset: 'pathfinder 2e', name: "Lizardfolk Defender", AC: 16, HP: 21, DEX: 7, HPD: [1, 7, 0] },
  { id: 1757, ruleset: 'pathfinder 2e', name: "Lizardfolk Scout", AC: 17, HP: 17, DEX: 8, HPD: [1, 8, 0] },
  { id: 1758, ruleset: 'pathfinder 2e', name: "Lizardfolk Stargazer", AC: 17, HP: 30, DEX: 8, HPD: [1, 8, 0] },
  { id: 1759, ruleset: 'pathfinder 2e', name: "Lizardfolk Warrior", AC: 17, HP: 36, DEX: 7, HPD: [1, 7, 0] },
  { id: 1760, ruleset: 'pathfinder 2e', name: "Llorona", AC: 32, HP: 165, DEX: 20, HPD: [1, 20, 0] },
  { id: 1761, ruleset: 'pathfinder 2e', name: "Loblobi", AC: 16, HP: 25, DEX: 6, HPD: [1, 6, 0] },
  { id: 1762, ruleset: 'pathfinder 2e', name: "Locathah Hunter", AC: 20, HP: 38, DEX: 9, HPD: [1, 9, 0] },
  { id: 1763, ruleset: 'pathfinder 2e', name: "Long-Horned Bison", AC: 23, HP: 110, DEX: 13, HPD: [1, 13, 0] },
  { id: 1764, ruleset: 'pathfinder 2e', name: "Long-Horned Bison Beheaded", AC: 18, HP: 32, DEX: 7, HPD: [1, 7, 0] },
  { id: 1765, ruleset: 'pathfinder 2e', name: "Lophiithu", AC: 43, HP: 400, DEX: 38, HPD: [1, 38, 0] },
  { id: 1766, ruleset: 'pathfinder 2e', name: "Loreavor", AC: 28, HP: 155, DEX: 19, HPD: [1, 19, 0] },
  { id: 1767, ruleset: 'pathfinder 2e', name: "Lorthact", AC: 38, HP: 300, DEX: 27, HPD: [1, 27, 0] },
  { id: 1768, ruleset: 'pathfinder 2e', name: "Love Siktempora", AC: 38, HP: 210, DEX: 28, HPD: [1, 28, 0] },
  { id: 1769, ruleset: 'pathfinder 2e', name: "Lovelorn", AC: 21, HP: 60, DEX: 10, HPD: [1, 10, 0] },
  { id: 1770, ruleset: 'pathfinder 2e', name: "Luminous Ooze", AC: 11, HP: 80, DEX: 6, HPD: [1, 6, 0] },
  { id: 1771, ruleset: 'pathfinder 2e', name: "Lunar Naga", AC: 24, HP: 100, DEX: 16, HPD: [1, 16, 0] },
  { id: 1772, ruleset: 'pathfinder 2e', name: "Lurker In Light", AC: 22, HP: 72, DEX: 13, HPD: [1, 13, 0] },
  { id: 1773, ruleset: 'pathfinder 2e', name: "Lusca", AC: 40, HP: 320, DEX: 31, HPD: [1, 31, 0] },
  { id: 1774, ruleset: 'pathfinder 2e', name: "Lyrakien", AC: 17, HP: 25, DEX: 8, HPD: [1, 8, 0] },
  { id: 1775, ruleset: 'pathfinder 2e', name: "Lyrt Cozurn", AC: 35, HP: 280, DEX: 27, HPD: [1, 27, 0] },
  { id: 1776, ruleset: 'pathfinder 2e', name: "Maftet Guardian", AC: 23, HP: 92, DEX: 14, HPD: [1, 14, 0] },
  { id: 1777, ruleset: 'pathfinder 2e', name: "Magma Scorpion", AC: 28, HP: 155, DEX: 18, HPD: [1, 18, 0] },
  { id: 1778, ruleset: 'pathfinder 2e', name: "Maharaja", AC: 45, HP: 320, DEX: 37, HPD: [1, 37, 0] },
  { id: 1779, ruleset: 'pathfinder 2e', name: "Malarunk", AC: 22, HP: 63, DEX: 13, HPD: [1, 13, 0] },
  { id: 1780, ruleset: 'pathfinder 2e', name: "Maliadi", AC: 40, HP: 285, DEX: 29, HPD: [1, 29, 0] },
  { id: 1781, ruleset: 'pathfinder 2e', name: "Mamlambo", AC: 28, HP: 155, DEX: 19, HPD: [1, 19, 0] },
  { id: 1782, ruleset: 'pathfinder 2e', name: "Mammoth", AC: 29, HP: 190, DEX: 18, HPD: [1, 18, 0] },
  { id: 1783, ruleset: 'pathfinder 2e', name: "Mammoth Turtle", AC: 36, HP: 270, DEX: 24, HPD: [1, 24, 0] },
  { id: 1784, ruleset: 'pathfinder 2e', name: "Manananggal", AC: 26, HP: 180, DEX: 16, HPD: [1, 16, 0] },
  { id: 1785, ruleset: 'pathfinder 2e', name: "Mandragora", AC: 21, HP: 60, DEX: 11, HPD: [1, 11, 0] },
  { id: 1786, ruleset: 'pathfinder 2e', name: "Mandragora Swarm", AC: 42, HP: 230, DEX: 30, HPD: [1, 30, 0] },
  { id: 1787, ruleset: 'pathfinder 2e', name: "Manifestation Of Dahak", AC: 52, HP: 600, DEX: 46, HPD: [1, 46, 0] },
  { id: 1788, ruleset: 'pathfinder 2e', name: "Manta Ray", AC: 16, HP: 24, DEX: 6, HPD: [1, 6, 0] },
  { id: 1789, ruleset: 'pathfinder 2e', name: "Manticore", AC: 23, HP: 90, DEX: 14, HPD: [1, 14, 0] },
  { id: 1790, ruleset: 'pathfinder 2e', name: "Manticore Paaridar", AC: 26, HP: 110, DEX: 18, HPD: [1, 18, 0] },
  { id: 1791, ruleset: 'pathfinder 2e', name: "Mari Lwyd", AC: 30, HP: 190, DEX: 21, HPD: [1, 21, 0] },
  { id: 1792, ruleset: 'pathfinder 2e', name: "Marid", AC: 28, HP: 145, DEX: 18, HPD: [1, 18, 0] },
  { id: 1793, ruleset: 'pathfinder 2e', name: "Marilith", AC: 40, HP: 380, DEX: 30, HPD: [1, 30, 0] },
  { id: 1794, ruleset: 'pathfinder 2e', name: "Marrmora", AC: 37, HP: 280, DEX: 27, HPD: [1, 27, 0] },
  { id: 1795, ruleset: 'pathfinder 2e', name: "Marsh Giant", AC: 27, HP: 150, DEX: 16, HPD: [1, 16, 0] },
  { id: 1796, ruleset: 'pathfinder 2e', name: "Marut", AC: 37, HP: 230, DEX: 26, HPD: [1, 26, 0] },
  { id: 1797, ruleset: 'pathfinder 2e', name: "Mastiff Of Tindalos", AC: 37, HP: 210, DEX: 29, HPD: [1, 29, 0] },
  { id: 1798, ruleset: 'pathfinder 2e', name: "Mastodon", AC: 26, HP: 175, DEX: 17, HPD: [1, 17, 0] },
  { id: 1799, ruleset: 'pathfinder 2e', name: "Masu", AC: 14, HP: 6, DEX: 8, HPD: [1, 8, 0] },
  { id: 1800, ruleset: 'pathfinder 2e', name: "Meat Guardian", AC: 20, HP: 60, DEX: 7, HPD: [1, 7, 0] },
  { id: 1801, ruleset: 'pathfinder 2e', name: "Mechanical Carny", AC: 17, HP: 33, DEX: 6, HPD: [1, 6, 0] },
  { id: 1802, ruleset: 'pathfinder 2e', name: "Mechanical Laborer", AC: 22, HP: 55, DEX: 11, HPD: [1, 11, 0] },
  { id: 1803, ruleset: 'pathfinder 2e', name: "Medusa", AC: 25, HP: 105, DEX: 16, HPD: [1, 16, 0] },
  { id: 1804, ruleset: 'pathfinder 2e', name: "Megalania", AC: 25, HP: 125, DEX: 15, HPD: [1, 15, 0] },
  { id: 1805, ruleset: 'pathfinder 2e', name: "Megalictis", AC: 19, HP: 42, DEX: 10, HPD: [1, 10, 0] },
  { id: 1806, ruleset: 'pathfinder 2e', name: "Megaloceros", AC: 21, HP: 58, DEX: 10, HPD: [1, 10, 0] },
  { id: 1807, ruleset: 'pathfinder 2e', name: "Megalodon", AC: 27, HP: 180, DEX: 20, HPD: [1, 20, 0] },
  { id: 1808, ruleset: 'pathfinder 2e', name: "Megaprimatus", AC: 26, HP: 150, DEX: 15, HPD: [1, 15, 0] },
  { id: 1809, ruleset: 'pathfinder 2e', name: "Megatherium", AC: 21, HP: 85, DEX: 13, HPD: [1, 13, 0] },
  { id: 1810, ruleset: 'pathfinder 2e', name: "Meladaemon", AC: 31, HP: 225, DEX: 21, HPD: [1, 21, 0] },
  { id: 1811, ruleset: 'pathfinder 2e', name: "Melfesh Monster", AC: 23, HP: 78, DEX: 14, HPD: [1, 14, 0] },
  { id: 1812, ruleset: 'pathfinder 2e', name: "Melianse", AC: 21, HP: 78, DEX: 11, HPD: [1, 11, 0] },
  { id: 1813, ruleset: 'pathfinder 2e', name: "Melixie", AC: 16, HP: 17, DEX: 4, HPD: [1, 4, 0] },
  { id: 1814, ruleset: 'pathfinder 2e', name: "Melodic Squalls", AC: 39, HP: 280, DEX: 30, HPD: [1, 30, 0] },
  { id: 1815, ruleset: 'pathfinder 2e', name: "Melody On The Wind", AC: 30, HP: 170, DEX: 21, HPD: [1, 21, 0] },
  { id: 1816, ruleset: 'pathfinder 2e', name: "Mengkare", AC: 50, HP: 575, DEX: 40, HPD: [1, 40, 0] },
  { id: 1817, ruleset: 'pathfinder 2e', name: "Merfolk Warrior", AC: 18, HP: 19, DEX: 6, HPD: [1, 6, 0] },
  { id: 1818, ruleset: 'pathfinder 2e', name: "Merfolk Wavecaller", AC: 17, HP: 30, DEX: 8, HPD: [1, 8, 0] },
  { id: 1819, ruleset: 'pathfinder 2e', name: "Mezlan", AC: 31, HP: 260, DEX: 25, HPD: [1, 25, 0] },
  { id: 1820, ruleset: 'pathfinder 2e', name: "Mi-Go", AC: 24, HP: 120, DEX: 14, HPD: [1, 14, 0] },
  { id: 1821, ruleset: 'pathfinder 2e', name: "Millindemalion", AC: 34, HP: 275, DEX: 23, HPD: [1, 23, 0] },
  { id: 1822, ruleset: 'pathfinder 2e', name: "Mimic", AC: 20, HP: 75, DEX: 9, HPD: [1, 9, 0] },
  { id: 1823, ruleset: 'pathfinder 2e', name: "Minchgorm", AC: 42, HP: 440, DEX: 30, HPD: [1, 30, 0] },
  { id: 1824, ruleset: 'pathfinder 2e', name: "Minister Of Tumult", AC: 36, HP: 190, DEX: 28, HPD: [1, 28, 0] },
  { id: 1825, ruleset: 'pathfinder 2e', name: "Minognos-Ushad", AC: 43, HP: 295, DEX: 30, HPD: [1, 30, 0] },
  { id: 1826, ruleset: 'pathfinder 2e', name: "Minotaur", AC: 20, HP: 70, DEX: 12, HPD: [1, 12, 0] },
  { id: 1827, ruleset: 'pathfinder 2e', name: "Misery Siktempora", AC: 32, HP: 160, DEX: 25, HPD: [1, 25, 0] },
  { id: 1828, ruleset: 'pathfinder 2e', name: "Mist Stalker", AC: 20, HP: 58, DEX: 13, HPD: [1, 13, 0] },
  { id: 1829, ruleset: 'pathfinder 2e', name: "Mitflit", AC: 15, HP: 10, DEX: 4, HPD: [1, 4, 0] },
  { id: 1830, ruleset: 'pathfinder 2e', name: "Mithral Golem", AC: 40, HP: 220, DEX: 26, HPD: [1, 26, 0] },
  { id: 1831, ruleset: 'pathfinder 2e', name: "Mix Couatl", AC: 27, HP: 135, DEX: 19, HPD: [1, 19, 0] },
  { id: 1832, ruleset: 'pathfinder 2e', name: "Mobogo", AC: 29, HP: 160, DEX: 21, HPD: [1, 21, 0] },
  { id: 1833, ruleset: 'pathfinder 2e', name: "Mohrg", AC: 28, HP: 120, DEX: 17, HPD: [1, 17, 0] },
  { id: 1834, ruleset: 'pathfinder 2e', name: "Mokele-Mbembe", AC: 27, HP: 172, DEX: 15, HPD: [1, 15, 0] },
  { id: 1835, ruleset: 'pathfinder 2e', name: "Monadic Deva", AC: 33, HP: 245, DEX: 25, HPD: [1, 25, 0] },
  { id: 1836, ruleset: 'pathfinder 2e', name: "Monkey", AC: 14, HP: 9, DEX: 6, HPD: [1, 6, 0] },
  { id: 1837, ruleset: 'pathfinder 2e', name: "Monkey Swarm", AC: 16, HP: 20, DEX: 8, HPD: [1, 8, 0] },
  { id: 1838, ruleset: 'pathfinder 2e', name: "Moon Hag", AC: 29, HP: 190, DEX: 22, HPD: [1, 22, 0] },
  { id: 1839, ruleset: 'pathfinder 2e', name: "Moonflower", AC: 24, HP: 120, DEX: 16, HPD: [1, 16, 0] },
  { id: 1840, ruleset: 'pathfinder 2e', name: "Moose", AC: 18, HP: 50, DEX: 9, HPD: [1, 9, 0] },
  { id: 1841, ruleset: 'pathfinder 2e', name: "Morlock", AC: 17, HP: 38, DEX: 7, HPD: [1, 7, 0] },
  { id: 1842, ruleset: 'pathfinder 2e', name: "Morlock Cultist", AC: 21, HP: 58, DEX: 11, HPD: [1, 11, 0] },
  { id: 1843, ruleset: 'pathfinder 2e', name: "Morlock Engineer", AC: 18, HP: 46, DEX: 8, HPD: [1, 8, 0] },
  { id: 1844, ruleset: 'pathfinder 2e', name: "Morlock Scavenger", AC: 16, HP: 20, DEX: 6, HPD: [1, 6, 0] },
  { id: 1845, ruleset: 'pathfinder 2e', name: "Morrigna", AC: 38, HP: 240, DEX: 28, HPD: [1, 28, 0] },
  { id: 1846, ruleset: 'pathfinder 2e', name: "Morrowkin", AC: 34, HP: 320, DEX: 24, HPD: [1, 24, 0] },
  { id: 1847, ruleset: 'pathfinder 2e', name: "Mosghuta, Boss Cow", AC: 15, HP: 70, DEX: 4, HPD: [1, 4, 0] },
  { id: 1848, ruleset: 'pathfinder 2e', name: "Mosquito Witch", AC: 30, HP: 180, DEX: 22, HPD: [1, 22, 0] },
  { id: 1849, ruleset: 'pathfinder 2e', name: "Mothman", AC: 26, HP: 90, DEX: 15, HPD: [1, 15, 0] },
  { id: 1850, ruleset: 'pathfinder 2e', name: "Movanic Deva", AC: 30, HP: 195, DEX: 22, HPD: [1, 22, 0] },
  { id: 1851, ruleset: 'pathfinder 2e', name: "Mpeshi", AC: 24, HP: 110, DEX: 14, HPD: [1, 14, 0] },
  { id: 1852, ruleset: 'pathfinder 2e', name: "Mu Spore", AC: 45, HP: 350, DEX: 36, HPD: [1, 36, 0] },
  { id: 1853, ruleset: 'pathfinder 2e', name: "Muckish Creep", AC: 27, HP: 160, DEX: 18, HPD: [1, 18, 0] },
  { id: 1854, ruleset: 'pathfinder 2e', name: "Mudwretch", AC: 16, HP: 40, DEX: 9, HPD: [1, 9, 0] },
  { id: 1855, ruleset: 'pathfinder 2e', name: "Mukradi", AC: 37, HP: 300, DEX: 24, HPD: [1, 24, 0] },
  { id: 1856, ruleset: 'pathfinder 2e', name: "Mulventok", AC: 24, HP: 115, DEX: 15, HPD: [1, 15, 0] },
  { id: 1857, ruleset: 'pathfinder 2e', name: "Mummified Cat", AC: 15, HP: 17, DEX: 7, HPD: [1, 7, 0] },
  { id: 1858, ruleset: 'pathfinder 2e', name: "Mummy Guardian", AC: 23, HP: 110, DEX: 16, HPD: [1, 16, 0] },
  { id: 1859, ruleset: 'pathfinder 2e', name: "Mummy Pharaoh", AC: 27, HP: 165, DEX: 20, HPD: [1, 20, 0] },
  { id: 1860, ruleset: 'pathfinder 2e', name: "Mummy Prophet of Set", AC: 33, HP: 250, DEX: 23, HPD: [1, 23, 0] },
  { id: 1861, ruleset: 'pathfinder 2e', name: "Munagola", AC: 31, HP: 165, DEX: 24, HPD: [1, 24, 0] },
  { id: 1862, ruleset: 'pathfinder 2e', name: "Munavri Spellblade", AC: 18, HP: 28, DEX: 7, HPD: [1, 7, 0] },
  { id: 1863, ruleset: 'pathfinder 2e', name: "Murder Of Crows", AC: 27, HP: 150, DEX: 19, HPD: [1, 19, 0] },
  { id: 1864, ruleset: 'pathfinder 2e', name: "Muse Phantom", AC: 21, HP: 50, DEX: 10, HPD: [1, 10, 0] },
  { id: 1865, ruleset: 'pathfinder 2e', name: "Mutant Bat", AC: 22, HP: 75, DEX: 16, HPD: [1, 16, 0] },
  { id: 1866, ruleset: 'pathfinder 2e', name: "Mutant Desert Drake", AC: 28, HP: 150, DEX: 17, HPD: [1, 17, 0] },
  { id: 1867, ruleset: 'pathfinder 2e', name: "Mutant Giant Toad", AC: 18, HP: 44, DEX: 9, HPD: [1, 9, 0] },
  { id: 1868, ruleset: 'pathfinder 2e', name: "Mutant Gnoll Hulk", AC: 26, HP: 195, DEX: 17, HPD: [1, 17, 0] },
  { id: 1869, ruleset: 'pathfinder 2e', name: "Mutated Sewer Ooze", AC: 16, HP: 45, DEX: 10, HPD: [1, 10, 0] },
  { id: 1870, ruleset: 'pathfinder 2e', name: "Muurfeli", AC: 39, HP: 300, DEX: 30, HPD: [1, 30, 0] },
  { id: 1871, ruleset: 'pathfinder 2e', name: "Myceloid", AC: 20, HP: 70, DEX: 10, HPD: [1, 10, 0] },
  { id: 1872, ruleset: 'pathfinder 2e', name: "Myrucarx", AC: 41, HP: 340, DEX: 33, HPD: [1, 33, 0] },
  { id: 1873, ruleset: 'pathfinder 2e', name: "Nabasu", AC: 27, HP: 165, DEX: 17, HPD: [1, 17, 0] },
  { id: 1874, ruleset: 'pathfinder 2e', name: "Nagaji Soldier", AC: 18, HP: 28, DEX: 8, HPD: [1, 8, 0] },
  { id: 1875, ruleset: 'pathfinder 2e', name: "Naiad", AC: 16, HP: 20, DEX: 6, HPD: [1, 6, 0] },
  { id: 1876, ruleset: 'pathfinder 2e', name: "Naiad Queen", AC: 26, HP: 100, DEX: 18, HPD: [1, 18, 0] },
  { id: 1877, ruleset: 'pathfinder 2e', name: "Najra Lizard", AC: 21, HP: 60, DEX: 11, HPD: [1, 11, 0] },
  { id: 1878, ruleset: 'pathfinder 2e', name: "Nalfeshnee", AC: 34, HP: 365, DEX: 25, HPD: [1, 25, 0] },
  { id: 1879, ruleset: 'pathfinder 2e', name: "Namorrodor", AC: 22, HP: 85, DEX: 11, HPD: [1, 11, 0] },
  { id: 1880, ruleset: 'pathfinder 2e', name: "Narlynark", AC: 22, HP: 75, DEX: 13, HPD: [1, 13, 0] },
  { id: 1881, ruleset: 'pathfinder 2e', name: "Narwhal", AC: 18, HP: 50, DEX: 10, HPD: [1, 10, 0] },
  { id: 1882, ruleset: 'pathfinder 2e', name: "Nasurgeth", AC: 45, HP: 510, DEX: 36, HPD: [1, 36, 0] },
  { id: 1883, ruleset: 'pathfinder 2e', name: "Naunet", AC: 24, HP: 120, DEX: 14, HPD: [1, 14, 0] },
  { id: 1884, ruleset: 'pathfinder 2e', name: "Necrohulk Flailer", AC: 32, HP: 295, DEX: 23, HPD: [1, 23, 0] },
  { id: 1885, ruleset: 'pathfinder 2e', name: "Necrohulk Smasher", AC: 36, HP: 345, DEX: 25, HPD: [1, 25, 0] },
  { id: 1886, ruleset: 'pathfinder 2e', name: "Necrohusk", AC: 22, HP: 75, DEX: 14, HPD: [1, 14, 0] },
  { id: 1887, ruleset: 'pathfinder 2e', name: "Necromunculus", AC: 16, HP: 20, DEX: 5, HPD: [1, 5, 0] },
  { id: 1888, ruleset: 'pathfinder 2e', name: "Necrophidius", AC: 19, HP: 50, DEX: 9, HPD: [1, 9, 0] },
  { id: 1889, ruleset: 'pathfinder 2e', name: "Nemhaith", AC: 36, HP: 255, DEX: 31, HPD: [1, 31, 0] },
  { id: 1890, ruleset: 'pathfinder 2e', name: "Nenchuuj", AC: 43, HP: 355, DEX: 33, HPD: [1, 33, 0] },
  { id: 1891, ruleset: 'pathfinder 2e', name: "Neothelid", AC: 37, HP: 345, DEX: 29, HPD: [1, 29, 0] },
  { id: 1892, ruleset: 'pathfinder 2e', name: "Nereid", AC: 30, HP: 175, DEX: 20, HPD: [1, 20, 0] },
  { id: 1893, ruleset: 'pathfinder 2e', name: "Nessian Warhound", AC: 28, HP: 150, DEX: 19, HPD: [1, 19, 0] },
  { id: 1894, ruleset: 'pathfinder 2e', name: "Ngara", AC: 33, HP: 215, DEX: 22, HPD: [1, 22, 0] },
  { id: 1895, ruleset: 'pathfinder 2e', name: "Night Hag", AC: 28, HP: 170, DEX: 18, HPD: [1, 18, 0] },
  { id: 1896, ruleset: 'pathfinder 2e', name: "Nightgaunt", AC: 21, HP: 60, DEX: 10, HPD: [1, 10, 0] },
  { id: 1897, ruleset: 'pathfinder 2e', name: "Nightmarchers", AC: 30, HP: 240, DEX: 27, HPD: [1, 27, 0] },
  { id: 1898, ruleset: 'pathfinder 2e', name: "Nightmare", AC: 24, HP: 100, DEX: 14, HPD: [1, 14, 0] },
  { id: 1899, ruleset: 'pathfinder 2e', name: "Nightmare Rook", AC: 46, HP: 380, DEX: 38, HPD: [1, 38, 0] },
  { id: 1900, ruleset: 'pathfinder 2e', name: "Nikaramsa", AC: 35, HP: 315, DEX: 25, HPD: [1, 25, 0] },
  { id: 1901, ruleset: 'pathfinder 2e', name: "Nilith", AC: 32, HP: 150, DEX: 19, HPD: [1, 19, 0] },
  { id: 1902, ruleset: 'pathfinder 2e', name: "Niodrhast", AC: 43, HP: 445, DEX: 33, HPD: [1, 33, 0] },
  { id: 1903, ruleset: 'pathfinder 2e', name: "Nixie", AC: 16, HP: 25, DEX: 6, HPD: [1, 6, 0] },
  { id: 1904, ruleset: 'pathfinder 2e', name: "Nkiruka", AC: 36, HP: 260, DEX: 25, HPD: [1, 25, 0] },
  { id: 1905, ruleset: 'pathfinder 2e', name: "Norn", AC: 46, HP: 375, DEX: 41, HPD: [1, 41, 0] },
  { id: 1906, ruleset: 'pathfinder 2e', name: "Nosferatu Malefactor", AC: 30, HP: 135, DEX: 19, HPD: [1, 19, 0] },
  { id: 1907, ruleset: 'pathfinder 2e', name: "Nosferatu Overlord", AC: 37, HP: 216, DEX: 27, HPD: [1, 27, 0] },
  { id: 1908, ruleset: 'pathfinder 2e', name: "Nosferatu Thrall", AC: 27, HP: 135, DEX: 16, HPD: [1, 16, 0] },
  { id: 1909, ruleset: 'pathfinder 2e', name: "Nosoi", AC: 16, HP: 18, DEX: 6, HPD: [1, 6, 0] },
  { id: 1910, ruleset: 'pathfinder 2e', name: "Nuckelavee", AC: 28, HP: 190, DEX: 16, HPD: [1, 16, 0] },
  { id: 1911, ruleset: 'pathfinder 2e', name: "Nucol", AC: 20, HP: 75, DEX: 11, HPD: [1, 11, 0] },
  { id: 1912, ruleset: 'pathfinder 2e', name: "Nuglub", AC: 18, HP: 34, DEX: 5, HPD: [1, 5, 0] },
  { id: 1913, ruleset: 'pathfinder 2e', name: "Nydazuul", AC: 31, HP: 180, DEX: 20, HPD: [1, 20, 0] },
  { id: 1914, ruleset: 'pathfinder 2e', name: "Nyktera", AC: 15, HP: 10, DEX: 6, HPD: [1, 6, 0] },
  { id: 1915, ruleset: 'pathfinder 2e', name: "Nyogoth", AC: 29, HP: 175, DEX: 19, HPD: [1, 19, 0] },
  { id: 1916, ruleset: 'pathfinder 2e', name: "Nyrissa", AC: 49, HP: 495, DEX: 41, HPD: [1, 41, 0] },
  { id: 1917, ruleset: 'pathfinder 2e', name: "Obrousian", AC: 36, HP: 250, DEX: 26, HPD: [1, 26, 0] },
  { id: 1918, ruleset: 'pathfinder 2e', name: "Obsidian Golem", AC: 40, HP: 230, DEX: 28, HPD: [1, 28, 0] },
  { id: 1919, ruleset: 'pathfinder 2e', name: "Ochre Jelly", AC: 12, HP: 150, DEX: 7, HPD: [1, 7, 0] },
  { id: 1920, ruleset: 'pathfinder 2e', name: "Ofalth", AC: 31, HP: 170, DEX: 18, HPD: [1, 18, 0] },
  { id: 1921, ruleset: 'pathfinder 2e', name: "Ogre Boss", AC: 25, HP: 130, DEX: 12, HPD: [1, 12, 0] },
  { id: 1922, ruleset: 'pathfinder 2e', name: "Ogre Glutton", AC: 18, HP: 70, DEX: 6, HPD: [1, 6, 0] },
  { id: 1923, ruleset: 'pathfinder 2e', name: "Ogre Hurler", AC: 19, HP: 65, DEX: 10, HPD: [1, 10, 0] },
  { id: 1924, ruleset: 'pathfinder 2e', name: "Ogre Spider", AC: 23, HP: 70, DEX: 13, HPD: [1, 13, 0] },
  { id: 1925, ruleset: 'pathfinder 2e', name: "Ogre Warrior", AC: 17, HP: 50, DEX: 5, HPD: [1, 5, 0] },
  { id: 1926, ruleset: 'pathfinder 2e', name: "Oil Living Graffiti", AC: 19, HP: 50, DEX: 9, HPD: [1, 9, 0] },
  { id: 1927, ruleset: 'pathfinder 2e', name: "Old Crackjaw", AC: 23, HP: 78, DEX: 11, HPD: [1, 11, 0] },
  { id: 1928, ruleset: 'pathfinder 2e', name: "Old Man Statue", AC: 36, HP: 160, DEX: 24, HPD: [1, 24, 0] },
  { id: 1929, ruleset: 'pathfinder 2e', name: "Old Thrasher", AC: 26, HP: 140, DEX: 16, HPD: [1, 16, 0] },
  { id: 1930, ruleset: 'pathfinder 2e', name: "Olethrodaemon", AC: 44, HP: 450, DEX: 33, HPD: [1, 33, 0] },
  { id: 1931, ruleset: 'pathfinder 2e', name: "Omox", AC: 25, HP: 395, DEX: 22, HPD: [1, 22, 0] },
  { id: 1932, ruleset: 'pathfinder 2e', name: "Onidoshi", AC: 27, HP: 125, DEX: 17, HPD: [1, 17, 0] },
  { id: 1933, ruleset: 'pathfinder 2e', name: "Onryo", AC: 32, HP: 180, DEX: 21, HPD: [1, 21, 0] },
  { id: 1934, ruleset: 'pathfinder 2e', name: "Ooze Mephit", AC: 14, HP: 24, DEX: 3, HPD: [1, 3, 0] },
  { id: 1935, ruleset: 'pathfinder 2e', name: "Opkherab", AC: 19, HP: 50, DEX: 10, HPD: [1, 10, 0] },
  { id: 1936, ruleset: 'pathfinder 2e', name: "Orc Brute", AC: 15, HP: 15, DEX: 5, HPD: [1, 5, 0] },
  { id: 1937, ruleset: 'pathfinder 2e', name: "Orc Warchief", AC: 19, HP: 32, DEX: 11, HPD: [1, 11, 0] },
  { id: 1938, ruleset: 'pathfinder 2e', name: "Orc Warrior", AC: 18, HP: 23, DEX: 6, HPD: [1, 6, 0] },
  { id: 1939, ruleset: 'pathfinder 2e', name: "Orca", AC: 21, HP: 75, DEX: 12, HPD: [1, 12, 0] },
  { id: 1940, ruleset: 'pathfinder 2e', name: "Oread Guard", AC: 19, HP: 20, DEX: 7, HPD: [1, 7, 0] },
  { id: 1941, ruleset: 'pathfinder 2e', name: "Orochi", AC: 42, HP: 400, DEX: 33, HPD: [1, 33, 0] },
  { id: 1942, ruleset: 'pathfinder 2e', name: "Ostiarius", AC: 21, HP: 67, DEX: 15, HPD: [1, 15, 0] },
  { id: 1943, ruleset: 'pathfinder 2e', name: "Ostovite", AC: 15, HP: 30, DEX: 4, HPD: [1, 4, 0] },
  { id: 1944, ruleset: 'pathfinder 2e', name: "Osyluth", AC: 28, HP: 135, DEX: 21, HPD: [1, 21, 0] },
  { id: 1945, ruleset: 'pathfinder 2e', name: "Otyugh", AC: 20, HP: 70, DEX: 10, HPD: [1, 10, 0] },
  { id: 1946, ruleset: 'pathfinder 2e', name: "Ouroboros", AC: 45, HP: 500, DEX: 33, HPD: [1, 33, 0] },
  { id: 1947, ruleset: 'pathfinder 2e', name: "Overgrown Viper Vine", AC: 35, HP: 290, DEX: 24, HPD: [1, 24, 0] },
  { id: 1948, ruleset: 'pathfinder 2e', name: "Oversized Chimera", AC: 33, HP: 220, DEX: 23, HPD: [1, 23, 0] },
  { id: 1949, ruleset: 'pathfinder 2e', name: "Ovinnik", AC: 20, HP: 59, DEX: 14, HPD: [1, 14, 0] },
  { id: 1950, ruleset: 'pathfinder 2e', name: "Owb", AC: 24, HP: 90, DEX: 13, HPD: [1, 13, 0] },
  { id: 1951, ruleset: 'pathfinder 2e', name: "Owb Prophet", AC: 34, HP: 225, DEX: 24, HPD: [1, 24, 0] },
  { id: 1952, ruleset: 'pathfinder 2e', name: "Owlbear", AC: 21, HP: 70, DEX: 13, HPD: [1, 13, 0] },
  { id: 1953, ruleset: 'pathfinder 2e', name: "Pachycephalosaurus", AC: 19, HP: 65, DEX: 10, HPD: [1, 10, 0] },
  { id: 1954, ruleset: 'pathfinder 2e', name: "Pairaka", AC: 24, HP: 105, DEX: 15, HPD: [1, 15, 0] },
  { id: 1955, ruleset: 'pathfinder 2e', name: "Pakalchi", AC: 26, HP: 140, DEX: 18, HPD: [1, 18, 0] },
  { id: 1956, ruleset: 'pathfinder 2e', name: "Palace Skelm", AC: 27, HP: 155, DEX: 15, HPD: [1, 15, 0] },
  { id: 1957, ruleset: 'pathfinder 2e', name: "Pale Sovereign", AC: 36, HP: 298, DEX: 28, HPD: [1, 28, 0] },
  { id: 1958, ruleset: 'pathfinder 2e', name: "Pale Stranger", AC: 29, HP: 155, DEX: 19, HPD: [1, 19, 0] },
  { id: 1959, ruleset: 'pathfinder 2e', name: "Pegasus", AC: 19, HP: 55, DEX: 12, HPD: [1, 12, 0] },
  { id: 1960, ruleset: 'pathfinder 2e', name: "Peluda", AC: 30, HP: 170, DEX: 21, HPD: [1, 21, 0] },
  { id: 1961, ruleset: 'pathfinder 2e', name: "Penanggalan", AC: 22, HP: 83, DEX: 11, HPD: [1, 11, 0] },
  { id: 1962, ruleset: 'pathfinder 2e', name: "Peng", AC: 32, HP: 200, DEX: 25, HPD: [1, 25, 0] },
  { id: 1963, ruleset: 'pathfinder 2e', name: "Penqual", AC: 37, HP: 205, DEX: 27, HPD: [1, 27, 0] },
  { id: 1964, ruleset: 'pathfinder 2e', name: "Peri", AC: 36, HP: 255, DEX: 26, HPD: [1, 26, 0] },
  { id: 1965, ruleset: 'pathfinder 2e', name: "Peryton", AC: 21, HP: 60, DEX: 13, HPD: [1, 13, 0] },
  { id: 1966, ruleset: 'pathfinder 2e', name: "Pesgahi the Poisoner", AC: 21, HP: 60, DEX: 11, HPD: [1, 11, 0] },
  { id: 1967, ruleset: 'pathfinder 2e', name: "Petitioner", AC: 15, HP: 22, DEX: 7, HPD: [1, 7, 0] },
  { id: 1968, ruleset: 'pathfinder 2e', name: "Phalanx Of Phalanges", AC: 15, HP: 16, DEX: 5, HPD: [1, 5, 0] },
  { id: 1969, ruleset: 'pathfinder 2e', name: "Phantom Beast", AC: 26, HP: 120, DEX: 18, HPD: [1, 18, 0] },
  { id: 1970, ruleset: 'pathfinder 2e', name: "Phantom Knight", AC: 21, HP: 45, DEX: 13, HPD: [1, 13, 0] },
  { id: 1971, ruleset: 'pathfinder 2e', name: "Phistophilus", AC: 30, HP: 150, DEX: 21, HPD: [1, 21, 0] },
  { id: 1972, ruleset: 'pathfinder 2e', name: "Phoenix", AC: 36, HP: 300, DEX: 27, HPD: [1, 27, 0] },
  { id: 1973, ruleset: 'pathfinder 2e', name: "Phomandala", AC: 45, HP: 300, DEX: 32, HPD: [1, 32, 0] },
  { id: 1974, ruleset: 'pathfinder 2e', name: "Pinacosaurus", AC: 21, HP: 70, DEX: 10, HPD: [1, 10, 0] },
  { id: 1975, ruleset: 'pathfinder 2e', name: "Piranha Swarm", AC: 16, HP: 40, DEX: 9, HPD: [1, 9, 0] },
  { id: 1976, ruleset: 'pathfinder 2e', name: "Piscodaemon", AC: 28, HP: 200, DEX: 19, HPD: [1, 19, 0] },
  { id: 1977, ruleset: 'pathfinder 2e', name: "Pit Fiend", AC: 46, HP: 335, DEX: 37, HPD: [1, 37, 0] },
  { id: 1978, ruleset: 'pathfinder 2e', name: "Pitax Warden", AC: 33, HP: 215, DEX: 22, HPD: [1, 22, 0] },
  { id: 1979, ruleset: 'pathfinder 2e', name: "Pixie", AC: 23, HP: 40, DEX: 12, HPD: [1, 12, 0] },
  { id: 1980, ruleset: 'pathfinder 2e', name: "Plague Giant", AC: 35, HP: 285, DEX: 25, HPD: [1, 25, 0] },
  { id: 1981, ruleset: 'pathfinder 2e', name: "Plague Zombie", AC: 13, HP: 50, DEX: 3, HPD: [1, 3, 0] },
  { id: 1982, ruleset: 'pathfinder 2e', name: "Planar Terra-Cotta Soldier", AC: 30, HP: 250, DEX: 21, HPD: [1, 21, 0] },
  { id: 1983, ruleset: 'pathfinder 2e', name: "Planar Terra-Cotta Squadron", AC: 37, HP: 300, DEX: 24, HPD: [1, 24, 0] },
  { id: 1984, ruleset: 'pathfinder 2e', name: "Planetar", AC: 39, HP: 300, DEX: 28, HPD: [1, 28, 0] },
  { id: 1985, ruleset: 'pathfinder 2e', name: "Platecarpus", AC: 19, HP: 46, DEX: 9, HPD: [1, 9, 0] },
  { id: 1986, ruleset: 'pathfinder 2e', name: "Pleroma", AC: 45, HP: 335, DEX: 37, HPD: [1, 37, 0] },
  { id: 1987, ruleset: 'pathfinder 2e', name: "Pokmit Bloody-Pike", AC: 42, HP: 335, DEX: 31, HPD: [1, 31, 0] },
  { id: 1988, ruleset: 'pathfinder 2e', name: "Polar Bear", AC: 22, HP: 73, DEX: 12, HPD: [1, 12, 0] },
  { id: 1989, ruleset: 'pathfinder 2e', name: "Polong", AC: 24, HP: 100, DEX: 17, HPD: [1, 17, 0] },
  { id: 1990, ruleset: 'pathfinder 2e', name: "Poltergeist", AC: 22, HP: 55, DEX: 11, HPD: [1, 11, 0] },
  { id: 1991, ruleset: 'pathfinder 2e', name: "Popobawa", AC: 37, HP: 270, DEX: 25, HPD: [1, 25, 0] },
  { id: 1992, ruleset: 'pathfinder 2e', name: "Poracha", AC: 23, HP: 50, DEX: 10, HPD: [1, 10, 0] },
  { id: 1993, ruleset: 'pathfinder 2e', name: "Portal Eater", AC: 37, HP: 420, DEX: 30, HPD: [1, 30, 0] },
  { id: 1994, ruleset: 'pathfinder 2e', name: "Prachalla", AC: 30, HP: 205, DEX: 19, HPD: [1, 19, 0] },
  { id: 1995, ruleset: 'pathfinder 2e', name: "Prairie Drake", AC: 18, HP: 36, DEX: 6, HPD: [1, 6, 0] },
  { id: 1996, ruleset: 'pathfinder 2e', name: "Precentor", AC: 39, HP: 295, DEX: 32, HPD: [1, 32, 0] },
  { id: 1997, ruleset: 'pathfinder 2e', name: "Predatory Rabbit", AC: 16, HP: 6, DEX: 5, HPD: [1, 5, 0] },
  { id: 1998, ruleset: 'pathfinder 2e', name: "Priest of Kabriri", AC: 19, HP: 63, DEX: 13, HPD: [1, 13, 0] },
  { id: 1999, ruleset: 'pathfinder 2e', name: "Primal Bandersnatch", AC: 44, HP: 395, DEX: 33, HPD: [1, 33, 0] },
  { id: 2000, ruleset: 'pathfinder 2e', name: "Procyal", AC: 26, HP: 170, DEX: 18, HPD: [1, 18, 0] },
  { id: 2001, ruleset: 'pathfinder 2e', name: "Profane Ghoul", AC: 30, HP: 180, DEX: 19, HPD: [1, 19, 0] },
  { id: 2002, ruleset: 'pathfinder 2e', name: "Provincial Jiang-Shi", AC: 31, HP: 130, DEX: 22, HPD: [1, 22, 0] },
  { id: 2003, ruleset: 'pathfinder 2e', name: "Prowler Wight", AC: 28, HP: 155, DEX: 18, HPD: [1, 18, 0] },
  { id: 2004, ruleset: 'pathfinder 2e', name: "Pteranodon", AC: 18, HP: 35, DEX: 8, HPD: [1, 8, 0] },
  { id: 2005, ruleset: 'pathfinder 2e', name: "Pufferfish", AC: 12, HP: 12, DEX: 4, HPD: [1, 4, 0] },
  { id: 2006, ruleset: 'pathfinder 2e', name: "Pugwampi", AC: 16, HP: 17, DEX: 6, HPD: [1, 6, 0] },
  { id: 2007, ruleset: 'pathfinder 2e', name: "Pukwudgie", AC: 25, HP: 100, DEX: 17, HPD: [1, 17, 0] },
  { id: 2008, ruleset: 'pathfinder 2e', name: "Purple Worm", AC: 32, HP: 270, DEX: 20, HPD: [1, 20, 0] },
  { id: 2009, ruleset: 'pathfinder 2e', name: "Purrodaemon", AC: 43, HP: 335, DEX: 33, HPD: [1, 33, 0] },
  { id: 2010, ruleset: 'pathfinder 2e', name: "Pygmy Kaava", AC: 15, HP: 15, DEX: 6, HPD: [1, 6, 0] },
  { id: 2011, ruleset: 'pathfinder 2e', name: "Pyrogeist", AC: 29, HP: 130, DEX: 20, HPD: [1, 20, 0] },
  { id: 2012, ruleset: 'pathfinder 2e', name: "Pyronite Ooze", AC: 19, HP: 350, DEX: 14, HPD: [1, 14, 0] },
  { id: 2013, ruleset: 'pathfinder 2e', name: "Qormintur", AC: 39, HP: 295, DEX: 29, HPD: [1, 29, 0] },
  { id: 2014, ruleset: 'pathfinder 2e', name: "Quantium Golem", AC: 47, HP: 325, DEX: 36, HPD: [1, 36, 0] },
  { id: 2015, ruleset: 'pathfinder 2e', name: "Quasit", AC: 17, HP: 25, DEX: 7, HPD: [1, 7, 0] },
  { id: 2016, ruleset: 'pathfinder 2e', name: "Quatoid", AC: 25, HP: 120, DEX: 18, HPD: [1, 18, 0] },
  { id: 2017, ruleset: 'pathfinder 2e', name: "Queen Sluagh", AC: 41, HP: 417, DEX: 31, HPD: [1, 31, 0] },
  { id: 2018, ruleset: 'pathfinder 2e', name: "Quelaunt", AC: 36, HP: 305, DEX: 29, HPD: [1, 29, 0] },
  { id: 2019, ruleset: 'pathfinder 2e', name: "Quetz Couatl", AC: 30, HP: 175, DEX: 21, HPD: [1, 21, 0] },
  { id: 2020, ruleset: 'pathfinder 2e', name: "Quetzalcoatlus", AC: 25, HP: 110, DEX: 15, HPD: [1, 15, 0] },
  { id: 2021, ruleset: 'pathfinder 2e', name: "Quickling", AC: 22, HP: 25, DEX: 9, HPD: [1, 9, 0] },
  { id: 2022, ruleset: 'pathfinder 2e', name: "Quintessivore", AC: 28, HP: 180, DEX: 17, HPD: [1, 17, 0] },
  { id: 2023, ruleset: 'pathfinder 2e', name: "Quoppopak", AC: 31, HP: 195, DEX: 22, HPD: [1, 22, 0] },
  { id: 2024, ruleset: 'pathfinder 2e', name: "Qurashith", AC: 40, HP: 340, DEX: 33, HPD: [1, 33, 0] },
  { id: 2025, ruleset: 'pathfinder 2e', name: "Radiant Warden", AC: 40, HP: 300, DEX: 30, HPD: [1, 30, 0] },
  { id: 2026, ruleset: 'pathfinder 2e', name: "Raja Rakshasa", AC: 30, HP: 155, DEX: 19, HPD: [1, 19, 0] },
  { id: 2027, ruleset: 'pathfinder 2e', name: "Raktavarna", AC: 16, HP: 14, DEX: 6, HPD: [1, 6, 0] },
  { id: 2028, ruleset: 'pathfinder 2e', name: "Rancorous Priesthood", AC: 31, HP: 195, DEX: 21, HPD: [1, 21, 0] },
  { id: 2029, ruleset: 'pathfinder 2e', name: "Raptor Guard", AC: 34, HP: 240, DEX: 22, HPD: [1, 22, 0] },
  { id: 2030, ruleset: 'pathfinder 2e', name: "Rat Snake Swarm", AC: 16, HP: 20, DEX: 8, HPD: [1, 8, 0] },
  { id: 2031, ruleset: 'pathfinder 2e', name: "Rat Swarm", AC: 14, HP: 14, DEX: 5, HPD: [1, 5, 0] },
  { id: 2032, ruleset: 'pathfinder 2e', name: "Ratajin Mastermind", AC: 18, HP: 30, DEX: 7, HPD: [1, 7, 0] },
  { id: 2033, ruleset: 'pathfinder 2e', name: "Ratfolk Grenadier", AC: 21, HP: 60, DEX: 10, HPD: [1, 10, 0] },
  { id: 2034, ruleset: 'pathfinder 2e', name: "Ravager Of Tindalos", AC: 43, HP: 250, DEX: 31, HPD: [1, 31, 0] },
  { id: 2035, ruleset: 'pathfinder 2e', name: "Raven", AC: 15, HP: 7, DEX: 5, HPD: [1, 5, 0] },
  { id: 2036, ruleset: 'pathfinder 2e', name: "Raven Swarm", AC: 19, HP: 30, DEX: 9, HPD: [1, 9, 0] },
  { id: 2037, ruleset: 'pathfinder 2e', name: "Ravener", AC: 47, HP: 500, DEX: 37, HPD: [1, 37, 0] },
  { id: 2038, ruleset: 'pathfinder 2e', name: "Ravener Husk", AC: 35, HP: 325, DEX: 26, HPD: [1, 26, 0] },
  { id: 2039, ruleset: 'pathfinder 2e', name: "Ravenile", AC: 24, HP: 306, DEX: 25, HPD: [1, 25, 0] },
  { id: 2040, ruleset: 'pathfinder 2e', name: "Raw Nerve", AC: 26, HP: 150, DEX: 17, HPD: [1, 17, 0] },
  { id: 2041, ruleset: 'pathfinder 2e', name: "Reaper Skull Puffball", AC: 25, HP: 195, DEX: 15, HPD: [1, 15, 0] },
  { id: 2042, ruleset: 'pathfinder 2e', name: "Reborn Sun Hunter", AC: 30, HP: 195, DEX: 24, HPD: [1, 24, 0] },
  { id: 2043, ruleset: 'pathfinder 2e', name: "Reborn Sun Mage", AC: 28, HP: 195, DEX: 24, HPD: [1, 24, 0] },
  { id: 2044, ruleset: 'pathfinder 2e', name: "Reborn Sun Warrior", AC: 31, HP: 245, DEX: 21, HPD: [1, 21, 0] },
  { id: 2045, ruleset: 'pathfinder 2e', name: "Red Commander Ant", AC: 40, HP: 315, DEX: 30, HPD: [1, 30, 0] },
  { id: 2046, ruleset: 'pathfinder 2e', name: "Red Fox", AC: 17, HP: 5, DEX: 7, HPD: [1, 7, 0] },
  { id: 2047, ruleset: 'pathfinder 2e', name: "Red Guard Ant", AC: 37, HP: 275, DEX: 27, HPD: [1, 27, 0] },
  { id: 2048, ruleset: 'pathfinder 2e', name: "Red Queen", AC: 42, HP: 335, DEX: 30, HPD: [1, 30, 0] },
  { id: 2049, ruleset: 'pathfinder 2e', name: "Redacted, The Weaver In Dreams", AC: 39, HP: 310, DEX: 33, HPD: [1, 33, 0] },
  { id: 2050, ruleset: 'pathfinder 2e', name: "Redcap", AC: 21, HP: 60, DEX: 12, HPD: [1, 12, 0] },
  { id: 2051, ruleset: 'pathfinder 2e', name: "Reef Octopus", AC: 17, HP: 20, DEX: 7, HPD: [1, 7, 0] },
  { id: 2052, ruleset: 'pathfinder 2e', name: "Reefclaw", AC: 20, HP: 17, DEX: 8, HPD: [1, 8, 0] },
  { id: 2053, ruleset: 'pathfinder 2e', name: "Relictner Eroder", AC: 32, HP: 265, DEX: 23, HPD: [1, 23, 0] },
  { id: 2054, ruleset: 'pathfinder 2e', name: "Remnant of Barzillai", AC: 29, HP: 135, DEX: 22, HPD: [1, 22, 0] },
  { id: 2055, ruleset: 'pathfinder 2e', name: "Remorhaz", AC: 25, HP: 120, DEX: 14, HPD: [1, 14, 0] },
  { id: 2056, ruleset: 'pathfinder 2e', name: "Resin-Seep Xulgath", AC: 30, HP: 195, DEX: 19, HPD: [1, 19, 0] },
  { id: 2057, ruleset: 'pathfinder 2e', name: "Revenant", AC: 23, HP: 115, DEX: 14, HPD: [1, 14, 0] },
  { id: 2058, ruleset: 'pathfinder 2e', name: "Rezatha", AC: 39, HP: 330, DEX: 28, HPD: [1, 28, 0] },
  { id: 2059, ruleset: 'pathfinder 2e', name: "Rhevanna", AC: 48, HP: 400, DEX: 42, HPD: [1, 42, 0] },
  { id: 2060, ruleset: 'pathfinder 2e', name: "Rhinoceros", AC: 22, HP: 70, DEX: 9, HPD: [1, 9, 0] },
  { id: 2061, ruleset: 'pathfinder 2e', name: "Rhu-Chalik", AC: 23, HP: 95, DEX: 17, HPD: [1, 17, 0] },
  { id: 2062, ruleset: 'pathfinder 2e', name: "Riding Dog", AC: 16, HP: 20, DEX: 7, HPD: [1, 7, 0] },
  { id: 2063, ruleset: 'pathfinder 2e', name: "Riding Horse", AC: 16, HP: 22, DEX: 5, HPD: [1, 5, 0] },
  { id: 2064, ruleset: 'pathfinder 2e', name: "Riding Pony", AC: 14, HP: 16, DEX: 4, HPD: [1, 4, 0] },
  { id: 2065, ruleset: 'pathfinder 2e', name: "Riekanoy", AC: 45, HP: 410, DEX: 31, HPD: [1, 31, 0] },
  { id: 2066, ruleset: 'pathfinder 2e', name: "Rigg Gargadilly", AC: 27, HP: 70, DEX: 15, HPD: [1, 15, 0] },
  { id: 2067, ruleset: 'pathfinder 2e', name: "Rime Sludge", AC: 8, HP: 38, DEX: 4, HPD: [1, 4, 0] },
  { id: 2068, ruleset: 'pathfinder 2e', name: "Ringhorn Ram", AC: 16, HP: 15, DEX: 6, HPD: [1, 6, 0] },
  { id: 2069, ruleset: 'pathfinder 2e', name: "Risen Fetch", AC: 48, HP: 470, DEX: 38, HPD: [1, 38, 0] },
  { id: 2070, ruleset: 'pathfinder 2e', name: "River Drake", AC: 19, HP: 45, DEX: 9, HPD: [1, 9, 0] },
  { id: 2071, ruleset: 'pathfinder 2e', name: "River Elasmosaurus", AC: 31, HP: 195, DEX: 21, HPD: [1, 21, 0] },
  { id: 2072, ruleset: 'pathfinder 2e', name: "Rivka", AC: 32, HP: 220, DEX: 21, HPD: [1, 21, 0] },
  { id: 2073, ruleset: 'pathfinder 2e', name: "Roc", AC: 27, HP: 180, DEX: 18, HPD: [1, 18, 0] },
  { id: 2074, ruleset: 'pathfinder 2e', name: "Roiling Incant", AC: 25, HP: 155, DEX: 15, HPD: [1, 15, 0] },
  { id: 2075, ruleset: 'pathfinder 2e', name: "Rokurokubi", AC: 18, HP: 30, DEX: 9, HPD: [1, 9, 0] },
  { id: 2076, ruleset: 'pathfinder 2e', name: "Rompo", AC: 21, HP: 80, DEX: 15, HPD: [1, 15, 0] },
  { id: 2077, ruleset: 'pathfinder 2e', name: "Roper", AC: 29, HP: 215, DEX: 21, HPD: [1, 21, 0] },
  { id: 2078, ruleset: 'pathfinder 2e', name: "Roru", AC: 25, HP: 120, DEX: 16, HPD: [1, 16, 0] },
  { id: 2079, ruleset: 'pathfinder 2e', name: "Rosethorn", AC: 18, HP: 30, DEX: 8, HPD: [1, 8, 0] },
  { id: 2080, ruleset: 'pathfinder 2e', name: "Rotbomber", AC: 31, HP: 250, DEX: 25, HPD: [1, 25, 0] },
  { id: 2081, ruleset: 'pathfinder 2e', name: "Rune Giant", AC: 38, HP: 330, DEX: 28, HPD: [1, 28, 0] },
  { id: 2082, ruleset: 'pathfinder 2e', name: "Runecarved Lich", AC: 42, HP: 330, DEX: 32, HPD: [1, 32, 0] },
  { id: 2083, ruleset: 'pathfinder 2e', name: "Rusalka", AC: 33, HP: 230, DEX: 22, HPD: [1, 22, 0] },
  { id: 2084, ruleset: 'pathfinder 2e', name: "Rust Hag", AC: 26, HP: 135, DEX: 16, HPD: [1, 16, 0] },
  { id: 2085, ruleset: 'pathfinder 2e', name: "Rust Monster", AC: 19, HP: 40, DEX: 8, HPD: [1, 8, 0] },
  { id: 2086, ruleset: 'pathfinder 2e', name: "Rust Ooze", AC: 11, HP: 80, DEX: 6, HPD: [1, 6, 0] },
  { id: 2087, ruleset: 'pathfinder 2e', name: "Rusty Mae", AC: 30, HP: 155, DEX: 22, HPD: [1, 22, 0] },
  { id: 2088, ruleset: 'pathfinder 2e', name: "Sabosan", AC: 22, HP: 78, DEX: 10, HPD: [1, 10, 0] },
  { id: 2089, ruleset: 'pathfinder 2e', name: "Sacristan", AC: 30, HP: 175, DEX: 19, HPD: [1, 19, 0] },
  { id: 2090, ruleset: 'pathfinder 2e', name: "Saggorak Poltergeist", AC: 33, HP: 180, DEX: 20, HPD: [1, 20, 0] },
  { id: 2091, ruleset: 'pathfinder 2e', name: "Sahni Bride-Of-The-Sea", AC: 24, HP: 120, DEX: 15, HPD: [1, 15, 0] },
  { id: 2092, ruleset: 'pathfinder 2e', name: "Salamander", AC: 26, HP: 125, DEX: 15, HPD: [1, 15, 0] },
  { id: 2093, ruleset: 'pathfinder 2e', name: "Samsaran Anchorite", AC: 15, HP: 16, DEX: 9, HPD: [1, 9, 0] },
  { id: 2094, ruleset: 'pathfinder 2e', name: "Sand Sentry", AC: 24, HP: 94, DEX: 14, HPD: [1, 14, 0] },
  { id: 2095, ruleset: 'pathfinder 2e', name: "Sandpoint Devil", AC: 27, HP: 165, DEX: 16, HPD: [1, 16, 0] },
  { id: 2096, ruleset: 'pathfinder 2e', name: "Sanzuwu", AC: 36, HP: 295, DEX: 26, HPD: [1, 26, 0] },
  { id: 2097, ruleset: 'pathfinder 2e', name: "Sard", AC: 43, HP: 400, DEX: 35, HPD: [1, 35, 0] },
  { id: 2098, ruleset: 'pathfinder 2e', name: "Sarglagon", AC: 27, HP: 120, DEX: 18, HPD: [1, 18, 0] },
  { id: 2099, ruleset: 'pathfinder 2e', name: "Sarkorian Wolf", AC: 15, HP: 24, DEX: 7, HPD: [1, 7, 0] },
  { id: 2100, ruleset: 'pathfinder 2e', name: "Sarvel Ever-Hunger", AC: 48, HP: 430, DEX: 39, HPD: [1, 39, 0] },
  { id: 2101, ruleset: 'pathfinder 2e', name: "Sasquatch", AC: 17, HP: 36, DEX: 8, HPD: [1, 8, 0] },
  { id: 2102, ruleset: 'pathfinder 2e', name: "Satyr", AC: 19, HP: 80, DEX: 10, HPD: [1, 10, 0] },
  { id: 2103, ruleset: 'pathfinder 2e', name: "Saurian Warmonger", AC: 39, HP: 340, DEX: 31, HPD: [1, 31, 0] },
  { id: 2104, ruleset: 'pathfinder 2e', name: "Saurian Worldwatcher", AC: 43, HP: 330, DEX: 34, HPD: [1, 34, 0] },
  { id: 2105, ruleset: 'pathfinder 2e', name: "Scalathrax", AC: 21, HP: 60, DEX: 11, HPD: [1, 11, 0] },
  { id: 2106, ruleset: 'pathfinder 2e', name: "Scalescribe", AC: 18, HP: 45, DEX: 11, HPD: [1, 11, 0] },
  { id: 2107, ruleset: 'pathfinder 2e', name: "Scalliwing", AC: 19, HP: 45, DEX: 12, HPD: [1, 12, 0] },
  { id: 2108, ruleset: 'pathfinder 2e', name: "Scarecophagus", AC: 24, HP: 100, DEX: 14, HPD: [1, 14, 0] },
  { id: 2109, ruleset: 'pathfinder 2e', name: "Scarecrow", AC: 19, HP: 60, DEX: 11, HPD: [1, 11, 0] },
  { id: 2110, ruleset: 'pathfinder 2e', name: "Scarlet Walker", AC: 33, HP: 225, DEX: 23, HPD: [1, 23, 0] },
  { id: 2111, ruleset: 'pathfinder 2e', name: "Sceaduinar", AC: 25, HP: 100, DEX: 15, HPD: [1, 15, 0] },
  { id: 2112, ruleset: 'pathfinder 2e', name: "Scorned Hound", AC: 15, HP: 22, DEX: 7, HPD: [1, 7, 0] },
  { id: 2113, ruleset: 'pathfinder 2e', name: "Scorpion Swarm", AC: 21, HP: 55, DEX: 11, HPD: [1, 11, 0] },
  { id: 2114, ruleset: 'pathfinder 2e', name: "Scrabbling Ribcage", AC: 16, HP: 20, DEX: 6, HPD: [1, 6, 0] },
  { id: 2115, ruleset: 'pathfinder 2e', name: "Screaming Sulfur", AC: 27, HP: 125, DEX: 22, HPD: [1, 22, 0] },
  { id: 2116, ruleset: 'pathfinder 2e', name: "Scrit", AC: 16, HP: 18, DEX: 6, HPD: [1, 6, 0] },
  { id: 2117, ruleset: 'pathfinder 2e', name: "Scythe Tree", AC: 24, HP: 105, DEX: 14, HPD: [1, 14, 0] },
  { id: 2118, ruleset: 'pathfinder 2e', name: "Sea Devil Baron", AC: 24, HP: 95, DEX: 13, HPD: [1, 13, 0] },
  { id: 2119, ruleset: 'pathfinder 2e', name: "Sea Devil Brute", AC: 21, HP: 60, DEX: 7, HPD: [1, 7, 0] },
  { id: 2120, ruleset: 'pathfinder 2e', name: "Sea Devil Scout", AC: 18, HP: 30, DEX: 9, HPD: [1, 9, 0] },
  { id: 2121, ruleset: 'pathfinder 2e', name: "Sea Drake", AC: 24, HP: 95, DEX: 14, HPD: [1, 14, 0] },
  { id: 2122, ruleset: 'pathfinder 2e', name: "Sea Hag", AC: 19, HP: 45, DEX: 10, HPD: [1, 10, 0] },
  { id: 2123, ruleset: 'pathfinder 2e', name: "Sea Serpent", AC: 35, HP: 210, DEX: 22, HPD: [1, 22, 0] },
  { id: 2124, ruleset: 'pathfinder 2e', name: "Sea Snake", AC: 16, HP: 15, DEX: 5, HPD: [1, 5, 0] },
  { id: 2125, ruleset: 'pathfinder 2e', name: "Seaweed Leshy", AC: 19, HP: 45, DEX: 10, HPD: [1, 10, 0] },
  { id: 2126, ruleset: 'pathfinder 2e', name: "Seetangeist", AC: 32, HP: 160, DEX: 19, HPD: [1, 19, 0] },
  { id: 2127, ruleset: 'pathfinder 2e', name: "Seething Spirit", AC: 29, HP: 145, DEX: 15, HPD: [1, 15, 0] },
  { id: 2128, ruleset: 'pathfinder 2e', name: "Sepid", AC: 34, HP: 350, DEX: 24, HPD: [1, 24, 0] },
  { id: 2129, ruleset: 'pathfinder 2e', name: "Sepoko", AC: 31, HP: 200, DEX: 22, HPD: [1, 22, 0] },
  { id: 2130, ruleset: 'pathfinder 2e', name: "Serpentfolk Granitescale", AC: 24, HP: 120, DEX: 13, HPD: [1, 13, 0] },
  { id: 2131, ruleset: 'pathfinder 2e', name: "Serpentfolk Venom Caller", AC: 24, HP: 105, DEX: 15, HPD: [1, 15, 0] },
  { id: 2132, ruleset: 'pathfinder 2e', name: "Seugathi Reality Warper", AC: 27, HP: 120, DEX: 17, HPD: [1, 17, 0] },
  { id: 2133, ruleset: 'pathfinder 2e', name: "Seugathi Servant", AC: 23, HP: 75, DEX: 14, HPD: [1, 14, 0] },
  { id: 2134, ruleset: 'pathfinder 2e', name: "Severed Head", AC: 15, HP: 7, DEX: 6, HPD: [1, 6, 0] },
  { id: 2135, ruleset: 'pathfinder 2e', name: "Sewer Ooze", AC: 8, HP: 40, DEX: 3, HPD: [1, 3, 0] },
  { id: 2136, ruleset: 'pathfinder 2e', name: "Shabti Redeemer", AC: 24, HP: 75, DEX: 8, HPD: [1, 8, 0] },
  { id: 2137, ruleset: 'pathfinder 2e', name: "Shabti Slayer", AC: 39, HP: 255, DEX: 28, HPD: [1, 28, 0] },
  { id: 2138, ruleset: 'pathfinder 2e', name: "Shabti Votary", AC: 40, HP: 260, DEX: 33, HPD: [1, 33, 0] },
  { id: 2139, ruleset: 'pathfinder 2e', name: "Shadern Immolator", AC: 15, HP: 21, DEX: 5, HPD: [1, 5, 0] },
  { id: 2140, ruleset: 'pathfinder 2e', name: "Shadow", AC: 20, HP: 40, DEX: 10, HPD: [1, 10, 0] },
  { id: 2141, ruleset: 'pathfinder 2e', name: "Shadow Drake", AC: 17, HP: 28, DEX: 6, HPD: [1, 6, 0] },
  { id: 2142, ruleset: 'pathfinder 2e', name: "Shadow Giant", AC: 33, HP: 275, DEX: 20, HPD: [1, 20, 0] },
  { id: 2143, ruleset: 'pathfinder 2e', name: "Shadow Worm", AC: 45, HP: 450, DEX: 33, HPD: [1, 33, 0] },
  { id: 2144, ruleset: 'pathfinder 2e', name: "Shadow Yai", AC: 39, HP: 290, DEX: 28, HPD: [1, 28, 0] },
  { id: 2145, ruleset: 'pathfinder 2e', name: "Shadowbound Monk Statue", AC: 28, HP: 120, DEX: 15, HPD: [1, 15, 0] },
  { id: 2146, ruleset: 'pathfinder 2e', name: "Shadowforged Guardian", AC: 29, HP: 170, DEX: 18, HPD: [1, 18, 0] },
  { id: 2147, ruleset: 'pathfinder 2e', name: "Shae", AC: 21, HP: 45, DEX: 10, HPD: [1, 10, 0] },
  { id: 2148, ruleset: 'pathfinder 2e', name: "Shaitan", AC: 25, HP: 110, DEX: 15, HPD: [1, 15, 0] },
  { id: 2149, ruleset: 'pathfinder 2e', name: "Shambler", AC: 22, HP: 120, DEX: 12, HPD: [1, 12, 0] },
  { id: 2150, ruleset: 'pathfinder 2e', name: "Shambler Troop", AC: 18, HP: 90, DEX: 7, HPD: [1, 7, 0] },
  { id: 2151, ruleset: 'pathfinder 2e', name: "Shanrigol Behemoth", AC: 27, HP: 140, DEX: 18, HPD: [1, 18, 0] },
  { id: 2152, ruleset: 'pathfinder 2e', name: "Shanrigol Heap", AC: 20, HP: 55, DEX: 9, HPD: [1, 9, 0] },
  { id: 2153, ruleset: 'pathfinder 2e', name: "Shantak", AC: 27, HP: 115, DEX: 18, HPD: [1, 18, 0] },
  { id: 2154, ruleset: 'pathfinder 2e', name: "Shatterling", AC: 36, HP: 305, DEX: 26, HPD: [1, 26, 0] },
  { id: 2155, ruleset: 'pathfinder 2e', name: "Shaukeen", AC: 16, HP: 22, DEX: 8, HPD: [1, 8, 0] },
  { id: 2156, ruleset: 'pathfinder 2e', name: "Shemhazian", AC: 39, HP: 350, DEX: 30, HPD: [1, 30, 0] },
  { id: 2157, ruleset: 'pathfinder 2e', name: "Shianshi Waymaker", AC: 42, HP: 330, DEX: 32, HPD: [1, 32, 0] },
  { id: 2158, ruleset: 'pathfinder 2e', name: "Shield Archon", AC: 31, HP: 125, DEX: 19, HPD: [1, 19, 0] },
  { id: 2159, ruleset: 'pathfinder 2e', name: "Shieldbearer Construct", AC: 17, HP: 30, DEX: 6, HPD: [1, 6, 0] },
  { id: 2160, ruleset: 'pathfinder 2e', name: "Shikigami", AC: 15, HP: 25, DEX: 10, HPD: [1, 10, 0] },
  { id: 2161, ruleset: 'pathfinder 2e', name: "Shimmernewt", AC: 22, HP: 120, DEX: 14, HPD: [1, 14, 0] },
  { id: 2162, ruleset: 'pathfinder 2e', name: "Shining Child", AC: 33, HP: 215, DEX: 23, HPD: [1, 23, 0] },
  { id: 2163, ruleset: 'pathfinder 2e', name: "Shoal Linnorm", AC: 38, HP: 295, DEX: 27, HPD: [1, 27, 0] },
  { id: 2164, ruleset: 'pathfinder 2e', name: "Shobhad Enforcer", AC: 38, HP: 320, DEX: 29, HPD: [1, 29, 0] },
  { id: 2165, ruleset: 'pathfinder 2e', name: "Shobhad Sniper", AC: 40, HP: 320, DEX: 30, HPD: [1, 30, 0] },
  { id: 2166, ruleset: 'pathfinder 2e', name: "Shock Zombie", AC: 21, HP: 140, DEX: 12, HPD: [1, 12, 0] },
  { id: 2167, ruleset: 'pathfinder 2e', name: "Shocker Lizard", AC: 18, HP: 32, DEX: 7, HPD: [1, 7, 0] },
  { id: 2168, ruleset: 'pathfinder 2e', name: "Shoggoth", AC: 39, HP: 275, DEX: 34, HPD: [1, 34, 0] },
  { id: 2169, ruleset: 'pathfinder 2e', name: "Shoggti", AC: 25, HP: 105, DEX: 13, HPD: [1, 13, 0] },
  { id: 2170, ruleset: 'pathfinder 2e', name: "Shoki", AC: 27, HP: 130, DEX: 21, HPD: [1, 21, 0] },
  { id: 2171, ruleset: 'pathfinder 2e', name: "Shoony Hierarch", AC: 19, HP: 60, DEX: 12, HPD: [1, 12, 0] },
  { id: 2172, ruleset: 'pathfinder 2e', name: "Shoony Militia Member", AC: 17, HP: 40, DEX: 8, HPD: [1, 8, 0] },
  { id: 2173, ruleset: 'pathfinder 2e', name: "Shoony Tiller", AC: 15, HP: 16, DEX: 6, HPD: [1, 6, 0] },
  { id: 2174, ruleset: 'pathfinder 2e', name: "Shraen Graveknight", AC: 37, HP: 295, DEX: 26, HPD: [1, 26, 0] },
  { id: 2175, ruleset: 'pathfinder 2e', name: "Shredskin", AC: 16, HP: 30, DEX: 6, HPD: [1, 6, 0] },
  { id: 2176, ruleset: 'pathfinder 2e', name: "Shrine Skelm", AC: 22, HP: 80, DEX: 11, HPD: [1, 11, 0] },
  { id: 2177, ruleset: 'pathfinder 2e', name: "Shuln", AC: 33, HP: 195, DEX: 20, HPD: [1, 20, 0] },
  { id: 2178, ruleset: 'pathfinder 2e', name: "Shulsaga", AC: 19, HP: 35, DEX: 10, HPD: [1, 10, 0] },
  { id: 2179, ruleset: 'pathfinder 2e', name: "Siabrae", AC: 36, HP: 218, DEX: 31, HPD: [1, 31, 0] },
  { id: 2180, ruleset: 'pathfinder 2e', name: "Sicklehand Construct", AC: 16, HP: 15, DEX: 6, HPD: [1, 6, 0] },
  { id: 2181, ruleset: 'pathfinder 2e', name: "Siege Shard", AC: 19, HP: 37, DEX: 9, HPD: [1, 9, 0] },
  { id: 2182, ruleset: 'pathfinder 2e', name: "Sigbin", AC: 21, HP: 75, DEX: 12, HPD: [1, 12, 0] },
  { id: 2183, ruleset: 'pathfinder 2e', name: "Silent Stalker", AC: 34, HP: 220, DEX: 24, HPD: [1, 24, 0] },
  { id: 2184, ruleset: 'pathfinder 2e', name: "Silvanshee", AC: 17, HP: 20, DEX: 8, HPD: [1, 8, 0] },
  { id: 2185, ruleset: 'pathfinder 2e', name: "Silverfish Swarm", AC: 13, HP: 9, DEX: 5, HPD: [1, 5, 0] },
  { id: 2186, ruleset: 'pathfinder 2e', name: "Simurgh", AC: 41, HP: 350, DEX: 32, HPD: [1, 32, 0] },
  { id: 2187, ruleset: 'pathfinder 2e', name: "Sinspawn", AC: 18, HP: 30, DEX: 10, HPD: [1, 10, 0] },
  { id: 2188, ruleset: 'pathfinder 2e', name: "Sister Of The Bloodshot Eye", AC: 31, HP: 250, DEX: 23, HPD: [1, 23, 0] },
  { id: 2189, ruleset: 'pathfinder 2e', name: "Sié Goluo", AC: 36, HP: 320, DEX: 25, HPD: [1, 25, 0] },
  { id: 2190, ruleset: 'pathfinder 2e', name: "Skarja", AC: 34, HP: 260, DEX: 25, HPD: [1, 25, 0] },
  { id: 2191, ruleset: 'pathfinder 2e', name: "Skaveling", AC: 22, HP: 80, DEX: 15, HPD: [1, 15, 0] },
  { id: 2192, ruleset: 'pathfinder 2e', name: "Skeletal Champion", AC: 19, HP: 25, DEX: 8, HPD: [1, 8, 0] },
  { id: 2193, ruleset: 'pathfinder 2e', name: "Skeletal Giant", AC: 17, HP: 50, DEX: 7, HPD: [1, 7, 0] },
  { id: 2194, ruleset: 'pathfinder 2e', name: "Skeletal Hellknight", AC: 20, HP: 25, DEX: 7, HPD: [1, 7, 0] },
  { id: 2195, ruleset: 'pathfinder 2e', name: "Skeletal Horse", AC: 16, HP: 33, DEX: 8, HPD: [1, 8, 0] },
  { id: 2196, ruleset: 'pathfinder 2e', name: "Skeletal Hulk", AC: 25, HP: 105, DEX: 16, HPD: [1, 16, 0] },
  { id: 2197, ruleset: 'pathfinder 2e', name: "Skeletal Knight", AC: 28, HP: 110, DEX: 5, HPD: [1, 5, 0] },
  { id: 2198, ruleset: 'pathfinder 2e', name: "Skeletal Mage", AC: 21, HP: 60, DEX: 9, HPD: [1, 9, 0] },
  { id: 2199, ruleset: 'pathfinder 2e', name: "Skeletal Soldier", AC: 17, HP: 16, DEX: 5, HPD: [1, 5, 0] },
  { id: 2200, ruleset: 'pathfinder 2e', name: "Skeletal Tiger Lord", AC: 28, HP: 99, DEX: 18, HPD: [1, 18, 0] },
  { id: 2201, ruleset: 'pathfinder 2e', name: "Skeletal Titan", AC: 33, HP: 210, DEX: 19, HPD: [1, 19, 0] },
  { id: 2202, ruleset: 'pathfinder 2e', name: "Skeletal Woolly Rhinoceros", AC: 22, HP: 75, DEX: 9, HPD: [1, 9, 0] },
  { id: 2203, ruleset: 'pathfinder 2e', name: "Skeleton Guard", AC: 16, HP: 4, DEX: 2, HPD: [1, 2, 0] },
  { id: 2204, ruleset: 'pathfinder 2e', name: "Skeleton Infantry", AC: 31, HP: 180, DEX: 17, HPD: [1, 17, 0] },
  { id: 2205, ruleset: 'pathfinder 2e', name: "Skinskitter", AC: 16, HP: 20, DEX: 7, HPD: [1, 7, 0] },
  { id: 2206, ruleset: 'pathfinder 2e', name: "Skinstitch", AC: 22, HP: 95, DEX: 12, HPD: [1, 12, 0] },
  { id: 2207, ruleset: 'pathfinder 2e', name: "Skrik Nettle", AC: 22, HP: 130, DEX: 16, HPD: [1, 16, 0] },
  { id: 2208, ruleset: 'pathfinder 2e', name: "Skulk", AC: 16, HP: 21, DEX: 5, HPD: [1, 5, 0] },
  { id: 2209, ruleset: 'pathfinder 2e', name: "Skull Fairy", AC: 19, HP: 45, DEX: 10, HPD: [1, 10, 0] },
  { id: 2210, ruleset: 'pathfinder 2e', name: "Skull Peeler", AC: 24, HP: 75, DEX: 17, HPD: [1, 17, 0] },
  { id: 2211, ruleset: 'pathfinder 2e', name: "Skulltaker", AC: 42, HP: 300, DEX: 33, HPD: [1, 33, 0] },
  { id: 2212, ruleset: 'pathfinder 2e', name: "Skum", AC: 16, HP: 40, DEX: 6, HPD: [1, 6, 0] },
  { id: 2213, ruleset: 'pathfinder 2e', name: "Skunk", AC: 15, HP: 7, DEX: 5, HPD: [1, 5, 0] },
  { id: 2214, ruleset: 'pathfinder 2e', name: "Slana", AC: 45, HP: 380, DEX: 36, HPD: [1, 36, 0] },
  { id: 2215, ruleset: 'pathfinder 2e', name: "Slime Mold", AC: 12, HP: 60, DEX: 6, HPD: [1, 6, 0] },
  { id: 2216, ruleset: 'pathfinder 2e', name: "Slithering Pit", AC: 14, HP: 220, DEX: 9, HPD: [1, 9, 0] },
  { id: 2217, ruleset: 'pathfinder 2e', name: "Sluagh Reaper", AC: 29, HP: 175, DEX: 21, HPD: [1, 21, 0] },
  { id: 2218, ruleset: 'pathfinder 2e', name: "Sludgespine Killers", AC: 14, HP: 8, DEX: 5, HPD: [1, 5, 0] },
  { id: 2219, ruleset: 'pathfinder 2e', name: "Slug", AC: 25, HP: 165, DEX: 14, HPD: [1, 14, 0] },
  { id: 2220, ruleset: 'pathfinder 2e', name: "Slurk", AC: 17, HP: 35, DEX: 6, HPD: [1, 6, 0] },
  { id: 2221, ruleset: 'pathfinder 2e', name: "Smiler", AC: 19, HP: 45, DEX: 12, HPD: [1, 12, 0] },
  { id: 2222, ruleset: 'pathfinder 2e', name: "Smilodon", AC: 23, HP: 110, DEX: 14, HPD: [1, 14, 0] },
  { id: 2223, ruleset: 'pathfinder 2e', name: "Smog Giant", AC: 23, HP: 145, DEX: 13, HPD: [1, 13, 0] },
  { id: 2224, ruleset: 'pathfinder 2e', name: "Smog Wraith", AC: 27, HP: 110, DEX: 19, HPD: [1, 19, 0] },
  { id: 2225, ruleset: 'pathfinder 2e', name: "Smoldering Leopard", AC: 19, HP: 45, DEX: 11, HPD: [1, 11, 0] },
  { id: 2226, ruleset: 'pathfinder 2e', name: "Snapping Flytrap", AC: 18, HP: 50, DEX: 7, HPD: [1, 7, 0] },
  { id: 2227, ruleset: 'pathfinder 2e', name: "Snapping Turtle", AC: 16, HP: 9, DEX: 3, HPD: [1, 3, 0] },
  { id: 2228, ruleset: 'pathfinder 2e', name: "Sod Hound", AC: 19, HP: 44, DEX: 9, HPD: [1, 9, 0] },
  { id: 2229, ruleset: 'pathfinder 2e', name: "Sodden Sentinel", AC: 28, HP: 320, DEX: 15, HPD: [1, 15, 0] },
  { id: 2230, ruleset: 'pathfinder 2e', name: "Solar", AC: 49, HP: 500, DEX: 40, HPD: [1, 40, 0] },
  { id: 2231, ruleset: 'pathfinder 2e', name: "Solar Glass Golem", AC: 31, HP: 195, DEX: 17, HPD: [1, 17, 0] },
  { id: 2232, ruleset: 'pathfinder 2e', name: "Solar Ibis", AC: 25, HP: 45, DEX: 17, HPD: [1, 17, 0] },
  { id: 2233, ruleset: 'pathfinder 2e', name: "Somnalu", AC: 37, HP: 290, DEX: 29, HPD: [1, 29, 0] },
  { id: 2234, ruleset: 'pathfinder 2e', name: "Somnalu Oculus", AC: 28, HP: 240, DEX: 26, HPD: [1, 26, 0] },
  { id: 2235, ruleset: 'pathfinder 2e', name: "Sorcerous Skull Swarm", AC: 34, HP: 190, DEX: 24, HPD: [1, 24, 0] },
  { id: 2236, ruleset: 'pathfinder 2e', name: "Sordesdaemon", AC: 37, HP: 300, DEX: 26, HPD: [1, 26, 0] },
  { id: 2237, ruleset: 'pathfinder 2e', name: "Soul Eater", AC: 26, HP: 80, DEX: 15, HPD: [1, 15, 0] },
  { id: 2238, ruleset: 'pathfinder 2e', name: "Soul Skelm", AC: 29, HP: 170, DEX: 19, HPD: [1, 19, 0] },
  { id: 2239, ruleset: 'pathfinder 2e', name: "Soul Slime", AC: 29, HP: 420, DEX: 27, HPD: [1, 27, 0] },
  { id: 2240, ruleset: 'pathfinder 2e', name: "Soul Swarm", AC: 28, HP: 234, DEX: 24, HPD: [1, 24, 0] },
  { id: 2241, ruleset: 'pathfinder 2e', name: "Soulbound Doll", AC: 20, HP: 23, DEX: 8, HPD: [1, 8, 0] },
  { id: 2242, ruleset: 'pathfinder 2e', name: "Soulbound Ruin", AC: 37, HP: 310, DEX: 25, HPD: [1, 25, 0] },
  { id: 2243, ruleset: 'pathfinder 2e', name: "Spark Bat", AC: 19, HP: 18, DEX: 7, HPD: [1, 7, 0] },
  { id: 2244, ruleset: 'pathfinder 2e', name: "Spawn Of Kothogaz", AC: 24, HP: 70, DEX: 14, HPD: [1, 14, 0] },
  { id: 2245, ruleset: 'pathfinder 2e', name: "Spawn Of Taon", AC: 30, HP: 175, DEX: 21, HPD: [1, 21, 0] },
  { id: 2246, ruleset: 'pathfinder 2e', name: "Spear Frog", AC: 14, HP: 12, DEX: 6, HPD: [1, 6, 0] },
  { id: 2247, ruleset: 'pathfinder 2e', name: "Speartooth", AC: 36, HP: 255, DEX: 25, HPD: [1, 25, 0] },
  { id: 2248, ruleset: 'pathfinder 2e', name: "Specter", AC: 25, HP: 95, DEX: 15, HPD: [1, 15, 0] },
  { id: 2249, ruleset: 'pathfinder 2e', name: "Spectral Devil", AC: 28, HP: 140, DEX: 19, HPD: [1, 19, 0] },
  { id: 2250, ruleset: 'pathfinder 2e', name: "Spellscar Fext", AC: 25, HP: 100, DEX: 15, HPD: [1, 15, 0] },
  { id: 2251, ruleset: 'pathfinder 2e', name: "Spellscar Sky Marauder", AC: 22, HP: 85, DEX: 12, HPD: [1, 12, 0] },
  { id: 2252, ruleset: 'pathfinder 2e', name: "Spellskein", AC: 16, HP: 15, DEX: 2, HPD: [1, 2, 0] },
  { id: 2253, ruleset: 'pathfinder 2e', name: "Sphinx", AC: 27, HP: 135, DEX: 18, HPD: [1, 18, 0] },
  { id: 2254, ruleset: 'pathfinder 2e', name: "Spider Swarm", AC: 15, HP: 12, DEX: 4, HPD: [1, 4, 0] },
  { id: 2255, ruleset: 'pathfinder 2e', name: "Spinosaurus", AC: 30, HP: 200, DEX: 21, HPD: [1, 21, 0] },
  { id: 2256, ruleset: 'pathfinder 2e', name: "Spiny Eurypterid", AC: 22, HP: 70, DEX: 10, HPD: [1, 10, 0] },
  { id: 2257, ruleset: 'pathfinder 2e', name: "Spiral Centurion", AC: 31, HP: 170, DEX: 20, HPD: [1, 20, 0] },
  { id: 2258, ruleset: 'pathfinder 2e', name: "Spirit Naga", AC: 28, HP: 160, DEX: 18, HPD: [1, 18, 0] },
  { id: 2259, ruleset: 'pathfinder 2e', name: "Spirit Of Stisshak", AC: 28, HP: 65, DEX: 18, HPD: [1, 18, 0] },
  { id: 2260, ruleset: 'pathfinder 2e', name: "Spirit Turtle", AC: 45, HP: 320, DEX: 35, HPD: [1, 35, 0] },
  { id: 2261, ruleset: 'pathfinder 2e', name: "Spiritbound Aluum", AC: 39, HP: 255, DEX: 28, HPD: [1, 28, 0] },
  { id: 2262, ruleset: 'pathfinder 2e', name: "Sportlebore Swarm", AC: 25, HP: 85, DEX: 13, HPD: [1, 13, 0] },
  { id: 2263, ruleset: 'pathfinder 2e', name: "Spriggan Bully", AC: 19, HP: 48, DEX: 10, HPD: [1, 10, 0] },
  { id: 2264, ruleset: 'pathfinder 2e', name: "Spriggan Warlord", AC: 25, HP: 120, DEX: 14, HPD: [1, 14, 0] },
  { id: 2265, ruleset: 'pathfinder 2e', name: "Spring-Heeled Jack", AC: 18, HP: 45, DEX: 10, HPD: [1, 10, 0] },
  { id: 2266, ruleset: 'pathfinder 2e', name: "Sprite", AC: 15, HP: 11, DEX: 4, HPD: [1, 4, 0] },
  { id: 2267, ruleset: 'pathfinder 2e', name: "Squirming Swill", AC: 17, HP: 28, DEX: 6, HPD: [1, 6, 0] },
  { id: 2268, ruleset: 'pathfinder 2e', name: "Squirrel Swarm", AC: 16, HP: 16, DEX: 6, HPD: [1, 6, 0] },
  { id: 2269, ruleset: 'pathfinder 2e', name: "Ssumzili", AC: 33, HP: 201, DEX: 25, HPD: [1, 25, 0] },
  { id: 2270, ruleset: 'pathfinder 2e', name: "Stag Lord Bandit", AC: 15, HP: 16, DEX: 4, HPD: [1, 4, 0] },
  { id: 2271, ruleset: 'pathfinder 2e', name: "Star Archon", AC: 43, HP: 400, DEX: 35, HPD: [1, 35, 0] },
  { id: 2272, ruleset: 'pathfinder 2e', name: "Starved Staff", AC: 32, HP: 250, DEX: 26, HPD: [1, 26, 0] },
  { id: 2273, ruleset: 'pathfinder 2e', name: "Steam Mephit", AC: 16, HP: 19, DEX: 3, HPD: [1, 3, 0] },
  { id: 2274, ruleset: 'pathfinder 2e', name: "Stegosaurus", AC: 23, HP: 125, DEX: 15, HPD: [1, 15, 0] },
  { id: 2275, ruleset: 'pathfinder 2e', name: "Stheno Harpist", AC: 16, HP: 19, DEX: 4, HPD: [1, 4, 0] },
  { id: 2276, ruleset: 'pathfinder 2e', name: "Sthira", AC: 45, HP: 475, DEX: 33, HPD: [1, 33, 0] },
  { id: 2277, ruleset: 'pathfinder 2e', name: "Stingray", AC: 16, HP: 15, DEX: 6, HPD: [1, 6, 0] },
  { id: 2278, ruleset: 'pathfinder 2e', name: "Stinkweed Shambler", AC: 18, HP: 30, DEX: 8, HPD: [1, 8, 0] },
  { id: 2279, ruleset: 'pathfinder 2e', name: "Stone Ghost", AC: 20, HP: 40, DEX: 13, HPD: [1, 13, 0] },
  { id: 2280, ruleset: 'pathfinder 2e', name: "Stone Giant", AC: 27, HP: 150, DEX: 16, HPD: [1, 16, 0] },
  { id: 2281, ruleset: 'pathfinder 2e', name: "Stone Golem", AC: 30, HP: 175, DEX: 17, HPD: [1, 17, 0] },
  { id: 2282, ruleset: 'pathfinder 2e', name: "Stone Lion", AC: 21, HP: 50, DEX: 13, HPD: [1, 13, 0] },
  { id: 2283, ruleset: 'pathfinder 2e', name: "Stone Lion Cub", AC: 18, HP: 28, DEX: 10, HPD: [1, 10, 0] },
  { id: 2284, ruleset: 'pathfinder 2e', name: "Stone Mauler", AC: 27, HP: 180, DEX: 16, HPD: [1, 16, 0] },
  { id: 2285, ruleset: 'pathfinder 2e', name: "Stone Sister", AC: 24, HP: 75, DEX: 11, HPD: [1, 11, 0] },
  { id: 2286, ruleset: 'pathfinder 2e', name: "Storm Giant", AC: 34, HP: 235, DEX: 24, HPD: [1, 24, 0] },
  { id: 2287, ruleset: 'pathfinder 2e', name: "Storm Hag", AC: 21, HP: 95, DEX: 12, HPD: [1, 12, 0] },
  { id: 2288, ruleset: 'pathfinder 2e', name: "Storm Lord", AC: 30, HP: 120, DEX: 18, HPD: [1, 18, 0] },
  { id: 2289, ruleset: 'pathfinder 2e', name: "Storm-Struck Arboreal", AC: 37, HP: 370, DEX: 28, HPD: [1, 28, 0] },
  { id: 2290, ruleset: 'pathfinder 2e', name: "Straugh", AC: 29, HP: 220, DEX: 17, HPD: [1, 17, 0] },
  { id: 2291, ruleset: 'pathfinder 2e', name: "Street Skelm", AC: 18, HP: 55, DEX: 8, HPD: [1, 8, 0] },
  { id: 2292, ruleset: 'pathfinder 2e', name: "Striding Fire", AC: 24, HP: 115, DEX: 14, HPD: [1, 14, 0] },
  { id: 2293, ruleset: 'pathfinder 2e', name: "Strigoi Progenitor", AC: 34, HP: 180, DEX: 23, HPD: [1, 23, 0] },
  { id: 2294, ruleset: 'pathfinder 2e', name: "Strigoi Servant", AC: 30, HP: 130, DEX: 20, HPD: [1, 20, 0] },
  { id: 2295, ruleset: 'pathfinder 2e', name: "Strix Kinmate", AC: 18, HP: 24, DEX: 9, HPD: [1, 9, 0] },
  { id: 2296, ruleset: 'pathfinder 2e', name: "Sturzstromer", AC: 41, HP: 280, DEX: 33, HPD: [1, 33, 0] },
  { id: 2297, ruleset: 'pathfinder 2e', name: "Stygira", AC: 26, HP: 80, DEX: 17, HPD: [1, 17, 0] },
  { id: 2298, ruleset: 'pathfinder 2e', name: "Succubus", AC: 23, HP: 100, DEX: 15, HPD: [1, 15, 0] },
  { id: 2299, ruleset: 'pathfinder 2e', name: "Sulfur Zombie", AC: 23, HP: 125, DEX: 12, HPD: [1, 12, 0] },
  { id: 2300, ruleset: 'pathfinder 2e', name: "Suli Dune Dancer", AC: 15, HP: 16, DEX: 5, HPD: [1, 5, 0] },
  { id: 2301, ruleset: 'pathfinder 2e', name: "Sulvik", AC: 27, HP: 190, DEX: 21, HPD: [1, 21, 0] },
  { id: 2302, ruleset: 'pathfinder 2e', name: "Sumbreiva", AC: 39, HP: 290, DEX: 29, HPD: [1, 29, 0] },
  { id: 2303, ruleset: 'pathfinder 2e', name: "Summer Hora", AC: 24, HP: 90, DEX: 14, HPD: [1, 14, 0] },
  { id: 2304, ruleset: 'pathfinder 2e', name: "Summer Hora Queen", AC: 35, HP: 190, DEX: 26, HPD: [1, 26, 0] },
  { id: 2305, ruleset: 'pathfinder 2e', name: "Sunburst Corpse", AC: 35, HP: 255, DEX: 25, HPD: [1, 25, 0] },
  { id: 2306, ruleset: 'pathfinder 2e', name: "Sunflower Leshy", AC: 16, HP: 20, DEX: 7, HPD: [1, 7, 0] },
  { id: 2307, ruleset: 'pathfinder 2e', name: "Svartalfar Killer", AC: 27, HP: 135, DEX: 16, HPD: [1, 16, 0] },
  { id: 2308, ruleset: 'pathfinder 2e', name: "Swordkeeper", AC: 29, HP: 285, DEX: 20, HPD: [1, 20, 0] },
  { id: 2309, ruleset: 'pathfinder 2e', name: "Syarstik Painted-Tiger", AC: 21, HP: 40, DEX: 14, HPD: [1, 14, 0] },
  { id: 2310, ruleset: 'pathfinder 2e', name: "Sykever", AC: 37, HP: 335, DEX: 29, HPD: [1, 29, 0] },
  { id: 2311, ruleset: 'pathfinder 2e', name: "Sylph Sneak", AC: 18, HP: 17, DEX: 5, HPD: [1, 5, 0] },
  { id: 2312, ruleset: 'pathfinder 2e', name: "Ta'apundo", AC: 45, HP: 440, DEX: 35, HPD: [1, 35, 0] },
  { id: 2313, ruleset: 'pathfinder 2e', name: "Taiga Giant", AC: 32, HP: 230, DEX: 23, HPD: [1, 23, 0] },
  { id: 2314, ruleset: 'pathfinder 2e', name: "Taiga Linnorm", AC: 44, HP: 385, DEX: 33, HPD: [1, 33, 0] },
  { id: 2315, ruleset: 'pathfinder 2e', name: "Taiga Yai", AC: 36, HP: 270, DEX: 30, HPD: [1, 30, 0] },
  { id: 2316, ruleset: 'pathfinder 2e', name: "Takulu Ot", AC: 21, HP: 60, DEX: 11, HPD: [1, 11, 0] },
  { id: 2317, ruleset: 'pathfinder 2e', name: "Talamira", AC: 34, HP: 185, DEX: 24, HPD: [1, 24, 0] },
  { id: 2318, ruleset: 'pathfinder 2e', name: "Taljjae", AC: 43, HP: 400, DEX: 33, HPD: [1, 33, 0] },
  { id: 2319, ruleset: 'pathfinder 2e', name: "Tallow Guardian", AC: 23, HP: 145, DEX: 13, HPD: [1, 13, 0] },
  { id: 2320, ruleset: 'pathfinder 2e', name: "Tallow Ooze", AC: 19, HP: 270, DEX: 14, HPD: [1, 14, 0] },
  { id: 2321, ruleset: 'pathfinder 2e', name: "Talon Peak Roc", AC: 33, HP: 220, DEX: 22, HPD: [1, 22, 0] },
  { id: 2322, ruleset: 'pathfinder 2e', name: "Tamikan", AC: 38, HP: 295, DEX: 23, HPD: [1, 23, 0] },
  { id: 2323, ruleset: 'pathfinder 2e', name: "Tanglebones", AC: 24, HP: 145, DEX: 17, HPD: [1, 17, 0] },
  { id: 2324, ruleset: 'pathfinder 2e', name: "Taon", AC: 38, HP: 230, DEX: 25, HPD: [1, 25, 0] },
  { id: 2325, ruleset: 'pathfinder 2e', name: "Tar Ooze", AC: 18, HP: 255, DEX: 18, HPD: [1, 18, 0] },
  { id: 2326, ruleset: 'pathfinder 2e', name: "Tar Zombie Mammoth", AC: 26, HP: 240, DEX: 18, HPD: [1, 18, 0] },
  { id: 2327, ruleset: 'pathfinder 2e', name: "Tar Zombie Predator", AC: 23, HP: 180, DEX: 15, HPD: [1, 15, 0] },
  { id: 2328, ruleset: 'pathfinder 2e', name: "Tar Zombie Snatcher", AC: 22, HP: 130, DEX: 12, HPD: [1, 12, 0] },
  { id: 2329, ruleset: 'pathfinder 2e', name: "Tarn Linnorm", AC: 46, HP: 400, DEX: 35, HPD: [1, 35, 0] },
  { id: 2330, ruleset: 'pathfinder 2e', name: "Tarrasque", AC: 54, HP: 540, DEX: 48, HPD: [1, 48, 0] },
  { id: 2331, ruleset: 'pathfinder 2e', name: "Tatterthread", AC: 46, HP: 400, DEX: 39, HPD: [1, 39, 0] },
  { id: 2332, ruleset: 'pathfinder 2e', name: "Tattoo Guardian", AC: 19, HP: 50, DEX: 8, HPD: [1, 8, 0] },
  { id: 2333, ruleset: 'pathfinder 2e', name: "Tatzlwyrm", AC: 18, HP: 30, DEX: 8, HPD: [1, 8, 0] },
  { id: 2334, ruleset: 'pathfinder 2e', name: "Taunting Skull", AC: 22, HP: 80, DEX: 13, HPD: [1, 13, 0] },
  { id: 2335, ruleset: 'pathfinder 2e', name: "Tehialai-Thief-Of-Ships", AC: 36, HP: 200, DEX: 26, HPD: [1, 26, 0] },
  { id: 2336, ruleset: 'pathfinder 2e', name: "Temteki", AC: 21, HP: 80, DEX: 13, HPD: [1, 13, 0] },
  { id: 2337, ruleset: 'pathfinder 2e', name: "Tendriculos", AC: 25, HP: 120, DEX: 15, HPD: [1, 15, 0] },
  { id: 2338, ruleset: 'pathfinder 2e', name: "Tenebric Giant", AC: 39, HP: 360, DEX: 31, HPD: [1, 31, 0] },
  { id: 2339, ruleset: 'pathfinder 2e', name: "Tengu Sneak", AC: 19, HP: 27, DEX: 6, HPD: [1, 6, 0] },
  { id: 2340, ruleset: 'pathfinder 2e', name: "Tenome", AC: 21, HP: 60, DEX: 14, HPD: [1, 14, 0] },
  { id: 2341, ruleset: 'pathfinder 2e', name: "Teraphant", AC: 27, HP: 175, DEX: 18, HPD: [1, 18, 0] },
  { id: 2342, ruleset: 'pathfinder 2e', name: "Terotricus", AC: 42, HP: 370, DEX: 31, HPD: [1, 31, 0] },
  { id: 2343, ruleset: 'pathfinder 2e', name: "Terra-Cotta Garrison", AC: 27, HP: 240, DEX: 22, HPD: [1, 22, 0] },
  { id: 2344, ruleset: 'pathfinder 2e', name: "Terra-Cotta Soldier", AC: 24, HP: 120, DEX: 14, HPD: [1, 14, 0] },
  { id: 2345, ruleset: 'pathfinder 2e', name: "Terror Bird", AC: 17, HP: 30, DEX: 6, HPD: [1, 6, 0] },
  { id: 2346, ruleset: 'pathfinder 2e', name: "Terror Shrike", AC: 20, HP: 60, DEX: 11, HPD: [1, 11, 0] },
  { id: 2347, ruleset: 'pathfinder 2e', name: "Terrorguard", AC: 37, HP: 255, DEX: 25, HPD: [1, 25, 0] },
  { id: 2348, ruleset: 'pathfinder 2e', name: "Terwa Chosen", AC: 27, HP: 140, DEX: 19, HPD: [1, 19, 0] },
  { id: 2349, ruleset: 'pathfinder 2e', name: "Terwa Prodigy", AC: 23, HP: 100, DEX: 17, HPD: [1, 17, 0] },
  { id: 2350, ruleset: 'pathfinder 2e', name: "Terwa Star Reader", AC: 24, HP: 115, DEX: 18, HPD: [1, 18, 0] },
  { id: 2351, ruleset: 'pathfinder 2e', name: "Teyam Ishtori", AC: 44, HP: 300, DEX: 25, HPD: [1, 25, 0] },
  { id: 2352, ruleset: 'pathfinder 2e', name: "Thanadaemon", AC: 34, HP: 270, DEX: 26, HPD: [1, 26, 0] },
  { id: 2353, ruleset: 'pathfinder 2e', name: "Thanatotic Titan", AC: 46, HP: 540, DEX: 36, HPD: [1, 36, 0] },
  { id: 2354, ruleset: 'pathfinder 2e', name: "Thasteron Khefak", AC: 19, HP: 42, DEX: 9, HPD: [1, 9, 0] },
  { id: 2355, ruleset: 'pathfinder 2e', name: "The Beast", AC: 28, HP: 120, DEX: 19, HPD: [1, 19, 0] },
  { id: 2356, ruleset: 'pathfinder 2e', name: "The Dancing Lady", AC: 28, HP: 135, DEX: 16, HPD: [1, 16, 0] },
  { id: 2357, ruleset: 'pathfinder 2e', name: "The Disciples", AC: 22, HP: 130, DEX: 15, HPD: [1, 15, 0] },
  { id: 2358, ruleset: 'pathfinder 2e', name: "The Gardener Creature", AC: 36, HP: 220, DEX: 24, HPD: [1, 24, 0] },
  { id: 2359, ruleset: 'pathfinder 2e', name: "The Horned Hunter", AC: 42, HP: 350, DEX: 35, HPD: [1, 35, 0] },
  { id: 2360, ruleset: 'pathfinder 2e', name: "The King of Biting Ants", AC: 44, HP: 300, DEX: 32, HPD: [1, 32, 0] },
  { id: 2361, ruleset: 'pathfinder 2e', name: "The Knurly Witch", AC: 45, HP: 324, DEX: 38, HPD: [1, 38, 0] },
  { id: 2362, ruleset: 'pathfinder 2e', name: "The Lonely Warrior", AC: 23, HP: 106, DEX: 14, HPD: [1, 14, 0] },
  { id: 2363, ruleset: 'pathfinder 2e', name: "The Misbegotten Troll", AC: 40, HP: 430, DEX: 31, HPD: [1, 31, 0] },
  { id: 2364, ruleset: 'pathfinder 2e', name: "The Stabbing Beast", AC: 38, HP: 275, DEX: 27, HPD: [1, 27, 0] },
  { id: 2365, ruleset: 'pathfinder 2e', name: "The Vanish Man", AC: 39, HP: 265, DEX: 28, HPD: [1, 28, 0] },
  { id: 2366, ruleset: 'pathfinder 2e', name: "The Wriggling Man", AC: 47, HP: 325, DEX: 35, HPD: [1, 35, 0] },
  { id: 2367, ruleset: 'pathfinder 2e', name: "Theater Phantasm", AC: 36, HP: 260, DEX: 32, HPD: [1, 32, 0] },
  { id: 2368, ruleset: 'pathfinder 2e', name: "Theletos", AC: 25, HP: 125, DEX: 18, HPD: [1, 18, 0] },
  { id: 2369, ruleset: 'pathfinder 2e', name: "Thessekka", AC: 35, HP: 255, DEX: 26, HPD: [1, 26, 0] },
  { id: 2370, ruleset: 'pathfinder 2e', name: "Thoqqua", AC: 17, HP: 45, DEX: 7, HPD: [1, 7, 0] },
  { id: 2371, ruleset: 'pathfinder 2e', name: "Thorn Guardian", AC: 39, HP: 330, DEX: 27, HPD: [1, 27, 0] },
  { id: 2372, ruleset: 'pathfinder 2e', name: "Thorn River Bandit", AC: 14, HP: 8, DEX: 2, HPD: [1, 2, 0] },
  { id: 2373, ruleset: 'pathfinder 2e', name: "Thrasfyr", AC: 40, HP: 350, DEX: 31, HPD: [1, 31, 0] },
  { id: 2374, ruleset: 'pathfinder 2e', name: "Three-Toed Sloth", AC: 14, HP: 10, DEX: 5, HPD: [1, 5, 0] },
  { id: 2375, ruleset: 'pathfinder 2e', name: "Thresholder Disciple", AC: 36, HP: 255, DEX: 26, HPD: [1, 26, 0] },
  { id: 2376, ruleset: 'pathfinder 2e', name: "Thresholder Hermeticist", AC: 39, HP: 290, DEX: 29, HPD: [1, 29, 0] },
  { id: 2377, ruleset: 'pathfinder 2e', name: "Thresholder Mystic", AC: 41, HP: 315, DEX: 31, HPD: [1, 31, 0] },
  { id: 2378, ruleset: 'pathfinder 2e', name: "Thulgant", AC: 42, HP: 305, DEX: 30, HPD: [1, 30, 0] },
  { id: 2379, ruleset: 'pathfinder 2e', name: "Thunderbird", AC: 31, HP: 200, DEX: 22, HPD: [1, 22, 0] },
  { id: 2380, ruleset: 'pathfinder 2e', name: "Thylacine", AC: 16, HP: 16, DEX: 4, HPD: [1, 4, 0] },
  { id: 2381, ruleset: 'pathfinder 2e', name: "Tick Swarm", AC: 28, HP: 130, DEX: 18, HPD: [1, 18, 0] },
  { id: 2382, ruleset: 'pathfinder 2e', name: "Tidal Master", AC: 28, HP: 155, DEX: 18, HPD: [1, 18, 0] },
  { id: 2383, ruleset: 'pathfinder 2e', name: "Tiddalik", AC: 22, HP: 155, DEX: 15, HPD: [1, 15, 0] },
  { id: 2384, ruleset: 'pathfinder 2e', name: "Tidehawk", AC: 32, HP: 213, DEX: 24, HPD: [1, 24, 0] },
  { id: 2385, ruleset: 'pathfinder 2e', name: "Tiefling Adept", AC: 17, HP: 29, DEX: 6, HPD: [1, 6, 0] },
  { id: 2386, ruleset: 'pathfinder 2e', name: "Tiger", AC: 21, HP: 60, DEX: 12, HPD: [1, 12, 0] },
  { id: 2387, ruleset: 'pathfinder 2e', name: "Tiger Lord", AC: 26, HP: 160, DEX: 18, HPD: [1, 18, 0] },
  { id: 2388, ruleset: 'pathfinder 2e', name: "Tikbalang", AC: 27, HP: 197, DEX: 16, HPD: [1, 16, 0] },
  { id: 2389, ruleset: 'pathfinder 2e', name: "Titan Centipede", AC: 28, HP: 155, DEX: 18, HPD: [1, 18, 0] },
  { id: 2390, ruleset: 'pathfinder 2e', name: "Tixitog", AC: 19, HP: 40, DEX: 9, HPD: [1, 9, 0] },
  { id: 2391, ruleset: 'pathfinder 2e', name: "Tollvych", AC: 33, HP: 200, DEX: 26, HPD: [1, 26, 0] },
  { id: 2392, ruleset: 'pathfinder 2e', name: "Tolokand", AC: 36, HP: 245, DEX: 27, HPD: [1, 27, 0] },
  { id: 2393, ruleset: 'pathfinder 2e', name: "Tomb Giant", AC: 32, HP: 255, DEX: 25, HPD: [1, 25, 0] },
  { id: 2394, ruleset: 'pathfinder 2e', name: "Tombstone Troll", AC: 13, HP: 25, DEX: 7, HPD: [1, 7, 0] },
  { id: 2395, ruleset: 'pathfinder 2e', name: "Tooth Fairy", AC: 15, HP: 8, DEX: 6, HPD: [1, 6, 0] },
  { id: 2396, ruleset: 'pathfinder 2e', name: "Tooth Fairy Swarm", AC: 18, HP: 28, DEX: 8, HPD: [1, 8, 0] },
  { id: 2397, ruleset: 'pathfinder 2e', name: "Tor Linnorm", AC: 47, HP: 440, DEX: 37, HPD: [1, 37, 0] },
  { id: 2398, ruleset: 'pathfinder 2e', name: "Tormented", AC: 35, HP: 250, DEX: 27, HPD: [1, 27, 0] },
  { id: 2399, ruleset: 'pathfinder 2e', name: "Toshigami", AC: 35, HP: 370, DEX: 30, HPD: [1, 30, 0] },
  { id: 2400, ruleset: 'pathfinder 2e', name: "Totenmaske", AC: 25, HP: 128, DEX: 15, HPD: [1, 15, 0] },
  { id: 2401, ruleset: 'pathfinder 2e', name: "Trailgaunt", AC: 19, HP: 45, DEX: 10, HPD: [1, 10, 0] },
  { id: 2402, ruleset: 'pathfinder 2e', name: "Trapdoor Ogre Spider", AC: 23, HP: 70, DEX: 13, HPD: [1, 13, 0] },
  { id: 2403, ruleset: 'pathfinder 2e', name: "Trapjaw Tangle", AC: 22, HP: 58, DEX: 11, HPD: [1, 11, 0] },
  { id: 2404, ruleset: 'pathfinder 2e', name: "Tree That Weeps", AC: 41, HP: 400, DEX: 30, HPD: [1, 30, 0] },
  { id: 2405, ruleset: 'pathfinder 2e', name: "Treerazer", AC: 54, HP: 550, DEX: 46, HPD: [1, 46, 0] },
  { id: 2406, ruleset: 'pathfinder 2e', name: "Triceratops", AC: 26, HP: 140, DEX: 16, HPD: [1, 16, 0] },
  { id: 2407, ruleset: 'pathfinder 2e', name: "Trilobite", AC: 15, HP: 7, DEX: 8, HPD: [1, 8, 0] },
  { id: 2408, ruleset: 'pathfinder 2e', name: "Trilobite Swarm", AC: 18, HP: 30, DEX: 9, HPD: [1, 9, 0] },
  { id: 2409, ruleset: 'pathfinder 2e', name: "Triton", AC: 18, HP: 30, DEX: 8, HPD: [1, 8, 0] },
  { id: 2410, ruleset: 'pathfinder 2e', name: "Triumph Siktempora", AC: 35, HP: 190, DEX: 24, HPD: [1, 24, 0] },
  { id: 2411, ruleset: 'pathfinder 2e', name: "Troll", AC: 20, HP: 115, DEX: 11, HPD: [1, 11, 0] },
  { id: 2412, ruleset: 'pathfinder 2e', name: "Troll Guard", AC: 35, HP: 340, DEX: 27, HPD: [1, 27, 0] },
  { id: 2413, ruleset: 'pathfinder 2e', name: "Troll King", AC: 28, HP: 220, DEX: 19, HPD: [1, 19, 0] },
  { id: 2414, ruleset: 'pathfinder 2e', name: "Trollhound", AC: 17, HP: 65, DEX: 6, HPD: [1, 6, 0] },
  { id: 2415, ruleset: 'pathfinder 2e', name: "Trumpet Archon", AC: 36, HP: 285, DEX: 26, HPD: [1, 26, 0] },
  { id: 2416, ruleset: 'pathfinder 2e', name: "Tulvak", AC: 31, HP: 220, DEX: 22, HPD: [1, 22, 0] },
  { id: 2417, ruleset: 'pathfinder 2e', name: "Tumblak", AC: 42, HP: 305, DEX: 33, HPD: [1, 33, 0] },
  { id: 2418, ruleset: 'pathfinder 2e', name: "Tupilaq", AC: 26, HP: 87, DEX: 16, HPD: [1, 16, 0] },
  { id: 2419, ruleset: 'pathfinder 2e', name: "Twigjack", AC: 19, HP: 50, DEX: 9, HPD: [1, 9, 0] },
  { id: 2420, ruleset: 'pathfinder 2e', name: "Two-Headed Troll", AC: 24, HP: 190, DEX: 18, HPD: [1, 18, 0] },
  { id: 2421, ruleset: 'pathfinder 2e', name: "Tylosaurus", AC: 27, HP: 137, DEX: 18, HPD: [1, 18, 0] },
  { id: 2422, ruleset: 'pathfinder 2e', name: "Tyrannosaurus", AC: 29, HP: 180, DEX: 19, HPD: [1, 19, 0] },
  { id: 2423, ruleset: 'pathfinder 2e', name: "Tyrannosaurus Imperator", AC: 35, HP: 260, DEX: 25, HPD: [1, 25, 0] },
  { id: 2424, ruleset: 'pathfinder 2e', name: "Tyrannosaurus Skeleton", AC: 27, HP: 140, DEX: 17, HPD: [1, 17, 0] },
  { id: 2425, ruleset: 'pathfinder 2e', name: "Tzitzimitl", AC: 43, HP: 390, DEX: 32, HPD: [1, 32, 0] },
  { id: 2426, ruleset: 'pathfinder 2e', name: "Ugvashi", AC: 19, HP: 40, DEX: 8, HPD: [1, 8, 0] },
  { id: 2427, ruleset: 'pathfinder 2e', name: "Ulgrem-Axaan", AC: 24, HP: 130, DEX: 18, HPD: [1, 18, 0] },
  { id: 2428, ruleset: 'pathfinder 2e', name: "Ulgrem-Lurann", AC: 18, HP: 55, DEX: 12, HPD: [1, 12, 0] },
  { id: 2429, ruleset: 'pathfinder 2e', name: "Ulistul", AC: 31, HP: 165, DEX: 22, HPD: [1, 22, 0] },
  { id: 2430, ruleset: 'pathfinder 2e', name: "Ulizmila's Cauldron", AC: 17, HP: 20, DEX: 4, HPD: [1, 4, 0] },
  { id: 2431, ruleset: 'pathfinder 2e', name: "Ulthadar", AC: 24, HP: 95, DEX: 20, HPD: [1, 20, 0] },
  { id: 2432, ruleset: 'pathfinder 2e', name: "Umasi", AC: 23, HP: 99, DEX: 12, HPD: [1, 12, 0] },
  { id: 2433, ruleset: 'pathfinder 2e', name: "Umbo", AC: 18, HP: 50, DEX: 8, HPD: [1, 8, 0] },
  { id: 2434, ruleset: 'pathfinder 2e', name: "Umbraex", AC: 45, HP: 450, DEX: 36, HPD: [1, 36, 0] },
  { id: 2435, ruleset: 'pathfinder 2e', name: "Umonlee", AC: 39, HP: 320, DEX: 25, HPD: [1, 25, 0] },
  { id: 2436, ruleset: 'pathfinder 2e', name: "Undine Hydromancer", AC: 16, HP: 15, DEX: 5, HPD: [1, 5, 0] },
  { id: 2437, ruleset: 'pathfinder 2e', name: "Unicorn", AC: 20, HP: 45, DEX: 13, HPD: [1, 13, 0] },
  { id: 2438, ruleset: 'pathfinder 2e', name: "Unrisen", AC: 28, HP: 220, DEX: 21, HPD: [1, 21, 0] },
  { id: 2439, ruleset: 'pathfinder 2e', name: "Unseen Servant", AC: 13, HP: 4, DEX: 0, HPD: [1, 0, 0] },
  { id: 2440, ruleset: 'pathfinder 2e', name: "Urbel", AC: 21, HP: 68, DEX: 8, HPD: [1, 8, 0] },
  { id: 2441, ruleset: 'pathfinder 2e', name: "Urdefhan Blood Mage", AC: 26, HP: 140, DEX: 15, HPD: [1, 15, 0] },
  { id: 2442, ruleset: 'pathfinder 2e', name: "Urdefhan Death Scout", AC: 24, HP: 92, DEX: 15, HPD: [1, 15, 0] },
  { id: 2443, ruleset: 'pathfinder 2e', name: "Urdefhan Dominator", AC: 35, HP: 250, DEX: 26, HPD: [1, 26, 0] },
  { id: 2444, ruleset: 'pathfinder 2e', name: "Urdefhan High Tormentor", AC: 30, HP: 195, DEX: 22, HPD: [1, 22, 0] },
  { id: 2445, ruleset: 'pathfinder 2e', name: "Urdefhan Hunter", AC: 34, HP: 190, DEX: 26, HPD: [1, 26, 0] },
  { id: 2446, ruleset: 'pathfinder 2e', name: "Urdefhan Lasher", AC: 24, HP: 120, DEX: 16, HPD: [1, 16, 0] },
  { id: 2447, ruleset: 'pathfinder 2e', name: "Urdefhan Tormentor", AC: 21, HP: 77, DEX: 13, HPD: [1, 13, 0] },
  { id: 2448, ruleset: 'pathfinder 2e', name: "Urdefhan Warrior", AC: 18, HP: 55, DEX: 9, HPD: [1, 9, 0] },
  { id: 2449, ruleset: 'pathfinder 2e', name: "Urglid (Grave Demon)", AC: 31, HP: 290, DEX: 20, HPD: [1, 20, 0] },
  { id: 2450, ruleset: 'pathfinder 2e', name: "Urveth", AC: 40, HP: 460, DEX: 32, HPD: [1, 32, 0] },
  { id: 2451, ruleset: 'pathfinder 2e', name: "Uthul", AC: 36, HP: 250, DEX: 20, HPD: [1, 20, 0] },
  { id: 2452, ruleset: 'pathfinder 2e', name: "Vaklish", AC: 33, HP: 185, DEX: 22, HPD: [1, 22, 0] },
  { id: 2453, ruleset: 'pathfinder 2e', name: "Valkyrie", AC: 33, HP: 215, DEX: 22, HPD: [1, 22, 0] },
  { id: 2454, ruleset: 'pathfinder 2e', name: "Vampire Bat Swarm", AC: 15, HP: 11, DEX: 10, HPD: [1, 10, 0] },
  { id: 2455, ruleset: 'pathfinder 2e', name: "Vampire Count", AC: 24, HP: 65, DEX: 17, HPD: [1, 17, 0] },
  { id: 2456, ruleset: 'pathfinder 2e', name: "Vampire Guardian", AC: 29, HP: 132, DEX: 21, HPD: [1, 21, 0] },
  { id: 2457, ruleset: 'pathfinder 2e', name: "Vampire Mastermind", AC: 27, HP: 115, DEX: 20, HPD: [1, 20, 0] },
  { id: 2458, ruleset: 'pathfinder 2e', name: "Vampire Spawn", AC: 22, HP: 40, DEX: 12, HPD: [1, 12, 0] },
  { id: 2459, ruleset: 'pathfinder 2e', name: "Vampire Squid", AC: 16, HP: 15, DEX: 7, HPD: [1, 7, 0] },
  { id: 2460, ruleset: 'pathfinder 2e', name: "Vampire Taviah", AC: 33, HP: 180, DEX: 23, HPD: [1, 23, 0] },
  { id: 2461, ruleset: 'pathfinder 2e', name: "Vampiric Mist", AC: 18, HP: 35, DEX: 9, HPD: [1, 9, 0] },
  { id: 2462, ruleset: 'pathfinder 2e', name: "Vanara Disciple", AC: 19, HP: 16, DEX: 6, HPD: [1, 6, 0] },
  { id: 2463, ruleset: 'pathfinder 2e', name: "Vanth", AC: 27, HP: 105, DEX: 15, HPD: [1, 15, 0] },
  { id: 2464, ruleset: 'pathfinder 2e', name: "Vanyver", AC: 34, HP: 295, DEX: 26, HPD: [1, 26, 0] },
  { id: 2465, ruleset: 'pathfinder 2e', name: "Vargouille", AC: 18, HP: 28, DEX: 8, HPD: [1, 8, 0] },
  { id: 2466, ruleset: 'pathfinder 2e', name: "Vaspercham", AC: 41, HP: 335, DEX: 30, HPD: [1, 30, 0] },
  { id: 2467, ruleset: 'pathfinder 2e', name: "Vaultbreaker Ooze", AC: 13, HP: 150, DEX: 10, HPD: [1, 10, 0] },
  { id: 2468, ruleset: 'pathfinder 2e', name: "Vavakia", AC: 42, HP: 350, DEX: 32, HPD: [1, 32, 0] },
  { id: 2469, ruleset: 'pathfinder 2e', name: "Vazgorlu", AC: 45, HP: 380, DEX: 33, HPD: [1, 33, 0] },
  { id: 2470, ruleset: 'pathfinder 2e', name: "Veiled Master", AC: 34, HP: 270, DEX: 25, HPD: [1, 25, 0] },
  { id: 2471, ruleset: 'pathfinder 2e', name: "Velociraptor", AC: 16, HP: 20, DEX: 6, HPD: [1, 6, 0] },
  { id: 2472, ruleset: 'pathfinder 2e', name: "Venexus", AC: 28, HP: 170, DEX: 20, HPD: [1, 20, 0] },
  { id: 2473, ruleset: 'pathfinder 2e', name: "Venexus's Wyrmlings", AC: 22, HP: 90, DEX: 12, HPD: [1, 12, 0] },
  { id: 2474, ruleset: 'pathfinder 2e', name: "Veranallia", AC: 45, HP: 475, DEX: 38, HPD: [1, 38, 0] },
  { id: 2475, ruleset: 'pathfinder 2e', name: "Verdurous Ooze", AC: 12, HP: 157, DEX: 8, HPD: [1, 8, 0] },
  { id: 2476, ruleset: 'pathfinder 2e', name: "Vermlek", AC: 16, HP: 55, DEX: 8, HPD: [1, 8, 0] },
  { id: 2477, ruleset: 'pathfinder 2e', name: "Veshumirix", AC: 39, HP: 300, DEX: 28, HPD: [1, 28, 0] },
  { id: 2478, ruleset: 'pathfinder 2e', name: "Vetalarana Emergent", AC: 26, HP: 100, DEX: 14, HPD: [1, 14, 0] },
  { id: 2479, ruleset: 'pathfinder 2e', name: "Vetalarana Manipulator", AC: 28, HP: 140, DEX: 22, HPD: [1, 22, 0] },
  { id: 2480, ruleset: 'pathfinder 2e', name: "Vexgit", AC: 16, HP: 24, DEX: 6, HPD: [1, 6, 0] },
  { id: 2481, ruleset: 'pathfinder 2e', name: "Vicious Army Ant Swarm", AC: 32, HP: 155, DEX: 23, HPD: [1, 23, 0] },
  { id: 2482, ruleset: 'pathfinder 2e', name: "Viewslog", AC: 28, HP: 161, DEX: 18, HPD: [1, 18, 0] },
  { id: 2483, ruleset: 'pathfinder 2e', name: "Vilderavn", AC: 40, HP: 300, DEX: 28, HPD: [1, 28, 0] },
  { id: 2484, ruleset: 'pathfinder 2e', name: "Vilderavn Herald", AC: 44, HP: 375, DEX: 35, HPD: [1, 35, 0] },
  { id: 2485, ruleset: 'pathfinder 2e', name: "Vine Lasher", AC: 16, HP: 15, DEX: 4, HPD: [1, 4, 0] },
  { id: 2486, ruleset: 'pathfinder 2e', name: "Vine Leshy", AC: 15, HP: 13, DEX: 6, HPD: [1, 6, 0] },
  { id: 2487, ruleset: 'pathfinder 2e', name: "Violet", AC: 16, HP: 24, DEX: 6, HPD: [1, 6, 0] },
  { id: 2488, ruleset: 'pathfinder 2e', name: "Violet Fungus", AC: 17, HP: 60, DEX: 8, HPD: [1, 8, 0] },
  { id: 2489, ruleset: 'pathfinder 2e', name: "Viper", AC: 15, HP: 8, DEX: 5, HPD: [1, 5, 0] },
  { id: 2490, ruleset: 'pathfinder 2e', name: "Viper Swarm", AC: 18, HP: 50, DEX: 12, HPD: [1, 12, 0] },
  { id: 2491, ruleset: 'pathfinder 2e', name: "Viper Vine", AC: 33, HP: 270, DEX: 22, HPD: [1, 22, 0] },
  { id: 2492, ruleset: 'pathfinder 2e', name: "Virthad", AC: 26, HP: 135, DEX: 18, HPD: [1, 18, 0] },
  { id: 2493, ruleset: 'pathfinder 2e', name: "Virulak Necromancer", AC: 24, HP: 115, DEX: 13, HPD: [1, 13, 0] },
  { id: 2494, ruleset: 'pathfinder 2e', name: "Virulak Villager", AC: 19, HP: 45, DEX: 8, HPD: [1, 8, 0] },
  { id: 2495, ruleset: 'pathfinder 2e', name: "Vishkanya Infiltrator", AC: 19, HP: 45, DEX: 10, HPD: [1, 10, 0] },
  { id: 2496, ruleset: 'pathfinder 2e', name: "Viskithrel", AC: 37, HP: 275, DEX: 27, HPD: [1, 27, 0] },
  { id: 2497, ruleset: 'pathfinder 2e', name: "Vitalia", AC: 39, HP: 425, DEX: 30, HPD: [1, 30, 0] },
  { id: 2498, ruleset: 'pathfinder 2e', name: "Void Zombie", AC: 13, HP: 26, DEX: 3, HPD: [1, 3, 0] },
  { id: 2499, ruleset: 'pathfinder 2e', name: "Voidglutton", AC: 30, HP: 90, DEX: 18, HPD: [1, 18, 0] },
  { id: 2500, ruleset: 'pathfinder 2e', name: "Voidworm", AC: 17, HP: 16, DEX: 4, HPD: [1, 4, 0] },
  { id: 2501, ruleset: 'pathfinder 2e', name: "Vordakai", AC: 33, HP: 200, DEX: 23, HPD: [1, 23, 0] },
  { id: 2502, ruleset: 'pathfinder 2e', name: "Vrock", AC: 28, HP: 185, DEX: 18, HPD: [1, 18, 0] },
  { id: 2503, ruleset: 'pathfinder 2e', name: "Vrolikai", AC: 44, HP: 375, DEX: 33, HPD: [1, 33, 0] },
  { id: 2504, ruleset: 'pathfinder 2e', name: "Vrykolakas Ancient", AC: 34, HP: 250, DEX: 23, HPD: [1, 23, 0] },
  { id: 2505, ruleset: 'pathfinder 2e', name: "Vrykolakas Master", AC: 30, HP: 190, DEX: 19, HPD: [1, 19, 0] },
  { id: 2506, ruleset: 'pathfinder 2e', name: "Vrykolakas Spawn", AC: 24, HP: 99, DEX: 14, HPD: [1, 14, 0] },
  { id: 2507, ruleset: 'pathfinder 2e', name: "Vulpinal", AC: 24, HP: 105, DEX: 15, HPD: [1, 15, 0] },
  { id: 2508, ruleset: 'pathfinder 2e', name: "Vulture Rat", AC: 15, HP: 8, DEX: 5, HPD: [1, 5, 0] },
  { id: 2509, ruleset: 'pathfinder 2e', name: "Waldgeist", AC: 26, HP: 100, DEX: 18, HPD: [1, 18, 0] },
  { id: 2510, ruleset: 'pathfinder 2e', name: "War Champion Kharostan", AC: 38, HP: 280, DEX: 29, HPD: [1, 29, 0] },
  { id: 2511, ruleset: 'pathfinder 2e', name: "War Horse", AC: 17, HP: 36, DEX: 6, HPD: [1, 6, 0] },
  { id: 2512, ruleset: 'pathfinder 2e', name: "War Pony", AC: 16, HP: 20, DEX: 5, HPD: [1, 5, 0] },
  { id: 2513, ruleset: 'pathfinder 2e', name: "War Sauropelta", AC: 34, HP: 220, DEX: 23, HPD: [1, 23, 0] },
  { id: 2514, ruleset: 'pathfinder 2e', name: "Warg", AC: 17, HP: 36, DEX: 8, HPD: [1, 8, 0] },
  { id: 2515, ruleset: 'pathfinder 2e', name: "Warsworn", AC: 37, HP: 350, DEX: 27, HPD: [1, 27, 0] },
  { id: 2516, ruleset: 'pathfinder 2e', name: "Wasp Swarm", AC: 18, HP: 45, DEX: 10, HPD: [1, 10, 0] },
  { id: 2517, ruleset: 'pathfinder 2e', name: "Watchtower Poltergeist", AC: 36, HP: 190, DEX: 24, HPD: [1, 24, 0] },
  { id: 2518, ruleset: 'pathfinder 2e', name: "Watchtower Shadow", AC: 36, HP: 190, DEX: 25, HPD: [1, 25, 0] },
  { id: 2519, ruleset: 'pathfinder 2e', name: "Watchtower Wraith", AC: 39, HP: 285, DEX: 28, HPD: [1, 28, 0] },
  { id: 2520, ruleset: 'pathfinder 2e', name: "Water Mephit", AC: 16, HP: 20, DEX: 3, HPD: [1, 3, 0] },
  { id: 2521, ruleset: 'pathfinder 2e', name: "Water Orm", AC: 30, HP: 170, DEX: 21, HPD: [1, 21, 0] },
  { id: 2522, ruleset: 'pathfinder 2e', name: "Water Wisp", AC: 14, HP: 20, DEX: 6, HPD: [1, 6, 0] },
  { id: 2523, ruleset: 'pathfinder 2e', name: "Water Yai", AC: 40, HP: 295, DEX: 32, HPD: [1, 32, 0] },
  { id: 2524, ruleset: 'pathfinder 2e', name: "Wayang Whisperblade", AC: 16, HP: 19, DEX: 9, HPD: [1, 9, 0] },
  { id: 2525, ruleset: 'pathfinder 2e', name: "Weasel", AC: 15, HP: 7, DEX: 4, HPD: [1, 4, 0] },
  { id: 2526, ruleset: 'pathfinder 2e', name: "Web Lurker", AC: 19, HP: 45, DEX: 10, HPD: [1, 10, 0] },
  { id: 2527, ruleset: 'pathfinder 2e', name: "Weeping Jack", AC: 33, HP: 210, DEX: 23, HPD: [1, 23, 0] },
  { id: 2528, ruleset: 'pathfinder 2e', name: "Wemmuth", AC: 37, HP: 335, DEX: 25, HPD: [1, 25, 0] },
  { id: 2529, ruleset: 'pathfinder 2e', name: "Wendigo", AC: 40, HP: 315, DEX: 32, HPD: [1, 32, 0] },
  { id: 2530, ruleset: 'pathfinder 2e', name: "Wereant Disciple", AC: 39, HP: 305, DEX: 28, HPD: [1, 28, 0] },
  { id: 2531, ruleset: 'pathfinder 2e', name: "Wereant Poisoner", AC: 40, HP: 350, DEX: 29, HPD: [1, 29, 0] },
  { id: 2532, ruleset: 'pathfinder 2e', name: "Wereant Sentinel", AC: 43, HP: 400, DEX: 32, HPD: [1, 32, 0] },
  { id: 2533, ruleset: 'pathfinder 2e', name: "Werebat", AC: 18, HP: 35, DEX: 9, HPD: [1, 9, 0] },
  { id: 2534, ruleset: 'pathfinder 2e', name: "Werebear", AC: 23, HP: 75, DEX: 11, HPD: [1, 11, 0] },
  { id: 2535, ruleset: 'pathfinder 2e', name: "Wereboar", AC: 18, HP: 45, DEX: 8, HPD: [1, 8, 0] },
  { id: 2536, ruleset: 'pathfinder 2e', name: "Werecrocodile", AC: 16, HP: 55, DEX: 7, HPD: [1, 7, 0] },
  { id: 2537, ruleset: 'pathfinder 2e', name: "Werendegar", AC: 47, HP: 374, DEX: 35, HPD: [1, 35, 0] },
  { id: 2538, ruleset: 'pathfinder 2e', name: "Wererat", AC: 19, HP: 45, DEX: 8, HPD: [1, 8, 0] },
  { id: 2539, ruleset: 'pathfinder 2e', name: "Weretiger", AC: 21, HP: 75, DEX: 11, HPD: [1, 11, 0] },
  { id: 2540, ruleset: 'pathfinder 2e', name: "Werewolf", AC: 19, HP: 63, DEX: 9, HPD: [1, 9, 0] },
  { id: 2541, ruleset: 'pathfinder 2e', name: "Weykoward", AC: 29, HP: 145, DEX: 18, HPD: [1, 18, 0] },
  { id: 2542, ruleset: 'pathfinder 2e', name: "Whalesteed", AC: 15, HP: 16, DEX: 7, HPD: [1, 7, 0] },
  { id: 2543, ruleset: 'pathfinder 2e', name: "Wheel Archon", AC: 41, HP: 230, DEX: 28, HPD: [1, 28, 0] },
  { id: 2544, ruleset: 'pathfinder 2e', name: "Whimwyrm", AC: 42, HP: 280, DEX: 31, HPD: [1, 31, 0] },
  { id: 2545, ruleset: 'pathfinder 2e', name: "Wight", AC: 18, HP: 50, DEX: 10, HPD: [1, 10, 0] },
  { id: 2546, ruleset: 'pathfinder 2e', name: "Wight Commander", AC: 32, HP: 220, DEX: 22, HPD: [1, 22, 0] },
  { id: 2547, ruleset: 'pathfinder 2e', name: "Wight Cultist", AC: 32, HP: 235, DEX: 22, HPD: [1, 22, 0] },
  { id: 2548, ruleset: 'pathfinder 2e', name: "Wihsaak", AC: 24, HP: 105, DEX: 14, HPD: [1, 14, 0] },
  { id: 2549, ruleset: 'pathfinder 2e', name: "Wild Hunt Archer", AC: 39, HP: 340, DEX: 30, HPD: [1, 30, 0] },
  { id: 2550, ruleset: 'pathfinder 2e', name: "Wild Hunt Horse", AC: 37, HP: 320, DEX: 29, HPD: [1, 29, 0] },
  { id: 2551, ruleset: 'pathfinder 2e', name: "Wild Hunt Hound", AC: 36, HP: 300, DEX: 29, HPD: [1, 29, 0] },
  { id: 2552, ruleset: 'pathfinder 2e', name: "Wild Hunt Monarch", AC: 46, HP: 450, DEX: 35, HPD: [1, 35, 0] },
  { id: 2553, ruleset: 'pathfinder 2e', name: "Wild Hunt Scout", AC: 42, HP: 380, DEX: 34, HPD: [1, 34, 0] },
  { id: 2554, ruleset: 'pathfinder 2e', name: "Will-o’-Wisp", AC: 27, HP: 50, DEX: 16, HPD: [1, 16, 0] },
  { id: 2555, ruleset: 'pathfinder 2e', name: "Windchaser", AC: 27, HP: 138, DEX: 16, HPD: [1, 16, 0] },
  { id: 2556, ruleset: 'pathfinder 2e', name: "Winged Owlbear", AC: 41, HP: 380, DEX: 32, HPD: [1, 32, 0] },
  { id: 2557, ruleset: 'pathfinder 2e', name: "Winter Hag", AC: 24, HP: 145, DEX: 16, HPD: [1, 16, 0] },
  { id: 2558, ruleset: 'pathfinder 2e', name: "Winter Wolf", AC: 23, HP: 70, DEX: 14, HPD: [1, 14, 0] },
  { id: 2559, ruleset: 'pathfinder 2e', name: "Witchfire", AC: 28, HP: 125, DEX: 18, HPD: [1, 18, 0] },
  { id: 2560, ruleset: 'pathfinder 2e', name: "Witchwyrd", AC: 22, HP: 110, DEX: 12, HPD: [1, 12, 0] },
  { id: 2561, ruleset: 'pathfinder 2e', name: "Withered", AC: 22, HP: 80, DEX: 11, HPD: [1, 11, 0] },
  { id: 2562, ruleset: 'pathfinder 2e', name: "Wizard Sponge", AC: 20, HP: 65, DEX: 10, HPD: [1, 10, 0] },
  { id: 2563, ruleset: 'pathfinder 2e', name: "Wolf", AC: 15, HP: 24, DEX: 7, HPD: [1, 7, 0] },
  { id: 2564, ruleset: 'pathfinder 2e', name: "Wolf Skeleton", AC: 16, HP: 12, DEX: 8, HPD: [1, 8, 0] },
  { id: 2565, ruleset: 'pathfinder 2e', name: "Wolliped", AC: 19, HP: 55, DEX: 9, HPD: [1, 9, 0] },
  { id: 2566, ruleset: 'pathfinder 2e', name: "Wolverine", AC: 18, HP: 34, DEX: 8, HPD: [1, 8, 0] },
  { id: 2567, ruleset: 'pathfinder 2e', name: "Wood Giant", AC: 24, HP: 120, DEX: 15, HPD: [1, 15, 0] },
  { id: 2568, ruleset: 'pathfinder 2e', name: "Wood Golem", AC: 23, HP: 95, DEX: 12, HPD: [1, 12, 0] },
  { id: 2569, ruleset: 'pathfinder 2e', name: "Woolly Rhinoceros", AC: 25, HP: 100, DEX: 11, HPD: [1, 11, 0] },
  { id: 2570, ruleset: 'pathfinder 2e', name: "Worm That Walks Cultist", AC: 36, HP: 200, DEX: 25, HPD: [1, 25, 0] },
  { id: 2571, ruleset: 'pathfinder 2e', name: "Wraith", AC: 24, HP: 80, DEX: 14, HPD: [1, 14, 0] },
  { id: 2572, ruleset: 'pathfinder 2e', name: "Wyrmwraith", AC: 39, HP: 280, DEX: 29, HPD: [1, 29, 0] },
  { id: 2573, ruleset: 'pathfinder 2e', name: "Wyrwood Sneak", AC: 17, HP: 16, DEX: 6, HPD: [1, 6, 0] },
  { id: 2574, ruleset: 'pathfinder 2e', name: "Wyvern", AC: 24, HP: 95, DEX: 13, HPD: [1, 13, 0] },
  { id: 2575, ruleset: 'pathfinder 2e', name: "Xae", AC: 28, HP: 150, DEX: 19, HPD: [1, 19, 0] },
  { id: 2576, ruleset: 'pathfinder 2e', name: "Xevalorg", AC: 34, HP: 235, DEX: 26, HPD: [1, 26, 0] },
  { id: 2577, ruleset: 'pathfinder 2e', name: "Xill", AC: 24, HP: 100, DEX: 15, HPD: [1, 15, 0] },
  { id: 2578, ruleset: 'pathfinder 2e', name: "Xilvirek", AC: 34, HP: 215, DEX: 23, HPD: [1, 23, 0] },
  { id: 2579, ruleset: 'pathfinder 2e', name: "Ximtal", AC: 39, HP: 380, DEX: 30, HPD: [1, 30, 0] },
  { id: 2580, ruleset: 'pathfinder 2e', name: "Xiuh Couatl", AC: 33, HP: 220, DEX: 23, HPD: [1, 23, 0] },
  { id: 2581, ruleset: 'pathfinder 2e', name: "Xorn", AC: 25, HP: 115, DEX: 15, HPD: [1, 15, 0] },
  { id: 2582, ruleset: 'pathfinder 2e', name: "Xotani", AC: 48, HP: 385, DEX: 39, HPD: [1, 39, 0] },
  { id: 2583, ruleset: 'pathfinder 2e', name: "Xotanispawn", AC: 40, HP: 340, DEX: 29, HPD: [1, 29, 0] },
  { id: 2584, ruleset: 'pathfinder 2e', name: "Xulgath Bilebearer", AC: 18, HP: 30, DEX: 6, HPD: [1, 6, 0] },
  { id: 2585, ruleset: 'pathfinder 2e', name: "Xulgath Bomber", AC: 25, HP: 115, DEX: 15, HPD: [1, 15, 0] },
  { id: 2586, ruleset: 'pathfinder 2e', name: "Xulgath Deepmouth", AC: 33, HP: 215, DEX: 26, HPD: [1, 26, 0] },
  { id: 2587, ruleset: 'pathfinder 2e', name: "Xulgath Demon-Caller", AC: 22, HP: 80, DEX: 11, HPD: [1, 11, 0] },
  { id: 2588, ruleset: 'pathfinder 2e', name: "Xulgath Gutrager", AC: 28, HP: 190, DEX: 19, HPD: [1, 19, 0] },
  { id: 2589, ruleset: 'pathfinder 2e', name: "Xulgath Hardscale", AC: 32, HP: 215, DEX: 21, HPD: [1, 21, 0] },
  { id: 2590, ruleset: 'pathfinder 2e', name: "Xulgath Herd-Tender", AC: 27, HP: 135, DEX: 17, HPD: [1, 17, 0] },
  { id: 2591, ruleset: 'pathfinder 2e', name: "Xulgath Leader", AC: 20, HP: 44, DEX: 9, HPD: [1, 9, 0] },
  { id: 2592, ruleset: 'pathfinder 2e', name: "Xulgath Mage", AC: 23, HP: 115, DEX: 12, HPD: [1, 12, 0] },
  { id: 2593, ruleset: 'pathfinder 2e', name: "Xulgath Roughrider", AC: 31, HP: 195, DEX: 21, HPD: [1, 21, 0] },
  { id: 2594, ruleset: 'pathfinder 2e', name: "Xulgath Skirmisher", AC: 24, HP: 95, DEX: 15, HPD: [1, 15, 0] },
  { id: 2595, ruleset: 'pathfinder 2e', name: "Xulgath Skulker", AC: 19, HP: 28, DEX: 7, HPD: [1, 7, 0] },
  { id: 2596, ruleset: 'pathfinder 2e', name: "Xulgath Spinesnapper", AC: 21, HP: 95, DEX: 11, HPD: [1, 11, 0] },
  { id: 2597, ruleset: 'pathfinder 2e', name: "Xulgath Stoneliege", AC: 26, HP: 135, DEX: 14, HPD: [1, 14, 0] },
  { id: 2598, ruleset: 'pathfinder 2e', name: "Xulgath Thoughtmaw", AC: 36, HP: 280, DEX: 30, HPD: [1, 30, 0] },
  { id: 2599, ruleset: 'pathfinder 2e', name: "Xulgath Warrior", AC: 16, HP: 21, DEX: 6, HPD: [1, 6, 0] },
  { id: 2600, ruleset: 'pathfinder 2e', name: "Yaganty", AC: 29, HP: 200, DEX: 20, HPD: [1, 20, 0] },
  { id: 2601, ruleset: 'pathfinder 2e', name: "Yamaraj", AC: 45, HP: 375, DEX: 37, HPD: [1, 37, 0] },
  { id: 2602, ruleset: 'pathfinder 2e', name: "Yeast Ooze", AC: 12, HP: 60, DEX: 6, HPD: [1, 6, 0] },
  { id: 2603, ruleset: 'pathfinder 2e', name: "Yellow Musk Brute", AC: 15, HP: 45, DEX: 4, HPD: [1, 4, 0] },
  { id: 2604, ruleset: 'pathfinder 2e', name: "Yellow Musk Creeper", AC: 18, HP: 34, DEX: 4, HPD: [1, 4, 0] },
  { id: 2605, ruleset: 'pathfinder 2e', name: "Yellow Musk Thrall", AC: 14, HP: 12, DEX: 0, HPD: [1, 0, 0] },
  { id: 2606, ruleset: 'pathfinder 2e', name: "Yeth Hound", AC: 18, HP: 55, DEX: 9, HPD: [1, 9, 0] },
  { id: 2607, ruleset: 'pathfinder 2e', name: "Yeti", AC: 21, HP: 115, DEX: 15, HPD: [1, 15, 0] },
  { id: 2608, ruleset: 'pathfinder 2e', name: "Yithian", AC: 27, HP: 112, DEX: 21, HPD: [1, 21, 0] },
  { id: 2609, ruleset: 'pathfinder 2e', name: "Young Black Dragon", AC: 25, HP: 125, DEX: 15, HPD: [1, 15, 0] },
  { id: 2610, ruleset: 'pathfinder 2e', name: "Young Blue Dragon", AC: 28, HP: 170, DEX: 18, HPD: [1, 18, 0] },
  { id: 2611, ruleset: 'pathfinder 2e', name: "Young Brass Dragon", AC: 25, HP: 125, DEX: 15, HPD: [1, 15, 0] },
  { id: 2612, ruleset: 'pathfinder 2e', name: "Young Brine Dragon", AC: 27, HP: 142, DEX: 16, HPD: [1, 16, 0] },
  { id: 2613, ruleset: 'pathfinder 2e', name: "Young Bronze Dragon", AC: 28, HP: 170, DEX: 18, HPD: [1, 18, 0] },
  { id: 2614, ruleset: 'pathfinder 2e', name: "Young Cloud Dragon", AC: 30, HP: 175, DEX: 22, HPD: [1, 22, 0] },
  { id: 2615, ruleset: 'pathfinder 2e', name: "Young Copper Dragon", AC: 27, HP: 150, DEX: 16, HPD: [1, 16, 0] },
  { id: 2616, ruleset: 'pathfinder 2e', name: "Young Crystal Dragon", AC: 27, HP: 105, DEX: 15, HPD: [1, 15, 0] },
  { id: 2617, ruleset: 'pathfinder 2e', name: "Young Forest Dragon", AC: 30, HP: 195, DEX: 18, HPD: [1, 18, 0] },
  { id: 2618, ruleset: 'pathfinder 2e', name: "Young Gold Dragon", AC: 32, HP: 230, DEX: 21, HPD: [1, 21, 0] },
  { id: 2619, ruleset: 'pathfinder 2e', name: "Young Green Dragon", AC: 28, HP: 135, DEX: 16, HPD: [1, 16, 0] },
  { id: 2620, ruleset: 'pathfinder 2e', name: "Young Linnorm", AC: 25, HP: 115, DEX: 15, HPD: [1, 15, 0] },
  { id: 2621, ruleset: 'pathfinder 2e', name: "Young Magma Dragon", AC: 28, HP: 175, DEX: 18, HPD: [1, 18, 0] },
  { id: 2622, ruleset: 'pathfinder 2e', name: "Young Red Dragon", AC: 30, HP: 210, DEX: 20, HPD: [1, 20, 0] },
  { id: 2623, ruleset: 'pathfinder 2e', name: "Young Sea Dragon", AC: 27, HP: 140, DEX: 15, HPD: [1, 15, 0] },
  { id: 2624, ruleset: 'pathfinder 2e', name: "Young Silver Dragon", AC: 31, HP: 200, DEX: 20, HPD: [1, 20, 0] },
  { id: 2625, ruleset: 'pathfinder 2e', name: "Young Sky Dragon", AC: 28, HP: 155, DEX: 19, HPD: [1, 19, 0] },
  { id: 2626, ruleset: 'pathfinder 2e', name: "Young Sovereign Dragon", AC: 31, HP: 195, DEX: 22, HPD: [1, 22, 0] },
  { id: 2627, ruleset: 'pathfinder 2e', name: "Young Umbral Dragon", AC: 31, HP: 195, DEX: 22, HPD: [1, 22, 0] },
  { id: 2628, ruleset: 'pathfinder 2e', name: "Young Underworld Dragon", AC: 25, HP: 115, DEX: 13, HPD: [1, 13, 0] },
  { id: 2629, ruleset: 'pathfinder 2e', name: "Young White Dragon", AC: 23, HP: 115, DEX: 13, HPD: [1, 13, 0] },
  { id: 2630, ruleset: 'pathfinder 2e', name: "Yshula", AC: 26, HP: 109, DEX: 17, HPD: [1, 17, 0] },
  { id: 2631, ruleset: 'pathfinder 2e', name: "Yulthruk", AC: 30, HP: 195, DEX: 21, HPD: [1, 21, 0] },
  { id: 2632, ruleset: 'pathfinder 2e', name: "Yurgak", AC: 36, HP: 240, DEX: 29, HPD: [1, 29, 0] },
  { id: 2633, ruleset: 'pathfinder 2e', name: "Yzobu", AC: 16, HP: 25, DEX: 6, HPD: [1, 6, 0] },
  { id: 2634, ruleset: 'pathfinder 2e', name: "Zaramuun", AC: 39, HP: 291, DEX: 30, HPD: [1, 30, 0] },
  { id: 2635, ruleset: 'pathfinder 2e', name: "Zashathal Head-Taker", AC: 36, HP: 250, DEX: 27, HPD: [1, 27, 0] },
  { id: 2636, ruleset: 'pathfinder 2e', name: "Zealborn", AC: 33, HP: 210, DEX: 23, HPD: [1, 23, 0] },
  { id: 2637, ruleset: 'pathfinder 2e', name: "Zebub", AC: 20, HP: 30, DEX: 12, HPD: [1, 12, 0] },
  { id: 2638, ruleset: 'pathfinder 2e', name: "Zelekhut", AC: 28, HP: 160, DEX: 21, HPD: [1, 21, 0] },
  { id: 2639, ruleset: 'pathfinder 2e', name: "Zephyr Hawk", AC: 21, HP: 36, DEX: 7, HPD: [1, 7, 0] },
  { id: 2640, ruleset: 'pathfinder 2e', name: "Zetogeki", AC: 22, HP: 90, DEX: 15, HPD: [1, 15, 0] },
  { id: 2641, ruleset: 'pathfinder 2e', name: "Zinba", AC: 28, HP: 220, DEX: 20, HPD: [1, 20, 0] },
  { id: 2642, ruleset: 'pathfinder 2e', name: "Zinogyvaz", AC: 39, HP: 240, DEX: 28, HPD: [1, 28, 0] },
  { id: 2643, ruleset: 'pathfinder 2e', name: "Zombie Brute", AC: 15, HP: 70, DEX: 4, HPD: [1, 4, 0] },
  { id: 2644, ruleset: 'pathfinder 2e', name: "Zombie Chuul", AC: 22, HP: 200, DEX: 11, HPD: [1, 11, 0] },
  { id: 2645, ruleset: 'pathfinder 2e', name: "Zombie Dragon", AC: 27, HP: 210, DEX: 16, HPD: [1, 16, 0] },
  { id: 2646, ruleset: 'pathfinder 2e', name: "Zombie Horse", AC: 13, HP: 50, DEX: 3, HPD: [1, 3, 0] },
  { id: 2647, ruleset: 'pathfinder 2e', name: "Zombie Hound", AC: 13, HP: 18, DEX: 1, HPD: [1, 1, 0] },
  { id: 2648, ruleset: 'pathfinder 2e', name: "Zombie Hulk", AC: 21, HP: 160, DEX: 8, HPD: [1, 8, 0] },
  { id: 2649, ruleset: 'pathfinder 2e', name: "Zombie Lord", AC: 20, HP: 80, DEX: 13, HPD: [1, 13, 0] },
  { id: 2650, ruleset: 'pathfinder 2e', name: "Zombie Mammoth", AC: 27, HP: 290, DEX: 17, HPD: [1, 17, 0] },
  { id: 2651, ruleset: 'pathfinder 2e', name: "Zombie Mammoths", AC: 18, HP: 200, DEX: 13, HPD: [1, 13, 0] },
  { id: 2652, ruleset: 'pathfinder 2e', name: "Zombie Owlbear", AC: 16, HP: 85, DEX: 8, HPD: [1, 8, 0] },
  { id: 2653, ruleset: 'pathfinder 2e', name: "Zombie Shambler", AC: 12, HP: 20, DEX: 0, HPD: [1, 0, 0] },
  { id: 2654, ruleset: 'pathfinder 2e', name: "Zombie Snake", AC: 13, HP: 35, DEX: 2, HPD: [1, 2, 0] },
  { id: 2655, ruleset: 'pathfinder 2e', name: "Zomok", AC: 39, HP: 310, DEX: 28, HPD: [1, 28, 0] },
  { id: 2656, ruleset: 'pathfinder 2e', name: "Zrukbat", AC: 18, HP: 30, DEX: 7, HPD: [1, 7, 0] },
  { id: 2657, ruleset: 'pathfinder 2e', name: "Zuipnyrn", AC: 20, HP: 35, DEX: 9, HPD: [1, 9, 0] },
  { id: 2658, ruleset: 'pathfinder 2e', name: "Zuishin", AC: 30, HP: 200, DEX: 21, HPD: [1, 21, 0] },
  { id: 2659, ruleset: 'pathfinder 2e', name: "Zyss Serpentfolk", AC: 18, HP: 25, DEX: 8, HPD: [1, 8, 0] },
];