/*global CANNON, THREE, $t, requestAnimationFrame, teal */


var randomStorage = [], useRandomStorage = true;

function createShape(vertices, faces, radius) {
    var cv = [], cf = [];
    for (var i = 0; i < vertices.length; ++i) {
        var v = vertices[i];
        var l = radius / Math.sqrt(v[0] * v[0] + v[1] * v[1] + v[2] * v[2]);
        cv.push(new CANNON.Vec3(v[0] * l, v[1] * l, v[2] * l));
    }
    for (var i = 0; i < faces.length; ++i) {
        cf.push(faces[i].slice(0, faces[i].length - 1));
    }
    return new CANNON.ConvexPolyhedron(cv, cf);
}

function makeGeom(vertices, faces, radius, tab, af) {
    var geom = new THREE.Geometry();
    for (var i = 0; i < vertices.length; ++i) {
        var vertex = (new THREE.Vector3()).fromArray(vertices[i]).normalize().multiplyScalar(radius);
        vertex.index = geom.vertices.push(vertex) - 1;
    }
    for (var i = 0; i < faces.length; ++i) {
        var ii = faces[i], fl = ii.length - 1;
        var aa = Math.PI * 2 / fl;
        for (var j = 0; j < fl - 2; ++j) {
            geom.faces.push(new THREE.Face3(ii[0], ii[j + 1], ii[j + 2], [geom.vertices[ii[0]],
                                                                          geom.vertices[ii[j + 1]], geom.vertices[ii[j + 2]]], 0, ii[fl] + 1));
            geom.faceVertexUvs[0].push([
                new THREE.Vector2((Math.cos(af) + 1 + tab) / 2 / (1 + tab),
                                  (Math.sin(af) + 1 + tab) / 2 / (1 + tab)),
                new THREE.Vector2((Math.cos(aa * (j + 1) + af) + 1 + tab) / 2 / (1 + tab),
                                  (Math.sin(aa * (j + 1) + af) + 1 + tab) / 2 / (1 + tab)),
                new THREE.Vector2((Math.cos(aa * (j + 2) + af) + 1 + tab) / 2 / (1 + tab),
                                  (Math.sin(aa * (j + 2) + af) + 1 + tab) / 2 / (1 + tab))]);
        }
    }
    //geom.computeFaceNormals();
    //geom.computeVertexNormals();
    geom.boundingSphere = new THREE.Sphere(new THREE.Vector3(), radius);
    return geom;
}

function createDieGeom(vertices, faces, radius, tab, af) {
    var geom = makeGeom(vertices, faces, radius, tab, af);
    geom.cannonShape = createShape(vertices, faces, radius);

    return geom;
}

var d20Labels = [' ', '0', '1', '2', '3', '4', '5', '6', '7', '8',
                 '9', '10', '11', '12', '13', '14', '15', '16', '17',
                 '18', '19', '20'];
var d100Labels = [' ', '00', '10', '20', '30', '40', '50',
                  '60', '70', '80', '90'];

function createDieMaterials(type, labelColor, dieColor, font) {
    if (type === 'd4') {
        return createD4Materials(scale / 2, scale * 2,
                                 labelColor,
                                 dieColor, font);
    } else {
        return _createDieMaterials(dieInfo[type].labels,
                                   scale * dieInfo[type].marginFactor,
                                   labelColor,
                                   dieColor, font);
    }
}

function _createDieMaterials(faceLabels, margin, labelColor, dieColor, font) {
        function createTextTexture(text, labelColor, dieColor, font) {
            if (text === undefined) {
                return null;
            }
            var canvas = document.createElement("canvas");
            var context = canvas.getContext("2d");
            var size = scale / 2;
            canvas.width = size + margin;
            canvas.height = size + margin;
            context.font = size + font;
            context.fillStyle = dieColor;
            context.fillRect(0, 0, canvas.width, canvas.height);
            context.textAlign = "center";
            context.textBaseline = "middle";
            context.fillStyle = labelColor;
            context.fillText(text, canvas.width / 2, canvas.height / 2);
            if (text === '6' || text === '9') {
                context.fillText('  .', canvas.width / 2, canvas.height / 2);
            }
            var texture = new THREE.Texture(canvas);
            texture.needsUpdate = true;
            return texture;
        }
        var materials = [];
        for (var i = 0; i < faceLabels.length; ++i) {
            materials.push(
                new THREE.MeshPhongMaterial(
                    copyto(materialOptions,
                              {map: createTextTexture(faceLabels[i],
                                                      labelColor,
                                                      dieColor, font)})
                )
            );
        }
        return materials;
    }

function createD4Materials(size, margin, labelColor, dieColor, font) {
    function createD4Text(text, labelColor, dieColor, font) {
        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");
        canvas.width = size + margin;
        canvas.height = size + margin;
        context.font = size + font;
        context.fillStyle = dieColor;
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillStyle = labelColor;
        context.translate(0, size / 10);
        for (var i in text) {
            context.fillText(text[i], canvas.width / 2,
                             canvas.height / 2 - size - margin / 10);
            context.translate(canvas.width / 2, canvas.height / 2);
            context.rotate(Math.PI * 2 / 3);
            context.translate(-canvas.width / 2, -canvas.height / 2);
        }
        var texture = new THREE.Texture(canvas);
        texture.needsUpdate = true;
        return texture;
    }
    var materials = [];
    var labels = [[], [0, 0, 0], [2, 4, 3], [1, 3, 4], [2, 1, 4], [1, 2, 3]];
    for (var i = 0; i < labels.length; ++i) {
        materials.push(new THREE.MeshPhongMaterial(copyto(materialOptions,
                                                             { map: createD4Text(labels[i], labelColor, dieColor, font) })));
    }
    return materials;
}

function createDieGeometry(type, radius) {
    var geometryCreators = {
        d4: createD4Geometry,
        d6: createD6Geometry,
        d8: createD8Geometry,
        d10: createD10Geometry,
        d12: createD12Geometry,
        d20: createD20Geometry,
        d100: createD10Geometry
    };

    return geometryCreators[type](radius);
}

function createD4Geometry(radius) {
    var vertices = [[1, 1, 1], [-1, -1, 1], [-1, 1, -1], [1, -1, -1]];
    var faces = [[1, 0, 2, 1], [0, 1, 3, 2], [0, 3, 2, 3], [1, 2, 3, 4]];
    return createDieGeom(vertices, faces, radius, -0.1, Math.PI * 7 / 6);
}

function createD6Geometry(radius) {
    var vertices = [[-1, -1, -1], [1, -1, -1], [1, 1, -1], [-1, 1, -1],
                    [-1, -1, 1], [1, -1, 1], [1, 1, 1], [-1, 1, 1]];
    var faces = [[0, 3, 2, 1, 1], [1, 2, 6, 5, 2], [0, 1, 5, 4, 3],
                 [3, 7, 6, 2, 4], [0, 4, 7, 3, 5], [4, 5, 6, 7, 6]];
    var returnv = createDieGeom(vertices, faces, radius, 0.1, Math.PI / 4);    
    return returnv;
}

function createD8Geometry(radius) {
    var vertices = [[1, 0, 0], [-1, 0, 0], [0, 1, 0], [0, -1, 0], [0, 0, 1], [0, 0, -1]];
    var faces = [[0, 2, 4, 1], [0, 4, 3, 2], [0, 3, 5, 3], [0, 5, 2, 4], [1, 3, 4, 5],
                 [1, 4, 2, 6], [1, 2, 5, 7], [1, 5, 3, 8]];
    return createDieGeom(vertices, faces, radius, 0, -Math.PI / 4 / 2);
}

function createD10Geometry(radius) {
    var a = Math.PI * 2 / 10,
        h = 0.105,
        v = -1;
    var vertices = [];
    for (var i = 0, b = 0; i < 10; ++i, b += a) {
        vertices.push([Math.cos(b), Math.sin(b), h * (i % 2 ? 1 : -1)]);
    }
    vertices.push([0, 0, -1]); vertices.push([0, 0, 1]);
    var faces = [[5, 7, 11, 0], [4, 2, 10, 1], [1, 3, 11, 2], [0, 8, 10, 3], [7, 9, 11, 4],
                 [8, 6, 10, 5], [9, 1, 11, 6], [2, 0, 10, 7], [3, 5, 11, 8], [6, 4, 10, 9],
                 [1, 0, 2, v], [1, 2, 3, v], [3, 2, 4, v], [3, 4, 5, v], [5, 4, 6, v],
                 [5, 6, 7, v], [7, 6, 8, v], [7, 8, 9, v], [9, 8, 0, v], [9, 0, 1, v]];
    return createDieGeom(vertices, faces, radius, 0, Math.PI * 6 / 5);
}

function createD12Geometry(radius) {
    var p = (1 + Math.sqrt(5)) / 2, q = 1 / p;
    var vertices = [[0, q, p], [0, q, -p], [0, -q, p], [0, -q, -p], [p, 0, q],
                    [p, 0, -q], [-p, 0, q], [-p, 0, -q], [q, p, 0], [q, -p, 0], [-q, p, 0],
                    [-q, -p, 0], [1, 1, 1], [1, 1, -1], [1, -1, 1], [1, -1, -1], [-1, 1, 1],
                    [-1, 1, -1], [-1, -1, 1], [-1, -1, -1]];
    var faces = [[2, 14, 4, 12, 0, 1], [15, 9, 11, 19, 3, 2], [16, 10, 17, 7, 6, 3], [6, 7, 19, 11, 18, 4],
                 [6, 18, 2, 0, 16, 5], [18, 11, 9, 14, 2, 6], [1, 17, 10, 8, 13, 7], [1, 13, 5, 15, 3, 8],
                 [13, 8, 12, 4, 5, 9], [5, 4, 14, 9, 15, 10], [0, 12, 8, 10, 16, 11], [3, 19, 7, 17, 1, 12]];
    return createDieGeom(vertices, faces, radius, 0.2, -Math.PI / 4 / 2);
}

function createD20Geometry(radius) {
    var t = (1 + Math.sqrt(5)) / 2;
    var vertices = [[-1, t, 0], [1, t, 0 ], [-1, -t, 0], [1, -t, 0],
                    [0, -1, t], [0, 1, t], [0, -1, -t], [0, 1, -t],
                    [t, 0, -1], [t, 0, 1], [-t, 0, -1], [-t, 0, 1]];
    var faces = [[0, 11, 5, 1], [0, 5, 1, 2], [0, 1, 7, 3], [0, 7, 10, 4], [0, 10, 11, 5],
                 [1, 5, 9, 6], [5, 11, 4, 7], [11, 10, 2, 8], [10, 7, 6, 9], [7, 1, 8, 10],
                 [3, 9, 4, 11], [3, 4, 2, 12], [3, 2, 6, 13], [3, 6, 8, 14], [3, 8, 9, 15],
                 [4, 9, 5, 16], [2, 4, 11, 17], [6, 2, 10, 18], [8, 6, 7, 19], [9, 8, 1, 20]];
    return createDieGeom(vertices, faces, radius, -0.2, -Math.PI / 4 / 2);
}

var scale = 50;
var materialOptions = {
    specular: '#111111',
    color: '#ffffff',
    emissive: '#000000',
    shininess: 80,  
    flatShading: true
};
var defaultLabelColor = '#aaaaaa';
var defaultDieColor = '#202020';
var knownDieTypes = ['d4', 'd6', 'd8', 'd10', 'd12', 'd20', 'd100'];

var dieInfo = {
    d4: {mass: 300, inertia: 5, radiusFactor: 1.2, marginFactor: null},
    d6: {mass: 300, inertia: 13, radiusFactor: 0.9, marginFactor: 1,
         labels: d20Labels},
    d8: {mass: 340, inertia: 10, radiusFactor: 1, marginFactor: 1,
         labels: d20Labels},
    d10: {mass: 340, inertia: 10, radiusFactor: 0.9, marginFactor: 1,
          labels: d20Labels},
    d12: {mass: 340, inertia: 10, radiusFactor: 0.9, marginFactor: 1,
          labels: d20Labels},
    d20: {mass: 340, inertia: 10, radiusFactor: 1, marginFactor: 1,
          labels: d20Labels},
    d100: {mass: 340, inertia: 10, radiusFactor: 0.9, marginFactor: 1.5,
           labels: d100Labels}
};
var dieMaterialCache = {}, dieGeometryCache = {};

function dieSignature(type, dieColor, labelColor) {
    return dieInfo[type].labels + dieColor + labelColor;
}

function createDie(type, labelColor, dieColor, font) {
    labelColor = labelColor || defaultLabelColor;
    dieColor = dieColor || defaultDieColor;

    if (!dieGeometryCache[type]) {
        dieGeometryCache[type] = createDieGeometry(
            type,
            1 * dieInfo[type].radiusFactor
        );
    }

    var dieSig = dieSignature(type, dieColor, labelColor);    

    var die = new THREE.Mesh(dieGeometryCache[type], createDieMaterials(type, labelColor, dieColor, font));
    die.castShadow = true;
    die.userData = {type: type,
                    labelColor: labelColor,
                    dieColor: dieColor};


    return die;
}

var copyto = function(obj, res) {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }
    if (obj instanceof Array) {
        for (var i = obj.length - 1; i >= 0; --i) {
            res[i] = $t.copy(obj[i]);
        }
    }
    else {
        for (var i in obj) {
            if (obj.hasOwnProperty(i)) {
                res[i] = copy(obj[i]);
            }
        }
    }
    return res;
};

var copy = function(obj) {
    if (!obj) {
        return obj;
    }
    return copyto(obj, new obj.constructor());
};