/* --- TO DO ----

add colour/material/font controls
save/load gui json output in db tied to google id
make text less fuzzy

*/

//dice tray
var diceTray = document.querySelector('#dice-tray');
var diceTrayToggle = document.querySelector('#dice-tray .dice-tray-toggle');
var diceTrayOverlay = document.querySelector('#dice-tray-overlay');
var diceDisplay = document.querySelector('#dice-display');
var diceThrowButton = document.querySelector('#dice-throw-button');
var diceClearButton = document.querySelector('#dice-clear-button');
var diceDisplayText = document.querySelector('#dice-display-text');
var guiContainer = document.querySelector('#gui-container');

var world, mass, timeStep=1/60,
camera, scene, renderer, geometry, material, mesh, controls, gui, options;

var cubeMap = {};

var trayOffset = {w:0, h:0};

var fov = 20;
var camera_z = 50;

var boxes = [];
var boxIndex = -1;

var controlsEnabled = true;
var rolled = false;

var walls = [];
var windowUnit = 37;

var ui = document.getElementById('ui');

var planeYmin;

var frustum = new THREE.Frustum();

initThree();
initCannon();
animate();

var diceTrayOpen = false;

diceTrayToggle.addEventListener('click', toggleDiceTray, false);

function toggleDiceTray()
{
    diceTrayOpen = !diceTrayOpen;
    diceTray.classList.toggle('open-dice-tray');

    if (diceTrayOpen)
    {
        container.style.pointerEvents = 'all';
        container.querySelector('canvas').style.pointerEvents = 'all';
    }
    else
    {
        closeOverlay();
        container.style.pointerEvents = 'none';
        container.querySelector('canvas').style.pointerEvents = 'none';
    }

    diceTrayOverlay.style.display = 'block';
    diceDisplay.style.display = 'block';
    if (guiContainer != null) { guiContainer.style.display = 'block'; }
    setTimeout(function() {
        diceTrayOverlay.style.opacity = '1';
        diceDisplay.style.opacity = '1';
        if (guiContainer != null) { guiContainer.style.opacity = '1'; }
    }, 0);
}

diceTrayOverlay.addEventListener('click', closeOverlay, false);

var closingOverlay = false;

function closeOverlay()
{
    if (!closingOverlay)
    {
        closingOverlay = true;
        diceTray.classList.remove('open-dice-tray');

        container.style.pointerEvents = 'none';
        container.querySelector('canvas').style.pointerEvents = 'none';

        diceTrayOverlay.style.opacity = '0';
        diceDisplay.style.opacity = '0';
        if (guiContainer != null) { guiContainer.style.opacity = '0'; }
        setTimeout(function() {
            diceTrayOverlay.style.display = 'none';
            diceDisplay.style.display = 'none';
            if (guiContainer != null) { guiContainer.style.display = 'none'; }
            closingOverlay = false;
        }, 310);
    }
}

var diceCup = {
    4: 0,
    6: 0,
    8: 0,
    10: 0,
    12: 0,
    20: 0,
    100: 0
};

var diceButtons = document.querySelectorAll('#dice-tray .dice-button');

for (var i=0;i<diceButtons.length;i++)
{
    var diceNumber = parseInt(diceButtons[i].getAttribute('number'));   

    (function() {
        var diceNum = diceNumber;
        diceButtons[i].addEventListener('click', function() {
            diceCup[diceNum]++;

            var diceCupKeys = Object.keys(diceCup);
            var diceCupValues = Object.values(diceCup);

            var diceString = '';

            for (var d=0;d<diceCupValues.length;d++)
            {
                if (diceCupValues[d])
                {
                    diceString += diceCupValues[d] + 'd' + diceCupKeys[d] + ' + ';
                }
            }

            diceString = diceString.substring(0, diceString.length - 3);
            diceDisplayText.innerHTML = diceString;
            
        }, false);
    }());   
}

diceThrowButton.addEventListener('click', diceThrow, false);

var ddToggle = document.getElementById('dd-toggle');

function diceThrow()
{
    //check 3d
    if (ddToggle.checked)
    {
        rollDice3d();
    }
    else
    {
        resetRoll();
        rollDice2d();
    }
}

function rollDice2d()
{   
    var total = 0;

    var diceCupKeys = Object.keys(diceCup);
    var diceCupValues = Object.values(diceCup);

    var resultsString = '';

    for (var d=0;d<diceCupValues.length;d++)
    {
        for (var m=0;m<diceCupValues[d];m++)
        {
            if (diceCupKeys[d] == 100)
            {
                var roll = Math.floor(randomNumber(0, 9)) * 10;
            }
            else
            {
                var roll = Math.floor(randomNumber(1, diceCupKeys[d]));
            }

            total += roll;

            resultsString += (roll + ' + ');
        }
    }

    resultsString = resultsString.substring(0, resultsString.length - 3);
    resultsString += ' = ' + total;
    diceDisplayText.innerHTML = resultsString;
}

diceClearButton.addEventListener('click', diceClear, false);

function diceClear(clearText = true)
{
    diceCup = {
        4: 0,
        6: 0,
        8: 0,
        10: 0,
        12: 0,
        20: 0,
        100: 0
    };

    if (clearText)
    {
        diceDisplayText.innerHTML = '';
    }

    resetRoll();
}

function initCannon()
{
    world = new CANNON.World();
    world.gravity.set(0,-50,0);
    world.broadphase = new CANNON.NaiveBroadphase();
    world.solver.iterations = 10;

    // Ground plane
    var plane = new CANNON.Plane();
    var groundBody = new CANNON.Body({ mass: 0 });
    groundBody.addShape(plane);
    groundBody.quaternion.setFromAxisAngle(new CANNON.Vec3(1,0,0),-Math.PI/2);
    world.addBody(groundBody);

    for (var i=0;i<walls.length;i++)
    {
        var wall = new CANNON.Body({
            type: 1,
        });

        walls[i].body = wall;

        world.addBody(walls[i].body);
    }

    updateWalls();
}

function updateWalls()
{
    for (var i=0;i<walls.length;i++)
    {        
        var shape = new CANNON.Box(new CANNON.Vec3(walls[i].mesh.geometry.parameters.width, walls[i].mesh.geometry.parameters.height + 100, walls[i].mesh.geometry.parameters.depth));

        walls[i].body.shapes = [];
        walls[i].body.addShape(shape);
        walls[i].body.position.set(walls[i].mesh.position.x, walls[i].mesh.position.y, walls[i].mesh.position.z);
        walls[i].body.quaternion.set(walls[i].mesh.quaternion.x, walls[i].mesh.quaternion.y, walls[i].mesh.quaternion.z, walls[i].mesh.quaternion.w);
    }
}

function addDiceBody(shape)
{
    //make dice
    var body = new CANNON.Body({
        mass: 1
    });

    boxes[boxIndex].body = body;

    body.addShape(shape);

    var velocity = options.velocity;
    velocity -= options.velocity/2;

    var directionH = randomNumber(0,2) - 1;
    var directionV = randomNumber(0,2) - 1;    

    body.velocity.set(velocity*directionH, 0, velocity*directionV);

    var p = (boxes.length/2) - boxIndex;

    body.position = new CANNON.Vec3(p, 10, p);    
    body.quaternion.setFromEuler(randomNumber(0,360), randomNumber(0,360), randomNumber(0,360));
    body.angularVelocity.set(randomNumber(4,10),randomNumber(4,10),randomNumber(4,10));
    body.angularDamping = 0.5;
    world.addBody(body);
}

function randomNumber(min, max)
{
    return (Math.random() * max) + min;  
}    

var gui;

function initThree()
{
    container = document.createElement( 'div' );
    container.id = 'canvasContainer';
    document.body.appendChild( container );

    scene = new THREE.Scene();

    //camera
    camera = new THREE.PerspectiveCamera( fov, window.innerWidth / window.innerHeight, 0.5, 10000 );
    cameraReset();
    scene.add( camera );
    
    // lights
    var light, materials;
    scene.add( new THREE.AmbientLight( 0xffffff, 1 ) );
    light = new THREE.DirectionalLight( 0xffffff, 0.5 );
    var d = 20;
    light.position.set( d, d, d );
    light.castShadow = true;
    light.shadow.camera.left = -d;
    light.shadow.camera.right = d;
    light.shadow.camera.top = d;
    light.shadow.camera.bottom = -d;
    light.shadow.camera.far = 3*d;
    light.shadow.camera.near = d;    
    scene.add( light );

    //cubemap
    // var path = '/template/textures/sky/';
    // var format = '.jpg';
    // var urls = [
    //     path + 'px' + format, path + 'nx' + format,
    //     path + 'py' + format, path + 'ny' + format,
    //     path + 'pz' + format, path + 'nz' + format
    // ];
    // cubeMap.reflectionCube = new THREE.CubeTextureLoader().load( urls );
    // cubeMap.reflectionCube.format = THREE.RGBFormat;
    // cubeMap.refractionCube = new THREE.CubeTextureLoader().load( urls );
    // cubeMap.refractionCube.mapping = THREE.CubeRefractionMapping;
    // cubeMap.refractionCube.format = THREE.RGBFormat;
    //scene.background = reflectionCube;

    scene.add( new THREE.AmbientLight( 0x222222 ) );
    var pointLight1 = new THREE.PointLight( 0xffffff, 0.5 );
    pointLight1.position.set( 50, 10, 0 );
    pointLight1.castShadow = false;
    scene.add( pointLight1 );
    var pointLight2 = new THREE.PointLight( 0xffffff, 0.5 );
    pointLight2.position.set( - 50, 0, 0 );
    scene.add( pointLight2 );
    var pointLight3 = new THREE.PointLight( 0xffffff, 0.5 );
    pointLight3.position.set( 0, - 10, - 50 );
    scene.add( pointLight3 );
    var pointLight4 = new THREE.PointLight( 0xffffff, 0.5 );
    pointLight4.position.set( 0, 0, 50 );
    scene.add( pointLight4 );


    // floor
    var floorGeo = new THREE.PlaneGeometry( 90, 120, 1, 1 );
    var floorMaterial = new THREE.MeshLambertMaterial( { color: 0x073027 } );
    floorMaterial = new THREE.ShadowMaterial();
    floorMaterial.opacity = 0.2;
    var floorMesh = new THREE.Mesh( floorGeo, floorMaterial );
    floorMesh.castShadow = true;
    floorMesh.receiveShadow = true;
    floorMesh.rotateX( - Math.PI / 2 );
    scene.add( floorMesh );

    //var wallMaterial = new THREE.MeshLambertMaterial( { color: 0x073027 } );
    var wallMaterial = new THREE.ShadowMaterial();

    for (var i=0;i<4;i++)
    {
        var mesh = new THREE.Mesh( new THREE.BoxGeometry(0,0,0), wallMaterial );
        mesh.quaternion.setFromAxisAngle(new THREE.Vector3(0,i<2,0),Math.PI/2);
        scene.add( mesh );
        walls.push({mesh: mesh, body:''});
    }

    updateWallMeshes();
    
    //gui
    options = {
        diceColor: "#458e73",
        textColor: "#f7f1e9",
        velocity: 50,
        font: 'Arial'
    };

    // $.ajax({
    //     method: "POST",
    //     url: "/template/dice-model.php"
    // })
    // .done(function(data) {
    //     if (data != "")
    //     {            
    //         localStorage.clear();
    //         gui = new dat.GUI({ autoPlace: false, load: JSON.parse(data) });
    //         gui.useLocalStorage = true;
    //     }
    //     else
    //     {
    //         gui = new dat.GUI({ autoPlace: false, load: JSON });
    //         gui.useLocalStorage = true;
    //     }

    //     //dat.GUI.toggleHide();
    //     gui.domElement.id = 'gui';

    //     if (guiContainer != null)
    //     {
    //         guiContainer.appendChild(gui.domElement);
    //     }

    //     gui.addColor(options, 'diceColor');
    //     gui.addColor(options, 'textColor');
    //     gui.add(options, 'velocity', 0, 100)
    //     gui.add(options, 'font', [ 'Arial', 'Calibri', 'Times New Roman', 'Georgia', 'Cambria', 'Comic Sans MS', 'Impact', 'Papyrus' ] );

    //     gui.remember(options);
    //     gui.preset = gui.__preset_select.value;

    //     var saveButton = gui.domElement.querySelector('.save-row .save');
    //     console.log(saveButton);

    //     if (saveButton != null)
    //     {
    //         saveButton.addEventListener('click', saveDice);
    //     }
    // })
    // .fail(function(data) {
        
    // });

    //render
    renderer = new THREE.WebGLRenderer( { antialias: true, alpha: true } );
    renderer.setClearColor( 0x000000, 0 ); // the default
    renderer.setSize( window.innerWidth, window.innerHeight );            
    container.appendChild( renderer.domElement );
    renderer.gammaInput = true;
    renderer.gammaOutput = true;
    renderer.shadowMap.enabled = true;

    if (controlsEnabled)
    {
        controls = new THREE.OrbitControls( camera, renderer.domElement );
    }

    window.addEventListener( 'resize', onWindowResize, false );            
}

function saveDice()
{
    console.log('save');
    var data = gui.getSaveObject();

    // $.ajax({
    //     method: "POST",
    //     url: "/template/dice-model.php",
    //     data: {
    //         data: data
    //     }
    // })
    // .done(function(data) {
    //     gui.open(data);
        
    // })
    // .fail(function(data) {
        
    // });
}

function cameraReset()
{
    camera.lookAt(new THREE.Vector3(0,0,0));
    camera.position.set(5, camera_z, 0);
    camera.quaternion.setFromAxisAngle(new THREE.Vector3(0,0,0),Math.PI/2);
}

function updateWallMeshes()
{
    //make frustum check size of screen
    frustum.setFromMatrix(new THREE.Matrix4().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse)); 

    var trayDims = [0,0];

    for (var i=0;i<2;i++)
    {
        var point = new THREE.Vector3(0,0,0);

        while(frustum.containsPoint(point))     //increase tray size until walls are at edge of frustum
        {
            trayDims[i]++;
            point = new THREE.Vector3(i>0?trayDims[i]:0,0,i>0?0:trayDims[i]);
        }

        trayDims[i] *= 2;
        trayDims[i]--;
    }

    var geometryW = new THREE.BoxGeometry( trayDims[0], 6, 1 );
    var geometryH = new THREE.BoxGeometry( trayDims[1], 6, 1 );

    for (var i=0;i<walls.length;i++)
    {
        var geometry = i>1 ? geometryH : geometryW;

        switch (i)
        {
            case 0:
                var trayX = trayDims[1]/2;
                var trayZ = 0;
                break;
            case 1:
                var trayX = -trayDims[1]/2;
                var trayZ = 0;
                break;
            case 2:
                var trayX = 0;
                var trayZ = trayDims[0]/2;
                break;
            case 3:
                var trayX = 0
                var trayZ = -trayDims[0]/2;
                break;
        }

        walls[i].mesh.geometry = geometry;
        walls[i].mesh.position.set(trayX, geometry.parameters.height/2, trayZ);
    }
}

function addDiceMesh(sides)
{
    //box
    var dice = createDie('d'+sides, options.textColor, options.diceColor, 'PT '+options.font);
    dice.position.set(0,10,0);

    for (var i=0;i<dice.material.length;i++)
    {
        //dice.material[i].envMap = cubeMap.reflectionCube;
        //dice.material[i].envMap = cubeMap.refractionCube;
        //dice.material[i].combine = THREE.MixOperation;
        dice.material[i].reflectivity = .4;
        dice.material[i].refractionRatio = 0.95;
    }

    boxes.push({
        mesh: dice,
        box: ''
    });
    boxIndex++;

    dice.quaternion.setFromAxisAngle(new THREE.Vector3(1,0,0),-Math.PI/2);
    scene.add(dice);

    addDiceBody(dice.geometry.cannonShape);
}

var resultTimer;

function rollDice3d()
{
    cameraReset();

    if (rolled)
    {        
        resetRoll();
    }

    rolled = true;
    
    var diceCupKeys = Object.keys(diceCup);
    var diceCupValues = Object.values(diceCup);

    var resultsString = '';

    for (var d=0;d<diceCupValues.length;d++)
    {
        for (var m=0;m<diceCupValues[d];m++)
        {
            addDiceMesh(diceCupKeys[d]);
        }
    }

    clearTimeout(resultTimer);
    waitForDice();
}

var waitTimer;

function waitForDice()
{
    clearTimeout(waitTimer);
    waitTimer = setTimeout(function() {
        var result = getResult();

        if (result === false)
        {
            waitForDice();
        }
        else
        {
            var resultString = '';

            for (var i=0;i<result.length;i++)
            {
                if (i==result.length-1)     //total
                {
                    resultString = resultString.substr(0, resultString.length - 3);
                    resultString += ' = ' + result[i];
                }
                else
                {
                    resultString += result[i] + ' + ';
                }
            }

            diceDisplayText.innerHTML = resultString;
        }
    }, 250);
}

function getResult()
{
    var results = [];
    var total = 0;

    for (var i=0;i<boxes.length;i++)
    {        
        var type = boxes[i].mesh.userData.type;

        var minVelocity = 0.2;

        var checks = [
            boxes[i].body.velocity.x > minVelocity,
            boxes[i].body.velocity.y > minVelocity,
            boxes[i].body.velocity.z > minVelocity,
            boxes[i].body.velocity.x < -minVelocity,
            boxes[i].body.velocity.y < -minVelocity,
            boxes[i].body.velocity.z < -minVelocity
        ];

        for (var c=0;c<checks.length;c++)
        {
            if (checks[c])
            {
                return false;
            }
        }

        if (type == 'd4')
        {
            var origin = new THREE.Vector3(boxes[i].mesh.position.x, -1, boxes[i].mesh.position.z);        
            var direction = new THREE.Vector3(0, 1, 0).normalize();
        }
        else
        {
            var origin = new THREE.Vector3(boxes[i].mesh.position.x, 10, boxes[i].mesh.position.z);        
            var direction = new THREE.Vector3(0, -1, 0).normalize();        
        }

        var raycaster = new THREE.Raycaster(origin, direction);
        var intersects = raycaster.intersectObjects([boxes[i].mesh]);
        var result = intersects[0].face.materialIndex-1;

        if (type == 'd10' && result == 0)
        {
            result = 10;
        }

        if (type == 'd100')
        {
            result *= 10;
        }

        total += result;
        results.push(result);
    }

    results.push(total);

    return results
}

function resetRoll()
{
    rolled = false;

    for (var i=0;i<boxes.length;i++)
    {
        scene.remove(boxes[i].mesh);
        world.remove(boxes[i].body);
    }

    boxes = [];
    boxIndex = -1;
}

var cameraRatio;

function onWindowResize()
{
    //cameraRatio = window.innerWidth > window.innerHeight ? 16/9 : 9/16;
    cameraRatio = window.innerWidth/window.innerHeight;

    if ((window.innerWidth / window.innerHeight) < cameraRatio)
    {
        var rWidth = window.innerWidth;
        var rHeight = window.innerWidth / cameraRatio;
    }
    else
    {
        var rWidth = window.innerHeight * cameraRatio;
        var rHeight = window.innerHeight;
    }

    camera.aspect = cameraRatio;
    camera.updateProjectionMatrix();

    updateWallMeshes();
    updateWalls();

    renderer.setSize(rWidth, rHeight);
}

onWindowResize();

function animate()
{
    requestAnimationFrame( animate );

    if (controlsEnabled)
    {
        controls.update();
    }

    updatePhysics();
    render();
}

function updatePhysics()
{
    // Step the physics world
    world.step(timeStep);

    // Copy coordinates from Cannon.js to Three.js
    for (var i=0;i<boxes.length;i++)
    {
        boxes[i].mesh.position.copy(boxes[i].body.position);
        boxes[i].mesh.quaternion.copy(boxes[i].body.quaternion);
    }
}

function render()
{
    renderer.render( scene, camera );
}
